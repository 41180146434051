<template>
  <div>
    <div class="section-wrapper">
      <v-container>
        <v-toolbar class="mt-3" flat color="white">
          <h4 class="ma-0 mr-3">Download</h4>

          <v-spacer></v-spacer>
          <v-text-field
            class="ml-9"
            block
            dense
            style="width: 700px"
            v-model="search"
            outlined
            @input="initialize()"
            hide-details=""
            placeholder="Search..."
          ></v-text-field>
        </v-toolbar>
      </v-container>

      <Attachment />
    </div>
  </div>
</template>

<script>
import Attachment from "./Logs/Attachment.vue";

export default {
  components: {
    Attachment,
  },

  data: () => ({
    search: "",
    options: {},
  }),

  methods: {
    initialize() {
      this.$store.dispatch("admin_log_reader/GetAttachmentlist", {
        options: this.options,
        page: this.options.page,
        search: this.search,
      });
    },
  },
};
</script>

<style>
</style>