<template>
  <div class="en-settings">
    <p v-show="false">{{ openId }}</p>
    <!-- {{participants.map(e=>e.email)}} -->
    <!-- Do not remove -->
    <!-- {{ participants.length }} -->
    <!-- {{ participant }} -->
    <!-- {{participants.map(e=>e.email)}} -->
    <p v-show="false">{{ unhideMail }}</p>
    <v-container fluid>
      <!-- <v-btn v-if="true" @click="deleteAllChannels">DELETE ALL CHATS</v-btn> -->
      <v-row>
        <v-col
          v-show="showList"
          class="pr-0 en-chat-side-card"
          md="3"
          sm="5"
          cols="12"
        >
          <v-card class="en-chat-cards pt-0">
            <div class="sticky-bar pt-3">
              <v-text-field
                hide-details
                v-model="searchText"
                placeholder="Search Chats.."
                solo-inverted
                flat
              ></v-text-field>

              <v-overflow-btn
                class="mt-3"
                :items="user_types"
                v-model="type"
                label="Select User Type"
                hide-details=""
              ></v-overflow-btn>

              <v-switch
                v-if="hiddenChats"
                v-model="hidden"
                @change="switchChange()"
                label="Hidden chats"
              ></v-switch>
            </div>
            <v-list nav>
              <v-list-item-group
                v-model="participant"
                mandatory
                color="primary"
              >
                <v-sheet @click="clicked = true">
                  <SideUser
                    @chatHidden="hideChat"
                    @chatUnhidden="unhideChat"
                    :hidden="hidden"
                    v-for="(item, i) in participants"
                    :lm="item.lastMessage"
                    :key="i"
                    :participant="item"
                    :showBadge="i !== participant"
                  />
                </v-sheet>
              </v-list-item-group>
            </v-list>

            <div class="text-center">
              <p v-if="participants.length == 0">No Chats found</p>
            </div>
          </v-card>
        </v-col>

        <!-- Chat Area  -->
        <message-area
          @messageSent="messageSent"
          v-show="!showList || isPc"
          v-if="
            participant !== -1 && client !== null && participants[participant]
          "
          :participant="participants[participant]"
          :key="participant"
          @unhideChat="unhideChat"
          @drawer-click="drawerToggle"
        >
        </message-area>

        <!-- For Loading -->
        <v-col v-else md="9" sm="7" cols="12">
          <v-card class="en-chat-cards">
            <div
              class="d-flex justify-center align-center"
              style="width: 100%; height: 100%"
            >
              <h3 v-if="participants.length === 0">No chats available</h3>

              <v-progress-circular
                v-else
                size="40"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <!-- FIlter -->
    <v-navigation-drawer v-model="drawer" fixed>
      <div class="sticky-bar pt-3 px-3">
        <div>
          <v-btn @click.stop="drawer = !drawer" depressed block>
            <v-icon left>mdi-chevron-left</v-icon>Back</v-btn
          >
        </div>

        <v-text-field
          hide-details
          v-model="searchText"
          placeholder="Search Chats.."
          solo-inverted
          flat
          class="mt-2"
        ></v-text-field>

        <v-overflow-btn
          class="mt-3"
          :items="user_types"
          v-model="type"
          label="Select User Type"
          hide-details=""
        ></v-overflow-btn>
      </div>
      <v-list class="en-filters" nav dense>
        <v-list-item-group v-model="participant" mandatory color="primary">
          <div class="en-mobile-sidebar" @click="clicked = true">
            <div
              v-for="(item, i) in participants"
              :key="i"
              @click.stop="drawer = !drawer"
            >
              <SideUser
                @chatHidden="hideChat"
                @chatUnhidden="unhideChat"
                :hidden="hidden"
                :participant="item"
                :lm="item.lastMessage"
              />
            </div>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import SideUser from "./SidebarUsers";
import Bubble from "./Bubble";
import MessageArea from "./MessageArea.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  components: {
    SideUser,
    Bubble,
    MessageArea,
  },
  computed: {
    showList() {
      if (this.isPc) return true;
      return this.participant === 0 && !this.clicked;
    },
    isPc() {
      return !this.$vuetify.breakpoint.xsOnly;
    },
    ...mapState({
      client: (state) => state.chat.client,
      user: (state) => state.currentUser.user,
      chatUsers: (state) => state.chat.user,
      id: (state) => state.chat.openId,
    }),

    unhideMail() {
      this.unhideChat(this.$store.state.chat.unhideMail);
      return this.$store.state.chat.unhideMail;
    },

    users() {
      return this.chatUsers.filter(
        (r) => !(this.hiddenChats ?? []).map((e) => e?.email).includes(r?.email)
      );
    },
    openId: {
      get() {
        if (this.id === 0) return this.id;
        console.log("callingopen id" + this.id);
        // this.participant = this.participants.findIndex((r) => r.id === this.id);
        return this.id;
      },

      set(val) {
        this.$store.dispatch("chat/setOpenId", val);
      },
    },

    participants() {
      return (this.hidden ? this.hiddenChats : this.users)
        .filter((r) => {
          if (r)
            return (
              (r.name.includes(this.searchText) ||
                r.email.includes(this.searchText)) &&
              (this.type === -1 ||
                (this.type === 4
                  ? r.account_type == 1 || r.account_type == 4
                  : r.account_type == this.type))
            );
          else return false;
        })
        .sort((a, b) => {
          //   if((!a.lastMessageTime) || (!b.lastMessageTime)) return 0;
          return b.lastMessageTime - a.lastMessageTime;
        });
    },
  },
  props: ["currentParticipant"],
  mounted() {
    this.setCurrentParticiant();

    this.getHiddenChats();
  },
  data: () => ({
    hiddenChats: null,
    hidden: false,
    clicked: false,
    searchText: "",
    type: -1,
    dialog: false,
    participant: -1,
    drawer: false,
    tab_1: true,
    tab_2: false,
    item: 1,
    user_types: [
      { text: "All", value: -1 },
      { text: "Providers", value: 2 },
      { text: "Partners", value: 3 },
      { text: "ETH Office", value: 4 },
    ],
    user_actions: [
      { title: "Open", href: "/home2/home" },
      { title: "Profile" },
      { title: "Delete" },
    ],
  }),

  // watch:{
  //     unhideMail(val){
  //         this.unhideChat(val)
  //     }
  //     // participant(val){
  //     //     if(val &&this.participants[val])
  //     //     this.openId=this.participants[val].id
  //     // }
  // },

  methods: {
    messageSent(author) {
      //   let participant = this.participants.findIndex(r=>r.email===author)
      //   console.log('participant author = ',author);
      //   console.log('participant = ',participant);
      //   console.log('this.participant = ',this.participant);
      this.$nextTick(() => {
        if (this.participant !== 0) this.participant = 0;
      });
    },

    setCurrentParticiant() {
      if (this.currentParticipant) {
        this.$nextTick().then((r) => {
          this.$nextTick(() => {
            console.log("PARTICIPANT BEFORE =>", this.participant);
            this.unhideChat(this.currentParticipant.email);
            this.$nextTick(() => {
              this.participant = this.participants.findIndex(
                (r) => r.email === this.currentParticipant.email
              );
            });
            console.log("PARTICIPANT AFTER =>", this.participant);
          });
        });
      }
    },

    switchChange() {
      if (this.hiddenChats) {
        this.participant = -1;
      }
    },
    hideChat(item) {
      if (!item?.email) return;
      const data = new FormData();
      data.append("participant", item.email);
      axios.post("api/hide_chat", data).then((r) => {
        if (!this.hiddenChats.find((r) => r.email === item.email))
          this.hiddenChats.push(item);
      });
    },

    unhideChat(mail) {
      if (!mail) return;
      console.log("unhide chat", mail);
      const data = new FormData();
      data.append("participant", mail);
      axios.post("api/un_hide_chat", data).then((r) => {
        this.hiddenChats = this.hiddenChats?.filter((r) => r.email !== mail);
      });
    },

    getHiddenChats() {
      axios.post("api/get_hidden_chats", new FormData()).then((r) => {
        console.log("Hidden Chats = ", r.data.data);
        this.hiddenChats = r.data.data.map((r) => {
          return {
            ...r,
            lastMessage: "",
            lastMessageTime: 0,
            readIndex: 0,
            messages: [],
            lastConsumedMessageIndex: 0,
          };
        });
        this.setCurrentParticiant();
      });
    },

    async deleteAllChannels() {
      this.$store.state.chat.client
        .getSubscribedChannels()
        .then(async function (paginator) {
          console.log("Channel found -> ", paginator);
          for (let i = 0; i < paginator.items.length; i++) {
            var channel = paginator.items[i];
            await channel.delete();
          }
        });

      this.$store.commit("chat/CLEAR_LIST");
    },

    drawerToggle() {
      this.drawer = !this.drawer;
    },

    tab1() {
      (this.tab_1 = true), (this.tab_2 = false);
    },

    tab1() {
      (this.tab_2 = true), (this.tab_1 = false);
    },
  },
};
</script>

<style scoped>
.v-navigation-drawer {
  width: 350px !important;
}

.theme--light.v-btn.v-btn--icon {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>

