<template>
  <div>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="notifications"
        :search="search"
        class="elevation-0 en-table-card"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Notifications</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              class="elevation-1"
              fab
              light
              small
              color="white"
              @click="deleteDialog()"
            >
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
            <v-btn
              class="elevation-1 ml-3"
              @click="refresh"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="px-4">
            <v-col cols="12" md="12">
              <v-text-field
                dense
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <!-- <template v-slot:item.type="{ item }">
          <v-icon color="primary">{{ item.type }}</v-icon>
        </template> -->
        <template v-slot:item.date="{ item }">
          {{
            moment
              .tz(
                item.created_at,
                user.timezone ? user.timezone : settings.default_timezon
              )
              .format("MM/DD/YYYY h:mm a")
          }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="
              new Date().valueOf() -
                moment
                  .tz(
                    item.created_at,
                    user.timezone ? user.timezone : settings.default_timezon
                  )
                  .valueOf() <=
              10 * 60 * 1000
            "
            class="mr-3"
            x-small
            depressed
            @click="joinVideoCall(item.body)"
            color="primary"
            >Join now</v-btn
          >
        </template>
      </v-data-table>

      <v-dialog v-model="delete_dialog" persistent max-width="290">
        <v-card>
          <v-card-title>Delete</v-card-title>
          <v-card-text>Are you sure you want to Delete?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()">No</v-btn>
            <v-btn
              color="primary"
              depressed
              style="width: 100px"
              @click="deleteAll()"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import axios from "axios";
export default {
  data: () => ({
    selected: [],
    delete_dialog: false,
    moment: moment,
    search: "",
    headers: [
      // {
      //   text: "",
      //   align: "start",
      //   value: "type",
      // },
      {
        text: "Title",
        align: "start",
        value: "title",
      },
      { text: "Message", value: "body" },
      { text: "Date/Time", value: "date" },
      { text: "", align: "end", sortable: false, value: "actions" },
    ],
  }),

  created() {
    this.$store.dispatch("themes/getAllGeneralSetting");
    this.initialize();
  },
  computed: {
    ...mapState({
      notifications: (state) => state.notifications.notifications,
      loading: (state) => state.notifications.loading,
      settings: (state) => state.themes.get_general_setting,
      user: (state) => state.currentUser.user,
    }),
  },
  methods: {
    deleteAll() {
      this.$store
        .dispatch("notifications/deleteNotifications", {
          all: 1,
        })
        .then((r) => {
          this.close();
        });
    },
    joinVideoCall(link) {
      window.open(link, "_blank");
    },
    refresh() {
      this.$store.dispatch("notifications/getNotifications").then((r) => {});
    },

    initialize() {},

    deleteDialog() {
      this.delete_dialog = true;
    },

    close() {
      this.delete_dialog = false;
    },
  },
};
</script>
