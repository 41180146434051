<template>
  <v-app>
    <v-progress-circular
      :size="70"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular
  ></v-app>
</template>

<script>

import { mapState } from "vuex";
export default {
  data: () => ({
    base_uri: null,
    logs: null,
  }),

  computed: {
    ...mapState({
      is_client: (state) => state.currentUser.is_client,
    }),
  },

  created() {

      if(this.is_client){
            var base_url = window.location.origin + "/entouch-log-viewer";
            window.location.replace(base_url);
      }
  },
  methods: {

  },
};
</script>

<style scoped>
</style>
