<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      show-arrows
    >
      <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <Email />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <Sms />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Email from "./notifications_tabs/Email";
import Sms from "./notifications_tabs/Sms";
export default {
  components: {
    Email,
    Sms,
  },
  data: () => ({
    items: ["Email", "Sms"],
  }),

  computed: {
    tab: {
      get: function () {
        return this.$store.state.global.tabs.notificationsTab;
      },
      set: function (value) {
        var setTab = {
          type: 2,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },
};
</script>
