<template>
  <div class="download-reports-section">
    <!-- v-if="
        user.account_type == $constants.ADMIN ||
        user.account_type == $constants.COORDINATOR
      " -->

    <v-row>  
    <v-col
        cols="12"
        :md="user.account_type == $constants.COORDINATOR ? '12' : '6'"
    >
    <div class="en-card mt-4">
      <h4>Facility Reports</h4>
      <v-autocomplete
        single-line
        :items="users"
        multiple
        dense
        filled
        chips
        v-model="selectedPartners"
        label="Select Partners"
        v-if="user.account_type == $constants.ADMIN && providers"
      >
        <!-- Add a tile with Select All as Lalbel and binded on a method that add or remove all items -->
        <v-list-item
          slot="prepend-item"
          ripple
          @click="toggle"
          v-if="users.length != 0"
        >
          <v-list-item-action>
            <v-icon
              :color="selectedPartners.length > 0 ? 'indigo darken-4' : ''"
              >{{ iconSelectAll }}</v-icon
            >
          </v-list-item-action>
          <v-list-item-title><h6>All</h6></v-list-item-title>
        </v-list-item>
        <v-divider slot="prepend-item" class="mt-2"></v-divider>

        <template v-slot:selection="{ index }">
          <v-chip v-if="index === 0">
            <span>Selected Items ({{ selectedPartners.length }})</span>
          </v-chip>
        </template>

        <!-- <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="remove(data.item)"
          >
            {{ data.item.text }}
          </v-chip>
        </template> -->
      </v-autocomplete>

      <!-- <div class="mt-5 d-flex align-start">
        <v-icon>mdi-file-document</v-icon>
        <p
          style="cursor: pointer"
          @click="openFileDialog()"
          class="partner-more-info"
        >
          Consent Forms, Diagnoses, Telehealth Coordinator Information
        </p>
      </div> -->

      <div class="py-3">
        <hr />
      </div>
      <!-- <v-btn outlined color="primary" @click="def">DEF</v-btn> -->
      <h6 class="mb-5 mt-1">Patient Visit Detail</h6>
      <!-- <p class="d-report-text">
        Detail may be used to bill the Medicare Origination Site Fee Q3014
      </p> -->
      <v-row>
        <v-col cols="12" md="5" sm="5">
          <v-dialog
            ref="dialog1"
            v-model="date1Modal"
            :return-value.sync="date1"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date1"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details=""
                dense
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="temDate1"
              scrollable
              :max="temDate2 + date"
              @change="StartDateFormat(temDate1)"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date1Modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog1.save(date1)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="5" sm="5">
          <v-dialog
            ref="dialog2"
            v-model="date2Modal"
            :return-value.sync="date2"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date2"
                label="End Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details=""
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="temDate2"
              scrollable
              :min="temDate1"
              :max="date"
              @change="EndDateFormat(temDate2)"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date2Modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog2.save(date2)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="2" sm="2">
          <v-btn
            depressed
            block
            color="primary"
            @click="getReports"
            :disabled="
              !temDate1 ||
              !temDate2 ||
              (selectedPartners.length == 0 &&
                user.account_type == $constants.ADMIN)
            "
            ><v-icon color="white">mdi-magnify</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <div class="py-3">
        <hr />
      </div>

      <div v-if="user.account_type != $constants.PROVIDER">
        <h6 class="mb-5 mt-1">No-Show Appointment</h6>
        <v-row>
          <v-col cols="12" md="5" sm="5">
            <v-dialog
              ref="dialog3"
              v-model="start_date_Modal"
              :return-value.sync="start_date1"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="start_date1"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details=""
                  dense
                  label="Start Date"
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                v-model="start_date"
                scrollable
                :max="end_date + date"
                @change="changeStartDateFormat(start_date)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="start_date_Modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog3.save(start_date1)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="5" sm="5">
            <v-dialog
              ref="dialog4"
              v-model="end_date_Modal"
              :return-value.sync="end_date2"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="end_date2"
                  label="End Date"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  hide-details=""
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                color="primary"
                v-model="end_date"
                scrollable
                :min="start_date"
                :max="date"
                @change="changeEndDateFormat(end_date)"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="end_date_Modal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog4.save(end_date2)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <v-btn
              depressed
              block
              color="primary"
              @click="getNoShowAppointment()"
              :disabled="
                !start_date ||
                !end_date ||
                (selectedPartners.length == 0 &&
                  user.account_type == $constants.ADMIN)
              "
              ><v-icon color="white">mdi-magnify</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
    </v-col>

    <!-- provider no show report -->
    <v-col cols="12" md="6">
    <div
      v-if="
        user.account_type == $constants.PROVIDER ||
        user.account_type == $constants.ADMIN
      "
      class="en-card mt-4"
    >
      <h4>Provider Reports</h4>
      <v-autocomplete
        v-if="user.account_type == $constants.ADMIN"
        single-line
        :items="all_provider"
        multiple
        dense
        filled
        chips
        v-model="selectedProvider"
        label="Select Providers"
      >
        <v-list-tile slot="prepend-item" ripple @click="toggle_provider">
          <v-list-tile-action>
            <v-icon
              class="pl-4"
              :color="selectedProvider.length > 0 ? 'indigo darken-4' : ''"
              >{{ SelectAllProvider }}</v-icon
            >
          </v-list-tile-action>
          <v-list-tile-title class="pl-6"> All</v-list-tile-title>
        </v-list-tile>
        <v-divider slot="prepend-item" class="mt-2"></v-divider>

        <template v-slot:selection="{ index }">
          <v-chip v-if="index === 0">
            <span>Selected Items ({{ selectedProvider.length }})</span>
          </v-chip>
        </template>
        <!-- <template v-slot:selection="data">
          <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            @click="data.select"
            @click:close="removeprovider(data.item)"
          >
            {{ data.item.text }}
          </v-chip>
        </template> -->
      </v-autocomplete>
      <hr />
      <h6 class="mb-5 mt-1">No-Show Provider</h6>
      <v-row>
        <v-col cols="12" md="5" sm="5">
          <v-dialog
            ref="dialog5"
            v-model="start_date_provider_Modal"
            :return-value.sync="start_date_provider1"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="start_date_provider1"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details=""
                dense
                label="Start Date"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="start_date_provider"
              scrollable
              :max="end_date_provider + date"
              @change="changeStartDateFormatProvider(start_date_provider)"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="start_date_provider_Modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog5.save(start_date_provider1)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="5" sm="5">
          <v-dialog
            ref="dialog6"
            v-model="end_date_provider_Modal"
            :return-value.sync="end_date_provider2"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="end_date_provider2"
                label="End Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details=""
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              v-model="end_date_provider"
              scrollable
              :min="start_date_provider"
              :max="date"
              @change="changeEndDateFormatProvider(end_date_provider)"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="end_date_provider_Modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog6.save(end_date_provider2)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="2" sm="2">
          <v-btn
            depressed
            block
            color="primary"
            @click="getNoShowProvider()"
            :disabled="
              !start_date_provider ||
              !end_date_provider ||
              (selectedProvider.length == 0 &&
                user.account_type == $constants.ADMIN)
            "
            ><v-icon color="white">mdi-magnify</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <!-- <p class="d-report-text">
        Detail may be used to bill the Medicare Origination Site Fee Q3014
      </p> -->
    </div>
    </v-col>
    </v-row>

    <!-- <v-dialog v-model="dialog" scrollable persistent max-width="1000">
      <v-card>
        <v-row>
          <v-card-title class="mt-3 ml-4">
            Download
            <v-text-field
              class="ml-9"
              block
              dense
              style="width: 700px"
              v-model="search"
              outlined
              @input="initialize()"
              hide-details=""
              placeholder="Search..."
            ></v-text-field>
          </v-card-title>
        </v-row>
        <v-card-text class="pa-0">
          <Attachment />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import Attachment from "../Logs/Attachment.vue";
import { log } from "../agora/utils/utils";
export default {
  components: {
    Attachment,
  },
  data: () => ({
    date: new Date().toISOString().slice(0, 10),
    dialog: false,
    // provider
    selectedProvider: [],
    all_provider: [],
    start_date_provider_Modal: false,
    end_date_provider_Modal: false,
    start_date_provider: "",
    end_date_provider: "",
    start_date_provider1: null,
    end_date_provider2: null,
    // partner
    selectedPartners: [],
    users: [],
    date1: null,
    date2: null,
    date1Modal: false,
    date2Modal: false,
    temDate1: "",
    temDate2: "",
    start_date1: null,
    end_date2: null,
    start_date_Modal: false,
    end_date_Modal: false,
    start_date: "",
    end_date: "",
    search: "",
    options: {},
  }),
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      providers: (state) => state.teleProviders.providers,
    }),

    // START select All JS
    likesAllFruit() {
      return this.selectedPartners.length === this.users.length;
    },
    likesSomeFruit() {
      return this.selectedPartners.length > 0 && !this.likesAllFruit;
    },
    iconSelectAll() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    //END select All
    // all no show provider
    likesAllProvider() {
      return this.selectedProvider.length === this.providers.length;
    },
    likesSomeProvider() {
      return this.selectedProvider.length > 0 && !this.likesAllProvider;
    },
    SelectAllProvider() {
      if (this.likesAllProvider) return "mdi-close-box";
      if (this.likesSomeProvider) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    //provider END select All
  },
  created() {
    this.$store.dispatch("teleProviders/getTeleProviders").then((r) => {
      this.users = r.data.data.map((r) => {
        if (r.locations != null) {
          var t_text = r.locations.Name;
          t_text += " (" + r.username + ")";
        } else {
          var t_text = r.username;
        }

        return {
          text: t_text,
          value: r.id,
        };
      });
    });
    // all no show provider
    this.$store.dispatch("teleProviders/getProviders").then((r) => {
      this.all_provider = r.data.data.map((r) => {
        var t_text = r.name;
        return {
          text: t_text,
          value: r.user_id,
        };
      });
    });
  },

  methods: {
    //   all partner and no show appintment
    // openFileDialog() {
    //   this.dialog = true;
    // },
    // initialize() {
    //   this.$store.dispatch("admin_log_reader/GetAttachmentlist", {
    //     options: this.options,
    //     page: this.options.page,
    //     search: this.search,
    //   });
    // },
    // def() {
    //   this.selectedPartners = [3, 4, 28];
    //   this.date1 = "12-01-2021";
    //   this.date2 = "12-30-2021";
    //   this.temDate1 = "12/01/2021";
    //   this.temDate2 = "12/30/2021";
    //   this.getReports();
    // },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedPartners = [];
        } else {
          //   this.selectedPartners = this.users.slice();
          var all_selected = [];
          this.users.filter(function (item, index) {
            all_selected.push(item.value);
          });
          this.selectedPartners = all_selected;
        }
      });
    },
    remove(item) {
      const index = this.selectedPartners.indexOf(item.value);
      if (index >= 0) this.selectedPartners.splice(index, 1);
    },
    StartDateFormat(date) {
      this.date1 = moment(String(date)).format("MM/DD/YYYY");
    },
    EndDateFormat(date) {
      this.date2 = moment(String(date)).format("MM/DD/YYYY");
    },
    changeStartDateFormat(date) {
      this.start_date1 = moment(String(date)).format("MM/DD/YYYY");
    },
    changeEndDateFormat(date) {
      this.end_date2 = moment(String(date)).format("MM/DD/YYYY");
    },
    getReports() {
      //   for combobox
      //   var search_partners = [];
      //   this.selectedPartners.filter(function (value, index) {
      //     search_partners.push(value.value);
      //   });
      //   console.log(JSON.stringify(this.selectedPartners));
      this.$router.push({
        name: "partnerCharges",
        params: {
          from_date: this.temDate1,
          to_date: this.temDate2,
          selectedPartners: this.selectedPartners, //search_partners
        },
      });
    },
    getNoShowAppointment() {
      this.$router.push({
        name: "partnerAppointment",
        params: {
          from_date: this.start_date,
          to_date: this.end_date,
          selectedPartners: this.selectedPartners, //search_partners
        },
      });
    },
    // all no show provider
    toggle_provider() {
      this.$nextTick(() => {
        if (this.likesAllProvider) {
          this.selectedProvider = [];
        } else {
          var all_provider_selected = [];
          this.selectedPartners = all_provider_selected;
          this.all_provider.filter(function (item, index) {
            all_provider_selected.push(item.value);
          });
          this.selectedProvider = all_provider_selected;
        }
      });
    },
    removeprovider(item) {
      const index = this.selectedProvider.indexOf(item.value);
      if (index >= 0) this.selectedProvider.splice(index, 1);
    },
    changeStartDateFormatProvider(date) {
      this.start_date_provider1 = moment(String(date)).format("MM/DD/YYYY");
    },
    changeEndDateFormatProvider(date) {
      this.end_date_provider2 = moment(String(date)).format("MM/DD/YYYY");
    },
    getNoShowProvider() {
      if (this.user.account_type == this.$constants.PROVIDER) {
        var userId = this.user.user_id;
        this.selectedProvider.push(userId);
      }
      this.$router.push({
        name: "noshowprovider",
        params: {
          from_date: this.start_date_provider,
          to_date: this.end_date_provider,
          selectedProvider: this.selectedProvider, //search_provider
        },
      });
    },
  },
};
</script>
