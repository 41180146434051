var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-1 px-3",staticStyle:{"width":"350px"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"6"}},[_c('p',{staticClass:"en-p2 pt-1"},[_vm._v("NOTIFICATIONS")])]),_vm._v(" "),_c('v-col',{staticClass:"text-end",attrs:{"md":"6","cols":"6"}},[_c('v-btn',{attrs:{"small":"","depressed":""},on:{"click":function($event){return _vm.openClearDialog()}}},[_vm._v("Clear")])],1)],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('div',{staticClass:"en-notific-scroller"},[_vm._l((_vm.notifications),function(item,i){return _c('div',{key:i,staticClass:"en-notific-pop"},[_c('p',{staticClass:"ml-0 time-text"},[_vm._v("\n        "+_vm._s(_vm.moment
            .tz(
              item.created_at,
              _vm.user.timezone ? _vm.user.timezone : _vm.settings.default_timezon
            )
            .fromNow())+"\n      ")]),_vm._v(" "),_c('p',{staticClass:"en-notific-name"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"12px","color":"grey"}},[_vm._v(_vm._s(item.body))]),_vm._v(" "),(item.type == 1)?_c('v-btn',{attrs:{"disabled":new Date().valueOf() -
            _vm.moment
              .tz(
                item.created_at,
                _vm.user.timezone ? _vm.user.timezone : _vm.settings.default_timezon
              )
              .valueOf() >=
          10 * 60 * 1000,"x-small":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.joinVideoCall(item.body)}}},[_vm._v("Join now")]):_c('p',{staticClass:"en-notific-message"},[_vm._v(_vm._s(item.body))])],1)}),_vm._v(" "),((_vm.notifications)&&_vm.notifications.length == 0)?_c('div',{staticClass:"text-center d-flex justify-center align-center",staticStyle:{"height":"100%"}},[_c('p',[_vm._v("No Notifications")])]):_vm._e()],2),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-0",attrs:{"block":"","color":"primary","depressed":"","to":{ name: 'allNotifications' }},on:{"click":_vm.resetNotificationCount}},[_vm._v("View All")])],1),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.clear_dialog),callback:function ($$v) {_vm.clear_dialog=$$v},expression:"clear_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Clear")]),_vm._v(" "),_c('v-card-text',[_vm._v("Are you sure you want to clear all notifications?")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("No")]),_vm._v(" "),_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.clearNotifications()}}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }