<template>
  <div>
    <!-- {{ getHeaderData.preferences_data }} -->
    <v-container>
      <v-card-actions class="py-0 mb-1">
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
      </v-card-actions>
      <v-data-table
        :headers="headers"
        :items="locations"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        class="elevation-0 en-table-card"
        :items-per-page="30"
        :footer-props="{
          'items-per-page-options': [10, 30, 50],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Locations</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-menu
              v-model="popover"
              :close-on-content-click="false"
              :nudge-width="400"
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="elevation-1 mr-3"
                  fab
                  light
                  small
                  color="white"
                >
                  <v-icon color="dark">mdi-view-column</v-icon>
                </v-btn>
              </template>

              <v-card class="pt-1 px-3">
                <v-checkbox
                  v-model="selected"
                  dense
                  hide-details
                  label="Check All"
                  @click.native.stop="selectAll()"
                />
                <v-divider class="mt-3 mb-0"></v-divider>
                <v-row>
                  <v-col md="6" cols="12">
                    <v-checkbox
                      v-for="(head, i) in allHeaders.filter((r) => !r.hide)"
                      :key="head.value"
                      dense
                      v-if="i % 2 === 0"
                      v-model="head.selected"
                      hide-details
                      :label="head.text.length === 0 ? 'Actions' : head.text"
                      class="checkbox"
                    />
                  </v-col>

                  <v-col md="6" cols="12">
                    <v-checkbox
                      v-for="(head, i) in allHeaders.filter((r) => !r.hide)"
                      :key="head.value"
                      dense
                      v-if="i % 2 !== 0"
                      v-model="head.selected"
                      hide-details
                      :label="head.text.length === 0 ? 'Actions' : head.text"
                      class="checkbox"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    block
                    color="primary"
                    depressed
                    class="mt-4"
                    style="width: 100px"
                    @click="setHeaders()"
                    >Done</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>

            <v-btn
              class="elevation-1"
              @click="refresh"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
          </v-toolbar>
          <v-row class="px-4">
            <v-col cols="12" md="12">
              <v-text-field
                dense
                clearable
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  data: () => ({
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Locations",
        disabled: true,
        to: "",
      },
    ],
    search: "",
    current_user: "",
    options: {},
    totalItems: 0,
    popover: false,
    timer: null,
    selected: false,
    checkbox: {},
    headers: [],
    allHeaders: [
      {
        text: "Id",
        align: "start",
        value: "id",
        width: "100px",
        selected: false,
      },
      { text: "Name", value: "Name", width: "150px", selected: true },
      { text: "City", value: "City", width: "150px", selected: true },
      { text: "State", value: "State", width: "150px", selected: true },
      { text: "Country", value: "Country", width: "150px", selected: false },
      { text: "Zipcode", value: "ZipCode", width: "120px", selected: true },
      {
        text: "Practice ID",
        value: "PracticeID",
        width: "150px",
        selected: false,
      },
      {
        text: "Practice Name",
        value: "PracticeName",
        width: "150px",
        selected: false,
      },
      {
        text: "Place Of Service",
        value: "PlaceOfService",
        width: "180px",
        selected: false,
      },
      {
        text: "Billing Name",
        value: "BillingName",
        width: "150px",
        selected: false,
      },
      { text: "Phone", value: "Phone", width: "150px", selected: true },
      { text: "Phone Ext", value: "PhoneExt", width: "150px", selected: false },
      { text: "Fax Phone", value: "FaxPhone", width: "150px", selected: false },
      {
        text: "Fax Phone Ext",
        value: "FaxPhoneExt",
        width: "150px",
        selected: false,
      },
      {
        text: "HCFA Box32 Facility ID",
        value: "HCFABox32FacilityID",
        width: "200px",
        selected: false,
      },
      {
        text: "CLIA Number",
        value: "CLIANumber",
        width: "150px",
        selected: false,
      },
      { text: "NPI", value: "NPI", width: "150px", selected: false },
      {
        text: "Facility ID Type",
        value: "FacilityIDType",
        width: "150px",
        selected: false,
      },
      {
        text: "Created At",
        value: "created_at",
        width: "150px",
        selected: false,
      },
    ],
    desserts: [],
  }),

  created() {
    this.setHeaders()
  },

  watch: {
    search: function () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.refresh();
      }, 500);
    },
    options: {
      handler() {
        this.refresh();
        
      },
      // deep: true,
    },

    getHeaderData(val) {
      if (val) {
        var data = JSON.parse(this.getHeaderData.preferences_data);
        this.headers = data;

        this.allHeaders.map(function (value, index) {
          var check = true;
          data.map(function (content, keyIndex) {
            if (value.text == content.text) {
              value.selected = true;
              check = false;
            }
          });

          if (check) {
            value.selected = false;
          }
        });
      } else {
        this.headers = this.allHeaders.filter((r) => r.selected);
      }
    },
  },
  mounted() {
    for (let i = 0; i < 14; i++) {
      this.$set(this.checkbox, i, false);
    }
    var data = localStorage.getItem("en_user");
    this.current_user = JSON.parse(data);
    var objs = {
      user_id: this.current_user.id,
      type: "location",
    };
    this.$store.dispatch("appointments/getUsersPreferencesData", objs);
    this.refresh();
  },
  computed: {
    ...mapState({
      locations: (state) => state.locations.locations,
      user: (state) => state.currentUser.user,
      getHeaderData: (state) => state.appointments.getHeaderData,
    }),
  },
  methods: {
    clearFilters() {
      this.search = "";
    },
    refresh() {
      this.loading = true;
      this.options.search = this.search;
      this.options.column = "Name";
      // }
      this.$store
        .dispatch("locations/getLocations", this.options)
        .then((response) => {
          this.totalItems = response.data.total;
          this.loading = false;
        });
    },

    setHeaders() {
      this.headers = this.allHeaders.filter((s) => s.selected);
      this.popover = false;
       
      var obj = {
        json_data: JSON.stringify(this.headers),
        user_id: this.current_user.id,
        type: "location",
      };
      this.$store.dispatch("appointments/usersPreferencesData", obj);
    },

    selectAll() {
      for (const i in this.allHeaders) {
        this.allHeaders[i].selected = this.selected;
      }
    },

    close() {
      this.popover = false;
      z;
    },
  },
};
</script>
