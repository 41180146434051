<template>
  <div>
    <div class="d-block d-md-flex mt-5">
      <div class="picked-image-con-round">
        <img :src="imageUrl" v-if="imageUrl" />
      </div>
      <div>
        <label class="custom-file-upload ml-5">
          <input type="file" @change="onFileChange" />
          <!-- <v-icon>mdi-file-image</v-icon>Upload Photo -->
        </label>
      </div>
    </div>
  
    <v-divider class="mt-5"></v-divider>

    <v-row class="mt-5">
      <v-col cols="12" md="6">
        <p class="en-fields-label">Full Name</p>
        <v-text-field
          v-model="name"
          dense
          outlined
          placeholder="Enter Name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <p class="en-fields-label">Email Address</p>
        <v-text-field
          dense
          v-model="email"
          disabled
          outlined
          placeholder="Enter Email"
        ></v-text-field>
      </v-col>

      <!-- <v-col cols="12" md="6">
        <p class="en-fields-label">Change Password</p>
        <v-text-field
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          @click:append="showPass = !showPass"
          dense
          v-model="newPassword"
          outlined
          placeholder="Enter New Password"
        ></v-text-field>
      </v-col> -->

      <v-col cols="12" md="6">
        <p class="en-fields-label">Timezone</p>
        <v-autocomplete
          v-model="timezone"
          :items="timezoneList"
          :loading="timezoneLoading"
          :disabled="timezoneLoading"
          outlined
          placeholder="Select Timezones"
          persistent-hint
          required
          dense
          hide-details
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching " . Press
                  <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <p class="en-fields-label">Phone</p>
        <v-text-field
          dense
          v-model="phone"
          outlined
          type="number"
          placeholder="Enter Phone"
        ></v-text-field>
      </v-col>
      <v-col
        v-if="user.account_type != 3 && user.account_type != 2 && user.account_type != 1"
        cols="12"
        md="6"
      >
        <p class="en-fields-label">Location</p>
        <v-combobox
          dense
          v-model="location"
          :items="['Demo 1', 'Demo 2']"
          outlined
          placeholder="Select Location"
        ></v-combobox>
      </v-col>

       <v-col cols="12" md="6" v-if="user.account_type==2">
        <p class="en-fields-label">Description</p>
         <v-textarea
          v-model="description"
          dense
          outlined
          placeholder="Enter Description"
        ></v-textarea>
      </v-col>

      <v-col cols="12" md="6" v-if="user.account_type==2">
          <h6 class="mb-3">Available Days</h6>
          <div class="checkbox-row">
            <div v-for="item in days" :key="item.value" class="check-box-divs" id="testing">
              <v-checkbox
                multiple
                class="mt-0"
                hide-details=""
                v-model="available_days"
                :label="item.label.substring(0,3)"
                :value="item.value"
              ></v-checkbox>
            </div>
          </div>
      </v-col>


      <v-col v-if="user.account_type == 1" cols="12" md="12">
        <v-switch
          inset
          class="mt-0"
          dense
          label="Call Logging"
          @click="callLoggingToggle"
          v-model="callLoggingSwitch"
        ></v-switch>

          <v-switch v-model="ringtoneSwitch" inset dense label="Call Ringtone"></v-switch>
      </v-col>
      <v-col cols="12" md="12" class="py-0">
        <v-btn
          depressed
          large
          color="primary"
          @click="updateProfile()"
          style="width: 170px"
          >Save Changes</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
      ringtoneSwitch:false,
    callLoggingSwitch: false,
    showPass: false,
    imageUrl: null,
    timezoneList: [],
    timezone: "",
    timezoneLoading: false,
    name: "",
    email: "",
    newPassword: "",
    file: null,
    phone: "",
    location: "",

    days: [
      { label: "Sunday", value: 1 },
      { label: "Monday", value: 2 },
      { label: "Tuesday", value: 3 },
      { label: "Wednesday", value: 4 },
      { label: "Thursday", value: 5 },
      { label: "Friday", value: 6 },
      { label: "Saturday", value: 7 },
    ],

    description:"",
    available_days: [],

  }),

  computed: {
    ...mapState({ user: (state) => state.currentUser.user }),
  },

  created() {
    // console.log("user data===============",this.user);
if(this.user.account_type == 1)
this.loadSettings();
    this.name = this.user.name;
    this.imageUrl = this.user.image;
    this.email = this.user.email;
    this.timezone = this.user.timezone;
    this.phone = this.user.phone;
    this.location = this.user.location;
    this.callLoggingSwitch = this.user.callLogging == 1 ? true : false;
    this.ringtoneSwitch = this.user.ringtone == 1 ? true : false;
    this.description = this.user.description;
    this.available_days = this.user.available_days;

    // console.log("User>>>>>>>>>>>>>>>>>>>>>> "+JSON.stringify(this.user));

    this.$store
      .dispatch("global/get", {
        url: "/api/get_timezones",
      })
      .then((r) => {
        this.timezoneList = r.data
        this.timezoneLoading = false;
      });
  },

  methods: {
      loadSettings(){
              const data = new FormData();
      this.$store
        .dispatch("global/post", {
          url: "/api/get_user_prefs",
          body: data,
        })
        .then((r) => {
          const d = r.data.data;
          this.ringtoneSwitch = d.ringtone;
        })
      },
    callLoggingToggle() {
    //   this.callLoggingSwitch = !this.callLoggingSwitch;
    },
    updateProfile() {
      this.$store.dispatch("global/actionloaderState", true, { root: true });
      const data = new FormData();
      data.append("name", this.name);
      data.append("phone", this.phone);
      data.append("location", this.location);
      // data.append("password", this.newPassword);
      data.append("ringtone", this.ringtoneSwitch ? "1" : "0");
      data.append("timezone", this.timezone);
      data.append("description", this.description);
      if(this.user.account_type == 2){
        data.append("description", this.description);
        data.append("available_days", JSON.stringify(this.available_days));
      }
        data.append("callLogging", this.callLoggingSwitch ? 1 : 0);

 
      if (this.file) {
        data.append("image", this.file, this.file.name);
      }
      this.$store
        .dispatch("currentUser/updateProfile", data)
        .then((r) => {
              this.user.ringtone = this.ringtoneSwitch?1:0
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.$store.dispatch("global/snackBarState", {
            show: true,
            color: "primary",
            message: "Save Successfully",
          });
          this.newPassword = "";
        })
        .catch((e) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.$store.dispatch("global/snackBarState", {
            show: true,
            color: "red",
            message: "Save Failed!",
          });
        });
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.imageUrl = URL.createObjectURL(this.file);
    },
  },
};
</script>
