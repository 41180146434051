var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('emoji-picker',{attrs:{"search":_vm.search},on:{"emoji":_vm.append},scopedSlots:_vm._u([{key:"emoji-invoker",fn:function(ref){
var clickEvent = ref.events.click;
return _c('div',{staticClass:"svg-button",class:{ 'button-reaction': _vm.emojiReaction },on:{"click":[function($event){$event.stopPropagation();return clickEvent.apply(null, arguments)},_vm.openEmoji]}},[_c('button',{staticClass:"emoji-btn",attrs:{"type":"button","data-toggle":"tooltip","data-placement":"top","title":"","data-original-title":"Emoji","param":_vm.emojiReaction ? 'reaction' : ''}},[_c('i',{staticClass:"ri-emotion-happy-line"})])])}},{key:"emoji-picker",fn:function(ref){
var emojis = ref.emojis;
var insert = ref.insert;
return (_vm.emojiOpened)?_c('div',{},[_c('transition',{attrs:{"name":"slide-up","appear":""}},[_c('div',{staticClass:"emoji-picker",class:{ 'picker-reaction': _vm.emojiReaction },style:({
							height: (_vm.emojiPickerHeight + "px"),
							top: _vm.positionTop ? _vm.emojiPickerHeight : (_vm.emojiPickerTop + "px"),
							right: _vm.emojiPickerRight,
							display: _vm.emojiPickerTop || !_vm.emojiReaction ? 'initial' : 'none'
						})},[_c('div',{staticClass:"emoji-picker__search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]),_vm._v(" "),_c('div',_vm._l((emojis),function(emojiGroup,category){return _c('div',{key:category},[(category !== 'Frequently used')?_c('h5',[_vm._v(_vm._s(category))]):_vm._e(),_vm._v(" "),(category !== 'Frequently used')?_c('div',{staticClass:"emojis"},_vm._l((emojiGroup),function(emoji,emojiName){return _c('span',{key:emojiName,attrs:{"title":emojiName},on:{"click":function($event){return insert({ emoji: emoji, emojiName: emojiName })}}},[_vm._v(_vm._s(emoji))])}),0):_vm._e()])}),0)])])],1):_vm._e()}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }