<template>
  <v-app>
    <div class="call-screen">
      <div class="call-parent">
        <!-- Full Screen  -->
        <div @click="parti_tooltip1 = false" class="video-full-screen">
          <div :id="localId"></div>
        </div>

        <!-- Participants -->
        <div class="call-scroll">
          <div style="position: relative">
            <v-sheet
              v-for="(item, i) in participants.filter((r) => r.participant)"
              :key="i"
              :class="
                participants.filter((r) => r.participant).length < 4
                  ? 'call-participants'
                  : 'call-participants-small'
              "
            >
              <div v-show="item.video"></div>

              <v-tooltip v-model="parti_tooltip1" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-sheet
                    v-show="!isVideoDisabled(item)"
                    class="partic-video"
                    @click="setActiveParticipant(item)"
                    :id="item.id"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-sheet>
                </template>
                <span>{{
                  item.participant.identity == user.email
                    ? "me"
                    : item.participant.identity
                }}</span>
              </v-tooltip>

              <img
                v-on:mouseover="parti_tooltip1 = false"
                @click="setActiveParticipant(item)"
                v-if="isVideoDisabled(item)"
                :src="
                  item.participant.image || '/assets/plaeholder-user-box.png'
                "
                alt=" "
              />

              <div class="participent-action-divs">
                <v-icon size="20" color="primary" v-if="isAudioDisabled(item)"
                  >mdi-volume-mute</v-icon
                >
                <v-icon
                  v-if="item.id !== user.email"
                  size="20"
                  :class="isAudioDisabled(item) ? 'ml-2' : ''"
                  @click="addUserForChat(item.id)"
                  color="primary"
                  >mdi-android-messages</v-icon
                >
              </div>
            </v-sheet>
          </div>
        </div>

        <!-- Showing name who is on full screen -->
        <div v-show="show_comp" class="person-in-full-screen">
          <v-chip small color="primary" label>{{
            localParticipant.identity == user.email
              ? "  You  "
              : localParticipant.identity
          }}</v-chip>
        </div>

        <div class="call-back-btn">
          <v-snackbar v-model="alert" top left dark
            >{{ alertText }}
          </v-snackbar>

          <v-fab-transition>
            <v-btn
              v-if="localParticipant.identity !== user.email && show_comp"
              @click="addUserForChat(localParticipant.identity)"
              class="mr-3 mt-1"
              color="dark"
              small
              dark
              fab
            >
              <v-icon>mdi-android-messages</v-icon>
            </v-btn>
          </v-fab-transition>
        </div>

        <div class="call-users-buttons"></div>

        <div v-if="client" class="global-chat-section chat-bbls-desktop">
          <div v-for="p in participant" :key="p.id">
            <ChatWidget :participant="p" />
          </div>
        </div>

        <div class="call-actions">
          <v-fab-transition>
            <v-btn
              v-if="user.account_type !== 3"
              v-show="show_comp"
              @click="addUserDialog()"
              class="mr-3 mr-md-5 mr-sm-5 mt-1"
              color="dark"
              dark
              fab
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-fab-transition>

          <!-- Pause Video BTN -->
          <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click="pauseVideo()"
              class="mr-3 mr-md-5 mr-sm-5 mt-1"
              color="dark"
              dark
              fab
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
            >
              <v-icon>{{ videoIconToggle }}</v-icon>
            </v-btn>
          </v-fab-transition>

          <!-- DISSCONNECT BTN -->
          <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click="openCallEndDialog()"
              color="red"
              dark
              fab
            >
              <v-icon>mdi-phone-hangup-outline</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-fab-transition v-if="user.account_type == $constants.PROVIDER">
            <!-- CHAT Action BTN -->
            <!-- <v-btn
              v-show="show_comp"
              @click="chatMenuToggle()"
              class="mr-5 mt-1"
              color="dark"
              small
              dark
              fab
            >
              <v-icon>mdi-comment-text</v-icon>
            </v-btn> -->
          </v-fab-transition>

          <!-- MICROPHONE BTN -->
          <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click="micToggle()"
              class="ml-3 ml-md-5 ml-sm-5 mt-1"
              color="dark"
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
              dark
              fab
            >
              <v-icon>{{ micIconToggle }}</v-icon>
            </v-btn>
          </v-fab-transition>

          <!-- Video Settings BTN -->
          <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click="mic_setting_dialog = true"
              class="ml-3 ml-md-5 ml-sm-5 mt-1"
              color="dark"
              dark
              fab
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
          </v-fab-transition>

          <!-- MUTE BTN -->
          <!-- <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click="volume()"
              class="ml-3 ml-md-5 ml-sm-5 mt-1"
              color="dark"
              dark
              fab
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
            >
              <v-icon>{{ volumeIconToggle }}</v-icon>
            </v-btn>
          </v-fab-transition> -->

          <!-- Chat BTN -->
          <v-fab-transition>
            <v-btn
              v-if="client"
              v-show="show_comp"
              @click="sheet = !sheet"
              class="ml-3 ml-md-5 ml-sm-5 mt-1 chat-bbls-mobile"
              color="dark"
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
              dark
              fab
            >
              <v-icon>mdi-message-processing</v-icon>
            </v-btn>
          </v-fab-transition>
        </div>

        <div v-if="false">
          <!-- This Note panel is hidden -->
          <v-card class="notes-panel" v-show="notes_menu">
            <v-card-actions>
              <p class="notes-panel-name">Notes</p>
              <v-spacer></v-spacer>
              <v-icon @click="notes_menu = false">mdi-close</v-icon>
            </v-card-actions>
            <v-divider></v-divider>
            <NotesSection />
          </v-card>
        </div>
      </div>
    </div>

    <!-- Show bottom Sheet on Mobile view (Chat bubbles)  -->
    <v-bottom-sheet hide-overlay v-model="sheet">
      <v-sheet class="text-center" height="109px">
        <div class="py-3">
          <div v-if="client" class="global-chat-section chat-bbls-launcher">
            <div v-for="p in participant" :key="p.id">
              <ChatWidget :participant="p" />
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- Participent Add Dialog -->
    <v-dialog v-model="add_user_dialog" max-width="400px">
      <v-card>
        <v-card-title>Add a user to call</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            show-arrows
          >
            <v-tab v-for="tabs in tab_items" :key="tabs">{{ tabs }}</v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <v-row>
                <!-- {{participants.map(e=>e.participant.identity)}} -->
                <v-col cols="12" md="12">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="providerId"
                    label="Select Provider"
                    :items="
                      providers.filter(
                        (r) =>
                          !participants
                            .map((e) => e.participant.identity)
                            .includes(r.mail) &&
                          localParticipant.identity != r.mail
                      )
                    "
                    class="mt-5"
                  >
                    <template v-slot:append="{ item }">
                      <v-icon :color="item.color"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 2 -->
            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="providerId"
                    label="Select Eth office"
                    :items="
                      getBasicUserData.filter(
                        (r) =>
                          !participants
                            .map((e) => e.participant.identity)
                            .includes(r.mail) &&
                          localParticipant.identity != r.mail
                      )
                    "
                    class="mt-5"
                  >
                    <template v-slot:append="{ item }">
                      <v-icon :color="item.color"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="close()" text>Close</v-btn>
          <v-btn
            depressed
            style="width: 120px"
            @click="addUser(providerId)"
            color="primary"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- END CALL DIALOG -->
    <v-dialog v-model="end_call_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Call End</v-card-title>
        <v-card-text>Are you sure you want to end the call?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="disconnect()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Microphone Select Dialog -->
    <v-dialog
      persistent
      eager
      overlay-opacity="9"
      v-model="mic_setting_dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Microphone  </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="text-center">
            <svg
              focusable="false"
              viewBox="0 0 100 100"
              aria-hidden="true"
              height="100"
              width="100"
              style="margin: 10px 0"
            >
              <defs>
                <clipPath id="level-indicator">
                  <rect x="0" y="100" width="100" height="100" />
                </clipPath>
              </defs>
              <path
                fill="rgb(220, 220, 220)"
                d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
              ></path>
              <path
                fill="rgb(220, 220, 220)"
                d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
              ></path>
              <path
                fill="#080"
                clip-path="url(#level-indicator)"
                d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
              ></path>
              <path
                fill="#080"
                clip-path="url(#level-indicator)"
                d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
              ></path>
            </svg>
          </div>

          <p class="en-fields-label">Select Audio Device</p>
          <v-select
            v-model="audioDeviceId"
            @change="updateAudioDevice()"
            outlined
            dense
            id="video-devices"
            :items="mics"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="micSelected()"
            depressed
            style="min-width: 120px"
            color="primary"
            dark
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      eager
      overlay-opacity="9"
      v-model="camera_setting_dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Camera </v-card-title>
        <v-divider></v-divider>
        <v-card-text>

          <div class="video-testing-container">

          </div>

          <p class="en-fields-label">Select Video Device</p>
          <v-select
            outlined
            dense
            v-model="videoDeviceId"
            id="video-devices"
            :items="devices"
            @change="updateVideoDevice()"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            @click="cameraSelected()"
            depressed
            style="min-width: 120px"
            color="primary"
            dark
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      :color="snackbar.color"
      top
      v-model="snackbar.show"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import ChatWidget from "../ChatWidget";

// import Notes from "../view/Notes";

import NotesSection from "../Notes";
// import NotesSection from "./SmallNotesWidget";
import Video from "twilio-video";
import { mapState } from "vuex";
import Axios from "axios";
import axios from "axios";
export default {
  components: {
    NotesSection,
    ChatWidget,
  },
  data: () => ({
    firstRun: true,
    deviceIds: {
      audio: null,
      video: null,
    },
    localTracks: {
      audio: null,
      video: null,
    },
    connectOptions: {
      // Available only in Small Group or Group Rooms only. Please set "Room Type"
      // to "Group" or "Small Group" in your Twilio Console:
      // https://www.twilio.com/console/video/configure
      bandwidthProfile: {
        video: {
          dominantSpeakerPriority: "high",
          mode: "collaboration",
          renderDimensions: {
            high: { height: 720, width: 1280 },
            standard: { height: 90, width: 160 },
          },
        },
      },

      // Available only in Small Group or Group Rooms only. Please set "Room Type"
      // to "Group" or "Small Group" in your Twilio Console:
      // https://www.twilio.com/console/video/configure
      dominantSpeaker: true,

      // Comment this line to disable verbose logging.
      logLevel: "debug",

      // Comment this line if you are playing music.
      maxAudioBitrate: 16000,

      // VP8 simulcast enables the media server in a Small Group or Group Room
      // to adapt your encoded video quality for each RemoteParticipant based on
      // their individual bandwidth constraints. This has no utility if you are
      // using Peer-to-Peer Rooms, so you can comment this line.
      preferredVideoCodecs: [{ codec: "VP8", simulcast: true }],

      // Capture 720p video @ 24 fps.
      video: { height: 720, frameRate: 24, width: 1280 },
    },

    videoDeviceId: 0,
    audioDeviceId: 0,
    mics: [],
    devices: [],
    mic_setting_dialog: false,
    camera_setting_dialog: false,
    callEnded: false,
    alert: false,
    alertText: "",
    sheet: false,
    parti_tooltip1: false,
    callTo: {},
    parti_tooltip2: false,
    stopAll: false,
    timer: null,
    providerId: 0,
    image_hover: false,
    add_user_dialog: false,
    providers: [],
    appId: "",
    accessToken: "",
    chat_menu: false,
    notes_menu: false,
    localParticipant: {},
    localTrack: {},
    participants: [],
    localId: "video-chat-window",
    chat_icon: 1,
    room: null,
    show_comp: true,
    comp_timer: null,
    video_icons: true,
    mic_icons: true,
    volume_icons: true,
    snackbar: {
      show: false,
      message: "",
      color: "",
    },
    tab: null,
    tab_items: ["Providers", "ETH Office"],
    end_call_dialog: false,
  }),

  computed: {
    ...mapState({
      chatUsers: (state) => state.chat.user,
      client: (state) => state.chat.client,
      user: (state) => state.currentUser.user,
      getBasicUserData: (state) =>
        state.users.getBasicUserData.map((r) => {
          return {
            text: r.name,
            value: r.id,
            color: r.color,
            mail: r.email,
          };
        }),
    }),
    participant() {
      return this.chatUsers.filter(
        (r) =>
          this.participants.map((e) => e.id).includes(r.email) ||
          this.localParticipant.identity == r.email
      );
    },

    videoIconToggle() {
      return this.video_icons == true ? "mdi-video" : "mdi-video-off";
    },

    micIconToggle() {
      return this.mic_icons == true ? "mdi-microphone" : "mdi-microphone-off";
    },

    volumeIconToggle() {
      return this.volume_icons == true ? "mdi-volume-high" : "mdi-volume-off";
    },
  },

  mounted() {
    if (this.isMobile) {
      this.connectOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
    }
  },
  created() {
    this.mic_setting_dialog = true;
    this.$store.dispatch("users/getBasicUser").then((r) => {});
    this.timer = setInterval(() => {
      this.$store.dispatch("currentUser/setStatus", {
        inCall: 1,
        loggedOut: 0,
      });
    }, 5000);
    this.getProviders();
    this.appId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );

    document.addEventListener("mousemove", this.myListener, false);
    navigator.mediaDevices.enumerateDevices().then(this.gotDevices);
  },
  beforeDestroy() {
    this.disconnect();
  },
  methods: {
    async getInputDevices(kind) {
      return (await navigator.mediaDevices.enumerateDevices())
        .filter((e) => e.kind === `${kind}input`)
        .map((e, i) => {
          return {
            text: e.label || `Camera ${i}`,
            value: e.deviceId,
          };
        });
    },
    async applyInputDevice(kind, deviceId) {
      // Create a new LocalTrack from the given Device ID.
      const [track] = await Video.createLocalTracks({
        audio: true,
        [kind]: { deviceId },
      });

      // Stop the previous LocalTrack, if present.
      if (this.localTracks[kind]) {
        this.localTracks[kind].stop();
      }

      // Render the current LocalTrack.
      this.localTracks[kind] = track;
      return new MediaStream([track.mediaStreamTrack]);
    },

    updateVideoDevice() {
      //   const stream = this.applyInputDevice("video", this.videoDeviceId);
      //  const $video = $("video", this.cameraId);
      //  $video.get(0).srcObject = stream;
      const localParticipant = this.room.localParticipant;
      console.log(localParticipant.identity, this.localParticipant.identity);
      const _this = this;

      const videoChatWindow = document.getElementById(
        localParticipant.identity === this.localParticipant.identity
          ? "video-chat-window"
          : this.localParticipant.identity
      );

      //     this.room.localParticipant.videoTracks.forEach((publication) => {
      //         const localVideoTrack=publication.track
      //         const tracks = Array.from(localParticipant.videoTracks.values());
      //         localParticipant.unpublishTracks(tracks.map((e) => e.track));
      //     });

      Video.createLocalVideoTrack({
        //TODO:CHECK HERE
        audio: true,
        deviceId: { exact: this.videoDeviceId },
      }).then(function (localVideoTrack) {
        const tracks = Array.from(localParticipant.videoTracks.values());
        localParticipant.unpublishTracks(tracks.map((e) => e.track));
        console.log(
          localParticipant.identity + " removed track: " + tracks[0].kind
        );
        //   detachTracks(tracks);

        localParticipant.publishTrack(localVideoTrack);
        console.log(
          localParticipant.identity + " added track: " + localVideoTrack.kind
        );
        const element = localVideoTrack.attach();
        // this.localTrack = publication.track;
        // this.localParticipant = this.room.localParticipant;
        videoChatWindow.innerHTML = "";
        videoChatWindow.appendChild(element);
        //   const previewContainer = document.getElementById("local-media");
        //   attachTracks([localVideoTrack], previewContainer);
      });
    },

    updateAudioDevice() {
      const localParticipant = this.room.localParticipant;
      console.log(localParticipant.identity, this.localParticipant.identity);
      const _this = this;

      const videoChatWindow = document.getElementById(
        localParticipant.identity === this.localParticipant.identity
          ? "video-chat-window"
          : this.localParticipant.identity
      );

      //     this.room.localParticipant.videoTracks.forEach((publication) => {
      //         const localVideoTrack=publication.track
      //         const tracks = Array.from(localParticipant.videoTracks.values());
      //         localParticipant.unpublishTracks(tracks.map((e) => e.track));
      //     });

      Video.createLocalAudioTrack({
        deviceId: { exact: this.audioDeviceId },
      }).then(function (localVideoTrack) {
        const tracks = Array.from(localParticipant.audioTracks.values());
        localParticipant.unpublishTracks(tracks.map((e) => e.track));
        console.log(
          localParticipant.identity + " removed track: " + tracks[0].kind
        );
        //   detachTracks(tracks);

        localParticipant.publishTrack(localVideoTrack);
        console.log(
          localParticipant.identity + " added track: " + localVideoTrack.kind
        );
        // const element = localVideoTrack.attach();
        // // this.localTrack = publication.track;
        // // this.localParticipant = this.room.localParticipant;
        // videoChatWindow.innerHTML = "";
        // videoChatWindow.appendChild(element);
        //   const previewContainer = document.getElementById("local-media");
        //   attachTracks([localVideoTrack], previewContainer);
      });
      this.updateVideoDevice();
    },
    gotDevices(mediaDevices) {
      //   const select = document.getElementById("video-devices");
      //   select.innerHTML = "";
      //   select.appendChild(document.createElement("option"));
      let count = 1;
      this.devices = mediaDevices
        .filter((e) => e.kind === "videoinput")
        .map((e, i) => {
          return {
            text: e.label || `Camera ${i}`,
            value: e.deviceId,
          };
        });

      this.mics = mediaDevices
        .filter((e) => e.kind === "audioinput")
        .map((e, i) => {
          return {
            text: e.label || `Camera ${i}`,
            value: e.deviceId,
          };
        });
      //   mediaDevices.forEach((mediaDevice) => {
      //     // if (mediaDevice.kind === 'videoinput') {

      if (Video.isSupported) this.mic_setting_dialog = true;
      else alert("This browser do not support video calling.");
    },

      //     // }
      //   });
    },
    addUserForChat(email) {
      console.log(this.localParticipant);
      this.$store.dispatch("chat/onNotification", { email: email });
    },
    addUserDialog() {
      this.providerId = 0;
      this.add_user_dialog = true;
    },

    isAudioDisabled(item) {
      let result = true;
      item.participant.audioTracks.forEach((element) => {
        if (element.isTrackEnabled) {
          result = false;
        }
      });
      return result;
    },

    isVideoDisabled(item) {
      let result = true;
      item.participant.videoTracks.forEach((element) => {
        if (element.isTrackEnabled) {
          result = false;
        }
      });
      return result;
    },
    addUser(userId) {
      var item;

      if (this.tab == 0)
        item = this.providers.filter((r) => r.value == userId)[0];
      else item = this.getBasicUserData.filter((r) => r.value == userId)[0];

      console.log("ITEM --------------------------------> ", item);

      this.$store
        .dispatch("users/addUserToCall", {
          sendTo: userId,
          roomId: this.appId,
          mail: item.mail,
          name: item.name ? item.name : "",
          sendCall: true,
          inviteOnly: true,
        })
        //   const data = new FormData();
        //   data.append("send_to", userId);
        //   data.append("room", this.appId);
        //   Axios.post("/api/add_user_to_call", data)
        .then((r) => {
          this.snackbar = {
            show: true,
            message: "Invite sent successfully",
          };
          this.close();
        });
    },
    getProviders() {
      const options = {
        search: "",
        column: "name",
      };
      this.$store
        .dispatch("teleProviders/getProviders", options)
        .then((response) => {
          this.providers = response.data.data.map((e) => {
            return {
              text: e.name,
              value: e.user_id,
              mail: e.email,
              color: e.color,
            };
          });
         // console.log("Providers >>>>>>>>>>> ", this.providers);
        });
    },
    log(log) {
      const data = new FormData();
      data.append("log", log);
      data.append("appointment", this.appId);
      this.$store.dispatch("global/post", {
        url: "/api/log_video_call",
        body: data,
      });
    },

    myListener() {
      this.show_comp = true;
      clearTimeout(this.comp_timer);
      if (this.$vuetify.breakpoint.smAndUp) {
        this.comp_timer = setTimeout(() => {
          this.show_comp = false;
        }, 3000);
      }
    },

    setActiveParticipant(item) {
      const activeWindow = document.getElementById("video-chat-window");
      const clickedWindow = document.getElementById(item.id);
      const track = Array.from(item.participant.videoTracks.values())[0];
      const localTrack = Array.from(
        this.localParticipant.videoTracks.values()
      )[0];
      console.log("track---------------> ", track);
      console.log("Local track ---------------> ", localTrack);
      console.log(
        "$$$$$$$$$$$$$$$$$$$$$$   APPENDING CHILD IN REPLACE ELEMENT for Id " +
          item.id
      );
      activeWindow.innerHTML = "";
      clickedWindow.innerHTML = "";
      activeWindow.appendChild(
        track.track.attach()
        //   , activeWindow.lastElementChild
      );
      clickedWindow.appendChild(
        localTrack.track.attach()
        // ,

        // clickedWindow.lastElementChild
      );
      const temp = this.localParticipant;
      this.localParticipant = item.participant;
      item.participant = temp;
      item.id = temp.identity;
    },

    getAccessToken(id) {
      window.onclose = (e) => {
        this.disconnect();
      };
      const _this = this;
      const axios = require("axios");

      // Request a new token
      axios
        .get("/api/access_token?room=" + id, {
          headers: {
            Authorization: `Bearer ${this.user.access_token}`,
          },
        })
        .then(function (response) {
          _this.accessToken = response.data;
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          console.log(_this.accessToken);
          _this.connectOptions.audio = {
            deviceId: { exact: _this.deviceIds.audio },
          };

          // Add the specified Room name to ConnectOptions.
          //   this.connectOptions.name = roomName;

          // Add the specified video device ID to ConnectOptions.
          _this.connectOptions.video.deviceId = {
            exact: _this.deviceIds.video,
          };
          _this.joinRoom();
          axios.get("/api/get_room?room=" + id);
        });
    },

    setUpTrackListeners(track, id) {
      track.on("disabled", () => {
        this.participants.forEach((p) => {
          if (p.id === id) {
            p.video = false;
          }
        });
      });
      track.on("enabled", () => {
        this.participants.forEach((p) => {
          if (p.id === id) {
            p.video = true;
          }
        });
      });
    },

    attachVideo(participant) {
      const _this = this;
      if (!this.participants.map((p) => p.id).includes(participant.identity)) {
        // console.log("---------------------->", res);

        this.participants = [
          ...this.participants,
          {
            id: participant.identity,
            video: true,
            audio: true,
            participant: participant,
          },
        ];

        // this.participants.push({
        //   id: participant.identity,
        //   video: true,
        //   audio: true,
        //   participant: participant,
        // });
      }
      this.addParticipantId(participant, participant.identity);
    },

    addParticipantId(participant, id) {
      const _this = this;
      participant.tracks.forEach((publication) => {
        console.log("ATTACHING TRACKS IN PARTICIPANT ");
        if (publication.track) {
          console.log(
            "$$$$$$$$$$$$$$$$$$$$$$ APPENDING CHILD IN addParticipantId for Id" +
              id
          );
          document.getElementById(id).appendChild(publication.track.attach());
          participant.loaded = true;
        }

        if (publication.isSubscribed) {
          _this.setUpTrackListeners(publication.track, id);
        }

        publication.on("subscribed", (track) => {
          console.log("Track is subscribed");
          _this.setUpTrackListeners(track, id);
        });

        publication.on("unsubscribed", () => {
          console.log("Track is UN-subscribed");
          this.participants.forEach((p) => {
            if (p.id === id) {
              p.video = false;
            }
          });
        });
      });
    },
    setCurrentActiveParticipant(room) {
      const { dominantSpeaker, localParticipant } = room;
      this.setActiveParticipant(dominantSpeaker || localParticipant);
    },
    async joinRoom() {
      const _this = this;
      if (this.stopAll) return;
      Video.connect(this.accessToken, this.connectOptions)
        .then((room) => {
          if (_this.stopAll) {
            room.disconnect();
            return;
          }

          console.log("call to = ", this.callTo);
          //   this.callTo = this.$store.state.users.callTo;
          //   this.$store.commit("users/SET_CALL_TO", null);
          //   if (this.callTo) {
          //     console.log("CALLING -------->  ", this.callTo);
          //     this.$db
          //       .collection("users")
          //       .doc(this.callTo.call_to)
          //       .set(this.callTo);
          //   }

          console.log(room);
          this.room = room;
          const videoChatWindow = document.getElementById("video-chat-window");

          room.localParticipant.videoTracks.forEach((publication) => {
            const element = publication.track.attach();
            this.localTrack = publication.track;
            this.localParticipant = room.localParticipant;
            videoChatWindow.appendChild(element);
          });

          //   Video.createLocalVideoTrack().then((track) => {
          //     this.localTrack = track;
          //     this.localParticipant = room.localParticipant;
          //     videoChatWindow.appendChild(track.attach());
          //   });

          //EXISTING PARTICIPANTS
          room.participants.forEach(async (participant) => {
            console.log(
              `Participant "${participant.identity}" is connected to the Room`
            );
            _this.attachVideo(participant);

            participant.on("trackSubscribed", (track) => {
              console.log(
                "$$$$$$$$$$$$$$$$$$$$$$   APPENDING CHILD IN trackSubscribed(EXISTING) for Id " +
                  participant.identity
              );
              const ele =
                participant.identity === this.localParticipant.identity
                  ? videoChatWindow
                  : document.getElementById(participant.identity);
              ele.innerHTML = "";
              ele.appendChild(track.attach());
            });
          });

          // WHEN NEW PARTICIPANT JOINS
          room.on("participantConnected", (participant) => {
            // const data = new FormData();

            // data.append("email", participant.identity);
            // const res = (await axios.post("/api/get_profile_from_email", data)).data
            //       participant.image = res.image;
            //       participant.name = res.name;
            // axios.post("/api/get_profile_from_email", data).then((r) => {
            //   const res = r.data;
            //   participant.image = res.image;
            //   participant.name = res.name;
            // });

            this.log(participant.identity + " Joined the call");
            this.attachVideo(participant);
            participant.on("trackSubscribed", (track) => {
              console.log(
                " $$$$$$$$$$$$$$$$$$$$$$   APPENDING CHILD IN trackSubscribed(NEW) for Id " +
                  participant.identity
              );
              const ele =
                participant.identity === this.localParticipant.identity
                  ? videoChatWindow
                  : document.getElementById(participant.identity);
              console.log("element " + participant.identity + " = ", ele);
              if (ele == null) return;
              ele.innerHTML = "";
              ele.appendChild(track.attach());
              if (this.participants.length === 1)
                this.$nextTick(() => {
                  if (ele !== videoChatWindow)
                    this.setActiveParticipant(this.participants[0]);
                });
              //    setTimeout(() => {
              //    }, 1000);
            });
            console.log(`Participant connected: ${participant.identity}`);
            this.alertText = participant.identity + " joined the call";
            this.alert = true;
            console.log(
              "Participant has connected total participants  = " +
                this.participants.length
            );
          });

          //WHEN A PARTICIPANT DISCONNECTS
          room.on("participantDisconnected", (participant) => {
            this.log(participant.identity + " disconnected from the call");
            this.alertText =
              participant.identity + " disconnected from the call";
            this.alert = true;
            console.log(
              "PARTICIPANT LENGTH BEFORE " + this.participants.length
            );
            const _this = this;
            this.participants = this.participants.filter(function (p) {
              return (
                p.id !== participant.identity &&
                p.id !== _this.localParticipant.identity
              );
            });
            console.log(`Participant disconnected: ${participant.identity}`);
            console.log("PARTICIPANT LENGTH AFTER " + this.participants.length);
          });

          //WHEN WE DISCONNECT
          room.on("disconnected", (room) => {
            // Detach the local media elements
            this.onDisconnected(room);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onDisconnected(room) {
      clearInterval(this.timer);
      const callto = this.$store.state.users.callTo;
      if (callto) {
        this.$db.collection("users").doc(callto.call_to).update({
          is_calling: false,
        });
      }

      this.log(
        room?.localParticipant?.identity + " disconnected from the call"
      );
      room?.localParticipant?.tracks.forEach((publication) => {
        publication?.track?.stop();
        // JSON.stringify(Video.LocalVideoTrack);
        try {
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
        } catch (error) {}
      });

      try {
        this.stopAll = true;
        this.localTrack.stop();
      } catch (error) {}

      this.callEnded = true;
      if (this.callEnded == true) {
        this.$router.replace({ name: "callEnded" });
      }

      return;
      if (window.history.length === 1) window.close();
      else this.$router.go(-1);
    },

    disconnect() {
      if (this.room) this.room.disconnect();
      else this.onDisconnected(this.room);
      this.end_call_dialog = false;
    },

    chatMenuToggle() {
      this.chat_menu = !this.chat_menu;
    },

    notesMenuToggle() {
      this.notes_menu = !this.notes_menu;
    },

    pauseVideo() {
      this.video_icons = !this.video_icons;
      if (this.video_icons == true) {
        this.room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.enable();
        });
        this.snackbar = {
          message: "Camera is On",
          color: "dark",
          show: true,
        };
      } else {
        this.room.localParticipant.videoTracks.forEach((publication) => {
          publication.track.disable();
        });
        this.snackbar = {
          message: "Camera is Off",
          color: "dark",
          show: true,
        };
      }
    },

    micToggle() {
      this.mic_icons = !this.mic_icons;

      if (this.mic_icons == true) {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.enable();
        });

        this.snackbar = {
          message: "Microphone is On",
          color: "dark",
          show: true,
        };
      } else {
        this.room.localParticipant.audioTracks.forEach((publication) => {
          publication.track.disable();
        });
        this.snackbar = {
          message: "Microphone is Off",
          color: "dark",
          show: true,
        };
      }
    },

    volume() {
      // console.log(Twilio.device.audio)
      var audio_context = null;
      var gain_node = null;

      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      audio_context = new AudioContext();

      gain_node = audio_context.createGain(); // Declare gain node
      gain_node.connect(audio_context.destination);

      console.log(gain_node);

      this.volume_icons = !this.volume_icons;
      //   navigator.mediaDevices.enumerateDevices().then((devices) => {
      //     const audioOutputDevice = devices.find(
      //       (device) => device.kind === "audiooutput"
      //     );

      //     console.log(audioOutputDevice);
      //   });
      if (this.volume_icons == true) {
        this.snackbar = {
          message: "Audio is On",
          color: "dark",
          show: true,
        };
        gain_node.gain.value = 1;
      } else {
        this.snackbar = {
          message: "Audio is Mute",
          color: "dark",
          show: true,
        };
        gain_node.gain.value = 0;
      }
    },

    openCallEndDialog() {
      this.end_call_dialog = true;
    },

    micSelected() {
      this.deviceIds.audio = this.audioDeviceId;
      if (this.localTracks.audio) {
        this.localTracks.audio.stop();
        this.localTracks.audio = null;
      }

      // Resolve the Promise with the saved device ID.
      const deviceId = this.audioDeviceId;
      localStorage.setItem(`audioDeviceId`, deviceId);

      this.mic_setting_dialog = false;
      this.camera_setting_dialog = true;
    },

    cameraSelected() {
      this.deviceIds.video = this.videoDeviceId;
      if (this.localTracks.video) {
        this.localTracks.video.stop();
        this.localTracks.video = null;
      }

      // Resolve the Promise with the saved device ID.
      const deviceId = this.videoDeviceId;
      localStorage.setItem(`videoDeviceId`, deviceId);
      this.camera_setting_dialog = false;

      if (this.firstRun) {
        this.firstRun = false;
        this.getAccessToken();
      }
    },

    close() {
      this.add_user_dialog = false;
      this.end_call_dialog = false;
    },
  };
</script>

<style   scoped>
>>> .call-parent .video-full-screen video {
  object-fit: contain !important;
}

>>> .chat-bbls-launcher .dismiss-user {
  margin-top: 48px;
  margin-left: -11px;
}
</style>
