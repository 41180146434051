<template>
  <div>
    <div class="section-wrapper">
      <v-card-actions class="py-0">
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-btn
          class="elevation-1"
          @click="initialize()"
          fab
          light
          small
          color="white"
        >
          <v-icon color="primary">mdi-sync</v-icon>
        </v-btn>
      </v-card-actions>

      <div class="text-end">
        <p
          v-if="user.account_type == $constants.COORDINATOR"
          class="d-report-text mb-3 mr-3"
        >
          For immediate assistance, contact the Health Service team at 844 485
          3041
        </p>
      </div>

      <v-row v-if="user.account_type != $constants.COORDINATOR" class="mt-3">
        <v-col md="6" sm="6" cols="12" class="pt-0">
          <v-card :to="{ name: 'patients' }" class="dash-cards">
            <div class="d-flex flex-column">
              <div class="d-flex mb-2 mt-2">
                <div class="dash-detail ml-3">
                  <v-progress-circular
                    class="mb-3"
                    v-if="!loaded"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <h1 v-else>{{ patientCount }}</h1>
                  <p class="mb-0">Patients</p>
                </div>
                <v-icon class="mr-3" size="35">mdi-account-group</v-icon>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col md="6" sm="6" cols="12" class="pt-0">
          <v-card
            :to="{
              name: 'appointments',
              params: { dashboard: true },
            }"
            class="dash-cards"
          >
            <div class="d-flex flex-column">
              <div class="d-flex mb-2 mt-2">
                <div class="dash-detail ml-3">
                  <v-progress-circular
                    class="mb-3"
                    v-if="!loaded"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <h1 v-else>{{ appCount }}</h1>
                  <p class="mb-0">Weekly Appointments</p>
                </div>
                <v-icon class="mr-3" size="35">mdi-calendar</v-icon>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col
          v-if="user.account_type == 1"
          md="6"
          sm="6"
          cols="12"
          class="pt-0"
        >
          <v-card :to="{ name: 'locations' }" class="dash-cards">
            <div class="d-flex flex-column">
              <div class="d-flex mb-2 mt-2">
                <div class="dash-detail ml-3">
                  <v-progress-circular
                    class="mb-3"
                    v-if="!loaded"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <h1 v-else>{{ locCount }}</h1>
                  <p class="mb-0">Partner Locations</p>
                </div>
                <v-icon class="mr-3" size="35">mdi-map-marker</v-icon>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col
          v-if="user.account_type == 1"
          md="6"
          sm="6"
          cols="12"
          class="pt-0"
        >
          <v-card :to="{ name: 'users' }" class="dash-cards">
            <div class="d-flex flex-column">
              <div class="d-flex mb-2 mt-2">
                <div class="dash-detail ml-3">
                  <v-progress-circular
                    class="mb-3"
                    v-if="!loaded"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <h1 v-else>{{ coordCount }}</h1>
                  <p class="mb-0">Users</p>
                </div>
                <v-icon class="mr-3" size="35">mdi-account-circle</v-icon>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="en-card mt-4" flat>
            <v-row>
              <v-col class="pt-0" cols="12" sm="8" md="8">
                <h5>Upcoming Appointments</h5>
              </v-col>
              <v-col
                class="text-md-end text-sm-end text-start pt-0"
                cols="12"
                md="4"
                sm="4"
              >
                <v-btn
                  v-if="user.account_type != $constants.COORDINATOR"
                  :to="{
                    name: 'appointments',
                    params: { dashboard: true },
                  }"
                  depressed
                  x-small
                  >View All</v-btn
                >
              </v-col>
            </v-row>

            <v-data-table
              hide-default-footer
              :headers="apptHeaders"
              :items="appointments"
              :items-per-page="5"
              class="elevation-0 mt-4"
            >
              <template v-slot:item.StartDate="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.StartTime,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.StartTime="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.StartTime,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("h:mm a")
                  }}
                </p>
              </template>
              <template v-slot:item.AppointmentStatus="{ item }">
                <v-btn x-small dark depressed :color="statusColors(item)">{{
                  item.AppointmentStatus
                }}</v-btn>
              </template>
            </v-data-table>
            <!-- </div> -->
          </v-card>
        </v-col>

        <!-- Need Appointments Start -->
        <v-col
          v-if="user.account_type == $constants.COORDINATOR"
          cols="12"
          md="6"
        >
          <v-card class="en-card mt-4" flat>
            <v-row>
              <v-col class="pt-0" cols="12" sm="8" md="8">
                <h5>Need Appointments</h5>
              </v-col>
              <v-col
                class="text-md-end text-sm-end text-start pt-0"
                cols="12"
                md="4"
                sm="4"
              >
                <!--    :to="{
                                        name: 'appointments',
                                        params: { dashboard: true }
                                    }" -->
                <v-btn
                  v-if="user.account_type != $constants.COORDINATOR"
                  @click="RequestAptDialog()"
                  depressed
                  x-small
                  color="primary"
                  >Request Appointment</v-btn
                >
              </v-col>
            </v-row>

            <v-data-table
              hide-default-footer
              :headers="needApptHeader"
              :items="n_appointments"
              :items-per-page="5"
              class="elevation-0 mt-4"
            >
              <template v-slot:item.StartDate="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.StartTime,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.StartTime="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.StartDate,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("h:mm a")
                  }}
                </p>
              </template>
              <template v-slot:item.AppointmentStatus="{ item }">
                <v-btn x-small dark depressed :color="statusColors(item)">{{
                  item.AppointmentStatus
                }}</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <!-- Need Appointments End-->

        <!-- Your Providers Sections -->
        <v-col
          v-if="user.account_type == $constants.COORDINATOR"
          cols="12"
          md="6"
        >
          <YourProvider ref="c1" />
        </v-col>

        <!-- Downloads and Reports -->
        <v-col
          v-if="user.account_type == $constants.COORDINATOR"
          cols="12"
          md="6"
        >
          <DownloadsAndReports />
        </v-col>

        <v-col
          v-if="user.account_type != $constants.COORDINATOR"
          cols="12"
          md="6"
        >
          <v-card class="en-card mt-4" flat>
            <v-row>
              <v-col class="pt-0" cols="12" md="8" sm="8">
                <h5>Recent 5 Patients</h5>
              </v-col>
              <v-col
                class="text-md-end text-sm-end text-start pt-0"
                cols="12"
                md="4"
                sm="4"
              >
                <v-btn :to="{ name: 'patients' }" depressed x-small
                  >View All</v-btn
                >
              </v-col>
            </v-row>

            <v-data-table
              hide-default-footer
              :headers="patientHeaders"
              :items="patientsData"
              :items-per-page="5"
              class="elevation-0 mt-4"
            >
              <template v-slot:item.StartDate="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.StartTime,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.StartTime="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.StartTime,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("h:mm a")
                  }}
                </p>
              </template>

              <template v-slot:item.AppointmentStatus="{ item }">
                <v-btn x-small dark depressed :color="statusColors(item)">{{
                  item.AppointmentStatus
                }}</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>

      <div>
        <v-toolbar class="mt-3" flat color="white">
          <h4 class="ma-0 mr-3">Sync Recent Logs</h4>
          <v-chip v-if="last_sync_data" small>
            {{ last_sync_data.created_at | moment("MM/DD/YYYY - h:mm a") }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn @click="getSyncData()" color="primary">Sync All</v-btn>
        </v-toolbar>
      </div>
    </div>

    <v-dialog v-model="apt_req_dialog" max-width="400">
      <v-card>
        <v-card-title>Appointment Request</v-card-title>
        <v-divider></v-divider>
        <v-textarea
          class="p-3"
          v-model="message"
          label="Your Message"
          filled
          :error-messages="messageErrors"
          @input="$v.message.$touch()"
          @blur="$v.message.$touch()"
          required
        ></v-textarea>

        <v-card-text>{{ apt_req_dialog_text }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="RequestAppointment()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import YourProvider from "./partner_sections/YourProviders.vue";
import DownloadsAndReports from "./partner_sections/DownloadsAndReports.vue";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    YourProvider,
    DownloadsAndReports,
  },
  data: () => ({
    patientsData: [],
    patientCount: 0,
    bread: [
      {
        text: "Dashboard",
        disabled: true,
        to: "",
      },
    ],
    loaded: false,
    locations: [],
    appCount: 0,
    locCount: 0,
    coordCount: 0,
    moment: moment,
    appointments: [],
    n_appointments: [],
    apptHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "PatientFullName",
        width: "220px",
      },
      { text: "Appt. Date", value: "StartDate", width: "150px" },
      { text: "Appt. Time", value: "StartTime", width: "150px" },
      { text: "Location", value: "ServiceLocationName", width: "150px" },
      // { text: "Status", value: "AppointmentStatus", align: "end" }
      // {
      //   text: "Reason",
      //   value: "AppointmentReason1",
      //   align: "end",
      //   width: "300px",
      // },
    ],

    needApptHeader: [
      {
        text: "Patient Name",
        align: "start",
        sortable: true,
        value: "PatientFullName",
        width: "220px",
      },
      { text: "Last Appt. Date", value: "StartDate", width: "150px" },
      // { text: "Reason", value: "AppointmentStatus", align: "end" },
      {
        text: "Reason",
        value: "AppointmentReason1",
        align: "end",
        width: "300px",
      },
    ],

    patientHeaders: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "patient.FullName",
      },
      { text: "Appt. Date", value: "StartDate" },
      { text: "Appt. Time", value: "StartTime" },
      { text: "Status", value: "AppointmentStatus", align: "end" },
    ],
    last_sync_data: null,

    appt_data: [],
    default_timezon: "America/Chicago",

    apt_req_dialog: false,
    message: "",
    apt_req_dialog_text:
      "A Health Services team member will respond within 24 hours.  For immediate assistance, please call 844 485 3041.",
  }),

  validations: {
    //  message: {
    //   required: requiredIf(function (abc) {
    //     return this.apt_req_dialog == true;
    //   }),
    // },
    message: { required },
  },

  created() {
    this.initialize();
    // console.log("user data===",this.user);
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),

    messageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("Message is required");
      return errors;
    },
  },
  methods: {
    initialize() {
      this.$store.dispatch("global/actionloaderState", true, {
        root: true,
      });
      this.$store
        .dispatch("global/post", {
          url: "/api/get_dashboard",
          body: new FormData(),
        })
        .then((r) => {
          let data = r.data.data;
          this.appCount = data.appCount;
          this.locCount = data.locCount;
          this.coordCount = data.coordCount;
          this.patientsData = data.patientsData;
          this.appointments = data.appointments;
          this.n_appointments = data.n_appointments;
          this.locations = data.locations;
          this.patientCount = data.patientCount;
          this.last_sync_data = data.last_sync_data;
          this.loaded = true;
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((error) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });

      //Call parent component to children component method
      if (this.$refs.c1 != undefined) {
        this.$refs.c1.initialize();
      }
    },

    getSyncData() {
      this.$store.dispatch("global/actionloaderState", true, {
        root: true,
      });
      axios
        .get("/api/getAllSyncData")
        .then((res) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((e) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },

    // Status Colors
    statusColors(item) {
      switch (item.AppointmentStatus) {
        case "Confirmed":
          return "success";
          break;
        case "CheckedIn":
          return "#3E63A3";
          break;
        case "Scheduled":
          return "orange";
          break;
        case "NoShow":
          return "grey";
          break;
        case "Cancelled":
          return "red";
          break;
        case "Rescheduled":
          return "secondary";
          break;
        case "CheckedOut":
          return "brown";
          break;
        case "ReminderSent":
          return "dark";
          break;
        case "Unknown":
          return "#933F64";
          break;
        case "Roomed":
          return "#234E59";
          break;
        case "NeedsReschedule":
          return "secondary";
          break;
        case "ReadyToBeSeen":
          return "#E07C7D";
          break;
        case "Tentative":
          return "#C7530E";
          break;

        default:
      }
    },

    RequestAptDialog() {
      this.apt_req_dialog = true;
    },

    async RequestAppointment() {
      if (this.$v.message.$invalid) {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: "please type any message",
        });
        return;
      } else {
        const fd = new FormData();
        fd.append("message", this.message);
        const item = {
          url: "/api/create_req_appointment",
          body: fd,
        };
        let res = await this.$store.dispatch("global/post", item);
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: res.data.message,
        });
      }

      //Note Start: Additional Check isGroupChatScr is used for when partner need an appointent btn click(From Dashboard)
      // if (this.user) {
      //     this.user.isGroupChatScr = true;
      //     this.$router.push({
      //         name: "chat",
      //         params: {
      //             currentParticipant: this.user
      //         }
      //     });
      // }
      //Note End:
      this.close();
    },

    close() {
      this.message = "";
      this.apt_req_dialog = false;
      this.$v.$reset();
    },
  },
};
</script>

<style scoped></style>
