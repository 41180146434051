<template>
   <v-card>
      <v-card-actions class="px-4 py-4">
            <v-card-title class="pa-0"></v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="onClose()">mdi-close</v-icon>
          </v-card-actions>
        <v-card-text class="pt-5 d-flex flex-column align-center">
          <div :class="[ !disableRecButton ? 'record-voice ' : 'record-voice-anim']">
            <v-icon color="white" size="40">mdi-microphone</v-icon>
          </div>
           
          <span class="mt-4">1:30</span>

          <div class="d-flex mt-5">
            <v-btn :disabled="disablePlayButton" class="elevation-1 mx-3" @click="voicePlayPause()" fab light small color="white">
              <v-icon color="primary">{{ isPlaying?'mdi-pause':'mdi-play'}}</v-icon>
            </v-btn>

            <v-btn :disabled="disableStopButton" class="elevation-1 mx-3" @click="stopRecording()" fab light small color="white">
              <v-icon :color="voice_stop ? 'grey': 'red'">mdi-stop</v-icon>
            </v-btn>

            <v-btn :disabled="disableRecButton" class="elevation-1 mx-3" @click="startRecording()" fab light small color="white">
              <v-icon color="primary">mdi-record</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!blob" color="primary" block @click="stopRecording();onSave(blob)">Save</v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
 import RecordRTC from 'recordrtc';
export default {
    props:['onSave','onClose'],
data:()=>({
    voice_stop: false,
    activeVoiceClass: "record-voice-anim",
    isPlaying: false,
    disableRecButton:false,
    disableStopButton:true,
    disableDownloadButton:false,
    disablePlayButton:true,
    delete_dialog: false,
    blob:null,
    isSafari:false,
    recorder:null,
    isEdge:false,
    microphone:null,
    audio:null,
}),
created(){
  this.audio = document.querySelector('audio');
  this.isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveOrOpenBlob || !!navigator.msSaveBlob);
  this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
},

methods:{
     voicePlayPause() {
      
      if(this.isPlaying){
        this.audio.pause();
      }else{
        this.audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },

    voiceStop() {
      this.voice_stop = true;
    },

    voiceNew() {},


    startRecording() {
    if (!this.microphone) {
        const _this = this;
        this.captureMicrophone(function(mic) {
            _this.microphone = mic;

            if(_this.isSafari) {
                _this.replaceAudio();

                _this.audio.muted = true;
                _this.audio.srcObject = _this.microphone;

                _this.disableRecButton = false;
               
                alert('Please click startRecording button again. First time we tried to access your microphone. Now we will record it.');
                return;
            }

            _this.startRecording();
        });
        return;
    }
  this.disableRecButton = true;
    this.disablePlayButton= true;
    this.replaceAudio();

    this.audio.muted = true;
    this.audio.srcObject = this.microphone;

    var options = {
        type: 'audio',
        numberOfAudioChannels: this.isEdge ? 1 : 2,
        checkForInactiveTracks: true,
        bufferSize: 16384
    };

    if(this.isSafari || this.isEdge) {
        options.recorderType = StereoAudioRecorder;
    }

    if(navigator.platform && navigator.platform.toString().toLowerCase().indexOf('win') === -1) {
        options.sampleRate = 48000; // or 44100 or remove this line for default
    }

    if(this.isSafari) {
        options.sampleRate = 44100;
        options.bufferSize = 4096;
        options.numberOfAudioChannels = 2;
    }

    if(this.recorder) {
        this.recorder.destroy();
        this.recorder = null;
    }

    this.recorder = RecordRTC(this.microphone, options);

    this.recorder.startRecording();

   this.disableStopButton = false;
   this.disableDownloadButton = true;
   
},
    captureMicrophone(callback) {
      //  this.disableReleaseButton = false;

       if(this.microphone) {
            callback(microphone);
            return;
        }

       if(typeof navigator.mediaDevices === 'undefined' || !navigator.mediaDevices.getUserMedia) {
           alert('This browser does not supports WebRTC getUserMedia API.');

            if(!!navigator.getUserMedia) {
                alert('This browser seems supporting deprecated getUserMedia API.');
           }
         return;
        }
       navigator.mediaDevices.getUserMedia({
            audio: this.isEdge ? true : {
                echoCancellation: false
           }
        }).then(function(mic) {
           callback(mic);
        }).catch(function(error) {
           alert('Unable to capture your microphone. Please check console logs.');
               console.error(error);
       });
},
    replaceAudio(src) {
    var newAudio = document.createElement('audio');
    newAudio.controls = true;
    newAudio.autoplay = true;

    if(src) {
        console.log("SOURCE URL  = "+JSON.stringify(src));
        newAudio.src = src;
    }
    console.log("AUDIO  = "+(this.audio));
    
    var parentNode = this.audio.parentNode;
    parentNode.innerHTML = '';
    parentNode.appendChild(newAudio);

    this.audio = newAudio;
},

    stopRecording() {
      this.disableStopButton = true;
      this.recorder.stopRecording(this.stopRecordingCallback);
    },
    stopRecordingCallback() {
      this.blob = this.recorder.getBlob()
      this.replaceAudio(URL.createObjectURL(this.blob));
     
     this.disableRecButton = false;
    const _this = this;
     setTimeout(function() {
         if(!_this.audio.paused) return;

        setTimeout(function() {
            if(!_this.audio.paused) return;
            _this.audio.play();
        }, 1000);
        
        _this.audio.play();
    }, 300);

    this.audio.play();
    this.disablePlayButton = false;
    this.isPlaying = true;
    this.disableDownloadButton = false;

    
        this.releaseMicrophone();
    
},
    releaseMicrophone() {
  
    //  this.disableReleaseButton = true;
     this.disableRecButton = false;

     if(this.microphone) {
          this.microphone.stop();
         this.microphone = null;
     }

    if(this.recorder) {
      this.stopRecording()
        // click(btnStopRecording);
    }
},

}
}
</script>

<style>

</style>