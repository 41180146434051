<template>
  <v-app>
    <div class="en-session-clear">
      <v-container>
        <v-row>
          <v-col
            order="2"
            order-md="1"
            class="en-session-detail"
            cols="12"
            md="6"
          >
            <h1>Clear Cookies</h1>
            <p>This will log you out from your current session</p>
            <div>
              <v-btn
                v-if="!clear_cookies"
                :loading="clear"
                @click="clearCookies"
                :disabled="clear"
                large
                depressed
                tile
                color="primary"
                >Clear Cookies</v-btn
              >
              <v-btn
                @click="logout()"
                v-if="clear_cookies"
                large
                depressed
                tile
                color="primary"
                >Go back to login</v-btn
              >
            </div>
          </v-col>

          <v-col
            order="1"
            order-md="2"
            class="en-session-banner"
            cols="12"
            md="6"
          >
            <img :src="'/assets/cookies.svg'" alt />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    clear: false,
    clear_cookies: false,
    snackbar: {
      message: "",
      color: null,
      show: false,
    },
  }),

  methods: {
    clearCookies() {
      this.clear = true;

      axios.get("/api/clear").then(async (r) => {
        this.clear = false;
        this.clear_cookies = true;
        await this.logout();
        localStorage.clear();
        sessionStorage.clear();
        this.deleteAllCookies();
        this.$router.replace({ name: "login" });
      });
    },

    deleteAllCookies() {
      var cookies = document.cookie;
      for (var i = 0; i < cookies.split(";").length; ++i) {
        var myCookie = cookies[i];
        var pos = myCookie.indexOf("=");
        var name = pos > -1 ? myCookie.substr(0, pos) : myCookie;

        console.log("name>>>>>>>>>>>>>" + myCookie);
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },

    async logout() {
           //alert("Session clear");
      this.logout_dialog = false;
      try {
        await this.$store.dispatch("currentUser/logout");
      } catch (error) {}
    },
  },
};
</script>
