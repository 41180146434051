<template>
  <div>
    <div class="d-flex mb-5">
      <v-icon size="25" color="primary">mdi-account</v-icon>
      <h5 class="mb-0 ml-2">My Profile</h5>
    </div>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      show-arrows
    >
      <v-tab v-for="tabs in tab_items" :key="tabs">{{ tabs }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card class="en-card elevation-0">
          <General />
        </v-card>
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card class="en-card elevation-0">
          <Password />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import General from "./my_profile_tab/MyProfileGeneral";
import Password from "./my_profile_tab/MyProfilePassword";
export default {
  components: {
    General,
    Password
  },
  data: () => ({
    tab_items: ["General", "Password"],
  }),
  computed: {
    tab: {
      get: function () {
        return this.$store.state.global.tabs.myProfileTabs;
      },
      set: function (value) {
        var setTab = {
          type: 8,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },
};
</script>
