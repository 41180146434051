import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import App from "../view/App";
import Dashboard from "../view/Dashboard";
import patientReport from "../view/PatientReport";
import Downloads from "../view/Downloads";
import Patients from "../view/Patients";
import LocationsGroups from "../view/LocationsGroups";
import Appointments from "../view/Appointments";
import GeneralSettings from "../view/GeneralSettings";
import Login from "../view/Login";
import VideoCall from "../view/VideoCall.vue"; //"../view/agora/components/Home"
import VideoCall2 from "../view/videoCall/VideoCall.vue"; //"../view/agora/components/Home"
import LockScreen from "../view/LockScreen";
import ForgotPassword from "../view/ForgotPassword";
import Users from "../view/Users";
import PartnerCharges from "../view/partner_sections/PartnerCharges.vue"
import PartnerAppointment from "../view/partner_sections/PartnerAppointment.vue"
import Partners from "../view/users_tabs/Partners";
import Providers from "../view/users_tabs/Providers";
import AllNotifications from "../view/AllNotifications";
import Locations from "../view/Locations";
import Notes from "../view/Notes";
import SessionClear from "../view/SessionClear";
import FaxTemplates from "../view/FaxTemplates";
import AddFaxTemplate from "../view/AddFaxTemplate";
import LogViewer from "../view/LogViewer";
import ChatSection from "../view/chat/ChatSection";
import CallEnded from "../view/CallEnded";

import ChatNew from "../view/chat_new/index"

import LogApp from "../view/Logs/LogApp";
import Attachment from "../view/Logs/Attachment";
import LogInfo from "../view/Logs/LogInfo";

// AppointmentRequests
import AppointmentRequests from "../view/apt_req/AppointmentRequests";
import AppointmentChat from "../view/apt_req/AppointmentChat";

// Lookup Tables
import LookupTables from "../view/LookupTables";
import AdminNotes from "../view/lookups_table_sections/AdminNotes";
import VisitReason from "../view/lookups_table_sections/VisitReason";
import ProcedureCodes from "../view/lookups_table_sections/ProcedureCodes";

import PageNotFound from "../view/404Page.vue";

import store from "../store/index";

Vue.prototype.Routes = window.routes;

const routes = [
    { path: "/login", name: "login", component: Login },

    {
        path: "/forgot-password",
        name: "forgotPassword",
        component: ForgotPassword
    },
    {
        path: "/session-clear",
        name: "sessionClear",
        component: SessionClear
    },

    {
        path: "/app",
        name: "app",
        component: App,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                component: Dashboard,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/patients",
                name: "patients",
                component: Patients,
                meta: { requirePin: true, requireAuth: true }
            },

            {
                path: "/locations-groups",
                name: "locationsGroups",
                component: LocationsGroups,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/appointments",
                name: "appointments",
                component: Appointments,
                props: true,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/users",
                name: "users",
                component: Users,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/providers",
                name: "providers",
                component: Providers,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/partners",
                name: "partners",
                component: Partners,
                meta: { requirePin: true, requireAuth: true }
            },

            {
                path: "/log-app",
                name: "logApp",
                component: LogApp,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/patient-report",
                name: "patientReport",
                component: patientReport,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/download-reports",
                name: "downloadReports",
                component: Downloads,
                meta: { requirePin: true, requireAuth: true }
            },

            {
                path: "/Attachment",
                name: "Attachment",
                component: Attachment,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/log-info",
                name: "logInfo",
                component: LogInfo,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/appointment-requests",
                name: "AppointmentRequests",
                component: AppointmentRequests,
                props: true,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/appointment-chat",
                name: "AppointmentChat",
                component: AppointmentChat,
                props: true,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/general-settings",
                name: "generalSettings",
                component: GeneralSettings,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/partner-charges",
                name: "partnerCharges",
                component: PartnerCharges,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },
            {
                path: "/partner-appointment",
                name: "partnerAppointment",
                component: PartnerAppointment,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },
            {
                path: "/partner-appointment",
                name: "noshowprovider",
                component: PartnerAppointment,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },

            {
                path: "/faxTemplates",
                name: "faxTemplates",
                component: FaxTemplates,
                meta: { requirePin: true, requireAuth: true }
            },

            {
                path: "/addFaxTemplate",
                name: "addFaxTemplate",
                component: AddFaxTemplate,
                meta: { requirePin: true, requireAuth: true }
            },

            {
                path: "/all-notifications",
                name: "allNotifications",
                component: AllNotifications,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/locations",
                name: "locations",
                component: Locations,
                meta: { requirePin: true, requireAuth: true }
            },
            {
                path: "/notes/:id",
                name: "notes",
                component: Notes,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },

            // LookupTables
            {
                path: "/lookup-tables",
                name: "lookupTables",
                component: LookupTables,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },
            {
                path: "/admin-notes",
                name: "adminNotes",
                component: AdminNotes,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },
            {
                path: "/visit-reason",
                name: "visitReason",
                component: VisitReason,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },
            {
                path: "/procedure-codes",
                name: "procedureCodes",
                component: ProcedureCodes,
                meta: { requirePin: true, requireAuth: true },
                props: true
            },
            {
                path: "/entouch-logViewer",
                name: "entouch-logViewer",
                component: LogViewer,
                meta: { requireAuth: true }
            },

            {
                path: "/lock",
                name: "lockScreen",
                component: LockScreen,
                meta: { requireAuth: true }
            },
            {
                path: "/video-calling/:id",
                name: "videoCall",
                component: VideoCall,
                meta: { requireAuth: true },
                props: true
            },
            {
                path: "/video-calling-twilio/:id",
                name: "videoCall",
                component: VideoCall2,
                meta: { requireAuth: true },
                props: true
            },

            {
                path: "/chat",
                name: "chat",
                component: ChatNew,
                meta: { requireAuth: true },
                props: true
            }
        ]
    },

    {
        path: "/call-ended",
        name: "callEnded",
        component: CallEnded
    },

    { path: "*", name: "pageNotFound", component: PageNotFound }
];

const router = new VueRouter({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: routes
});

function checkPin(to, next) {
    if (to.meta.requirePin && localStorage.getItem("checkPin") == 1) {
        if (store.state.currentUser.user.usePin) {
            next({ name: "lockScreen" });
        } else {
            next();
        }
    } else {
        next();
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {
        store.dispatch("currentUser/checkLocalStorage");
        if (store.state.currentUser.loggedin) checkPin(to, next);
        else next({ name: "login" });
    } else {
        if (to.name == "login" && store.state.currentUser.loggedin)
            next({ name: "dashboard" });
        else next();
    }
});

export default router;
