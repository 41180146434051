<template>
  <div class="en-card mt-4">
    <v-data-table
      :headers="headers"
      :items="providers"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItems"
      class="elevation-0 en-table-card pb-2"
      style="border: none !important"
      :items-per-page="5"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>PDF Reports</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            class="elevation-1 mr-3"
            @click="refresh"
            fab
            light
            small
            color="white"
          >
            <v-icon color="primary">mdi-sync</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.status="{ item }">
        <v-btn x-small dark depressed :color="statusColors(item)">{{
          statusName(item)
        }}</v-btn>
      </template>
      <template v-slot:item.type="{ item }">
        <v-btn x-small depressed>{{ reportType(item) }}</v-btn>
      </template>
      <template v-slot:item.fromDate="{ item }">
        {{ formattedDate(item.fromDate) }}
      </template>
      <template v-slot:item.toDate="{ item }">
        {{ formattedDate(item.toDate) }}
      </template>

      <template v-slot:item.created_at="{ item }">
        <span v-if="item.status == 3">
          {{ item.created_at | moment("MM/DD/YYYY - h:mm a") }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-start align-center">
          <v-icon
            class="mr-3 chat-icon"
            @click="viewPdf(item)"
            size="20"
            color="primary"
            >mdi-information</v-icon
          >

          <img
            @click="downloadXls(item)"
            width="20"
            :src="'/assets/excel_icon.svg'"
            alt=""
          />

          <v-icon
            v-if="item.status == 3"
            class="ml-3 chat-icon"
            @click="downloadPdf(item)"
            size="20"
            color="primary"
            >mdi-download</v-icon
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { log } from "../agora/utils/utils";

export default {
  components: {},
  data: () => ({
    providers: [],
    loading: false,
    options: {},
    totalItems: 0,
    headers: [
      { text: "Start Date", value: "fromDate", align: "start" },
      { text: "End Date", value: "toDate" },
      { text: "Report", value: "type" },
      { text: "Status", value: "status" },
      { text: "Date", value: "created_at" },
      {
        text: "Download",
        align: "start",
        sortable: false,
        value: "actions",
        width: "70px",
      },
    ],
  }),
  watch: {
    options: {
      handler() {
        this.refresh();
        //console.log("Options Handler Called" + JSON.stringify(this.options));
      },
      // deep: true,
    },
  },
  created() {},

  computed: {
    ...mapState({}),
  },

  methods: {
    async refresh() {
      this.loading = true;
      const item = {
        url: "/api/pdf_reports",
        body: new FormData(),
        options: this.options,
      };
      let response = await this.$store.dispatch("global/post", item, {
        root: true,
      });
      this.totalItems = response.data.data.total;
      this.providers = response.data.data.data;
      this.loading = false;
    },

    downloadXls(item) {
      var api_url = "/api/getPdfReportData?uuid=" + item.pdf_uuid;
      window.open(api_url, data);
    },

    // downloadPdf(item) {
    //   this.loading = true;
    //   this.$store
    //     .dispatch("global/get", {
    //       url: "/api/download_pdf_report/" + item.pdf_uuid,
    //     })
    //     .then((response) => {
    //       this.loading = false;
    //       window.open(response.data.data);
    //     })
    //     .catch((e) => {
    //       this.loading = false;
    //     });
    // },

    viewPdf(item) {
      this.loading = true;
      this.$store
        .dispatch("global/get", {
          url: "/api/view_pdf_report/" + item.pdf_uuid,
        })
        .then((response) => {
          this.loading = false;
          window.open(response.data.data);
        })
        .catch((e) => {
          this.loading = false;
        });
    },

    downloadPdf(item) {
      let windowName =
        "w_" + Date.now() + Math.floor(Math.random() * 100000).toString();
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", "/api/download_pdf_report");
      form.setAttribute("target", windowName);
      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "id");
      hiddenField.setAttribute("value", item.pdf_uuid);
      form.appendChild(hiddenField);

      var hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("name", "CSRFToken");
      hiddenField.setAttribute(
        "value",
        "OWY4NmQwODE4ODRjN2Q2NTlhMmZlYWEwYzU1YWQwMTVhM2JmNGYxYjJiMGI4MjJjZDE1ZDZMGYwMGEwOA=="
      );
      form.appendChild(hiddenField);
      document.body.appendChild(form);
      var openedWindow = window.open("", windowName);
      form.submit();

      openedWindow.onload = function () {
        openedWindow.window.close();
      };
    },

    // Status Colors
    statusColors(item) {
      switch (item.status) {
        case 0:
          return "gray";
        case 1:
          return "orange";
        case 3:
          return "success";
        case 4:
          return "red";
        case 5:
          return "blue";
        default:
      }
    },
    // Status Colors
    statusName(item) {
      switch (item.status) {
        case 0:
          return "Pending";
        case 1:
          return "Processing";
        case 3:
          return "Completed";
        case 4:
          return "Failed";
        case 5:
          return "Submitted";
        default:
      }
    },
    // Status Colors
    reportType(item) {
      switch (item.type) {
        case 1:
          return "Charges";
        case 2:
          return "No show Appointment";
        case 3:
          return "No show Provider";
        default:
      }
    },
    formattedDate(date) {
      const list = date.split("-");
      return `${list[1]}/${list[2]}/${list[0]}`;
    },
  },
};
</script>

<style scoped>
>>> .v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0px 10px;
  background: #f1f3f6;
}

>>> .v-data-table > .v-data-table__wrapper > table > thead {
  background: white;
}

>>> .v-data-table__wrapper > table > tbody > tr > td {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px 10px 0px 0px !important;
}

>>> .v-data-table__wrapper > table > tbody > tr {
  background: white;
}

@media screen and (max-width: 480px) {
  >>> .v-data-table__wrapper > table > tbody > tr > td {
    border-top: none;
    background-color: white !important;
  }

  >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 16px !important;
  }

  .chat-icon {
    display: none !important;
  }

  >>> .v-data-table__mobile-row__header {
    display: none !important;
  }

  >>> .v-data-table__mobile-row__cell {
    width: 100% !important;
  }
}

/*  iPads, Tablets (Portrait)*/
@media (min-width: 481px) and (max-width: 768px) {
  >>> .v-data-table__wrapper > table > tbody > tr > td {
    border-top: none;
    background-color: white !important;
  }

  >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 16px !important;
  }

  .chat-icon {
    display: none !important;
  }

  >>> .v-data-table__mobile-row__header {
    display: none !important;
  }
}
</style>
