<template>
  <div>
    <div class="d-flex mb-5">
      <v-icon size="25" color="primary">mdi-key</v-icon>
      <h5 class="mb-0 ml-2">API Keys</h5>
    </div>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      show-arrows
    >
      <v-tab v-for="item in tab_items" :key="item">{{ item }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <Kareo />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <EmailKey />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <SmsKey />
      </v-tab-item>
      <!-- <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <VideoKey />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <Chat />
      </v-tab-item> -->
    </v-tabs-items>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import Kareo from "./apis_tabs/Kareo";
import EmailKey from "./apis_tabs/EmailKey";
import SmsKey from "./apis_tabs/SmsKey";
import VideoKey from "./apis_tabs/VideoKey";
import Chat from "./apis_tabs/Chat";

export default {
  components: {
    Kareo,
    EmailKey,
    SmsKey,
    VideoKey,
    Chat,
  },
  data: () => ({
    tab_items: ["Kareo", "Email", "SMS",],// "Video", "Chat"],
  }),

  computed: {
    tab: {
      get: function () {
        return this.$store.state.global.tabs.apisTabs;
      },
      set: function (value) {
        var setTab = {
          type: 7,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },
};
</script>
