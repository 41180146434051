import Axios from "axios";

const state = {
    fax_templates: [],
    tinymc_obj : {} ,
    chipsList : [
        {'meta' : "Name"},
        {'meta' : "Phone"},
        {'meta' : "Email"},
        {'meta' : "Appointment Time"}
    ],
};

const getters = {};

const actions = {
    async getFaxTemplates(context, options) {
        const item = {
            url: "/api/getFaxTemplates",
            body: new FormData(),
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, { root: true });
        context.commit("SET_FAX_TEMPLATES", res.data.data.data);
        return res;
    },

    async getProviders(context, options) {
        const item = {
            url: "/api/get_providers",
            body: new FormData(),
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, { root: true });
        context.commit("SET_PROVIDERS", res.data.data);
        return res;
    }
};

const mutations = {
    SET_FAX_TEMPLATES(state, data) {
        state.fax_templates = data;
    },
    SET_TINYMC_OBJECT(state, obj) {

        state.tinymc_obj = obj;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
