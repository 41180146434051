import Axios from "axios";
import { reject } from "lodash";

const state = {
    is_client: false,
    showLoader: false,
    errorMessage: "",
    setNewPassword: false,
    showLoginSnackBar: {
        show: false,
        message: "",
        color: ""
    },
    user: {},
    loggedin: false
};

const getters = {};

const actions = {

    updateVideoProfile(context,videoProfile){
          context.commit('VIDEO_PROFILE',videoProfile)
          localStorage.setItem("en_user",JSON.stringify(context.state.user))
    },

    async loginApi(context, data) {


            context.commit("SHOW_LOADER", true);
            var formData = new FormData();
            formData.append("email", data.email);
            formData.append("password", data.password);

            await axios.post("api/login", formData).then((response) => {

                if(response.message == 'Request failed with status code 403'){
                context.commit("LOGIN_FAIL", "Invalid Email / Password");

            }else{
                    var user = response.data.user;
                    localStorage.setItem("en_user", JSON.stringify(user));
                    context.commit("IS_CLIENT", true);
                    context.commit("LOGIN_SUCCESS", user);
                }

                context.commit("SHOW_LOADER", false);
                // await context.dispatch("login", user);
            }).catch((error) => {



                context.commit("SHOW_LOADER", false);
                context.commit("LOGIN_FAIL", error.response.data.message);

            });


    },

    async updateProfile(context, data) {
        // console.log(
        //     "UPDATE PROFILE IN STORE CALLED WITH DATA = " + JSON.stringify(data)
        // );
        const params = {
            url: "/api/update_profile",
            body: data,
            showMessage: true
        };
        let r = await context.dispatch("global/post", params, { root: true });
        const user = r.data.user;
        const userData = localStorage.getItem("en_user");
        const objData = JSON.parse(userData);
        const access_token = objData.access_token;
        const refresh_token = objData.refresh_token;
        user.access_token = access_token;
        user.refresh_token = refresh_token;
        localStorage.setItem("en_user", JSON.stringify(user));

        //console.log("Login Response = " + JSON.stringify(r.data));
        await context.dispatch("login", user);
    },

    resetNotificationsCount(context, data) {
        context.commit("RESET_COUNT")
        localStorage.setItem("en_user", JSON.stringify(context.state.user));
        Axios.post('/api/reset_notification_count')
    },

    async updatePassword(context, item) {
        // console.log(
        //     "UPDATE PROFILE IN STORE CALLED WITH DATA = " + JSON.stringify(item)
        // );

        const formData = new FormData();
        formData.append('old_password', item.old_password);
        formData.append('new_password', item.new_password);
        const params = {
            url: "/api/update_password",
            body: formData,
            showMessage: true
        };
        let r = await context.dispatch("global/post", params, { root: true }).then((response) => {

            context.dispatch("global/snackBarState", {
                show: true,
                color: response.data.status == 200 ? "primary" : "red",
                message: response.data.message,
            }, { root: true });


        }).catch((error) => {


        });


    },

    async setStatus(context, { inCall, loggedOut }) {
        if (context.state.loggedin) {
            const data = new FormData();
            data.append("in_call", inCall);
            data.append("logged_out", loggedOut);
        return new Promise(async (resolve, reject) => {

             await Axios.post("/api/update_user_presence", data).then((resp) => {
                resolve(resp);

             }).catch((error) => {

                reject(error)

            });
        });
        }
    },

    login(context, user) {
        context.commit("SHOW_LOADER", false);
        context.commit("LOGIN_SUCCESS", user);
        // await context.dispatch("loadColors");
    },
    async logout(context, call = true) {


        await context.dispatch("setStatus", {
            inCall: 0,
            loggedOut: 1
        });
        //console.log("LOGOUT CALLED");

        if (call) {
          try {
            let r = await axios.post("/api/logout", new FormData());
          } catch (error) {

          }
        }
        localStorage.removeItem("en_user");
        context.commit("LOGGED_OUT");
    },
    async checkLocalStorage(context) {
        const userString = localStorage.getItem("en_user");
        if (userString) {
           // console.log("User Exists");
            const user = JSON.parse(userString);
            await context.dispatch("login", user);
        } else {
            //console.log("User Does not Exists");
        }
    }
};

const mutations = {
    VIDEO_PROFILE(state,profile){
            state.user.video_profile = profile
    },
    RESET_COUNT(state, item) {
        state.user.unreadNotifications = 0;
    },
    SHOW_LOADER(state, status) {
        state.showLoader = status;
    },
    SHOW_LOADER(state, status) {
        state.showLoader = status;
    },
    LOGIN_SUCCESS(state, user) {
        //console.log("User = " + JSON.stringify(user));
        state.user = user;
        state.hasError = false;
        state.loggedin = true;
        state.showLoginSnackBar = {
            show: true,
            color: "primary",
            message: "Login Successfully"
        };
        state.loggedin = true;
    },
    LOGGED_OUT(state) {
        state.user = null;
        state.loggedin = false;
        state.showLoader = false;
        state.hasError = false;
        state.showLoginSnackBar = {
            show: true,
            color: "dark",
            message: "Logged-Out Successfully"
        };
    },
    LOGIN_FAIL(state, errorMessage) {
        state.hasError = true;
        state.errorMessage = errorMessage;
        state.showLoginSnackBar = {
            show: true,
            color: "red",
            message: errorMessage
        };
    },
    IS_CLIENT(state, is_client) {
        state.is_client = is_client;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
