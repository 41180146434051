<template>
  <v-app>
    <div class="call-screen d-lg-flex" @click="closeMobSheetFromOutside()">
      <!-- <v-card>{{ user.email }}</v-card> -->
      <!-- <v-card>{{remoteStreams.map(e=>e.getId())}}</v-card> -->
      <!-- {{activeId}} -->
      <!-- <div style="height:400px;width:400px" id="my-media"></div> -->
      <div class="call-parent w-100 overflow-hidden position-relative">
        <!-- Full Screen  -->
        <div @click.stop="parti_tooltip1 = false" class="video-full-screen">
          <div class="agora-video-player" :id="localId"></div>
        </div>

        <!-- Participants Boxs -->
        <div class="call-scroll">
          <div style="position: relative">
            <v-sheet
              v-for="remoteStream in rs"
              :key="remoteStream.getId()"
              :class="
                remoteStreams.length < 4
                  ? 'call-participants'
                  : 'call-participants-small'
              "
            >
              <v-tooltip color="primary" left>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on" class="partic-video">
                    <div
                      @click.stop="setActiveParticipant(remoteStream)"
                      class="agora-video-player"
                      :id="remoteStream.getId()"
                    ></div>
                  </div>
                </template>
                <span>{{ remoteStream.getId() }}</span>
              </v-tooltip>

              <img
                v-if="!remoteStream.video"
                :src="'/assets/plaeholder-user-box.png'"
                alt=" "
              />

              <!-- Video Boxes Actions -->
              <div class="participent-action-divs">
                <v-icon size="20" color="primary" v-if="!remoteStream.audio">
                  mdi-volume-mute
                </v-icon>

                <!-- Chat Icon -->
                <v-icon
                  v-if="
                    remoteStream &&
                    localStream &&
                    remoteStream.getId() !== localStream.getId()
                  "
                  size="20"
                  :class="remoteStream.audio ? '' : 'ml-2'"
                  @click.stop="addUserForChat(remoteStream.getId())"
                  color="primary"
                  >mdi-android-messages</v-icon
                >

                <!-- Volume Setting Slider (On Box Participents) -->
                <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="20"
                      class="ml-2"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-volume-high
                    </v-icon>
                  </template>
                  <v-list>
                    <div class="vol-card">
                      <v-slider
                        class="mx-2"
                        hide-details=""
                        @change="changeAudioLevel(remoteStream)"
                        v-model="remoteStream.audioLevel"
                        :max="100"
                      ></v-slider>
                    </div>
                  </v-list>
                </v-menu>
              </div>
            </v-sheet>
            <audio autoplay=""></audio>
          </div>
        </div>

        <!-- Showing name who is on full screen -->
        <div v-show="show_comp" class="person-in-full-screen">
          <v-chip small color="primary" label>{{ activeName || "" }}</v-chip>
        </div>

        <div class="call-back-btn">
          <v-snackbar v-model="alert" top left dark
            >{{ alertText }}
          </v-snackbar>
          <v-card v-show="show_local" class="my-video-con">
            <div class="agora-video-player" id="my-media"></div>
          </v-card>

          <v-fab-transition>
            <v-btn
              v-if="show_comp"
              @click.stop="changeCamera()"
              class="mr-3 mt-1"
              color="dark"
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
              dark
              fab
            >
              <v-icon>mdi-camera-switch</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-fab-transition>
            <v-btn
              v-if="activeId && activeId !== user.email && show_comp"
              @click.stop="addUserForChat(activeId)"
              class="mr-3 mt-1"
              color="dark"
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
              dark
              fab
            >
              <v-icon>mdi-android-messages</v-icon>
            </v-btn>
          </v-fab-transition>
        </div>

        <div class="call-users-buttons"></div>

        <div v-if="desktopSheet">
          <div
            v-for="p in participant"
            :key="p.id"
            class="global-chat-section chat-bbls-desktop"
          >
            <div v-for="p in participant" :key="p.id">
              <ChatWidget :participant="p" @closeChatWidget="closeChatWidget" />
            </div>
          </div>
        </div>

        <div class="call-actions">
          <v-fab-transition>
            <v-btn
              v-if="user.account_type !== 3"
              v-show="show_comp"
              @click.stop="addUserDialog()"
              class="mr-3 mr-md-5 mr-sm-5 mt-1"
              color="dark"
              dark
              fab
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
            >
              <v-icon>mdi-account-plus</v-icon>
            </v-btn>
          </v-fab-transition>

          <!-- Pause Video BTN -->
          <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click.stop="pauseVideo()"
              class="mr-3 mr-md-5 mr-sm-5 mt-1"
              color="dark"
              dark
              fab
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
            >
              <v-icon>{{ videoIconToggle }}</v-icon>
            </v-btn>
          </v-fab-transition>

          <!-- DISSCONNECT BTN -->
          <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click.stop="openCallEndDialog()"
              color="red"
              dark
              fab
            >
              <v-icon>mdi-phone-hangup-outline</v-icon>
            </v-btn>
          </v-fab-transition>

          <v-fab-transition v-if="user.account_type == $constants.PROVIDER">
            <!-- CHAT Action BTN -->
            <!-- <v-btn
              v-show="show_comp"
              @click="chatMenuToggle()"
              class="mr-5 mt-1"
              color="dark"
              small
              dark
              fab
            >
              <v-icon>mdi-comment-text</v-icon>
            </v-btn> -->
          </v-fab-transition>

          <!-- MICROPHONE BTN -->
          <v-fab-transition>
            <v-btn
              v-show="show_comp"
              @click.stop="micToggle()"
              class="ml-3 ml-md-5 ml-sm-5 mt-1"
              color="dark"
              :small="$vuetify.breakpoint.smAndUp"
              :x-small="$vuetify.breakpoint.mobile"
              dark
              fab
            >
              <v-icon>{{ micIconToggle }}</v-icon>
            </v-btn>
          </v-fab-transition>

          <!-- Volume Setting Slider (On Full Screen Participent) -->
          <v-menu v-if="currentActiveStream" top offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-fab-transition>
                <v-btn
                  v-show="show_comp"
                  v-bind="attrs"
                  v-on="on"
                  class="ml-3 ml-md-5 ml-sm-5 mt-1"
                  color="dark"
                  :small="$vuetify.breakpoint.smAndUp"
                  :x-small="$vuetify.breakpoint.mobile"
                  dark
                  fab
                >
                  <v-icon>mdi-volume-high</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
            <v-list v-show="show_comp">
              <div v-if="activeId" class="vol-card">
                <v-slider
                  class="mx-2"
                  hide-details=""
                  @change="changeAudioLevel(currentActiveStream)"
                  v-model="currentActiveStream.audioLevel"
                  :max="100"
                ></v-slider>
              </div>
            </v-list>
          </v-menu>

          <!-- Chat BTN -->
          <v-fab-transition>
            <div v-if="client">
              <v-btn
                v-show="show_comp"
                @click.stop="sheet = !sheet"
                class="ml-3 ml-md-5 ml-sm-5 mt-1 chat-bbls-mobile"
                color="dark"
                :small="$vuetify.breakpoint.smAndUp"
                :x-small="$vuetify.breakpoint.mobile"
                dark
                fab
              >
                <v-icon>mdi-message-processing</v-icon>
              </v-btn>
            </div>
          </v-fab-transition>
        </div>

        <!-- This Note panel is hidden -->
        <div v-if="false">
          <v-card class="notes-panel" v-show="notes_menu">
            <v-card-actions>
              <p class="notes-panel-name">Notes</p>
              <v-spacer></v-spacer>
              <v-icon @click.stop="notes_menu = false">mdi-close</v-icon>
            </v-card-actions>
            <v-divider></v-divider>
            <NotesSection />
          </v-card>
        </div>
      </div>

      <div class="user-chat-sidebar" id="chat-show">
        <div class="px-3 px-lg-4 pt-3 pt-lg-4">
          <div class="user-chat-nav text-right">
            <button
              type="button"
              class="btn nav-btn"
              id="user-profile-hide"
              @click.stop="hideChat"
            >
              <i class="ri-close-line"></i>
            </button>
          </div>
        </div>
        <chatvia v-if="showChatPage" :currentParticipant="currentChatUser" />
      </div>
    </div>

    <!-- Show bottom Sheet on Mobile view (Chat bubbles)  chat_wideget_new-->
    <v-bottom-sheet hide-overlay persistent v-model="mobSheet">
      <v-sheet class="text-center" height="109px">
        <div class="py-3">
          <div class="global-chat-section chat-bbls-launcher">
            <div v-for="p in participant" :key="p.id">
              <ChatWidget :participant="p" @closeChatWidget="closeChatWidget" />
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- Participent Add Dialog -->
    <v-dialog v-model="add_user_dialog" max-width="400px">
      <v-card>
        <v-card-title>Add a user to call</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            show-arrows
          >
            <v-tab v-for="tabs in tab_items" :key="tabs">{{ tabs }}</v-tab>
          </v-tabs>
          <v-divider></v-divider>
          <v-tabs-items v-model="tab">
            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <v-row>
                <!-- {{participants.map(e=>e.participant.identity)}} -->
                <v-col cols="12" md="12">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="providerId"
                    label="Select Provider"
                    :items="
                      providers.filter(
                        (r) =>
                          !participants
                            .map((e) => e.participant.identity)
                            .includes(r.mail) &&
                          localParticipant.identity != r.mail
                      )
                    "
                    class="mt-5"
                  >
                    <template v-slot:append="{ item }">
                      <v-icon :color="item.color"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 2 -->
            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <v-row>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    dense
                    outlined
                    v-model="providerId"
                    label="Select Eth office"
                    :items="
                      getBasicUserData.filter(
                        (r) =>
                          !participants
                            .map((e) => e.participant.identity)
                            .includes(r.mail) &&
                          localParticipant.identity != r.mail
                      )
                    "
                    class="mt-5"
                  >
                    <template v-slot:append="{ item }">
                      <v-icon :color="item.color"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click.stop="close()" text>Close</v-btn>
          <v-btn
            depressed
            style="width: 120px"
            @click.stop="addUser(providerId)"
            color="primary"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- END CALL DIALOG -->
    <v-dialog v-model="end_call_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Call End</v-card-title>
        <v-card-text>Are you sure you want to end the call?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click.stop="disconnect()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Microphone Select Dialog -->
    <v-dialog persistent eager v-model="mic_setting_dialog" max-width="500px">
      <v-card>
        <v-card-title>Microphone </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-progress-linear :value="level" />
          <div class="text-center">
            <svg
              id="svg-rect"
              focusable="false"
              viewBox="0 0 100 100"
              aria-hidden="true"
              height="100"
              width="100"
              style="margin: 10px 0"
            >
              <defs>
                <clipPath id="level-indicator">
                  <rect x="0" y="100" width="100" height="100" />
                </clipPath>
              </defs>
              <path
                fill="rgb(220, 220, 220)"
                d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
              ></path>
              <path
                fill="rgb(220, 220, 220)"
                d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
              ></path>
              <path
                fill="#080"
                clip-path="url(#level-indicator)"
                d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
              ></path>
              <path
                fill="#080"
                clip-path="url(#level-indicator)"
                d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
              ></path>
            </svg>
          </div>

          <p class="en-fields-label">Select Audio Device</p>
          <v-select
            v-model="audioDeviceId"
            @change="updateAudioDevice()"
            outlined
            dense
            id="video-devices"
            :items="mics"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click.stop="mic_setting_dialog = false"
            depressed
            style="min-width: 120px"
            color="primary"
            text
            >CANCEL</v-btn
          >
          <v-btn
            @click.stop="micSelected()"
            depressed
            style="min-width: 120px"
            color="primary"
            dark
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Camera Select Dialog -->
    <v-dialog
      persistent
      eager
      v-model="camera_setting_dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>Camera </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <video
            id="video-test"
            class="video-testing-container"
            playsinline
          ></video>

          <v-card-text>
            <p>Select video quality</p>
            <v-slider
              v-if="videoProfiles"
              :hint="videoProfileHint"
              persistent-hint
              @change="sliderChange"
              v-model="sliderValue"
              :max="videoProfiles.length - 1"
              :step="1"
              ticks="always"
              tick-size="2"
            ></v-slider>
          </v-card-text>

          <v-select
            label="Select video profile"
            outlined
            v-show="false"
            v-model="videoProfile"
            :items="videoProfiles"
          ></v-select>
          <p class="en-fields-label">Select Video Device</p>
          <v-select
            outlined
            dense
            v-if="videoDevices.length > 0"
            v-model="videoDeviceId"
            id="video-devices"
            :items="videoDevices"
            @change="updateVideoDevice(videoDeviceId)"
          ></v-select>

          <v-select
            v-else
            outlined
            dense
            id="video-devices"
            :items="['default-camera']"
          ></v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click.stop="camera_setting_dialog = false"
            depressed
            style="min-width: 120px"
            color="primary"
            text
            >CANCEL</v-btn
          >
          <v-btn
            @click.stop="switchCamera(videoDeviceId)"
            depressed
            style="min-width: 120px"
            color="primary"
            dark
            >Apply</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Connecting...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="2000"
      :color="snackbar.color"
      top
      v-model="snackbar.show"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click.stop="snackbar.show = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import ChatWidget from "./ChatWidgetNew.vue";
import Chatvia from "./chat_new/index.vue";
import RTCClient from "../view/agora/agora-rtc-client";
import StreamPlayer from "../view/agora/components/stream-player";
// import Notes from "../view/Notes";

import NotesSection from "./Notes";
// import NotesSection from "./SmallNotesWidget";
import Video from "twilio-video";
import { mapState } from "vuex";
import Axios from "axios";
import micLevel from "./mic_level";

import axios from "axios";
export default {
  components: {
    NotesSection,
    ChatWidget,
    StreamPlayer,
    Chatvia,
  },
  watch: {
    sliderValue(val) {
      this.sliderChange(val);
    },
  },
  data: () => ({
    showChatPage: false,
    chatUsers: [],
    currentChatUser: {},
    loading: false,
    showVol: false,
    sliderValue: 0,
    videoProfile: "",
    audioStream: null,
    level: 0,
    videoDevices: [],
    show_local: true,
    i: 1,
    activeId: null,
    activeName: "",
    showFullScreen: true,
    option: {
      appid: "",
      token: "",
      uid: null,
      channel: "",
    },
    disableJoin: false,
    localStream: null,
    activeStream: null,
    remoteStreams: [],
    rtc: null,
    firstRun: true,
    videoDeviceId: 0,
    audioDeviceId: 0,
    mics: [],
    devices: [],
    mic_setting_dialog: false,
    camera_setting_dialog: false,
    callEnded: false,
    alert: false,
    alertText: "",
    mobSheet: false,
    desktopSheet: false,
    parti_tooltip1: false,
    callTo: {},
    parti_tooltip2: false,
    stopAll: false,
    timer: null,
    providerId: 0,
    image_hover: false,
    activeKey: 0,
    add_user_dialog: false,
    providers: [],
    appId: "",
    accessToken: "",
    chat_menu: false,
    notes_menu: false,
    localParticipant: {},
    localTrack: {},
    participants: [],
    localId: "video-chat-window",
    chat_icon: 1,
    room: null,
    show_comp: true,
    comp_timer: null,
    video_icons: true,
    mic_icons: true,
    volume_icons: true,
    snackbar: {
      show: false,
      message: "",
      color: "",
    },
    tab: null,
    tab_items: ["Providers", "ETH Office"],
    end_call_dialog: false,

    //Chat New Widget
    participant: [
      //   {
      //     email: "rob.thomas@encounter.health",
      //     id: 11,
      //     name: "Rob Thomas",
      //     imageUrl: "https://entouch-web.objects-us-east-1.dream.io/public/images/profile/rob.thomas-encounter.health/c2ab931fb58cafc95ec363e460fea028-79.jpg",
      //   },
    ],
  }),

  computed: {
    currentActiveStream() {
      return this.remoteStreams.find((r) => r.getId() === this.activeId);
    },
    videoProfileHint() {
      return this.videoProfiles[this.sliderValue]
        ? this.videoProfiles[this.sliderValue].text
        : "";
    },
    rs() {
      return this.remoteStreams.filter((r) => r.getId() !== this.activeId);
    },
    isMobile() {
      if (
        typeof navigator === "undefined" ||
        typeof navigator.userAgent !== "string"
      ) {
        return false;
      }
      return /Mobile/.test(navigator.userAgent);
    },
    ...mapState({
      Users: (state) => state.chat.user,
      client: (state) => state.chat.client,
      videoProfiles: (state) => state.generalSetting.videoProfiles,
      user: (state) => state.currentUser.user,
      vProfile: (state) => state.themes.get_general_setting.videoProfile,
      getBasicUserData: (state) =>
        state.users.getBasicUserData.map((r) => {
          return {
            text: r.name,
            value: r.id,
            color: r.color,
            mail: r.email,
          };
        }),
    }),

    // participant() {
    //   return this.Users.filter(
    //     (r) =>
    //       r &&
    //       (this.remoteStreams.map((r) => r.getId()).includes(r.email) ||
    //         this.localStream?.getId() == r.email)
    //   );
    // },

    videoIconToggle() {
      return this.video_icons == true ? "mdi-video" : "mdi-video-off";
    },

    micIconToggle() {
      return this.mic_icons == true ? "mdi-microphone" : "mdi-microphone-off";
    },

    volumeIconToggle() {
      return this.volume_icons == true ? "mdi-volume-high" : "mdi-volume-off";
    },

    currentUserId() {
      return this.user.id;
    },
  },

  mounted() {
    this.videoProfile = this.user.video_profile || this.vProfile;
    this.sliderValue = this.videoProfiles.findIndex(
      (r) => r.value === this.videoProfile
    );
    if (this.isMobile) {
    }

    var channel = this.$pusher.subscribe("my-channel");
    var my_id = this.currentUserId;
    const _this = this;
    //When Pusher Event Call Participent Bubble will be add automatically
    channel.bind("my-event", function (data) {
      //    console.log("pusherrrrrrrrr in video call====", data);
      //   console.log("my id", my_id);
      //   console.log("data to_user id", data.to_user);

      //Trigger Only When Route VideoCall
      if (_this.$route.name == "videoCall") {
        if (my_id == data.to_user) {
          //use for only single(to user)
          //   console.log("Pusher Event in Video call====", data.to_user);
          // console.log("", data.notification.from_user_email);
          _this.addUserForChat(data.notification.from_user_email);
        }
      }
    });

    window.addEventListener("resize", this.setResizeChatUI, { passive: true });
  },
  created() {
    this.option.appid = process.env.MIX_AGORA_ID;
    if (!this.option.appid) {
      alert(
        "App ID not found.Please make sure MIX_AGORA_ID is added in your .env file"
      );
      this.$router.replace({ name: "callEnded" });
      return;
    }
    this.$store.dispatch("users/getBasicUser").then((r) => {});
    this.timer = setInterval(() => {
      this.$store.dispatch("currentUser/setStatus", {
        inCall: 1,
        loggedOut: 0,
      });
    }, 5000);
    this.getProviders();
    this.appId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    document.addEventListener("mousemove", this.myListener, false);
    this.option.channel = this.appId;
    this.option.uid = this.user.email;
    this.initRTC();
    this.initDevices();
  },

  beforeDestroy() {
    this.disconnect();
  },

  methods: {
    closeMobSheetFromOutside() {
    //console.log("closeMobSheetFromOutside");
      if (window.innerWidth < 600) {
          this.mobSheet = false;
      }
    },

    setResizeChatUI() {
      if (window.innerWidth < 600) {
        if (this.participant.length > 0) {
          this.mobSheet = true;
          this.desktopSheet = false;
        }
      } else {
        this.mobSheet = false;
        this.desktopSheet = true;
      }
    },
    hideChat() {
      document.getElementById("chat-show").style.display = "none";
      this.showChatPage = false;
    },
    changeAudioLevel(stream) {
      console.log("calling set audio level", stream.audioLevel);
      stream.setAudioVolume(stream.audioLevel);
    },
    sliderChange(val) {
      console.log(val, this.videoProfiles.length);
      if (this.videoProfiles[val]) {
        this.videoProfile = this.videoProfiles[val].value;
        console.log(this.videoProfiles[val].value);
      }
    },
    //       gotoTwilio(){
    //  let routeData = this.$router.resolve({
    //         path: `/video-calling-twilio/${this.appId}`,
    //       });
    //     //   if (this.user.usePin) {
    //     //     localStorage.setItem("checkPin", 1);
    //     //     this.$router.push({ name: "lockScreen" });
    //     //   }
    //       //   if (this.user.account_type == this.$constants.PROVIDER)
    //       window.open(routeData.href, "_blank");
    //       //   else
    //       // this.$router.push({
    //       //   path: `/video-calling/${slId}`,
    //       // });
    //       },
    changeCamera() {
      this.camera_setting_dialog = true;
      if (this.videoDeviceId) this.updateVideoDevice(this.videoDeviceId);
    },
    async initDevices() {
      this.mics = await this.getInputDevices("audio");
      this.videoDevices = await this.getInputDevices("video");

      this.audioDeviceId = localStorage.getItem("audioDeviceId");
      this.videoDeviceId = localStorage.getItem("videoDeviceId");

      if (!this.audioDeviceId) this.mic_setting_dialog = true;
      else if (!this.videoDeviceId) this.camera_setting_dialog = true;
      else {
        this.firstRun = false;
        this.joinRoom();
      }
    },

    storeVideoDeviceId() {
      const data = new FormData();
      data.append("video_profile", this.videoProfile);
      axios.post("/api/setVideoProfile", data);
      this.$store.dispatch("currentUser/updateVideoProfile", this.videoProfile);
    },

    switchCamera(deviceId) {
      this.camera_setting_dialog = false;
      localStorage.setItem("videoDeviceId", deviceId);
      this.storeVideoDeviceId();
      if (this.firstRun) this.localStream?.getVideoTrack()?.stop();
      this.localStream?.switchDevice("video", deviceId);
      this.localStream?.setVideoProfile(this.videoProfile);
      if (this.firstRun) {
        this.firstRun = false;
        this.joinRoom();
      }
      this.stop();
    },

    titleCase(str) {
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    },

    setActiveParticipant(rs) {
      console.log("Called setActive part");
      let currentActive = this.remoteStreams.find(
        (r) => r.getId() === this.activeId
      );
      currentActive.stop();
      rs.stop();
      rs.play(this.localId);
      this.activeId = rs.getId();
      this.activeName = this.getActiveName(this.activeId);

      console.log("Active Name = " + this.activeName);

      this.$nextTick(() => {
        currentActive.play(currentActive.getId());
      });
    },

    getActiveName(mail) {
      var new_str = mail.split("@")[0];
      new_str = new_str.replace(".", " ");

      var activeName = this.titleCase(new_str);
      return activeName;
    },

    initRTC() {
      this.loading = true;
      this.rtc = new RTCClient();
      let rtc = this.rtc;
      rtc.getDevices((devices) => {
        console.log(
          "AST-1 devices =====> ",
          devices.filter((r) => r.kind === "videoinput")
        );
        this.videoDevices = devices
          .filter((r) => r.kind === "videoinput")
          .map((r, i) => {
            return { value: r.deviceId, text: r.label || "Camera " + i };
          });
          console.log("AST-1 video devices =====> ",this.videoDevices);
      });
      
      rtc.on("stream-added", (evt) => {
        let { stream } = evt;
        console.log(
          "AST-7 [agora] [stream-added] stream-added",
          stream.getId()
        );
        rtc.client.subscribe(stream);
        // console.log('Audio level',stream.getAudioLevel());
      });

      rtc.on("stream-subscribed", async (evt) => {
        console.log("AST-8 Stream subscribed", evt);
        let { stream } = evt;
        stream.audioLevel = 100;
        console.log(
          "AST-8 [agora] [stream-subscribed] stream-added",
          stream.getId()
        );
        console.log(
          "AST-8 remote streams length before push = " +
            this.remoteStreams.length
        );
        if (!this.remoteStreams.find((r) => r.getId() == stream.getId())) {
          this.remoteStreams.push(stream);
        }
        this.loading = false;
        console.log(
          "AST-8 remote streams length After push = " +
            this.remoteStreams.length
        );
        if (this.activeId === stream.getId()) {
          this.$nextTick(() => {
            stream.play(this.localId);
            console.log(this.localId + " Playing = ", stream);
          });
        } else {
          if (this.remoteStreams.length === 1) {
            console.log(
              "AST-8 Remote stream length = ",
              this.remoteStreams.length
            );
            this.show_local = true;
            if (this.localStream && this.localStream.isPlaying()) {
              this.localStream.stop();
              this.localStream.play("my-media");
            }

            this.$nextTick(() => {
              stream.play(this.localId);
              console.log("Stream playing in " + this.localId, stream);
              this.activeId = stream.getId();
              this.activeName = this.getActiveName(this.activeId);
            });
          } else {
            this.$nextTick(() => {
              stream.play(stream.getId());
              console.log(stream.getId() + " plaiying " + stream);
            });
          }
        }
        //if (stream.getId() == this.activeId) this.activeStream = stream;
      });

      rtc.on("stream-removed", (evt) => {
        let { stream } = evt;
        console.log("Stream REMOVED", evt);
        console.log("[agora] [stream-removed] stream-removed", stream.getId());
        // this.remoteStreams = this.remoteStreams.filter(
        //   (it) => it.getId() !== stream.getId()
        // );
      });

      rtc.on("peer-online", (evt) => {
        console.log(`Peer ${evt.uid} is online`);
      });

      rtc.on("peer-leave", async (evt) => {
        console.log(`Peer ${evt.uid} left`);
        this.remoteStreams.find((r) => r.getId() === evt.uid)?.stop();
        this.remoteStreams = this.remoteStreams.filter(
          (r) => r.getId() !== evt.uid
        );
        if (this.remoteStreams.length > 0) {
          await this.$nextTick();
          this.remoteStreams[0].stop();
          this.remoteStreams[0].play(this.localId);
          this.activeId = this.remoteStreams[0].getId();
          this.activeName = this.getActiveName(this.activeId);
        }
      });

      rtc.on("mute-audio", (evt) => {
        this.remoteStreams.find((r) => r.getId() === evt.uid).audio = false;
      });

      rtc.on("unmute-audio", (evt) => {
        this.remoteStreams.find((r) => r.getId() === evt.uid).audio = true;
      });

      rtc.on("mute-video", (evt) => {
        this.remoteStreams.find((r) => r.getId() === evt.uid).video = false;
      });

      rtc.on("unmute-video", (evt) => {
        this.remoteStreams.find((r) => r.getId() === evt.uid).video = true;
      });
    },

    joinC() {
      console.log("AST-nav1 Navigator media Devices");
      // navigator.mediaDevices.enumerateDevices().then(this.gotDevices);
      navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        this.gotDevices(mediaDevices);
      });
    },

    async getInputDevices(kind) {
      console.log("AST-nav2 Navigator media Devices");
      return (await navigator.mediaDevices.enumerateDevices())
        .filter((e) => e.kind === `${kind}input`)
        .map((e, i) => {
          return {
            text: e.label || (kind === "audio" ? "Mic" : "Camera") + ` ${i}`,
            value: e.deviceId,
          };
        });
    },

    async updateVideoDevice(cameraId) {
      var doc = document.getElementById("video-test");

      try {
        //   doc.srcObject={};
        doc.srcObject = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: cameraId },
        }); //.filter(r=>r.deviceId===cameraId)[0].stream;
        doc.onloadedmetadata = (e) => {
          doc.play();
        };
      } catch (error) {
        console.log("AST-nav3 Navigator media Devices error === ", error);
      }
    },

    async updateAudioDevice() {
      console.log("AST-nav4 Navigator media Devices");
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: this.audioDeviceId },
      });
      this.audioStream = stream;
      const levelIndicator = document.getElementById("svg-rect");
      const maxLevel = Number(levelIndicator.getAttribute("height"));

      micLevel(stream, maxLevel, (level) => {
        // console.log('onlevel getting called',maxLevel-level);
        this.level = (level / maxLevel) * 100;
        // levelIndicator.setAttribute('height', (level))
      });
    },

    gotDevices(mediaDevices) {
      let count = 1;
      this.devices = mediaDevices
        .filter((e) => e.kind === "videoinput")
        .map((e, i) => {
          return {
            text: e.label || `Camera ${i}`,
            value: e.deviceId,
          };
        });

      this.mics = mediaDevices
        .filter((e) => e.kind === "audioinput")
        .map((e, i) => {
          return {
            text: e.label || `Camera ${i}`,
            value: e.deviceId,
          };
        });
    },

    //Add Bubble Chat User
    async addUserForChat(email) {
      // console.log("Opend Chat Bubble====", email);
      // console.log("Vuetify Width====== ", this.$vuetify.breakpoint.width);
      // console.log("start==",this.mobSheet);
      // console.log("particiepent length=", this.participant.length);
      if (this.$vuetify.breakpoint.width < 600) {
        this.mobSheet = true;
        this.desktopSheet = false;
      } else {
        this.mobSheet = false;
        this.desktopSheet = true;
      }
      const data = new FormData();
      data.append("email", email);
      let user = this.chatUsers.find((e) => e.email == email);
      if (!user) {
        user = (await axios.post("/api/get_single_user", data)).data.data[0];
        this.chatUsers.push(user);
      }
      this.currentChatUser = user;

      //Open Chat Bubble
      const placeHolder = "/assets/plaeholder-user.png";

      //check if participent already added in chat
      let participant = this.participant.find(
        (r) => r.id == this.currentChatUser.email
      );
      //Show Chat Bubble Icon
      if (!participant) {
        this.participant.push({
          id: this.currentChatUser.email,
          email: this.currentChatUser.email,
          name: this.currentChatUser.name,
          imageUrl: this.currentChatUser.image ?? placeHolder,
          image: this.currentChatUser.image ?? placeHolder,
          user_id: this.currentChatUser.id,
        });
      }

      //console.log(JSON.stringify(this.currentChatUser));
      //this.$store.dispatch("chat/onNotification", { email: email });
    },

    closeChatWidget(participant) {
      // console.log("close chat widget participent Video Call==============",participant);
      this.participant = this.participant.filter(
        (p) => p.user_id !== participant.participant.user_id
      );
      //check if two participents in icon chats
      if (this.$vuetify.breakpoint.width < 600) {
        if (this.participant.length == 0) {
          this.mobSheet = false;
        }
      }
    },

    addUserDialog() {
      this.providerId = 0;
      this.add_user_dialog = true;
    },

    isAudioDisabled(item) {},

    isVideoDisabled(item) {},

    addUser(userId) {
      var item;

      if (this.tab == 0)
        item = this.providers.filter((r) => r.value == userId)[0];
      else item = this.getBasicUserData.filter((r) => r.value == userId)[0];

      console.log("ITEM --------------------------------> ", item);

      this.$store
        .dispatch("users/addUserToCall", {
          sendTo: userId,
          roomId: this.appId,
          mail: item.mail,
          name: item.name ? item.name : "",
          sendCall: true,
          inviteOnly: true,
        })

        .then((r) => {
          this.snackbar = {
            show: true,
            message: "Invite sent successfully",
          };
          this.close();
        });
    },

    getProviders() {
      const options = {
        search: "",
        column: "name",
      };
      this.$store
        .dispatch("teleProviders/getProviders", options)
        .then((response) => {
          this.providers = response.data.data.map((e) => {
            return {
              text: e.name,
              value: e.id,
              mail: e.email,
              color: e.color,
            };
          });
          // console.log("Providers >>>>>>>>>>> ", this.providers);
        });
    },

    log(log) {
      const data = new FormData();
      data.append("log", log);
      data.append("appointment", this.appId);
      this.$store.dispatch("global/post", {
        url: "/api/log_video_call",
        body: data,
      });
    },

    myListener() {
      this.show_comp = true;
      clearTimeout(this.comp_timer);
      if (this.$vuetify.breakpoint.smAndUp) {
        this.comp_timer = setTimeout(() => {
          this.show_comp = false;
        }, 3000);
      }
    },

    stop() {
      const video = document.getElementById("video-test");
      var stream = video.srcObject;
      var tracks = stream.getTracks();
      for (var i = 0; i < tracks.length; i++) {
        var track = tracks[i];
        track.stop();
      }
      video.srcObject = null;
      if (this.audioStream) {
        var audioTracks = this.audioStream.getTracks();
        for (let index = 0; index < audioTracks.length; index++) {
          const element = audioTracks[index];
          element.stop();
        }
      }
    },
    initLocalStream(stream) {
      //stream published
      this.localStream = stream;

      //   this.activeStream = this.localStream;
      //   this.activeId = this.localStream.getId();
      this.$nextTick(() => {
        this.localStream.play(
          this.remoteStreams.length === 0 ? this.localId : "my-media"
        );
        if (this.remoteStreams.length === 0) this.show_local = false;
      });
      // this.remoteStreams.push(this.activeStream)
      console.log("AST-6 localStream = ", this.localStream);
    },
    //here
    async joinRoom() {
      this.rtc
        .joinChannel(this.option)
        .then(() => {
          this.publishLocalStream(
            this.videoDeviceId,
            this.audioDeviceId,
            this.videoProfile
          );
        })
        .catch((err) => {
          console.log(err);
          alert("Failed to join video call. Please try reloading the page.");
          //join channel error
          this.disconnect();
        });
    },
    publishLocalStream(cameraId, micId, videoProfile) {
      this.rtc
        .publishStream(cameraId, micId, videoProfile)
        .then((stream) => {
          this.initLocalStream(stream);
        })
        .catch((err) => {
          if (err.msg === "OverconstrainedError") {
            this.publishLocalStream(null, null, videoProfile);
          }
        });
    },
    onDisconnected(room) {
      clearInterval(this.timer);
      const callto = this.$store.state.users.callTo;
      if (callto) {
        try {
          this.$db.collection("users").doc(callto.call_to).update({
            is_calling: false,
          });
        } catch (error) {}
      }

      this.log(
        room?.localParticipant?.identity + " disconnected from the call"
      );

      try {
        this.stopAll = true;
      } catch (error) {}

      this.callEnded = true;
      if (this.callEnded == true) {
        this.$router.replace({ name: "callEnded" });
      }
    },

    disconnect() {
      this.end_call_dialog = false;
      this.rtc
        .leaveChannel()
        .then(() => {
          //channel left
          this.onDisconnected();
        })
        .catch((err) => {
          //Leave Failure
        });
      this.localStream = null;
      this.remoteStreams = [];
    },

    chatMenuToggle() {
      this.chat_menu = !this.chat_menu;
    },

    notesMenuToggle() {
      this.notes_menu = !this.notes_menu;
    },

    pauseVideo() {
      this.video_icons = !this.video_icons;
      if (this.video_icons == true) {
        this.localStream.unmuteVideo();
        this.snackbar = {
          message: "Camera is On",
          color: "dark",
          show: true,
        };
      } else {
        this.localStream.muteVideo();
        this.snackbar = {
          message: "Camera is Off",
          color: "dark",
          show: true,
        };
      }
    },

    micToggle() {
      this.mic_icons = !this.mic_icons;

      if (this.mic_icons == true) {
        this.localStream.unmuteAudio();
        this.snackbar = {
          message: "Microphone is On",
          color: "dark",
          show: true,
        };
      } else {
        this.localStream.muteAudio();
        this.snackbar = {
          message: "Microphone is Off",
          color: "dark",
          show: true,
        };
      }
    },

    volume() {
      // console.log(Twilio.device.audio)
      var audio_context = null;
      var gain_node = null;

      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      audio_context = new AudioContext();

      gain_node = audio_context.createGain(); // Declare gain node
      gain_node.connect(audio_context.destination);

      console.log(gain_node);

      this.volume_icons = !this.volume_icons;

      if (this.volume_icons == true) {
        this.snackbar = {
          message: "Audio is On",
          color: "dark",
          show: true,
        };
        gain_node.gain.value = 1;
      } else {
        this.snackbar = {
          message: "Audio is Mute",
          color: "dark",
          show: true,
        };
        gain_node.gain.value = 0;
      }
    },

    openCallEndDialog() {
      this.end_call_dialog = true;
    },

    micSelected() {
      this.mic_setting_dialog = false;
      localStorage.setItem("audioDeviceId", this.audioDeviceId);
      if (!this.videoDeviceId) this.camera_setting_dialog = true;
    },

    cameraSelected() {
      this.camera_setting_dialog = false;
    },

    close() {
      this.add_user_dialog = false;
      this.end_call_dialog = false;
    },
  },
};
</script>

<style   scoped>
>>> .call-parent .video-full-screen video {
  object-fit: contain !important;
}

>>> .chat-bbls-launcher .dismiss-user {
  margin-top: 48px;
  margin-left: -11px;
}
.agora-video-player {
  height: 100%;
  width: 100%;
}
</style>


<style scoped>
/* Mobile devices chat_new*/
@media screen and (max-width: 480px) {
  >>> .v-snack__wrapper {
    min-width: 285px !important;
  }
}
</style>
