<template>
  <div>
    <div v-show="false"><audio /></div>

    <v-container :class="this.$route.name == 'videoCall' ? 'zero-width' : ''">
      <v-dialog v-model="dialog" max-height="600px" persistent width="550px">
        <template v-slot:activator="{ on, attrs }">
          <v-fab-transition>
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              dark
              fixed
              bottom="12"
              right
              fab
              @click="setStatus()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </template>
        <CreateNote v-if="dialog" :onClose="close" @noteCreated="noteCreated" />
      </v-dialog>

      <!-- CARD -->

      <div
        :class="[
          this.$route.name == 'videoCall' ? 'note-area-panel' : 'note-area',
        ]"
      >
        <SingleNote
          v-for="(note, i) in notes"
          :key="i"
          :note="note"
          @noteEdited="noteCreated"
        />

        <div class="no-result-section" v-if="notes == 0">
          <img class="no-notes" :src="'/assets/no_notes_found.svg'" alt="" />
        </div>

        <div v-if="this.$route.name == 'videoCall'">
          <br /><br /><br /><br /><br />
        </div>
      </div>
    </v-container>
  </div>
</template>


<script>
import { mapState } from "vuex";
import CreateNote from "./notes_widget/CreateNotes";
import SingleNote from "./notes_widget/SingleNote";

export default {
  components: {
    CreateNote,
    SingleNote,
  },
  data: () => ({
    appId: null,

    dialog: false,
    editedIndex: -1,
  }),

  computed: {
    ...mapState({
      notes: (state) => state.notes.notes,
    }),
  },
  created() {
    this.$store.commit("notes/SET_DELETE_STATUS", false);
    this.appId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    this.refresh();
  },
  methods: {
    refresh() {
      this.$store.dispatch("notes/getNotes", this.appId);
    },

    setStatus() {
      var obj = { createNewNote: 0 };
      this.$store.dispatch("notes/checkImageStatus", obj);
    },

    noteCreated() {
      this.dialog = false;
      this.refresh();
    },
    close() {
      this.dialog = false;
      this.comments_dialog = false;
      this.editedIndex = -1;
    },
  },
};
</script>


<style scoped>
>>> .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
  padding: 0px 10px;
}

.zero-width {
  padding: 0px !important;
}

.v-btn--fixed.v-btn--bottom {
  bottom: 30px;
  right: 30px;
}
</style>
