<template>
  <v-card flat class="en-table-card mt-5">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      show-arrows
    >
      <v-tab v-for="item in tab_items" :key="item">{{ item }}</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <ProviderSms />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <PartnersSms />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import ProviderSms from "./sms_tabs/ProviderSmsTab";
import PartnersSms from "./sms_tabs/PartnersSmsTab";

export default {
  components: {
    ProviderSms,
    PartnersSms,
  },
  data: () => ({
    tab_items: ["Provider", "Partners"],
  }),

  computed: {
    tab: {
      get: function () {
        return this.$store.state.global.tabs.smsTabs;
      },
      set: function (value) {
        var setTab = {
          type: 6,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },
};
</script>
