var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-4 mt-5"},[_c('v-chip',{staticClass:"mr-2 mb-1",on:{"click":_vm.nameClick}},[_vm._v("Name")]),_vm._v(" "),_c('v-chip',{staticClass:"mr-2 mb-1",on:{"click":_vm.phoneClick}},[_vm._v("Provider")]),_vm._v(" "),_c('v-chip',{staticClass:"mr-2 mb-1",on:{"click":_vm.emailClick}},[_vm._v("Appointment Time")]),_vm._v(" "),_c('v-chip',{staticClass:"mr-2 mb-1",on:{"click":_vm.locationClick}},[_vm._v("Appointment Location")]),_vm._v(" "),_c('div',{staticClass:"mt-5 mb-4"},[_c('editor',{attrs:{"initial-value":_vm.provider_email,"init":{
        images_upload_url: 'postAcceptor.php',
        images_upload_base_path: '/some/basepath',
        images_upload_credentials: true,
        height: 300,
        content_style: 'body {font-family: Arial;}',
        branding: false,
        selector: 'textarea#full-featured-non-premium',
        menubar: 'file edit view insert format tools table tc',
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'textcolor' ],
        toolbar:
          'undo redo | bold italic underline strikethrough removeformat | forecolor backcolor  | fontselect | fontsizeselect | formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | fullscreen  preview save print | insertfile image template link codesample | ltr rtl',
        toolbar_sticky: true,
        toolbar_mode: 'sliding',
      }},model:{value:(_vm.provider_email),callback:function ($$v) {_vm.provider_email=$$v},expression:"provider_email"}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticStyle:{"width":"170px"},attrs:{"color":"primary","depressed":"","large":""},on:{"click":function($event){return _vm.save()}}},[_vm._v("Save Changes")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }