<template>
  <v-container>
    <v-card-actions class="py-0 mb-1">
      <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
    </v-card-actions>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      show-arrows
    >
      <!-- <v-tab v-for="item in items" :key="item">{{ item }}</v-tab> -->
      <v-tab v-if="user.account_type == $constants.ADMIN"
        >Ui Configuration</v-tab
      >
      <v-tab>My Profile</v-tab>
      <v-tab v-if="user.account_type == $constants.ADMIN">Notifications</v-tab>
      <v-tab v-if="user.account_type == $constants.ADMIN">Apis</v-tab>
      <v-tab v-if="user.account_type != $constants.COORDINATOR">User settings</v-tab>
    </v-tabs>
    <v-divider></v-divider>

    <!-- COORDINATOR -->

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-if="user.account_type == $constants.ADMIN"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card class="en-card elevation-0">
          <UiConfiguration />
        </v-card>
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card class="en-card elevation-0">
          <MyProfile />
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-if="user.account_type == $constants.ADMIN"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card class="en-card elevation-0">
          <Notifications />
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-if="user.account_type == $constants.ADMIN"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card class="en-card elevation-0">
          <Apis />
        </v-card>
      </v-tab-item>
      <v-tab-item
        v-else
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <v-card class="en-card elevation-0">
          <UserSettings />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import UiConfiguration from "./general_settings/UiConfiguration";
import MyProfile from "./general_settings/MyProfile";
import Apis from "./general_settings/Apis";
import Notifications from "./general_settings/Notifications";
import UserSettings from "./general_settings/UserSettings";
import { mapState } from "vuex";

export default {
  components: {
    UiConfiguration,
    MyProfile,
    Apis,
    Notifications,
    UserSettings,
  },
  created(){
    this.$store.dispatch("generalSetting/getAllGeneralSetting");
  },
  data() {
    return {
      bread: [
        {
          text: "Dashboard",
          disabled: false,
          to: "/dashboard",
        },
        {
          text: "General Settings",
          disabled: true,
          to: "",
        },
      ],
    };
  },

  created() {
    // if (this.user.account_type == this.$constants.PROVIDER || this.user.account_type == this.$constants.COORDINATOR) {
    //   this.items.push("User settings");
    // } else {
    //   this.items.push("Apis");
    // }
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      snackbar: (state) => state.global.showSnackBar,
    }),

    tab: {
      get: function () {
        return this.$store.state.global.tabs.generalSettingsTab;
      },
      set: function (value) {
        var setTab = {
          type: 0,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },
};
</script>
