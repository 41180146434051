<template>
  <div>
    <v-navigation-drawer
      :mini-variant="miniVariant"
      v-model="drawer"
      app
      color="darker"
    >
      <v-list dense :rounded="!miniVariant" flat>
        <div class="text-center">
          <img
            :class="miniVariant == true ? 'mini-nav-logo' : 'nav-logo'"
            class="vu-sidebar-logo"
            v-if="get_general_setting.imageurl"
            :src="get_general_setting.imageurl"
          />

          <img
            :class="miniVariant == true ? 'mini-nav-logo' : 'nav-logo'"
            :src="'/assets/et_icon_dark.png'"
            alt
            v-else
          />
        </div>

        <div
          :class="[
            checkCurrentActiveNavItem(1)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'dashboard' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-chart-arc</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <div
          :class="[
            checkCurrentActiveNavItem(2)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'patients' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Patients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>
        <div
          v-if="user ? user.account_type != $constants.COORDINATOR : ''"
          :class="[
            checkCurrentActiveNavItem(3)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'appointments' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Appointments</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <div
          v-if="user ? user.account_type != $constants.COORDINATOR : ''"
          :class="[
            checkCurrentActiveNavItem(4)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'users' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Users</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <!-- <div
          v-if="user.account_type == $constants.ADMIN"
          :class="[
            checkCurrentActiveNavItem(5)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'locations' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Locations</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div> -->

        <!-- <div
          v-if="user.account_type == $constants.ADMIN"
          :class="[
            checkCurrentActiveNavItem(6)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'faxTemplates' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-fax</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Fax Templates</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div> -->

        <div
          v-if="user.account_type == $constants.ADMIN"
          :class="[
            checkCurrentActiveNavItem(7)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'lookupTables' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-table</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Lookup Tables</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <div
          :class="[
            checkCurrentActiveNavItem(8)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'generalSettings' }">
            <v-list-item>
              <v-list-item-action>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>General Settings</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <!-- invoice -->
        <div
          v-if="user ? user.account_type == 1 : ''"
          :class="[
            checkCurrentActiveNavItem(10)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'Attachment' }">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Attachment</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <!-- Appointment Request Inbox-->
        <div
          v-show="user ? user.account_type != $constants.COORDINATOR : ''"
          v-if="
            user
              ? user.support_team == 1 ||
                user.account_type == $constants.COORDINATOR ||
                user.account_type == $constants.ADMIN
              : ''
          "
          :class="[
            checkCurrentActiveNavItem(11)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'AppointmentRequests' }">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-calendar-check-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Inbox</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <div
          v-if="user ? user.account_type == 1 : ''"
          :class="[
            checkCurrentActiveNavItem(9)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'logApp' }">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-math-log</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Logs </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <div
          v-if="
            user
              ? user.account_type == 1 ||
                user.account_type == $constants.PROVIDER ||
                user.account_type == $constants.COORDINATOR
              : ''
          "
          :class="[
            checkCurrentActiveNavItem(12)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'patientReport' }">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file-document</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Reports</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <div
          :class="[
            checkCurrentActiveNavItem(13)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '',
          ]"
        >
          <router-link :to="{ name: 'downloadReports' }">
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file-document</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Downloads</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </router-link>
        </div>

        <div
          :class="[
            checkCurrentActiveNavItem(0)
              ? miniVariant == true
                ? 'en-active-mini'
                : activeClass
              : '' + 'logout-option',
          ]"
        >
          <v-divider
            style="border-color: rgba(255, 255, 255, 0.12) !important"
          ></v-divider>
          <v-list-item @click="logoutDialog()">
            <v-list-item-action>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar extension-height="0" app color="white" light class="elevation-3">
      <v-icon class="drawer-icon" @click.stop="drawer = !drawer"
        >mdi-menu</v-icon
      >
      <v-icon class="drawer-mini-icon" @click.stop="miniDrawer()"
        >mdi-menu</v-icon
      >

      <div v-if="user.account_type == $constants.COORDINATOR" class="ml-3">
        <v-chip
          small
          class="mr-2"
          v-for="location in locations"
          :key="location"
          >{{ location }}</v-chip
        >
      </div>

      <v-spacer></v-spacer>

      <!-- Chats -->
      <!-- {{countReady}} -->
      <!-- {{totalUnreadCount}} -->
      <v-menu
        :key="count"
        v-model="chatMenu"
      >
        <template v-slot:activator="{}">
          <v-badge
            :content="totalUnreadCountNew()"
            overlap
            class="mr-6"
            :color="totalUnreadCountNew() > 0 ? 'primary' : 'transparent'"
            ><v-icon
              @click="openChatMenu()"
              style="cursor: pointer"
              size="25"
              :color="$route.name == 'chat' ? 'primary' : 'grey'"
              >mdi-android-messages</v-icon
            ></v-badge
          >
        </template>
        <v-card
          v-for="(item, i) in participants"
          :key="i"
          @click="addForChat(item)"
          ><sidebar-users :participant="item" :showBadge="true"> </sidebar-users
        ></v-card>
      </v-menu>

      <v-menu
        v-model="popover"
        :close-on-content-click="false"
        offset-x
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" class="d-flex align-center mr-4">
            <v-icon
              style="cursor: pointer"
              size="25"
              :color="$route.name == 'allNotifications' ? 'primary' : 'grey'"
              >mdi-bell</v-icon
            >
            <p
              v-if="
                user.unreadNotifications &&
                notifications &&
                notifications.length > 0
              "
              class="mb-0 noti-count"
            >
              {{ user.unreadNotifications }}
            </p>
          </div>
        </template>

        <WebNotifications v-if="popover" @closeMenu="close" />
      </v-menu>

      <v-menu transition="slide-x-transition" bottom right offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-sheet
            v-bind="attrs"
            v-on="on"
            class="elevation-0 d-flex align-center"
          >
            <p class="mb-0 mr-2 navbar-user-name">
              <b>{{ user.name }}</b>
            </p>
            <img class="nav-img" v-if="user.smallImage" :src="user.image" />
            <img class="nav-img" v-else :src="'/assets/plaeholder-user.png'" />
          </v-sheet>
        </template>

        <v-list style="width: 100%">
          <div class="navbar-user-name-mobile">
            <p class="mx-4 mb-1">
              <b>{{ user.name }}</b>
            </p>
            <v-divider></v-divider>
          </div>
          <v-list-item @click="openProfile()">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>

          <!-- <router-link :to="{ name: 'sessionClear' }">
            <v-list-item>
              <v-list-item-title>Session Clear</v-list-item-title>
            </v-list-item>
          </router-link> -->

          <v-list-item @click="logoutDialog()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- COMMON LOADER -->
      <loader
        v-if="loaderstate"
        :loading="loaderstate"
        color="primary"
        slot="extension"
      />
    </v-app-bar>

    <v-dialog v-model="logout_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Logout</v-card-title>
        <v-card-text>Are you sure you want to logout?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="logout()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- COMMON SNACKBAR -->
    <v-snackbar
      elevation="20"
      :timeout="3000"
      :color="snackbar.color"
      top
      right
      v-model="snackbar.show"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <incoming-call />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SidebarUsers from "./chat/SidebarUsers.vue";
import Loader from "./CommonLoader";
import MyProfile from "./general_settings/MyProfile";
import IncomingCall from "./IncomingCall.vue";
import WebNotifications from "./WebNotifications.vue";

export default {
  components: {
    Loader,
    MyProfile,
    WebNotifications,
    IncomingCall,
    SidebarUsers,
  },
  data: () => ({
    chatMenu: false,
    key: 0,
    drawerRight: false,
    drawer: null,
    activeClass: "en-active",
    miniVariant: false,
    logout_dialog: false,
    popover: false,
    is_calling: false,
    locations: [],
  }),

  created() {
    this.$db
      .collection("users")
      .doc(this.user.email)
      .onSnapshot(function (doc) {
        this.key++;
      });

    this.$store.dispatch("generalSetting/getAllGeneralSetting");

    var that = this;
    setTimeout(function () {
      that.$store.dispatch("notifications/getNotifications");
    }, 3000);
  },

  computed: {
    ...mapGetters({ logo: "themes/logo" }),
    ...mapState({
      user: (state) => state.currentUser.user,
      snackbar: (state) => state.global.showSnackBar,
      loaderstate: (state) => state.global.showLoader,
      get_general_setting: (state) => state.generalSetting.get_general_setting,
      setNavbarStatus: (state) => state.themes.setNavbarStatus,
      notifications: (state) => state.notifications.notifications,
      loading: (state) => state.notifications.loading,
      users: (state) => state.chat.user,
      count: (state) => state.chat.unreadCountNew,
      countReady: (state) => state.chat.countReady,
      countReadyNew: (state) => state.chat.countReadyNew,
      search_filters: (state) => state.appointments.filters,
    }),

    currentPage() {
      return this.$route.name;
    },

    participants() {
      return this.users.filter((r) => this.unreadCount(r) > 0);
    },
  },

  mounted() {
    window.addEventListener("beforeunload", this.leaving);
  },

  watch: {
    setNavbarStatus(val) {
      if (val == true) {
        this.$store.dispatch("generalSetting/getAllGeneralSetting");
      }
    },
  },

  methods: {
    initialize() {
      this.$store.dispatch("global/actionloaderState", true, {
        root: true,
      });
      this.$store
        .dispatch("global/post", {
          url: "/api/get_dashboard",
          body: new FormData(),
        })
        .then((r) => {
          let data = r.data.data;
          this.locations = data.locations;
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((error) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },

    leaving() {
      this.logout();
    },

    totalUnreadCount() {
      if (!this.countReady) return 0;

      let total = 0;
      this.users.forEach((element) => {
        total += this.unreadCount(element);
      });
      console.log(total);
      return total >= 0 ? total : 0;
    },

    totalUnreadCountNew() {
      if (!this.countReadyNew) return 0;

      let total = this.count;
      // this.users.forEach(element => {
      //     total += this.unreadCount(element);
      // });
      console.log(total);
      return total >= 0 ? total : 0;
    },
    openChatMenu() {
      if (this.participants.length > 0) this.chatMenu = true;
      else {
        if (this.$route.name === "chat") return;
        this.$router.push({ name: "chat" });
        this.$store.dispatch("chat/setOpenId", 0);
      }
    },
    addForChat(item) {
      if (this.$route.name === "chat") {
        this.$store.dispatch("chat/setOpenId", item.id);
      } else
        this.$router.push({
          name: "chat",
          params: {
            currentParticipant: item,
          },
        });
    },

    unreadCount(element) {
      if (!element) return 0;
      let length =
        element.messages?.length <= 0 ? 0 : element.messages?.length - 1;
      if (element.readIndex > length) element.readIndex = length;
      return (
        length -
        (element.readIndex && element.readIndex > 0 ? element.readIndex : 0)
      );
    },

    openProfile() {
      this.$router.push({ name: "generalSettings" });
      if (this.user.account_type == this.$constants.ADMIN) {
        this.$store.state.global.tabs.generalSettingsTab = 1;
      } else {
        this.$store.state.global.tabs.generalSettingsTab = 0;
      }
    },

    checkCurrentActiveNavItem(element) {
      switch (element) {
        case 1: //Dashboard
          var linkArray1 = ["dashboard"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 2: //Patients
          var linkArray1 = ["patients"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 3: //Appointments
          var linkArray1 = ["locationsGroups", "appointments", "notes"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 4: //Users
          var linkArray1 = ["users"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 5: //LOcations
          var linkArray1 = ["locations"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 6: //Flex Templates
          var linkArray1 = ["faxTemplates", "addFaxTemplate"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 7: //Lookup Tables
          var linkArray1 = [
            "lookupTables",
            "adminReasons",
            "visitReason",
            "procedureCodes",
          ];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 8: //General Settings
          var linkArray1 = ["generalSettings"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 9: //Logs
          var linkArray1 = ["logApp", "logInfo"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 10: //Attachment
          var linkArray1 = ["Attachment", "Attachment"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 11:
          var linkArray1 = ["AppointmentRequests"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 12:
          var linkArray1 = ["patientReport"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;
        case 13:
          var linkArray1 = ["downloadReports"];
          if (linkArray1.includes(this.currentPage)) return true;
          break;

        default:
      }
      return false;
    },

    miniDrawer() {
      this.miniVariant = !this.miniVariant;
    },

    logoutDialog() {
      this.logout_dialog = true;
    },

    async logout() {
      this.search_filters.location_model2 = [];
      this.$store.commit("appointments/SET_FILTERS", this.search_filters);
      //alert("Navbar");
      this.logout_dialog = false;
      await this.$store.dispatch("currentUser/logout");
      this.$router.replace({ name: "login" });
    },

    close() {
      this.logout_dialog = false;
      this.popover = false;
    },
  },
};
</script>

<style scoped>
.en-active {
  opacity: 1;
  visibility: visible;
  background: rgba(255, 255, 255, 0.212);
  transition: all 0.5s;
  border-radius: 30px;
}

.en-active i {
  color: white !important;
}

.en-active .v-list-item__title {
  color: white !important;
}

/* MINI BAR CLASSES  */

.en-active-mini {
  opacity: 1;
  visibility: visible;
  background: rgba(255, 255, 255, 0.212);
  transition: all 0.5s;
}

.en-active-mini i {
  color: white !important;
}

.en-active-mini .v-list-item__title {
  color: white !important;
}

.v-icon.v-icon {
  color: #9aa2ac;
}

>>> .v-list--dense .v-list-item .v-list-item__title {
  font-weight: 600 !important;
  color: #9aa2ac;
}

>>> .v-toolbar__extension {
  padding: 0 !important;
}
</style>

<style scoped>
/* Mobile devices */
@media screen and (max-width: 480px) {
  >>> .v-snack__wrapper {
    min-width: 285px !important;
  }
}
</style>
