<template>
  <div>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="logsbylevel"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :options.sync="options"
        :server-items-length="totalServerItems"
        item-key="id"
        show-expand
        class="log-table-card"
        :footer-props="footerProps"
        sort-by="id"
      >
        <template
          v-slot:[`item.data-table-expand`]="{ item, isExpanded, expand }"
        >
          <v-icon
            v-if="item.stack_traces.length > 0"
            :class="[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded },
            ]"
            @click.stop="expand(!isExpanded)"
            >$expand</v-icon
          >
        </template>
        <!-- Expend Code End-->

        <template v-slot:item.date="{ item }">
          {{ item.date | moment("MM/DD/YYYY - h:mm a ") }}
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              dense
              v-model="search"
              outlined
              @input="searchChange"
              hide-details=""
              placeholder="Search..."
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-icon color="primary" size="30" @click="RefreshList()"
              >mdi-sync</v-icon
            >
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="json-container">
              <v-btn depressed small class="mb-4" @click="CopyToClipBoard(item)"
                ><v-icon small left>mdi-content-copy</v-icon> Copy</v-btn
              >
              <vue-json-pretty
                :path="'res'"
                :data="item.stack_traces"
                @click="handleClick"
              >
              </vue-json-pretty>
            </div>
          </td>
        </template>
      </v-data-table>
    </v-container>

    <br />
    <br />
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { mapState } from "vuex";

export default {
  components: {
    VueJsonPretty,
  },
  data: () => ({
    timer: "",
    search: "",
    expanded: [],
    singleExpand: false,
    headers: [
      { text: "", value: "data-table-expand" },
      { text: "ENV", width: "100px", value: "environment" },
      { text: "Level", width: "100px", value: "level" },
      { text: "Time", width: "100px", value: "date" },
      { text: "Header", width: "300px", value: "context.message" },
      { text: "", align: "end", value: "data-table-expand" },
    ],
    logsList: [],

    //Pagination Properties
    options: {},
    itemsPerPage: 10,
    footerProps: { "items-per-page-options": [10, 20, 30, 40, 50] },
    page: 1,
  }),

  computed: {
    ...mapState({
      logsbylevel: (state) => state.admin_log_reader.logsbylevel,
      totalServerItems: (state) => state.admin_log_reader.totalServerItems,
    }),
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  created() {
    //this.initialize();
  },

  methods: {
    searchChange(e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.RefreshList();
      }, 300);
    },
    initialize() {
      this.$store.dispatch("admin_log_reader/GetLogsByLevel", {
        options: this.options,
        page: this.options.page,
        search: this.search,
        filename: localStorage.getItem("filename"),
        level: localStorage.getItem("level"),
      });
    },

    //============ Call Child to Parent Method==================
    // initializeFromChild() {
    //   this.$emit("initializeFromChild");
    // },

    RefreshList() {
      this.initialize();
    },

    handleClick() {
      //console.log("handled");
    },

    CopyToClipBoard(content) {
      let text = JSON.stringify(content.stack_traces);
      let input = document.createElement("input");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      let result = document.execCommand("copy");
      document.body.removeChild(input);
      if (result) {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: "Copy To Clipboard.",
        });
      }
    },

    expandAll: function () {
      this.expanded = this.logsbylevel.filter((item) => item.stack_traces);
    },
    collapseAll: function () {
      this.expanded = [];
    },
  },
};
</script>
