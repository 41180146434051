<template>
  <div>
    <v-row>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          v-model="old_password"
          :error-messages="oldPasswordErrors"
          @input="$v.old_password.$touch()"
          @blur="$v.old_password.$touch()"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"
          label="Old Password"
          outlined
          dense
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6" sm="6">
        <v-text-field
          v-model="new_password"
          :error-messages="newPasswordErrors"
          @input="$v.new_password.$touch()"
          @blur="$v.new_password.$touch()"
          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show2 ? 'text' : 'password'"
          label="New Password"
          outlined
          dense
          @click:append="show2 = !show2"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" class="py-0">
        <v-btn
          depressed
          large
          color="primary"
          @click="changePass()"
          style="width: 170px"
          >Save Changes</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
export default {
  data: () => ({
    show1: false,
    show2: false,
    old_password: "",
    new_password: "",
  }),

  validations: {
    old_password: { required },
    new_password: { required, minLength: minLength(5) },
  },

  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.old_password.$dirty) return errors;

      !this.$v.old_password.required && errors.push("Old Password is required");
      return errors;
    },

    newPasswordErrors() {
      const errors = [];
      if (!this.$v.new_password.$dirty) return errors;
      !this.$v.new_password.required && errors.push("New Password is required");
      !this.$v.new_password.minLength &&
        errors.push("New Password must be at least 8 characters.");
      return errors;
    },
  },

  methods: {
    changePass() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "Please check all form fields",
        });
      } else {


       this.$store
        .dispatch("currentUser/updatePassword", {  old_password : this.old_password  , new_password : this.new_password  })
        .then((response) => {

            this.new_password = "";
            this.old_password = "";

        })
        .catch((e) => {

          this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: e.message,
        });

        });


      }
    },
  },
};
</script>
