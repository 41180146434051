<template>
  <div>
    <!-- {{ user }} -->
    <!-- {{ getHeaderData.preferences_data }} -->
    <v-container>
      <v-card-actions>
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="appointments"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        class="elevation-0 en-table-card"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Locations Groups</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              class="elevation-1 mr-3"
              @click="refresh"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-actions class="mx-2">
            <v-text-field
              dense
              outlined
              placeholder="Search"
              v-model="search"
              hide-details
            ></v-text-field>
          </v-card-actions>
        </template>

        <template v-slot:item.ServiceLocationName="{ item }">
          <router-link :to="{ name: 'appointments' }">
            <p class="mb-0">{{ item.ServiceLocationName }}</p>
          </router-link>
        </template>

        <template v-slot:item.appts="{ item }">
          <p class="mb-0">150</p>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon size="15" color="green" class="mr-3"
            >mdi-checkbox-blank-circle</v-icon
          >
          <v-btn color="primary" depressed x-small
            ><v-icon size="18" left>mdi-video</v-icon> Call
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Locations Groups",
        disabled: true,
        to: "",
      },
    ],
    options: {},
    totalItems: 0,
    search: "",
    headers: [
      {
        text: "",
        value: "actions",
        align: "start",
        width: "120px",
        sortable: false,
      },

      {
        text: "Service Location",
        value: "ServiceLocationName",
        width: "250px",
      },

      {
        text: "Appointments",
        align: "start",
        value: "appts",
        width: "150px",
      },
    ],
  }),

  computed: {
    ...mapState({
      appointments: (state) => state.appointments.appointments,
    }),
  },

  created() {
    this.initialize();
  },

  methods: {
    refresh() {
        this.options.is_group_location = true ;
      this.$store
        .dispatch("appointments/getAppointments", this.options)
        .then((response) => {
          this.totalItems = response.data.total;
        });
    },

    initialize() {
      this.refresh();
    },
  },
};
</script>
