var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(_vm._s(_vm.showOnlyName))]),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-0 en-table-card",staticStyle:{"border":"none !important"},attrs:{"headers":_vm.headers,"items":_vm.all_list,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItems,"search":_vm.search,"items-per-page":30,"footer-props":{
            'items-per-page-options': [30, 50]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("All")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"elevation-1",attrs:{"fab":"","light":"","small":"","color":"white"},on:{"click":_vm.refresh}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-sync")])],1)],1),_vm._v(" "),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"dense":"","clearable":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center w-100"},[_c('div',{staticClass:"d-flex align-center w-100"},[_c('v-icon',{staticClass:"mr-3 ml-md-3 ml-sm-3",attrs:{"disabled":item.color !== '#00cc00' &&
                                item.color !== '#ffd801',"size":"20","color":"green"},on:{"click":function($event){return _vm.startVideoCall(item)}}},[_vm._v("mdi-video")]),_vm._v(" "),_c('v-icon',{staticClass:"mr-3 chat-icon",attrs:{"size":"20","color":"primary"},on:{"click":function($event){return _vm.addForChat(item)}}},[_vm._v("mdi-android-messages")]),_vm._v(" "),_c('v-sheet',{staticClass:"mr-3",attrs:{"color":item.color,"elevation":"0","height":"47","width":"5"}}),_vm._v(" "),_c('span',{staticClass:"name-hover",on:{"click":function($event){return _vm.addForChat(item)}}},[_vm._v(_vm._s(item.name))])],1),_vm._v(" "),_c('div',{staticClass:"show-only-mobile"})])]}},{key:"item.account_type",fn:function(ref){
                                var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.getType(item.account_type)))])]}},{key:"item.actions",fn:function(ref){
                                var item = ref.item;
return undefined}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }