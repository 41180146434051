<template>
  <div>
    <v-scroll-x-transition>
      <v-card v-show="is_calling" class="incoming-call-card">
        <div class="incoming-detail-full">
          <img v-if="image" :src="image" alt=" " />
          <img v-else :src="'/assets/default_user.png'" />
          <div class="incoming-call-detail ml-md-3">
            <p class="call-title">incoming video call</p>
            <h3>{{ name }}</h3>
            <p class="call-detail-text">
              <v-icon>mdi-email</v-icon>{{ email }}
            </p>
            <p class="call-detail-text">
              <v-icon>mdi-map-marker</v-icon>{{ name }}
            </p>
          </div>
        </div>
        <div
          style="width: 100%"
          class="
            d-flex
            justify-md-end justify-center justify-sm-end
            mt-4 mt-md-0 mt-sm-0
          "
        >
          <v-btn @click="rejectCall()" color="red" dark fab>
            <v-icon size="25" color="white">mdi-close</v-icon>
          </v-btn>
          <v-btn
            @click="acceptCall()"
            class="ml-3 call-btn"
            color="#80CF62"
            dark
            fab
          >
            <v-icon size="28" color="white">mdi-phone-outline</v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-scroll-x-transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    isPlaying: false,
    audio: {},
    id: 0,
    timer: null,
    is_calling: false,
    name: "",
    email: "",
    room: 0,
    image: "",
  }),
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  mounted() {
    //console.log("EMAIL IN MOUNTED  = = = " + this.user.email);
    const _this = this;
    this.$db
      .collection("users")
      .doc(this.user.email)
      .onSnapshot(function (doc) {
        //  console.log("Current data: ", JSON.stringify(doc.data()));
        const data = doc.data();
        if (data) {
          _this.is_calling = data.is_calling;
          _this.id = data.id;
          _this.name = data.name;
          _this.email = data.email;
          _this.room = data.room;
          _this.image = data.image;
         // console.log("IN LISTENER IS CALLING = " + _this.is_calling);
          if (data.is_calling) {
            _this.play();
            _this.timer = setTimeout(() => {
              _this.missedCall(data);
            }, 1000 * 60);
          } else {
            _this.stop();
          }
        }
      });
    // console.log("Mounted Hook audio object=========", this.audio);
  },

  methods: {
    play() {
      //   if(!this.user.ringtone) return ;
      this.audio = new Audio("/assets/incoming_call.mp3");
      this.audio.loop = true;
      this.audio.muted = false;
      this.audio.resume = true;
      this.audio.play();
      this.isPlaying = true;
      console.log("Play Audio=========", this.audio);
    },

    stop() {
      if (!this.isPlaying) return;
      this.audio.muted = true;
      this.audio.pause();
      this.audio.remove();
      console.log("Stop Audio=========", this.audio);
      this.audio = {};
      this.isPlaying = false;
    },

    makeNotification(title, body, sendTo) {
      this.$store.dispatch("users/makeWebNotification", {
        title: title,
        body: body,
        sendTo: sendTo,
      });
    },

    missedCall(data) {
      this.makeNotification(
        "Missed call from " + data.name,
        "Missed video call from " + data.name,
        this.user.id
      );
      this.clearDoc(false, false);
    },

    rejectCall() {
      this.makeNotification(
        "Call Declined",
        this.user.name + " declined your call",
        this.id
      );
      clearTimeout(this.timer);
      this.is_calling = false;
      this.clearDoc(false, true);
    },

    acceptCall() {
      clearTimeout(this.timer);
      let routeData = this.$router.resolve({
        path: `/video-calling/${this.room}`,
      });
      window.open(routeData.href, "_blank");

      //   this.$router.push({
      //     path: `/video-calling/${this.room}`,
      //   });
      this.clearDoc(true, false);
    },

    clearDoc(accepted, rejected) {
      this.$db.collection("users").doc(this.user.email).set(
        {
          is_calling: false,
          accepted: accepted,
          rejected: rejected,
        },
        {
          merge: true,
        }
      );
    },
  },
};
</script>

<style>
</style>
