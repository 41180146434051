<template>
  <div>
    <div class="calendar-relative-pos">
      <v-date-picker
        color="primary"
        @change="dateChange"
        v-model="picker"
        full-width
        class="en-table-card"
        style="z-index: 0 !important"
        :picker-date.sync="pickerDate"
      ></v-date-picker>

      <v-btn
        color="white"
        small
        class="mb-2 today-btn"
        outlined
        @click="setToday(true)"
        >Today</v-btn
      >
    </div>

    <v-card
      class="en-card mt-2"
      :ripple="false"
      @click="add_providers_dialog = true"
      flat
    >
      <h4 class="mb-2">Providers</h4>
      <v-chip v-if="select_all_providers">All selected</v-chip>
      <v-chip
        v-else
        v-for="(p, i) in provider_model"
        :key="p.value"
        @click:close="removeProvider(i)"
        small
        close
        class="mr-2 mb-2"
        >{{ p.text }}</v-chip
      >
    </v-card>

    <v-card
      v-if="user.account_type == $constants.COORDINATOR"
      class="en-card mt-2"
      flat
    >
      <h4 class="mb-2">Locations</h4>
      <v-chip
        v-for="(p, i) in location_model"
        :key="i"
        small
        class="mr-2 mb-2"
        >{{ p.text }}</v-chip
      >
    </v-card>

    <v-card
      v-else
      class="en-card mt-2"
      :ripple="false"
      @click="add_locations_dialog = true"
      flat
    >
      <h4 class="mb-2">Locations</h4>
      <v-chip v-if="select_all_locations">All selected</v-chip>
      <v-chip
        v-else
        v-for="(p, i) in location_model"
        :key="p.value"
        @click:close="removeLocation(i)"
        small
        close
        class="mr-2 mb-2"
        >{{ p.text }}</v-chip
      >
    </v-card>

    <!-- <v-autocomplete
      v-model="provider_model"
      :items="providers"
      auto-select-first
      clearable
      deletable-chips
      multiple
      small-chips
      hide-details
      outlined
      label="Providers"
      class="mt-5"
    >
      <template v-slot:prepend-item>
        <v-list>
          <v-list-item @click.native.stop="selectAllProviders()">
            <v-checkbox
              class="mt-0 mb-1"
              v-model="select_all_providers"
              hide-details
              @click.native.stop="selectAllProviders()"
            />
            <v-list-item-title>Select All</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
      </template>
    </v-autocomplete> -->

    <!-- <v-autocomplete
      v-model="location_model"
      :items="locations"
      :search-input.sync="locSearch"
      :loading="locLoading"
      @change="locationChange"
      label="Locations"
      auto-select-first
      clearable
      deletable-chips
      multiple
      small-chips
      hide-details
      outlined
      class="mt-5"
      @focus="onFocus()"
      :allow-overflow="false"
    >
      <template v-slot:prepend-item>
        <v-list-item @click.native.stop="selectAllLocations()">
          <v-checkbox
            class="mt-0 mb-1"
            v-model="select_all_locations"
            hide-details
            @click.native.stop="selectAllLocations()"
          />
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-autocomplete> -->

    <v-dialog
      v-model="add_providers_dialog"
      hide-overlay
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>Providers</v-card-title>
        <!-- v-model="provider_model" -->

        <v-text-field
          @change="providerInput"
          dense
          outlined
          v-model="providerSearch"
          hide-details=""
          placeholder="Search..."
          class="mx-5"
        ></v-text-field>

        <v-card-text class="pt-3" style="height: 500px">
          <!-- <v-chip small close>Demo Provider</v-chip> -->

          <v-list v-if="providers.length != 0">
            <v-list-item @click="selectAllProviders()">
              <v-checkbox
                class="mt-0 mb-1"
                v-model="select_all_providers"
                hide-details
                @click="selectAllProviders()"
              />
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-list>
            <v-list-item-group v-model="provider_model" multiple>
              <template v-for="(item, i) in searchedProviders">
                <v-list-item
                  :key="`item-${i}`"
                  :value="item"
                  @change="listItemChange"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        class="mt-0 mb-1"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="add_providers_dialog = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 120px"
            @click="setChanged(1)"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="add_locations_dialog"
      hide-overlay
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title>Locations</v-card-title>
        <v-text-field
          v-model="locSearch"
          :loading="locLoading"
          dense
          outlined
          hide-details=""
          placeholder="Search..."
          class="mx-5"
        ></v-text-field>

        <v-card-text class="pt-3" style="height: 500px">
          <v-list v-if="locations.length != 0">
            <v-list-item @click="selectAllLocations()">
              <v-checkbox
                class="mt-0 mb-1"
                v-model="select_all_locations"
                hide-details
                @click="selectAllLocations()"
              />
              <v-list-item-title>Select All</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>

          <v-list>
            <v-list-item-group v-model="location_model" multiple>
              <template v-for="(item, i) in locations">
                <v-list-item
                  @change="locationItemChange"
                  :key="`item-${i}`"
                  :value="item"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox
                        :input-value="active"
                        class="mt-0 mb-1"
                        dense
                        hide-details
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="add_locations_dialog = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 120px"
            @click="setChanged(2)"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { log } from "./agora/utils/utils";

export default {
  data: () => ({
    pickerDate: "",
    providerSearch: "",
    add_providers_dialog: false,
    add_locations_dialog: false,
    select_all_locations: false,
    select_all_providers: false,
    // search: "",
    provider_model: [],
    location_model: [],
    locations: [],
    locSearch: "",
    proSearch: "",
    providers: [],
    locLoading: false,
    //  picker: new Date().toISOString().substr(0, 10),
  }),
  created() {
    this.onFocus(true);
    this.getProviders();
  },
  mounted() {
    this.setToday();
  },

  computed: {
    searchedProviders() {
      return this.providers.filter((r) =>
        r.text.toLowerCase().includes(this.providerSearch.toLowerCase())
      );
    },

    ...mapState({
      // picker: (state) => state.appointments.picker,
      user: (state) => state.currentUser.user,
      search_filters: (state) => state.appointments.filters,
    }),

    picker: {
      get: function () {
        return this.$store.state.appointments.picker;
      },
      set: function (value) {
        this.$store.commit("appointments/SET_APPOINTMENTS_DATE", value);
      },
    },

    // : {
    //   get: function () {
    //       alert("herere");
    //     this.provider_model =
    //       this.$store.state.appointments.filters.provider_model2;
    //   },
    //   set: function (value) {
    //     // console.log("Value >>>>>>>>", value);
    //     // var search_filters = this.$store.state.appointments.filters;
    //     // this.search_filters.provider_model = value;
    //     // this.$store.commit("appointments/SET_FILTERS", this.search_filters);
    //   },
    // },
    // location_model2: {
    //   get: function () {
    //     this.location_model =
    //       this.$store.state.appointments.filters.location_model2;
    //   },
    //   set: function (value) {
    //     // console.log("Value >>>>>>>>", value);
    //     // var search_filters = this.$store.state.appointments.filters;
    //     // this.search_filters.location_model = value;
    //     // this.$store.commit("appointments/SET_FILTERS", this.search_filters);
    //   },
    // },
  },

  watch: {
    pickerDate(v) {
      var onlyDay = this.picker.split("-").pop();
      this.picker = v + "-" + onlyDay;
      this.dateChange(this.picker);
    },

    locSearch() {
      if (this.locSearch) this.comboInput();
    },
    proSearch() {
      if (this.proSearch) this.providerInput();
    },
  },

  methods: {
    reset() {
      this.select_all_providers = false;
      this.selectAllProviders();
      this.select_all_locations = false;
      this.selectAllLocations();
    },

    setToday(value) {
      if (
        this.$store.state.appointments.appointments_filter_date == "" ||
        value
      ) {
        this.picker = new Date().toISOString().substr(0, 10);
        var today = new Date().toISOString().substr(0, 7);
        this.pickerDate = today;
        // this.$store.commit("appointments/SET_APPOINTMENTS_DATE", today);
        // this.$emit("dateChanged", today);
      } else {
        this.$emit(
          "dateChanged",
          this.$store.state.appointments.appointments_filter_date
        );
      }
    },

    removeProvider(p) {
      this.provider_model.splice(p, 1);
      this.setChanged(1);
    },
    listItemChange() {
      this.select_all_providers =
        this.provider_model.length === this.providers.length - 1;
    },

    removeLocation(p) {
      this.location_model.splice(p, 1);
      this.setChanged(2);
    },

    locationItemChange() {
      this.select_all_locations =
        this.location_model.length === this.locations.length - 1;
    },

    selectAllProviders() {
      this.select_all_providers = !this.select_all_providers;

      if (this.select_all_providers) {
        this.provider_model = [...this.searchedProviders];
        this.search_filters.provider_model2 = [...this.searchedProviders];
      } else {
        this.provider_model = [];
        this.search_filters.provider_model2 = [];
      }

      this.$store.commit("appointments/SET_FILTERS", this.search_filters);
    },

    selectAllLocations() {
      this.select_all_locations = !this.select_all_locations;
      if (this.select_all_locations) {
        this.location_model = [...this.locations];
        this.search_filters.location_model2 = [...this.locations];
      } else {
        this.location_model = [];
        this.search_filters.location_model2 = [];
      }
      this.$store.commit("appointments/SET_FILTERS", this.search_filters);
    },

    setChanged(type) {
      // type 1 Provider , 2 locations
      if (type == 1) {
        console.log(this.provider_model);
        this.$emit("providerChanged", this.provider_model);
        this.add_providers_dialog = false;
        this.search_filters.provider_model2 = this.provider_model;
      } else {
        console.log(this.location_model);
        this.$emit("locationChanged", this.location_model);
        this.add_locations_dialog = false;
        this.search_filters.location_model2 = this.location_model;
      }
      this.$store.commit("appointments/SET_FILTERS", this.search_filters);
    },

    onFocus(on_created_time) {
      var on_created_time = on_created_time || false;
      this.comboInput("", on_created_time);
    },

    dateChange(item) {
      //console.log("Date >>>>>>>>>>>" + JSON.stringify(item));
      this.$store.commit("appointments/SET_APPOINTMENTS_DATE", item);
      this.$emit("dateChanged", item);
    },
    // providerChange(item) {
    //   this.$emit("providerChanged", item);
    // },
    // locationChange(item) {
    //   this.locSearch = "";
    //   this.$emit("locationChanged", item);
    // },
    getProviders() {
      const options = {
        search: "",
        column: "name",
      };
      this.$store
        .dispatch("teleProviders/getProviders", options)
        .then((response) => {
          this.providers = response.data.data.map((e) => {
            return {
              text: e.name,
              value: e.user_id,
            };
          });

          if (this.user.account_type == 2)
            this.provider_model = this.searchedProviders.filter(
              (r) => r.value == this.user.user_id
            );
          else {
            this.provider_model =
              this.$store.state.appointments.filters.provider_model2;

            var result = this.provider_model.filter((o1) => {
              return this.providers.some(function (o2) {
                return o1.value === o2.value; // return the ones with equal id
              });
            });
            this.provider_model = result;

            if (this.provider_model.length == 0) this.selectAllProviders();
            if (this.provider_model.length === this.providers.length) {
              this.select_all_providers = true;
            }
          }
          console.log("getProviders getting called");
          this.setChanged(1);
        });
    },

    comboInput(search, on_created_time) {
      console.log("combo input getting called");
      this.locLoading = true;
      const data = new FormData();
      data.append("search", search ?? this.locSearch);

      if (this.user.account_type == this.$constants.COORDINATOR) {
        data.append("action", true);
      }

      this.$store
        .dispatch("global/post", {
          url: "/api/get_locations_search",
          body: data,
        })
        .then((res) => {
          this.locations = res.data.map(function (r) {
            return {
              text: r.Name,
              value: r.SL_ID,
            };
          });
          this.locLoading = false;

          if (this.locations.length == 0) {
            this.select_all_locations = false;
          }

          if (on_created_time) {
            this.location_model =
              this.$store.state.appointments.filters.location_model2;
            if (this.location_model.length == 0) this.selectAllLocations();
            if (this.location_model.length === this.locations.length) {
              this.select_all_locations = true;
            }
            this.setChanged(2);
          }
        });
    },

    providerInput(search) {},

    close() {
      this.add_providers_dialog = false;
      this.add_locations_dialog = false;
    },
  },
};
</script>

<style scoped>
.menuable__content__active {
  z-index: 0 !important;
}
</style>
