<template>
    <div class="calendar-page">
        <!-- {{providers}} -->
        <!-- {{ currentType }} -->
        <!-- {{events.map(e=>e.StartTime +' - '+ e.EndTime +' - '+e.category)}} -->
        <v-row class="my-0">
            <v-col cols="12" md="12" class="text-start pt-0">
                <v-btn-toggle
                    dense
                    color="primary"
                    light
                    mandatory
                    v-model="type"
                >
                    <v-btn
                        v-if="
                            user
                                ? user.account_type != $constants.COORDINATOR
                                : ''
                        "
                        value="day"
                    >
                        <v-icon size="20" left>mdi-view-sequential</v-icon>
                        <span class="hidden-sm-and-down">Day</span>
                    </v-btn>

                    <v-btn
                        v-if="
                            user
                                ? user.account_type != $constants.COORDINATOR
                                : ''
                        "
                        value="week"
                    >
                        <v-icon size="20" left>mdi-view-list</v-icon>
                        <span class="hidden-sm-and-down">Week</span>
                    </v-btn>

                    <v-btn value="month">
                        <v-icon size="20" left>mdi-view-module</v-icon>
                        <span class="hidden-sm-and-down">Month</span>
                    </v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>

        <!-- {{ appointments }} -->
        <div class="calendar-div">
            <div
                class="calendarParent scroll scroll-color-colors"
                v-dragscroll.pass="$vuetify.breakpoint.smAndUp"
            >
                <v-sheet
                    :height="type == 'month' ? 800 : 600"
                    :width="calendarWidth"
                >
                    <v-calendar
                        color="primary"
                        :type="currentType"
                        category-show-all
                        :category-days="type == 'day' ? 1 : 7"
                        :categories="providers"
                        ref="calendar"
                        :interval-minutes="60"
                        :event-more="false"
                        category-hide-dynamic
                        :now="appointments_filter_date"
                        :value="
                            type == 'week'
                                ? getMonday(appointments_filter_date).valueOf()
                                : appointments_filter_date
                        "
                        :weekdays="weekday"
                        :events="events"
                        :event-overlap-mode="mode"
                        @click:date="dateClicked"
                        :event-overlap-threshold="30"
                        :event-color="getEventColor"
                        @change="getEvents"
                        @click:event="viewAppt"
                        @mousedown:event="startDrag"
                        @mousedown:time-category="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                    >
                        <template v-slot:event="{ event }">
                            <div>
                                <small v-if="headers[0].selected">{{
                                    event.PatientFullName
                                }}</small>

                                <small
                                    v-if="
                                        headers[0].selected && multipleSelected
                                    "
                                >
                                    -
                                </small>

                                <small v-if="headers[3].selected">{{
                                    event.dob
                                }}</small>

                                <small
                                    v-if="
                                        multipleSelected && headers[3].selected
                                    "
                                >
                                    -
                                </small>

                                <small v-if="headers[4].selected">
                                    {{
                                        moment
                                            .tz(
                                                event.StartTime,
                                                user.timezone
                                                    ? default_timezon
                                                    : default_timezon
                                            )
                                            .format("h:mm a")
                                    }}</small
                                >

                                <small
                                    v-if="
                                        headers[4].selected && multipleSelected
                                    "
                                >
                                    -
                                </small>

                                <small v-if="headers[2].selected">{{
                                    event.ServiceLocationName
                                }}</small>

                                <small
                                    v-if="
                                        headers[2].selected && multipleSelected
                                    "
                                >
                                    -
                                </small>

                                <small v-if="headers[6].selected">{{
                                    event.AppointmentStatus
                                }}</small>

                                <small
                                    v-if="
                                        multipleSelected && headers[6].selected
                                    "
                                >
                                    -
                                </small>

                                <small v-if="headers[5].selected">{{
                                    event.AppointmentReason1
                                }}</small>

                                <small
                                    v-if="
                                        headers[5].selected && multipleSelected
                                    "
                                >
                                    -
                                </small>

                                <small v-if="headers[1].selected">{{
                                    event.provider ? event.provider.name : ""
                                }}</small>
                            </div>
                        </template>

                        <template v-slot:day="item">
                            <div>
                                <v-btn
                                    @click="clickMore(item)"
                                    x-small
                                    depressed
                                    text
                                    color="primary"
                                    v-if="counts && counts[item.date] > 4"
                                >
                                    show {{ counts[item.date] - 4 }} more.
                                </v-btn>
                            </div>
                        </template>
                    </v-calendar>
                </v-sheet>
            </div>
        </div>

        <v-dialog v-model="more_appt" scrollable max-width="1200px">
            <v-card>
                <!-- <v-card-title>More Appointments</v-card-title>
        <v-divider></v-divider> -->
                <v-card-text class="px-0">
                    <moreAppts
                        :more_appts="appointments"
                        :moreDate="more_date"
                        :key="moreApptsKey"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        depressed
                        style="width: 100px"
                        @click="close"
                        >Done</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="info_dialog" scrollable max-width="800px">
            <v-card>
                <v-card-title>Appointment Info</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <Logs :app="app" v-if="info_dialog" />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        depressed
                        style="width: 100px"
                        @click="close"
                        >Done</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            ref="dialog1"
            v-model="date_change_model"
            :return-value.sync="date"
            persistent
            width="290px"
        >
            <v-date-picker color="primary" v-model="date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="date_change_model = false">
                    Cancel
                </v-btn>
                <v-btn text color="primary" @click="date_change_model = false">
                    OK
                </v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import moreAppts from "./MoreAppt";

import Logs from "./ApptLogDialog";
import { log } from "./agora/utils/utils";

export default {
    components: {
        Logs,
        moreAppts
    },

    props: ["headers", "fromDashboard"],
    data: () => ({
        createStartTime: "",
        moreApptsKey: 0,
        default_timezon: "America/Chicago",
        clicks: 0,
        timer: null,
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        createStart: null,
        extendOriginal: null,
        more_date: {},
        app: {},
        // today: '2020-09-08 10:10:00',
        date: null,
        date_change_model: false,
        locSearch: "",
        locLoading: false,
        locations: [],
        moment: moment,
        info_dialog: false,
        more_appt: false,
        categories: [],
        months: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],

        dateString: "",
        // type: "month",
        popover: false,
        types: ["month", "week", "day", "4day"],
        mode: "stack",
        modes: ["stack", "column"],
        weekday: [0,1, 2, 3, 4, 5, 6],
        weekdays: [
            { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
            { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
            { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
            { text: "Mon, Wed, Fri", value: [1, 3, 5] }
        ],
        // value: "2020-09-08 10:10:00",

        colors: [
            "blue",
            "indigo",
            "deep-purple",
            "cyan",
            "green",
            "orange",
            "grey darken-1"
        ],
        names: [
            "Opration",
            "Check-up",
            "PTO",
            "Travel",
            "Opration",
            "Conference"
        ],

        columns: [
            {
                label: "Service Location",
                val: true
            },
            {
                label: "Provider Name",
                val: false
            },
            {
                label: "Patient Name",
                val: true
            },
            {
                label: "Reason",
                val: true
            },
            {
                label: "Time",
                val: true
            },
            {
                label: "Status",
                val: false
            }
        ]
    }),

    created() {
        this.type = "month";
        if (this.fromDashboard) {
            this.type = "week";
        }
    },

    watch: {
        locSearch() {
            if (this.locSearch) this.locationInput();
        },
        type() {
            if (this.type == "month") this.onclickMonth();
        }
    },
    computed: {
        //todo : rollback if not working
        currentType() {
            return (this.providers && this.providers.length) > 0
                ? this.type == "day" || this.type == "week"
                    ? "category"
                    : this.type
                : this.type;
        },

        multipleSelected() {
            return this.headers.filter(e => e.selected).length > 1;
        },

        calendarWidth() {
            if (!this.providers) return "1200px";
            return this.type == "week" && this.providers.length > 1
                ? this.$vuetify.breakpoint.smAndDown ? this.providers.length * 1800 : "100%" //this.providers.length * 1800
                : this.$vuetify.breakpoint.smAndDown ? 1500 : "100%";
        },

        type: {
            get: function() {
                return this.$store.state.global.tabs.calendarTypeTab;
            },

            set: function(value) {
                var setTab = {
                    type: 9,
                    value: value
                };
                this.$store.commit("global/UPDATE_TABS", setTab);
            }
        },

        ...mapState({
            counts: state => state.appointments.counts,
            appointments: state => state.appointments.appointments,
            user: state => state.currentUser.user,
            appointments_filter_date: state =>
                state.appointments.appointments_filter_date,
            providerList: state => state.appointments.providers
        }),
        providers() {
            return this.providerList.map(e => e.name ?? "");
        },
        events() {
            return this.appointments.map((a, index) => {
                console.log(
                    "Total Length : " +
                        this.appointments.length +
                        " Appt num " +
                        index +
                        "ID : " +
                        a.id
                );
                //  a.StartTime = "2020-11-23T11:00:00Z";
                //  a.EndTime = "2020-11-23T13:00:00Z";
                let timezone = this.user.timezone
                    ? this.user.timezone
                    : this.default_timezon;

                // console.log("Appointment: ", timezone);

                return {
                    ...a,
                    timezone: timezone,
                    id: a.id,
                    name: a.PatientFullName,

                    // start: moment
                    //   .tz(new Date(a.StartTime), this.type == "month" ? "UTC" : timezone)
                    //   .format("YYYY-MM-DD H:mm"),

                    //   start: new Date(a.StartTime),

                    start: new Date(
                        (typeof a.StartTime === "string"
                            ? new Date(a.StartTime)
                            : a.StartTime
                        ).toLocaleString("en-US", { timeZone: timezone })
                    ),

                    // end: moment
                    //   .tz(new Date(a.EndTime), this.type == "month" ? "UTC" : timezone)
                    //   .format("YYYY-MM-DD H:mm"),

                    //   end: new Date(a.EndTime),
                    end: new Date(
                        (typeof a.EndTime === "string"
                            ? new Date(a.EndTime)
                            : a.EndTime
                        ).toLocaleString("en-US", { timeZone: timezone })
                    ),

                    color: (a.reason ?? { color: "#000" }).color ?? "#000",
                    timed: true,
                    category:
                        this.type == "day" || this.type == "week"
                            ? a.provider == null
                                ? "NA"
                                : a.provider.name
                            : null
                };
            });
        }
    },
    mounted() {
        this.$refs.calendar.scrollToTime("08:00");
    },
    methods: {
        getMonday(d) {
            d = new Date(d);
            var day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        timeClick(event) {
            this.clicks++;
            if (this.clicks == 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0;
                }, 300);
            } else {
                this.clicks = 0;
                // console.log("Double click EVENT = " + JSON.stringify(event));
                this.startDrag({ event: event, timed: true });
                clearTimeout(this.timer);
            }
        },
        startDrag({ event, timed }) {
            //   console.log("start drag called");
            if (event && timed) {
                this.dragEvent = event;
                this.dragTime = null;
                this.extendOriginal = null;
            }
        },

        startTime(tms) {
            //   console.log("start Time called" + JSON.stringify(tms));

            this.createStartTime = tms.date + " " + tms.time;
            const mouse = this.toTime(tms);

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start;

                this.dragTime = mouse - start;
            } else {
                this.createStart = this.roundTime(mouse);

                // alert(JSON.stringify(tms.category))
                this.createEvent = {
                    id: this.events.length,
                    PatientFullName: `Appointment #${this.events.length}`,
                    StartTime: this.createStart,
                    EndTime: this.createStart,
                    AppointmentStatus: "Scheduled",
                    timed: true,
                    provider: { name: tms.category ?? "" }
                };
                try {
                    this.createEvent.ProviderId =
                        this.user.account_type === 2
                            ? this.user.user_id
                            : this.providerList.find(
                                  r =>
                                      r.name ===
                                      (tms.category?.categoryName ?? "")
                              ).user_id;
                } catch (error) {}
                this.appointments.push(this.createEvent);
            }
        },

        extendBottom(event) {
            this.createEvent = event;
            this.createStart = event.start;
            this.extendOriginal = event.end;
        },

        mouseMove(tms) {
            console.log("Mouse move called ", this.toTime(tms));
            const mouse = this.toTime(tms);

            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start;
                const end = this.dragEvent.end;
                const duration = end - start;
                const newStartTime = mouse - this.dragTime;
                const newStart = this.roundTime(newStartTime);
                const newEnd = newStart + duration;
                this.dragEvent.start = newStart;
                this.dragEvent.end = newEnd;
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false);
                const min = Math.min(mouseRounded, this.createStart);
                const max = Math.max(mouseRounded, this.createStart);
                this.createEvent.StartTime = min;
                this.createEvent.EndTime = max;
            }
        },

        endDrag(item) {
            //   console.log("End drag called", this.createEvent);

            this.dragTime = null;
            this.dragEvent = null;
            //   this.createEvent = null;

            this.createStart = null;
            this.extendOriginal = null;
            this.createEvent.StartTime = this.createStartTime;
            this.createEvent.EndTime = item.date + " " + item.time;

            this.$emit("createEvent", this.createEvent);
        },

        onclickMonth() {
            this.$emit("dateChanged", this.appointments_filter_date);
        },

        cancelDrag() {
            console.log("Cancel drag called");

            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal;
                } else {
                    const i = this.events.indexOf(this.createEvent);
                    if (i !== -1) {
                        this.events.splice(i, 1);
                    }
                }
            }

            this.createEvent = null;
            this.createStart = null;
            this.dragTime = null;
            this.dragEvent = null;
        },

        roundTime(time, down = true) {
            const roundTo = 15; // minutes
            const roundDownTime = roundTo * 60 * 1000;

            return down
                ? time - (time % roundDownTime)
                : time + (roundDownTime - (time % roundDownTime));
        },

        toTime(tms) {
            return new Date(
                tms.year,
                tms.month - 1,
                tms.day,
                tms.hour,
                tms.minute
            ).getTime();
        },

        dateSelector() {
            this.date_change_model = true;
        },

        locationChange() {
            this.locSearch = "";
        },

        locationInput() {
            this.locLoading = true;
            const data = new FormData();
            data.append("search", this.locSearch);
            this.$store
                .dispatch("global/post", {
                    url: "/api/get_locations_search",
                    body: data
                })
                .then(res => {
                    this.locations = res.data.map(function(r) {
                        return {
                            text: r.Name,
                            value: r.SL_ID
                        };
                    });
                    this.locLoading = false;
                });
        },

        clickMore(item) {
            this.moreApptsKey++;
            this.more_appt = true;
            this.more_date = item.date;
        },

        dateClicked(item) {
            this.$store.commit("appointments/SET_APPOINTMENTS_DATE", item.date);
            this.$emit("dateChanged", item.date);
            this.type = "day";
        },

        statusColors(item) {
            switch (item.AppointmentStatus) {
                case "Confirmed":
                    return "success";
                    break;
                case "CheckedIn":
                    return "#3E63A3";
                    break;
                case "Scheduled":
                    return "orange";
                    break;
                case "NoShow":
                    return "grey";
                    break;
                case "Cancelled":
                    return "red";
                    break;
                case "Rescheduled":
                    return "secondary";
                    break;
                case "CheckedOut":
                    return "brown";
                    break;
                case "ReminderSent":
                    return "dark";
                    break;
                case "Unknown":
                    return "#933F64";
                    break;
                case "Roomed":
                    return "#234E59";
                    break;
                case "NeedsReschedule":
                    return "secondary";
                    break;
                case "ReadyToBeSeen":
                    return "#E07C7D";
                    break;
                case "Tentative":
                    return "#C7530E";
                    break;

                default:
            }
        },
        selectAll() {},
        // refresh(search) {
        //   const filters = [
        //     {
        //       column: "StartDate",
        //       search: search,
        //     },
        //   ];
        //   const options = {};
        //   options.filters = filters;
        //   options.timezone = this.user.timezone
        //     ? this.user.timezone
        //     : this.default_timezon;
        //   this.$store
        //     .dispatch("appointments/getAppointments", options)
        //     .then((r) => {});
        // },

        getEvents(item) {
            //   console.log("-----------> Get events getting called with item ", item);
            //   if (this.type == "week") {
            //     var filterDate = this.getMonday(this.appointments_filter_date)
            //       .toISOString()
            //       .substr(0, 10);
            //     console.log("week type---------------");
            //     this.$store.commit("appointments/SET_APPOINTMENTS_DATE", filterDate);
            //     this.$emit("dateChanged", `${filterDate}`);
            //     return;
            //   }

            const start = item.start;
            const end = item.end;
            if (start.date !== end.date)
                this.dateString = `${this.months[start.month - 1]} ${start.day}
    - ${this.months[end.month - 1]}
    ${end.day},${end.year}`;
            else
                this.dateString = `${this.months[end.month - 1]} ${end.day},${
                    end.year
                }`;
            const _this = this;
            //   this.refresh(`${start.date},${end.date}`);
            this.$emit("dateChangedFromCal", `${start.date}`);
            //   console.log("VALUIE =========== " + JSON.stringify(item));
        },

        getEventColor(event) {
            //   console.log("event : " + JSON.stringify(event));
            return event.color;
        },
        // rnd(a, b) {
        //   return Math.floor((b - a + 1) * Math.random()) + a;
        // },

        viewAppt(item) {
            this.app = this.appointments.filter(
                r => r.id == item.eventParsed.input.id
            )[0];
            this.info_dialog = true;
        },

        close() {
            this.info_dialog = false;
            this.popover = false;
            this.more_appt = false;
        }
    }
};
</script>

<style scoped lang="scss">
.v-event-draggable {
    padding-left: 6px;
}

.v-event-timed {
    user-select: none;
    -webkit-user-select: none;
}

.v-event-drag-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    height: 4px;
    cursor: ns-resize;

    &::after {
        display: none;
        position: absolute;
        left: 50%;
        height: 4px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        width: 16px;
        margin-left: -8px;
        opacity: 0.8;
        content: "";
    }

    &:hover::after {
        display: block;
    }
}

.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: white;
}

.v-btn-toggle > .v-btn.v-btn--active {
    background-color: var(--primary) !important;
    color: white;
    border: none;
}

.v-btn-toggle > .v-btn.v-btn--active i {
    color: white !important;
}

::v-deep .v-calendar-category .v-calendar-daily__head {
    background-color: white !important;
}

::v-deep .v-calendar-daily__interval {
    background-color: white !important;
}

::v-deep .v-calendar-daily__intervals-head {
    background-color: white !important;
}
</style>
