<template>
  <div :id="file.name">
    <audio v-show="false" @timeupdate="onTimeUpdate" controls></audio>

    <div class="notes-voice-box">
      <div class="d-flex align-center">
        <v-icon color="red">mdi-microphone</v-icon>
        <p>{{ file.name }}</p>
        <v-btn small dark depressed fab @click="togglePlay">
          <v-icon>{{ isPlaying ? "mdi-pause" : "mdi-play" }}</v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <span class="mr-2 mt-1">{{ convertSecondstoTime(current) }}</span>
        <v-slider
          :readonly="total == 0"
          :max="total"
          min="0"
          @end="seekerChange"
          @click="seekerChange"
          v-model="current"
          class="mt-2"
          hide-details
          color="red"
          track-color="grey"
          always-dirty
        ></v-slider>
        <span class="mt-1 ml-2">{{ convertSecondstoTime(total) }}</span>
      </div>

      <div
        class="delete-download-widget"
        style="padding: 0 !important; padding-top: 5px"
      >
        <v-icon v-if="file.uuid" color="primary" class="mr-3"
        @click="$emit('download',file.uuid)"
          >mdi-download</v-icon
        >
        <v-icon @click="deleteDialogOpen()" color="red">mdi-delete</v-icon>
      </div>
    </div>
    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="deleteAt()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["file"],
  data: () => ({
    current: 0,
    total: 0,
    isPlaying: false,
    delete_dialog: false,
    audio: null,
  }),

  mounted() {
    this.audio = document.getElementById(this.file.name).firstChild;
    this.audio.src = this.file.url
      ? this.file.url
      : URL.createObjectURL(this.file.file);
    console.log(this.audio);
  },

  computed: {
    // current(){
    //   return this.audio?this.audio.currentTime:"0"
    // },
    // total(){
    //   return this.audio?this.audio.duration:"0"
    // }
  },
  methods: {
    deleteAt() {
      this.$emit("delete");
      this.close();
    },
    seekerChange(e) {
      console.log(e);
      this.audio.currentTime = this.current;
    },
    convertSecondstoTime(given_seconds) {
      const dateObj = new Date(given_seconds * 1000);
      const hours = dateObj.getUTCHours();
      const minutes = dateObj.getUTCMinutes();
      const seconds = dateObj.getSeconds();
      return (
        (hours > 0 ? hours.toString().padStart(2, "0") + ":" : "") +
        minutes.toString().padStart(2, "0") +
        ":" +
        seconds.toString().padStart(2, "0")
      );
    },
    onTimeUpdate(item) {
      console.log(this.audio.seekable);
      this.current = Math.trunc(this.audio.currentTime);
      if (this.audio.duration !== "Infinity")
        this.total = Math.trunc(this.audio.duration);
      if (this.audio.ended) {
        this.isPlaying = false;
      }
    },
    togglePlay() {
      if (this.audio.paused) {
        this.audio.play();
        this.isPlaying = true;
      } else {
        this.audio.pause();
        this.isPlaying = false;
      }
    },

    deleteDialogOpen() {
      this.delete_dialog = true;
    },

    close() {
      this.delete_dialog = false;
    },
  },
};
</script>
