import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

import store from "../js/store/index";

Vue.use(Vuetify);
const opts = {
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
            light: {
                primary: store.state.themes.colors.primary,
                dark: "#2D4859",
                darker: store.state.themes.colors.darker,
                red: "#FF4D4F",
                sidebar_light: "#9aa2ac",
                back_color: "#f1f3f6",
                green: "#00cc00"
            },
            dark: {
                primary: store.state.themes.colors.primary,
                dark: "#2D4859",
                darker: store.state.themes.colors.darker,
                red: "#FF4D4F",
                sidebar_light: "#9aa2ac",
                back_color: "#f1f3f6",
                green: "#00cc00"
            }
        }
    }
};

export default new Vuetify(opts);
