<template>
  <div>
    <v-container>
      <v-card-actions class="py-0 mb-1">
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="templates"
        :options.sync="options"
        :loading="loading"
        :server-items-length="totalItems"
        :search="search"
        class="elevation-0 en-table-card"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Fax Templates</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              class="elevation-1"
              @click="refresh"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
            <v-btn class="ml-2" @click="addNew" depressed color="primary">
              Add New
            </v-btn>
          </v-toolbar>
          <v-row class="px-4">
            <v-col cols="12" md="12">
              <v-text-field
                dense
                clearable
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.serviceLocation="{ item }">
          <span v-if="item.service_location.length == 0"> N/A </span>
          <span v-if="item.service_location.length == 1">
            {{
              item.service_location[0].location != null
                ? item.service_location[0].location.Name
                : "N/A"
            }}
          </span>
          <v-btn v-if="item.service_location.length > 1" color="primary" small depressed @click="serviceLocationDialogOpen(item)">Multiple locations</v-btn>
          <!-- <v-icon

            small
            color="primary"
            @click="serviceLocationDialogOpen(item)"
            >mdi-information</v-icon
          > -->
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="primary" small @click="editItem(item)"
            >mdi-pencil</v-icon
          >
        </template>

        <template v-slot:item.status>
          <v-btn x-small depressed color="success">Active</v-btn>
        </template>
      </v-data-table>
    </v-container>

    <!-- notification detail Dialog -->
    <v-dialog v-model="dialog" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span>Service Location</span>
          <v-spacer></v-spacer>
          <v-btn @click="close()" icon color="grey">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <!-- <v-card class="en-card mt-4" flat> -->

        <v-col class="pt-0"> </v-col>
        <v-col class="pt-1">
          <v-chip
            class="mr-2 mb-2"
            v-for="service in currentItem.service_location"
            :key="service.id"
            >{{
              service.location == null ? "N/A" : service.location.Name
            }}</v-chip
          >
        </v-col>
        <!-- </v-card> -->

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="close">close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";

export default {
  data: () => ({
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Fax Template",
        disabled: true,
        to: "",
      },
    ],
    loading: false,
    currentItem: Object,
    totalItems: 10,
    dialog: false,
    editedItem: {
      id: 0,
      title: "",
      locations: "",
      description: "",
    },
    timer: null,
    options: {},
    show1: false,
    search: "",
    headers: [
      {
        text: "Title",
        align: "start",
        value: "title",
      },
      {
        text: "Service Locations",
        align: "start",
        value: "serviceLocation",
      },
      { text: "Action", align: "end", sortable: false, value: "actions" },
    ],
  }),

  created() {
    this.initialize();
  },

  watch: {
    search: function () {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.refresh();
      }, 500);
    },
    options: {
      handler() {
        this.refresh();
        //console.log("Options Handler Called" + JSON.stringify(this.options));
      },
      // deep: true,
    },
  },

  computed: {
    ...mapState({
      templates: (state) => state.faxTemplates.fax_templates,
    }),
  },
  methods: {
    clearFilters() {
      this.search = "";
    },
    refresh() {
      this.options.search = this.search;
      this.options.column = "title";
      this.$store
        .dispatch("faxTemplates/getFaxTemplates", this.options)
        .then((response) => {
          this.totalItems = response.data.data.total;
        });
    },

    initialize() {
      this.refresh();
    },

    serviceLocationDialogOpen(item) {
      this.currentItem = item;
      this.dialog = true;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.addNew();
    },

    addNew() {
      this.$store.commit("faxTemplates/SET_TINYMC_OBJECT", this.editedItem);
      this.$router.push({ name: "addFaxTemplate" });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>
