<template>
  <div class="your-provider-section">
    <div class="en-card mt-4">
      <v-row>
        <v-col cols="12" sm="7" md="7">
          <h5>Your Psychiatric Provider</h5>
        </v-col>
        <v-col
          v-if="user.account_type != $constants.COORDINATOR"
          class="text-sm-end text-md-end"
          cols="12"
          sm="5"
          md="5"
        >
          <v-icon @click="VideoCall(1)">mdi-video</v-icon>
          <v-icon @click="addForChat(1)" class="ml-2"
            >mdi-android-messages</v-icon
          >
        </v-col>
        <v-col class="py-1" cols="12" sm="12" md="12">
          <v-row>
            <v-col class="py-1" cols="12" sm="2" md="2">
              <div v-if="user.psychiatric_provider">
              <img
                v-if="user.psychiatric_provider.user.image != null && user.psychiatric_provider.user.image != '' "
                :src="user.psychiatric_provider.user.image"
                alt=""
              />
              <h6 v-else class="f-name">
                {{ user.psychiatric_provider.user.name[0] }}
              </h6>
              </div>

            </v-col>

            <v-col class="py-1 pb-3" cols="12" sm="10" md="10">
              <h5 class="name-is mb-0">
                {{
                  user.psychiatric_provider
                    ? user.psychiatric_provider.user.name
                    : "N/A"
                }}
              </h5>
              <p class="mb-4">
                {{
                  user.psychiatric_provider
                    ? user.psychiatric_provider.user.description
                    : "N/A"
                }}
              </p>

              <h6 style="font-size: 14px;" class="mb-0">Office Days:</h6>
              <div class="week-days" v-if="user.psychiatric_provider">
                <p
                  v-for="item in daysFilter(
                    user.psychiatric_provider.user.available_days
                  )"
                  :key="item.value"
                >
                  {{ item.label }} 
                </p>
              </div>

            </v-col>
          </v-row>
        </v-col>

        <v-col class="px-0 py-2" cols="12" sm="12" md="12">
          <hr />
        </v-col>
        <v-col cols="12" sm="7" md="7">
          <h5>Your Talk Therapy Provider</h5>
        </v-col>
        <v-col
          v-if="user.account_type != $constants.COORDINATOR"
          class="text-sm-end text-md-end"
          cols="12"
          sm="5"
          md="5"
        >
          <v-icon @click="VideoCall(2)">mdi-video</v-icon>
          <v-icon @click="addForChat(2)" class="ml-2"
            >mdi-android-messages</v-icon
          >
        </v-col>

        <v-col class="py-1" cols="12" sm="12" md="12">
          <v-row>
            <v-col class="py-1" cols="12" sm="2" md="2">
                 <div v-if="user.psychiatric_provider">
              <img
                v-if="user.talk_therapy_provider.user.image != null  && user.talk_therapy_provider.user.image != ''"
                :src="user.talk_therapy_provider.user.image"
                alt=""
              />
              <h6 v-else class="f-name">
                {{ user.talk_therapy_provider.user.name[0] }}
              </h6>
              </div>
            </v-col>

            <v-col class="py-1 pb-3" cols="12" sm="10" md="10">
              <h5 class="name-is mb-0">
                {{
                  user.talk_therapy_provider
                    ? user.talk_therapy_provider.user.name
                    : "N/A"
                }}
              </h5>
              <p class="mb-4">
                {{
                  user.talk_therapy_provider
                    ? user.talk_therapy_provider.user.description
                    : "N/A"
                }}
              </p>

              <h6 style="font-size: 14px" class="mb-0">Office Days:</h6>
              <div class="week-days" v-if="user.talk_therapy_provider">
                <p class="mb-3"
                  v-for="item in daysFilter(
                    user.talk_therapy_provider.user.available_days
                  )"
                  :key="item.value"
                >
                  {{ item.label }} 
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    days: [
      { label: "Sunday", value: 1 },
      { label: "Monday", value: 2 },
      { label: "Tuesday", value: 3 },
      { label: "Wednesday", value: 4 },
      { label: "Thursday", value: 5 },
      { label: "Friday", value: 6 },
      { label: "Saturday", value: 7 },
    ],
    user:{
},
  }),

  computed: {
    ...mapState({
      loginUser: (state) => state.currentUser.user,
    }),
  },

  created() {
    this.initialize();
    // console.log("====================",this.user);
  },
  methods: {
    initialize(){
      this.$store
          .dispatch("global/post", {
              url: "/api/your_psy_talk_providers",
              body: new FormData()
          })
          .then(r => {
              this.$store.dispatch("global/actionloaderState", false, {
                  root: true
              });
              this.user = r.data.data;
          })
          .catch(error => {
              this.$store.dispatch("global/actionloaderState", false, {
                  root: true
              });
          });
    },
    addForChat(type) {
      if (type == 1) {
        var currentParticipant = this.user.psychiatric_provider.user;
      } else if (type == 2) {
        var currentParticipant = this.user.talk_therapy_provider.user;
      }
      this.$store.dispatch("chat/addParticipant", currentParticipant);
      this.$router.push({
        name: "chat",
        params: {
          currentParticipant: currentParticipant,
        },
      });
    },

    VideoCall(type) {
      if (type == 1) {
        var currentParticipant = this.user.psychiatric_provider.user;
      } else if (type == 2) {
        var currentParticipant = this.user.talk_therapy_provider.user;
      }
      this.startVideoCall(currentParticipant);
    },

    startVideoCall(item) {
      const roomId = this.makeVideoChatId(item);
      const _this = this;
      //TestComment
      this.$db
        .collection("users")
        .doc(item.email)
        .onSnapshot(function (doc) {
          _this.refresh();
        });
      this.$store.dispatch("users/addUserToCall", {
        sendTo: item.id,
        roomId: roomId,
        mail: item.email,
        name: item.name ? item.name : "",
      });
    },

    makeVideoChatId(item) {
      let id;
      if (item.id > this.user.id) id = `${item.id}_${this.user.id}`;
      else id = `${this.user.id}_${item.id}`;
      const hash = id.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
      return hash;
    },

    daysFilter(available_days) {
      var arr = [];
      if (available_days.length > 0) {
        available_days.forEach((element) => {
          arr.push(this.days.find((r) => r.value == element));
        });
        return arr;
      }
    },
  },
};
</script>

<style></style>
