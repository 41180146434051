var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card-actions',{staticClass:"py-0 mb-1"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"items":_vm.bread}})],1),_vm._v(" "),_c('v-card',{staticClass:"en-card elevation-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.titleErrors,"required":"","label":"Title","outlined":"","dense":""},on:{"input":function($event){return _vm.$v.editedItem.title.$touch()},"blur":function($event){return _vm.$v.editedItem.title.$touch()}},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"12"}},_vm._l((_vm.chipsList),function(item,i){return _c('v-chip',{key:i,staticClass:"mr-1 mb-1",attrs:{"small":""},on:{"click":function($event){return _vm.addChip(item.meta)}}},[_vm._v(_vm._s(item.meta))])}),1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('editor',{attrs:{"initial-value":_vm.editedItem.description,"init":{
              height: 400,
              content_style: 'body {font-family: Arial;}',
              branding: false,
              selector: 'textarea#full-featured-non-premium',
              menubar: 'file edit view insert format tools table tc',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
                'textcolor' ],
              toolbar:
                'undo redo | bold italic underline strikethrough removeformat | forecolor backcolor  | fontselect | fontsizeselect | formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | fullscreen  preview save print | insertfile image template link codesample | ltr rtl',
              toolbar_sticky: true,
              toolbar_mode: 'sliding',
            }},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-combobox',{attrs:{"items":_vm.locations,"loading":_vm.locLoading,"disabled":_vm.addLoading,"search-input":_vm.locSearch,"hide-selected":"","outlined":"","label":"Locations","multiple":"","persistent-hint":"","small-chips":"","error-messages":_vm.locationErrors,"required":""},on:{"update:searchInput":function($event){_vm.locSearch=$event},"update:search-input":function($event){_vm.locSearch=$event},"change":_vm.comboChange,"focus":function($event){return _vm.onFocus()},"blur":function($event){return _vm.$v.editedItem.locations.$touch()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var attrs = ref.attrs;
            var item = ref.item;
            var parent = ref.parent;
            var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":((item.color) + " lighten-3"),"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.text))]),_vm._v(" "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("mdi-close")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("No results Found")])],1)],1)]},proxy:true}]),model:{value:(_vm.editedItem.locations),callback:function ($$v) {_vm.$set(_vm.editedItem, "locations", $$v)},expression:"editedItem.locations"}})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"depressed":"","color":"primary"},on:{"click":_vm.update}},[_vm._v("Save")])],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.add_more),callback:function ($$v) {_vm.add_more=$$v},expression:"add_more"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Add More")])]),_vm._v(" "),_c('v-card-text',[_vm._v("Want to add more Template?")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.close}},[_vm._v("No")]),_vm._v(" "),_c('v-btn',{attrs:{"depressed":"","color":"primary darken-1","width":"100px"},on:{"click":function($event){return _vm.addMore()}}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }