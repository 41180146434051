<template>
  <div class="notes-video-widget">
    <video-player
      class="video-player-box"
      ref="videoPlayer"
      :options="playerOptions"
      :playsinline="true"
      customEventName="customstatechangedeventname"
      @play="onPlayerPlay($event)"
      @pause="onPlayerPause($event)"
      @statechanged="playerStateChanged($event)"
      @ready="playerReadied"
    ></video-player>
    <div class="delete-download-widget">
      <v-icon v-if="file.uuid" color="primary" class="mr-3"
      @click="$emit('download',file.uuid)"
        >mdi-download</v-icon
      >
      <v-icon @click="deleteDialogOpen()" color="red">mdi-delete</v-icon>
    </div>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="deleteAt"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";

export default {
  props: ["file", "onDelete"],
  components: {
    videoPlayer,
  },

  computed: {},

  data: () => ({
    delete_dialog: false,
    playerOptions: {
      muted: true,
      player: null,
      language: "en",
      playbackRates: [0.7, 1.0, 1.5, 2.0],
      sources: [
        {
          type: "video/mp4",
          src:
            "https://vod-progressive.akamaized.net/exp=1600517991~acl=%2A%2F1062516367.mp4%2A~hmac=7b71c721af734a6c0e6226e205f7bc23dc4396fe2b1165ea7c3ba27e987f2004/vimeo-prod-skyfire-std-us/01/1551/11/282759939/1062516367.mp4?filename=Pexels+Videos+1291114.mp4",
        },
      ],
      poster: "/static/images/author.jpg",
    },
  }),
  mounted() {
    this.player = this.$refs.videoPlayer.player;
    this.playerOptions.sources[0].src = this.file.url
      ? this.file.url
      : URL.createObjectURL(this.file.file);
  },
  methods: {
    deleteAt() {
      this.$emit("delete");
      this.close();
    },
    // listen event
    onPlayerPlay(player) {},
    onPlayerPause(player) {},
    playerStateChanged(playerCurrentState) {},
    playerReadied(player) {},
    deleteDialogOpen() {
      this.delete_dialog = true;
    },
    close() {
      this.delete_dialog = false;
    },
  },
};
</script>
