<template>
  <div>
      <div v-if="loading" class="text-center">
   <v-progress-linear
      indeterminate
      color="primary"
    ></v-progress-linear>
      </div>
    <div v-else v-for="comment in comments" class="comment" :key="comment.id">
      <div class="d-flex">
        <img class="comment-img" :src="comment.postedBy.image" alt />
        <div style="width: 100%">
          <p class="commentor-name">
            {{ comment.postedBy.name }}
            <span class="comment-sub-text">{{comment.created_at | moment("from", true)}}</span>
          </p>
          <p class="comment-text">{{ comment.comment }}</p>
          <v-divider class="mx-3"></v-divider>
        </div>
      </div>
    </div>

    <div class="no-comment-section" v-if="comments == 0">
      <img class="no-comment" :src="'/assets/no_comments.svg'" alt="" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["noteId"],
  data: () => ({

  }),
  computed: {
    ...mapState({
      comments: (state) => state.notes.comments,
      loading: (state) => state.notes.loading
    }),
  },
  created() {
      console.log("COMMENT CREATED CALLED --------------");



  },
};
</script>
