<template>
  <div>
    <div class="logs-header">
      <v-container class="pb-0">
        <h2>Logs</h2>

        <v-tabs
          class="mt-4"
          v-model="tab"
          background-color="transparent"
          color="primary"
        >
          <v-tab
            v-for="item in tab_items"
            :key="item"
            @click="initializer(item)"
          >
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-container>
    </div>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <Dashboard />
      </v-tab-item>
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <Logs />
      </v-tab-item>

      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <LogSettings />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Dashboard from "./DashboardLogs";
import Logs from "./Logs";
import LogSettings from "./LogSettings";
export default {
  components: {
    Dashboard,
    Logs,
    LogSettings,
  },
  data: () => ({
    tab_items: ["Dashboard", "Logs", "Log Settings"],
  }),

  computed: {
    tab: {
      get: function () {
        return this.$store.state.global.tabs.logsTabs;
      },
      set: function (value) {
        var setTab = {
          type: 10,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },

  methods: {
    initializer(item) {
      //dashboard
      if (this.tab_items[0] == item) {
        this.$store.dispatch("admin_log_reader/getdashboard", {});
      }

      //logs
      if (this.tab_items[1] == item) {
        this.$store.dispatch("admin_log_reader/GetAllLogsFiles", {
          options: {
            itemsPerPage: 10,
            page: 1,
          },
          page: 1,
        });
      }

      if (this.tab_items[2] == item) {
        //this.$store.dispatch("admin_log_reader/GetAllLogsFiles", {});
      }
    },
  },
};
</script>

<style></style>
