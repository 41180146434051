<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" md="12">
        <p class="en-fields-label">Sendgrid API key</p>
        <v-text-field
          v-model="item.sendgrid_key"
          dense
          outlined
          :error-messages="sgKeyErrors"
          @input="$v.item.sendgrid_key.$touch()"
          @blur="$v.item.sendgrid_key.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <p class="en-fields-label">Name</p>
        <v-text-field
          v-model="item.name_from"
          dense
          outlined
          :error-messages="nameFromErrors"
          @input="$v.item.name_from.$touch()"
          @blur="$v.item.name_from.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <p class="en-fields-label">Email</p>
        <v-text-field
          v-model="item.email_from"
          dense
          outlined
          :error-messages="emailFromErrors"
          @input="$v.item.email_from.$touch()"
          @blur="$v.item.email_from.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" class="py-0">
        <v-btn
          depressed
          @click="saveAPISetting()"
          large
          color="primary"
          style="width: 170px"
          >Save Changes</v-btn
        >

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="elevation-1 ml-3"
              fab
              light
              small
              color="white"
              @click="openEmailDialog()"
            >
              <v-icon color="primary">mdi-flask</v-icon>
            </v-btn>
          </template>
          <span>Test</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-dialog v-model="email_dialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>Enter Your Email</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="en-fields-label">Email</p>
              <v-text-field
                v-model="email"
                dense
                outlined
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> Close </v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 120px"
            @click="close()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, requiredIf, email } from "vuelidate/lib/validators";

export default {
  data: () => ({
    item: {
      sendgrid_key: "",
      name_from: "",
      email_from: "",
    },
    email: "",
    email_dialog: false,
  }),
 created(){
    this.item.sendgrid_key= this.$store.state.generalSetting.get_general_setting.sendgrid_key
    this.item.name_from= this.$store.state.generalSetting.get_general_setting.name_from
    this.item.email_from= this.$store.state.generalSetting.get_general_setting.email_from
  },
  validations: {
    item: {
      sendgrid_key: { required },
      name_from: { required },
      email_from: { required, email },
    },
    email: {
      required: requiredIf(function (abc) {
        return this.email_dialog == true;
      }),
      email,
    },
  },


  computed: {
    ...mapState({
      get_general_setting: (state) => state.generalSetting.get_general_setting,
    }),

    sgKeyErrors() {
      const errors = [];
      if (!this.$v.item.sendgrid_key.$dirty) return errors;
      !this.$v.item.sendgrid_key.required &&
        errors.push("Sendgrid API key is required.");
      return errors;
    },

    nameFromErrors() {
      const errors = [];
      if (!this.$v.item.name_from.$dirty) return errors;
      !this.$v.item.name_from.required && errors.push("Name is required.");
      return errors;
    },

    emailFromErrors() {
      const errors = [];
      if (!this.$v.item.email_from.$dirty) return errors;
      !this.$v.item.email_from.required && errors.push("Email is required.");
      !this.$v.item.email_from.email && errors.push("Email must be valid.");
      return errors;
    },

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email is required.");
      !this.$v.email.email && errors.push("Email must be valid.");
      return errors;
    },
  },

  methods: {
    openEmailDialog() {
      this.email_dialog = true;
      this.$v.$reset();
    },

    saveAPISetting() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "Sendgrid API key is required.",
        });
      } else {
        this.$store.dispatch("generalSetting/setAPIVariable", this.item);
      }
    },

    close() {
      this.email_dialog = false;
      this.$v.$reset();
    },
  },
};
</script>
