<template>
  <div>
    <v-sheet @click="clickNoteImage()">
      <img
        :class="[
          this.$route.name == 'videoCall' ? 'note-img-panel' : 'note-img',
        ]"
        :src="source"
        alt
      />
    </v-sheet>
    <div class="delete-download-widget">
      <v-icon
        v-if="file.uuid && !get_Delete_Status"
        color="primary"
        class="mr-3"
        @click="$emit('download', file.uuid)"
        >mdi-download</v-icon
      >
      <v-icon v-if="get_Delete_Status" @click="deleteDialogOpen()" color="red"
        >mdi-delete</v-icon
      >
    </div>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="deleteAt(file)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  props: ["file"],
  data: () => ({
    delete_dialog: false,
  }),
  computed: {
    ...mapState({
      get_Delete_Status: (state) => state.notes.get_Delete_Status,
    }),

    source() {
      var url = window.location.origin;
      return this.file.url
        ? this.file.url
        : URL.createObjectURL(this.file.file);
    },
  },

  methods: {
    deleteAt(value) {
      var obj = {
        image_id: value.id,
        note_id: value.note_id,
        uuid: value.uuid,
      };
      console.log(obj);
      this.$store.dispatch("notes/deleteNotesImage", obj);
      this.$emit("delete");
      this.close();
    },
    deleteDialogOpen() {
      this.delete_dialog = true;
    },

    close() {
      this.delete_dialog = false;
    },
  },
};
</script>
