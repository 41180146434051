<template>
  <v-container>
    <v-card-actions class="py-0 mb-1">
      <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
    </v-card-actions>
    <v-snackbar top right :elevation="0" color="transparent" v-model="alert">
      <v-alert dense prominent type="error">
        <v-row align="center">
          <v-col class="grow">
            {{ alertError }}
          </v-col>
          <v-col class="shrink">
            <v-btn fab x-small @click="alert = false">
              <v-icon color="white">mdi-close</v-icon></v-btn
            >
          </v-col>
        </v-row>
      </v-alert>
    </v-snackbar>

    <v-data-table
      :custom-filter="localSearch"
      :loading="loading"
      :items="codes"
      :search="search"
      :headers="headers"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Procedure Codes</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            class="elevation-1 mr-3"
            @click="refresh"
            fab
            light
            small
            color="white"
          >
            <v-icon color="primary">mdi-sync</v-icon>
          </v-btn>

          <!-- Add new dialog -->
          <v-dialog
            v-model="addEditDialog"
            persistent
            scrollable
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="clearCurrentItem"
                color="primary"
                dark
                depressed
                v-bind="attrs"
                v-on="on"
                >Add New</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      outlined
                      dense
                      v-model="currentItem.code"
                      label="Code"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      outlined
                      v-model="currentItem.description"
                      dense
                      label="Description"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  :disabled="
                    addLoading || !currentItem.code || !currentItem.description
                  "
                  :loading="addLoading"
                  style="width: 100px"
                  @click="save()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <v-text-field
              dense
              clearable
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.code="{ item }">
        <div class="d-flex justify-start">
          <b>{{ item.code }}</b>
        </div>
      </template>

      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-end">
          <v-icon
            small
            color="primary"
            @click="editDialogOpen(item)"
            class="mr-3"
            >mdi-pencil</v-icon
          >
          <v-icon small color="red" @click="deleteDialogOpen(item)" class="mr-3"
            >mdi-delete</v-icon
          >
          <v-switch
            @change="changeStatus(item)"
            :loading="statusChanging == item.id"
            :disabled="statusChanging == item.id"
            dense
            v-model="item.is_active"
          ></v-switch>
          <!-- <p>{{1-item.is_active}}</p> -->
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            :disabled="deleting"
            :loading="deleting"
            color="primary"
            depressed
            style="width: 100px"
            @click="deleteCode()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { log } from "../agora/utils/utils";
export default {
  data: () => ({
    alert: false,
    alertError: false,
    deleting: false,
    currentItem: {
      id: -1,
      code: "",
      description: "",
    },
    formTitle: "Add new code",
    addEditDialog: false,
    statusChanging: -1,
    deleteDialog: false,
    addLoading: false,
    search: "",
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Lookup Tables",
        disabled: false,
        to: "/lookup-tables",
      },

      {
        text: "Procedure Codes",
        disabled: true,
        to: "",
      },
    ],
    codes: [],
    headers: [
      {
        text: "Code",
        value: "code",
      },
      {
        text: "Description",
        value: "description",
      },
      {
        text: "",
        value: "action",
      },
    ],
  }),
  watch: {
    alert: function (val) {
      if (val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
  },
  created() {
    this.refresh();
  },
  methods: {
    clearCurrentItem() {
      this.currentItem.id = -1;
      this.currentItem.code = "";
      this.currentItem.description = "";
    },
    editDialogOpen(item) {
      this.currentItem.id = item.id;
      this.currentItem.code = item.code;
      this.currentItem.description = item.description;
      this.addEditDialog = true;
    },
    deleteDialogOpen(item) {
      this.currentItem.id = item.id;
      this.deleteDialog = true;
    },
    localSearch(value, search, item) {
      return (
        item.code.toLowerCase().includes(search.toLowerCase()) ||
        item.description.toLowerCase().includes(search.toLowerCase())
      );
    },
    changeStatus(item) {
      this.statusChanging = item.id;
      const data = new FormData();
      data.append("id", item.id);
      data.append("status", item.is_active ? 1 : 0);

      axios
        .post("/api/change_code_status", data)
        .then((r) => {
          this.statusChanging = -1;
        })
        .catch((e) => {
          this.showError("An error occured. Please try again");
          this.statusChanging = -1;
          item.is_active = 1 - item.is_active;
        });
    },
    showError(error) {
      this.alert = true;
      this.alertError = error || "An error occured";
    },
    save() {
      this.addLoading = true;
      const data = new FormData();
      if (this.currentItem.id != -1) {
        data.append("id", this.currentItem.id);
      }
      data.append("code", this.currentItem.code);
      data.append("description", this.currentItem.description);

      axios
        .post("/api/add_edit_code", data)
        .then((r) => {
          this.addLoading = false;
          this.refresh();
          this.close();
        })
        .catch((e) => {
          this.addLoading = false;
          if (this.currentItem.id == -1) {
            this.showError("Failed to add Procedure Code. Please try again");
          } else {
            this.showError("Failed to edit Procedure Code. Please try again");
          }
          this.close();
        });
    },

    deleteCode() {
      this.deleting = true;
      const data = new FormData();
      if (this.currentItem.id != -1) {
        data.append("id", this.currentItem.id);
      }
      axios
        .post("/api/delete_code", data)
        .then((r) => {
          this.deleting = false;
          this.$store.dispatch(
            "global/snackBarState",
            {
              show: true,
              color: "primary",
              message: "Delete successfully",
            },
            { root: true }
          );
          this.refresh();
          this.close();
        })
        .catch((e) => {
          this.showError("Failed to delete code. Please try again");
          this.deleting = false;
          this.close();
        });
    },

    refresh() {
      this.loading = true;

      axios
        .post("/api/get_procedure_codes")
        .then((r) => {
          this.loading = false;
          this.codes = r.data.data;
        })
        .catch((e) => {
          this.loading = false;
          this.showError("Someting went wrong");
        });
    },
    close() {
      this.addEditDialog = false;
      this.deleteDialog = false;
    },
  },
};
</script>

<style>
</style>
