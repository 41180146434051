<template>
    <div>
        <p v-show="false">{{ showOnlyName }}</p>

        <v-data-table
            :headers="headers"
            :items="getBasicUserData"
            sort-by="name"
            :search="search"
            class="en-table-card"
            style="border: none !important"
            :items-per-page="30"
            :footer-props="{
                'items-per-page-options': [10, 30, 50]
            }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>ETH Office</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn
                        class="elevation-1 mr-3"
                        @click="initialize()"
                        fab
                        light
                        small
                        color="white"
                    >
                        <v-icon color="primary">mdi-sync</v-icon>
                    </v-btn>

                    <v-dialog
                        v-model="dialog"
                        persistent
                        scrollable
                        max-width="750px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-if="user.account_type == 1"
                                depressed
                                color="primary"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Add New
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title>
                                <span>{{ formTitle }}</span>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <v-tabs
                                    v-model="tab"
                                    background-color="transparent"
                                    color="primary"
                                    show-arrows
                                >
                                    <v-tab>General</v-tab>
                                    <v-tab v-if="editedIndex != -1"
                                        >Password</v-tab
                                    >
                                </v-tabs>
                                <v-divider></v-divider>

                                <v-tabs-items v-model="tab">
                                    <v-tab-item
                                        transition="fade-transition"
                                        reverse-transition="fade-transition"
                                    >
                                        <v-row class="mt-4">
                                            <v-col cols="12" md="6">
                                                <v-select
                                                    :disabled="
                                                        disableAccType &&
                                                            tc.account_type == 1
                                                    "
                                                    outlined
                                                    dense
                                                    v-model="type"
                                                    :items="types"
                                                    label="Account Type"
                                                ></v-select>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="tc.name"
                                                    outlined
                                                    dense
                                                    :disabled="addLoading"
                                                    label="Name"
                                                    :error-messages="nameErrors"
                                                    @input="$v.tc.name.$touch()"
                                                    @blur="$v.tc.name.$touch()"
                                                    required
                                                    @keyup.enter="add()"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="tc.username"
                                                    outlined
                                                    dense
                                                    :disabled="addLoading"
                                                    label="Username"
                                                    :error-messages="
                                                        usernameErrors
                                                    "
                                                    @input="
                                                        $v.tc.username.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.username.$touch()
                                                    "
                                                    required
                                                    @keyup.enter="add()"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="tc.email"
                                                    outlined
                                                    :disabled="addLoading"
                                                    dense
                                                    label="Email"
                                                    :error-messages="
                                                        emailErrors
                                                    "
                                                    @input="
                                                        $v.tc.email.$touch()
                                                    "
                                                    @blur="$v.tc.email.$touch()"
                                                    required
                                                    @keyup.enter="add()"
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <!-- <v-text-field
                          v-model="tc.phone"
                          outlined
                          dense
                          :disabled="addLoading"
                          label="Phone"
                          type="number"
                          :error-messages="phoneErrors"
                          @input="$v.tc.phone.$touch()"
                          @blur="$v.tc.phone.$touch()"
                          required
                          @keyup.enter="add()"
                        ></v-text-field> -->
                                                <VuePhoneNumberInput
                                                    :disabled="addLoading"
                                                    show-code-on-list
                                                    color="#2d4859"
                                                    valid-color="#2d4859"
                                                    v-model="tc.phone"
                                                    :default-phone-number="
                                                        tc.phone
                                                    "
                                                    :no-example="true"
                                                />
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-autocomplete
                                                    v-model="tc.timezone"
                                                    :error-messages="
                                                        timezoneErrors
                                                    "
                                                    @input="
                                                        $v.tc.timezone.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.timezone.$touch()
                                                    "
                                                    :items="timezoneList"
                                                    :loading="timezoneLoading"
                                                    :disabled="timezoneLoading"
                                                    outlined
                                                    label="Timezones"
                                                    persistent-hint
                                                    required
                                                    dense
                                                    type="text"
                                                >
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    No results
                                                                    matching " .
                                                                    Press
                                                                    <kbd
                                                                        >enter</kbd
                                                                    >
                                                                    to create a
                                                                    new one
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                md="6"
                                                v-if="editedIndex == -1"
                                            >
                                                <v-text-field
                                                    v-model="tc.password"
                                                    :error-messages="
                                                        passwordErrors
                                                    "
                                                    @input="
                                                        $v.tc.password.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.password.$touch()
                                                    "
                                                    :disabled="addLoading"
                                                    :append-icon="
                                                        show1
                                                            ? 'mdi-eye'
                                                            : 'mdi-eye-off'
                                                    "
                                                    :type="
                                                        show1
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    label="Password"
                                                    outlined
                                                    dense
                                                    @click:append="
                                                        show1 = !show1
                                                    "
                                                    @keyup.enter="add()"
                                                ></v-text-field>
                                            </v-col>

                                            <!-- Hide some reason -->
                                            <v-col
                                                v-if="false"
                                                cols="12"
                                                md="12"
                                            >
                                                <v-combobox
                                                    v-model="tc.location"
                                                    :items="locations"
                                                    :loading="locLoading"
                                                    :disabled="addLoading"
                                                    :search-input.sync="
                                                        locSearch
                                                    "
                                                    hide-selected
                                                    @change="comboChange"
                                                    outlined
                                                    label="Locations"
                                                    multiple
                                                    persistent-hint
                                                    small-chips
                                                    :error-messages="
                                                        locationErrors
                                                    "
                                                    @blur="
                                                        $v.tc.location.$touch()
                                                    "
                                                    required
                                                >
                                                    <template
                                                        v-slot:selection="{
                                                            attrs,
                                                            item,
                                                            parent,
                                                            selected
                                                        }"
                                                    >
                                                        <v-chip
                                                            v-if="
                                                                item ===
                                                                    Object(item)
                                                            "
                                                            v-bind="attrs"
                                                            :color="
                                                                `${item.color} lighten-3`
                                                            "
                                                            :input-value="
                                                                selected
                                                            "
                                                            label
                                                            small
                                                        >
                                                            <span
                                                                class="pr-2"
                                                                >{{
                                                                    item.text
                                                                }}</span
                                                            >
                                                        </v-chip>
                                                    </template>

                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    >No results
                                                                    Found</v-list-item-title
                                                                >
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-combobox>
                                            </v-col>
                                        </v-row>
                                    </v-tab-item>

                                    <!-- Tab Item 2 -->
                                    <v-tab-item
                                        transition="fade-transition"
                                        reverse-transition="fade-transition"
                                    >
                                        <v-row class="mt-3">
                                            <!-- <v-col cols="12" md="12">
                        <v-text-field
                          v-model="tc.old_password"
                          :error-messages="oldPasswordErrors"
                          @input="$v.tc.old_password.$touch()"
                          @blur="$v.tc.old_password.$touch()"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show2 ? 'text' : 'password'"
                          label="Old Password"
                          outlined
                          dense
                          @click:append="show2 = !show2"
                        ></v-text-field>
                      </v-col> -->

                                            <v-col cols="12" md="12">
                                                <v-text-field
                                                    v-model="tc.new_password"
                                                    :error-messages="
                                                        newPasswordErrors
                                                    "
                                                    @input="
                                                        $v.tc.new_password.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.new_password.$touch()
                                                    "
                                                    :append-icon="
                                                        show3
                                                            ? 'mdi-eye'
                                                            : 'mdi-eye-off'
                                                    "
                                                    :type="
                                                        show3
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    label="New Password"
                                                    outlined
                                                    dense
                                                    @click:append="
                                                        show3 = !show3
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card-text>
                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-switch
                                    dense
                                    class="mt-0 mb-1 ml-4"
                                    inset
                                    v-model="tc.support_team"
                                    hide-details=""
                                    label="Health Services"
                                ></v-switch>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="close">
                                    Cancel
                                </v-btn>
                                <v-btn
                                    v-if="editedIndex == -1"
                                    color="primary"
                                    depressed
                                    style="width: 120px"
                                    @click="add()"
                                >
                                    Save
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="primary"
                                    depressed
                                    style="width: 120px"
                                    @click="add()"
                                >
                                    Update
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title>Confirm Delete</v-card-title>
                            <v-card-text>
                                Are you sure you want to delete this item?
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDelete"
                                    >Cancel</v-btn
                                >
                                <v-btn
                                    color="primary"
                                    text
                                    @click="deleteItemConfirm"
                                    >OK</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
                <v-row class="px-4">
                    <v-col cols="12" md="12">
                        <v-text-field
                            dense
                            clearable
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.name="{ item }">
                <div class="d-flex align-center w-100">
                    <div class="d-flex align-center w-100">
                        <v-icon
                            @click="startVideoCall(item)"
                            :disabled="
                                item.color !== '#00cc00' &&
                                    item.color !== '#ffd801'
                            "
                            size="20"
                            color="green"
                            class="mr-3 ml-md-3 ml-sm-3"
                            >mdi-video</v-icon
                        >

                        <v-icon
                            class="mr-3 chat-icon"
                            @click="addForChat(item)"
                            size="20"
                            color="primary"
                            >mdi-android-messages</v-icon
                        >

                        <v-sheet
                            class="mr-3"
                            :color="item.color"
                            elevation="0"
                            height="47"
                            width="5"
                        ></v-sheet>
                        <span class="name-hover" @click="addForChat(item)"
                            >{{ item.name }}
                            <!-- <v-chip v-if="item.support_team == 1" class="mx-2" x-small
            >{{$constants.GROUP_NAME}}</v-chip> -->
                        </span>
                    </div>

                    <div class="show-only-mobile d-flex">
                        <!-- Show Only Mobile -->
                        <v-icon
                            v-if="user.account_type == 1"
                            color="primary"
                            small
                            class="ml-3"
                            @click="editItem(item)"
                        >
                            mdi-pencil
                        </v-icon>

                        <!-- Delete Icon -->
                        <v-icon
                            v-if="user.account_type == 1"
                            color="red"
                            small
                            class="ml-3"
                            @click="deleteItem(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </div>
                </div>
            </template>

            <template v-slot:item.account_type="{ item }">
                <v-chip>{{ getType(item.account_type) }}</v-chip>
            </template>

            <template v-slot:item.support_team="{ item }">
                <v-chip
                    :style="
                        'background-color:' +
                            bgColor(item.support_team) +
                            'color:white;'
                    "
                    >{{ item.support_team == 1 ? "YES" : "NO" }}</v-chip
                >
            </template>

            <template v-slot:item.actions="{ item }">
                <!-- Edit Icon -->
                <v-icon
                    v-if="user.account_type == 1"
                    color="primary"
                    small
                    class="ml-3"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>

                <!-- Delete Icon -->
                <v-icon
                    v-if="user.account_type == 1"
                    color="red"
                    small
                    class="ml-3"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <h4>No Data Found</h4>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Axios from "axios";
import {
    required,
    minLength,
    email,
    requiredIf
} from "vuelidate/lib/validators";
import { mapState } from "vuex";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
    components: {
        VuePhoneNumberInput
    },
    data: () => ({
        timezoneList: [],
        timezoneLoading: false,
        disableAccType: true,
        locations: [],
        search: "",
        type: "Admin",
        types: ["Admin", "Basic"],
        locSearch: "",
        show1: false,
        show2: false,
        show3: false,
        tc: {
            name: "",
            username: "",
            email: "",
            phone: "",
            location: [],
            password: "",
            timezone: "",
            support_team: 0,
            // old_password: "",
            new_password: ""
        },
        defaultItem: {
            name: "",
            username: "",
            email: "",
            phone: "",
            password: "",
            timezone: "",
            support_team: 0,
            // old_password: "",
            new_password: ""
        },
        addLoading: false,
        locLoading: false,

        dialog: false,
        dialogDelete: false,
        headers: [
            {
                text: "Name",
                align: "start",
                value: "name",
                width: "300px"
            },
            { text: "Type", value: "account_type", width: "180px" },
            { text: "Username", value: "username", width: "180px" },
            // { text: "E-Mail", value: "email", width: "250px" },
            {
                text: "Health Services",
                align: "center",
                width: "180px",
                value: "support_team"
            },
            // { text: "Phone", value: "phone", width: '200px' },
            {
                text: "",
                value: "actions",
                align: "end",
                sortable: false,
                width: "100px"
            }
        ],
        editedIndex: -1,
        tab: null,
        tab_items: ["General", "Password"]
    }),

    validations: {
        tc: {
            name: { required },
            username: { required },
            email: { required, email },
            phone: { required, minLength: minLength(10) },
            timezone: {
                required: requiredIf(function(abc) {
                    return this.editedIndex == -1;
                })
            },
            password: {
                required: requiredIf(function(abc) {
                    return this.editedIndex == -1;
                }),
                minLength: minLength(8)
            },
            // old_password: {
            //   required: requiredIf(function (abc) {
            //     return this.editedIndex != 1 && this.tab == 1;
            //   }),
            // },
            new_password: {
                required: requiredIf(function(abc) {
                    return this.editedIndex != 1 && this.tab == 1;
                })
            }
        }
    },

    computed: {
        ...mapState({
            user: state => state.currentUser.user,
            getBasicUserData: state => state.users.getBasicUserData
        }),

        showOnlyName() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.headers.splice(1);
            }
            return "";
        },

        formTitle() {
            return this.editedIndex === -1 ? "Create New" : "Update";
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.tc.name.$dirty) return errors;
            !this.$v.tc.name.required && errors.push("Name is required");
            return errors;
        },

        usernameErrors() {
            const errors = [];
            if (!this.$v.tc.username.$dirty) return errors;
            !this.$v.tc.username.required &&
                errors.push("Username is required");
            return errors;
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.tc.email.$dirty) return errors;
            !this.$v.tc.email.email && errors.push("Must be valid e-mail");
            !this.$v.tc.email.required && errors.push("E-mail is required");
            return errors;
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.tc.password.$dirty) return errors;
            !this.$v.tc.password.minLength &&
                errors.push("Password must be at least 8 characters");
            !this.$v.tc.password.required &&
                errors.push("Password is required");
            return errors;
        },

        phoneErrors() {
            const errors = [];
            if (!this.$v.tc.phone.$dirty) return errors;
            !this.$v.tc.phone.minLength &&
                errors.push("Phone must be at least 10 numbers");
            !this.$v.tc.phone.required && errors.push("Phone is required");
            return errors;
        },

        locationErrors() {
            const errors = [];
            if (!this.$v.tc.location.$dirty) return errors;
            !this.$v.tc.location.required &&
                errors.push("Locations is required");
            return errors;
        },

        timezoneErrors() {
            const errors = [];
            if (!this.$v.tc.timezone.$dirty) return errors;
            !this.$v.tc.timezone.required &&
                errors.push("Timezone is required");
            return errors;
        },

        // oldPasswordErrors() {
        //   const errors = [];
        //   if (!this.$v.tc.old_password.$dirty) return errors;
        //   !this.$v.tc.old_password.required && errors.push("Password is required");
        //   return errors;
        // },

        newPasswordErrors() {
            const errors = [];
            if (!this.$v.tc.new_password.$dirty) return errors;

            !this.$v.tc.new_password.required &&
                errors.push("New Password is required");
            return errors;
        }
    },

    created() {
        this.initialize();
        this.timezoneLoading = true;
        this.$store.dispatch("global/actionloaderState", true, {
            root: true
        });
        this.$store
            .dispatch("global/get", {
                url: "/api/get_timezones"
            })
            .then(r => {
                // this.timezoneList = r.data.map(e => {
                //     e.value = e.original_value;
                //     return e;
                // });
                this.timezoneList = r.data;
                this.timezoneLoading = false;
                this.$store.dispatch("global/actionloaderState", false, {
                    root: true
                });
            })
            .catch(response => {
                this.$store.dispatch("global/actionloaderState", false, {
                    root: true
                });
            });
    },

    watch: {
        locSearch() {
            if (this.locSearch) this.comboInput();
        }
    },

    methods: {
        getType(type) {
            if (type == 1) return "Admin";
            // if (type == 2) return "Provider";
            // if (type == 3) return "Partner";
            if (type == 4) return "Eth-Office";
        },

        startVideoCall(item) {
            const roomId = this.makeVideoChatId(item);
            const _this = this;
            this.$db
                .collection("users")
                .doc(item.email)
                .onSnapshot(function(doc) {
                    _this.initialize();
                });
            this.$store.dispatch("users/addUserToCall", {
                sendTo: item.id,
                roomId: roomId,
                mail: item.email,
                name: item.name ? item.name : ""
            });

            //   this.$router.push({
            //     path: `/video-calling/${roomId}`,
            //   });
        },

        makeVideoChatId(item) {
            let id;
            if (item.id > this.user.id) id = `${item.id}_${this.user.id}`;
            else id = `${this.user.id}_${item.id}`;
            const hash = id.split("").reduce(function(a, b) {
                a = (a << 5) - a + b.charCodeAt(0);
                return a & a;
            }, 0);
            return hash;
        },
        checkAdminCount() {
            Axios.get("/api/check_admin_count").then(r => {
                this.disableAccType = r.data <= 1;
            });
        },
        addForChat(item) {
            //   this.$store.dispatch("chat/addParticipant", item);
            this.$router.push({
                name: "chat",
                params: {
                    currentParticipant: item
                }
            });
        },
        add() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$store.dispatch("global/snackBarState", {
                    show: true,
                    color: "red",
                    message: "All form fields is required."
                });
            } else {
                if (this.editedIndex > -1) {
                    this.tc.type = this.types.indexOf(this.type);
                    this.$store.dispatch("users/update_user", this.tc);
                    this.dialog = false;
                } else {
                    this.addLoading = true;
                    this.tc.type = this.types.indexOf(this.type);

                    this.$store
                        .dispatch("users/create_user", this.tc)
                        .then(r => {
                            this.addLoading = false;
                            this.dialog = false;

                            this.$store.dispatch("global/snackBarState", {
                                show: true,
                                color: "primary",
                                message: "Create Successfully"
                            });
                        })
                        .catch(e => {
                            this.addLoading = false;
                            this.$store.dispatch("global/snackBarState", {
                                show: true,
                                color: "red",
                                message: "Create Failed!"
                            });
                        });
                }
            }
        },
        comboInput() {
            this.locLoading = true;
            console.log(this.locSearch);
            const data = new FormData();
            data.append("search", this.locSearch);
            this.$store
                .dispatch("global/post", {
                    url: "/api/get_locations_search",
                    body: data
                })
                .then(res => {
                    this.locations = res.data.map(function(r) {
                        return {
                            text: r.Name,
                            value: r.SL_ID
                        };
                    });
                    this.locLoading = false;
                    console.log(JSON.stringify(res.data));
                });
        },
        comboChange() {
            this.locSearch = "";
            console.log(this.tc.location);
        },

        initialize() {
            this.$store.dispatch("global/actionloaderState", true, {
                root: true
            });
            this.$store
                .dispatch("users/getBasicUser")
                .then(r => {
                    this.$store.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                })
                .catch(r => {
                    this.$store.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                });
            this.checkAdminCount();
        },

        editItem(item) {
            this.editedIndex = this.getBasicUserData.indexOf(item);
            if (item.account_type == 4) {
                this.type = "Basic";
            }
            this.tc = JSON.parse(JSON.stringify(item));
            this.dialog = true;
        },

        deleteItem(item) {
            this.editedIndex = this.getBasicUserData.indexOf(item);
            this.tc = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm() {
            this.$store.dispatch("users/delete_user", { id: this.tc.id });
            this.closeDelete();
        },

        close() {
            this.dialog = false;
            this.tc = Object.assign({}, this.defaultItem);
            this.type = "Admin";
            this.$v.$reset();
            this.editedIndex = -1;
            this.initialize();
        },

        closeDelete() {
            this.dialogDelete = false;
            this.tc = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        },

        bgColor(val) {
            return val == 1 ? "#2d4859;" : "#e0e0e0;";
        }
    }
};
</script>

<style scoped>
>>> .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0px 10px;
    background: #f1f3f6;
}

>>> .v-data-table > .v-data-table__wrapper > table > thead {
    background: white;
}

>>> .v-data-table__wrapper > table > tbody > tr > td {
    border-top: thin solid rgba(0, 0, 0, 0.12);
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0px 10px 0px 0px !important;
}

>>> .v-data-table__wrapper > table > tbody > tr {
    background: white;
}

@media screen and (max-width: 480px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__cell {
        width: 100% !important;
    }
}

/*  iPads, Tablets (Portrait)*/
@media (min-width: 481px) and (max-width: 768px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }
}
</style>
