var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-data-table',{staticClass:"elevation-0 en-table-card",attrs:{"headers":_vm.headers,"items":_vm.notifications,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Notifications")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"elevation-1",attrs:{"fab":"","light":"","small":"","color":"white"},on:{"click":function($event){return _vm.deleteDialog()}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1),_vm._v(" "),_c('v-btn',{staticClass:"elevation-1 ml-3",attrs:{"fab":"","light":"","small":"","color":"white"},on:{"click":_vm.refresh}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-sync")])],1)],1),_vm._v(" "),_c('v-row',{staticClass:"px-4"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"dense":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm.moment
            .tz(
              item.created_at,
              _vm.user.timezone ? _vm.user.timezone : _vm.settings.default_timezon
            )
            .format("MM/DD/YYYY h:mm a"))+"\n      ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(
            new Date().valueOf() -
              _vm.moment
                .tz(
                  item.created_at,
                  _vm.user.timezone ? _vm.user.timezone : _vm.settings.default_timezon
                )
                .valueOf() <=
            10 * 60 * 1000
          )?_c('v-btn',{staticClass:"mr-3",attrs:{"x-small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.joinVideoCall(item.body)}}},[_vm._v("Join now")]):_vm._e()]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete")]),_vm._v(" "),_c('v-card-text',[_vm._v("Are you sure you want to Delete?")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("No")]),_vm._v(" "),_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.deleteAll()}}},[_vm._v("Yes")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }