<template>
  <div @mousemove="mouseMove">
    <v-app>
      <Nav v-if="$route.name !== 'videoCall' && $route.name !== 'lockScreen'" />
      <v-main class="en-main">
        <router-view></router-view>
        <!-- v-for="p in participants" :key="p.id" :participant="p" -->
        <!-- <div v-if="client" class="global-chat-section">
          <div v-for="p in participant" :key="p.id">
            <ChatWidget :participant="p" />
          </div>
        </div> -->

        <v-snackbar
          v-model="snackNew"
          top
          right
          dark
          color="primary"
          :timeout="3000"
        >
          {{ from }}: {{ message }}
        </v-snackbar>
        <!-- Notification Popup -->
        <!-- <div v-if="isFirebaseSupported" class="notification-section">
          <v-card
            :ripple="false"
            v-show="noti"
            v-for="(item, i) in 1"
            :key="i"
            class="notific-card"
            transition="slide-x-transition"
            @click="toChat"
          >
            <div class="d-flex align-start">
              <div class="notific-card-child">
                <h4>{{ mail }}</h4>
                <p>{{ message }}</p>
              </div>
              <v-icon @click.native.stop="noti = false" size="20" color="grey"
                >mdi-close-circle</v-icon
              >
            </div>
          </v-card>
        </div> -->

        <!-- Showing Iphone's Case -->
        <!-- <div v-if="!isFirebaseSupported" class="notification-section"> -->

        <!-- Twillio Push Notification -->

        <div class="notification-section">
          <v-card
            :ripple="false"
            v-show="snackbar"
            class="notific-card"
            transition="slide-x-transition"
            style="border-left: 5px solid var(--green) !important"
          >
            <div class="d-flex align-start">
              <div class="notific-card-child">
                <h3>{{ authorName }}</h3>
                <!-- <h4>{{ authorMail }}</h4> -->
                <p>{{ text }}</p>
              </div>
              <v-icon
                @click.native.stop="snackbar = false"
                size="20"
                color="grey"
                >mdi-close-circle</v-icon
              >
            </div>
          </v-card>
        </div>
      </v-main>

      <v-footer
        absolute
        style="border-top: 1px solid lightgrey !important"
        inset
        color="white"
        app
        v-if="
          $route.name !== 'videoCall' &&
          $route.name !== 'lockScreen' &&
          $route.name !== 'chat'
        "
      >
        <div
          style="width: 100%"
          class="d-flex justify-space-between align-center"
        >
          <span class="pa-3"
            >&#169; Powered by Entouch | {{ new Date().getFullYear() }} Encounter Telehealth</span
          >

          <v-btn :to="{ name: 'sessionClear' }" depressed small>Clear</v-btn>
        </div>
      </v-footer>
    </v-app>
  </div>
</template>


<script>
import Nav from "./Navbar";

import ChatWidget from "./ChatWidget";
import { mapState } from "vuex";
import Chat from "twilio-chat";
import axios from "axios";
import firebase from "firebase/app";

export default {
  computed: {
    isFirebaseSupported() {
      return firebase.messaging.isSupported();
    },
    snackbar: {
      get: function () {
        return this.snack;
      },
      set: function (val) {
        this.$store.commit("chat/SET_SNACKBAR", val);
      },
    },
    ...mapState({
      text: (state) => state.chat.snackbarText, //step-4
      authorMail: (state) => state.chat.snackbarEmail,
      authorName: (state) => state.chat.snackbarName,
      snack: (state) => state.chat.snackbar, //show snackbar===true,false
      participant: (state) => state.chat.user,
      client: (state) => state.chat.client,
      user: (state) => state.currentUser.user,
    }),
    currentUserId() {
      return this.user.id;
    },
  },
  components: {
    Nav,
    ChatWidget,
  },
  data: () => ({
    show_notific: false,
    noti: false,
    mail: "",
    snackNew: false,
    from: "",
    message: "",
    body: "",
    fab: false,
    timer: null,
    mouseMoving: true,
    chat_menu: true,
  }),

  created() {
    //console.log("Check isFirebaseSupported===",this.isFirebaseSupported);
    // console.log(this.$route.name);
    // console.log("PushManager" in window);

    setInterval(() => {
      //   console.log("Mouse Moved SetInterval Created");
      //   console.log("Mouse Moving : " + this.mouseMoving);
      if (this.mouseMoving) {
        this.$store
          .dispatch("currentUser/setStatus", {
            inCall: 0,
            loggedOut: 0,
          })
          .then((response) => {})
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              //  this.$store.dispatch("currentUser/logout");
              //  this.$router.replace({ name: "login" });
            }
          });
      }
    }, 5000);
  },
  mounted() {
    //TODO : Disabled temp
    //  this.getAccessToken();

    this.getUnreadMessagesCountNew();
    this.initChannel();
    // this.$store.dispatch("chat/getAccessToken");
  },

  methods: {
    initChannel() {
      var channel = this.$pusher.subscribe("my-channel");

      /**
       *-------------------------------------------------------------
       * My Event
       *-------------------------------------------------------------
       */
      var my_id = this.currentUserId;
      const _this = this;

      channel.bind("my-event", function (data) {
        console.log("IN CHANNEL EVENT APP.vue", data);
        console.log("Router name ", _this.$route.name);

        if (my_id == data.to_user) {
          if (!data.typing && _this.$route.name !== "chat") {
            _this.getUnreadMessagesCountNew();

            if (data.notification) {
              _this.snackNew = true;
              _this.from = data.notification.from;
              _this.message = data.notification.message;
            }
          }
        }
      });

      //My Group Event Show Notification Messages And Unread Messages Count
      channel.bind("my-group", function (data) {
        // console.log("app.vue Group Event ========", data);
        if (my_id != data.from_user_id) {
          if (_this.$route.name !== "chat") {
            _this.getUnreadMessagesCountNew();
            if (data.notification) {
              _this.snackNew = true;
              _this.from = data.notification.from;
              _this.message = data.notification.message;
            }
          }
        }
      });
    },

    toChat() {
      if (this.$route.name !== "videoCall")
        this.$router.push({
          name: "chat",
        });
      else {
        this.$store.dispatch("chat/onNotification", {
          email: this.mail,
        });
      }
    },
    setUpMessanging() {
      //   const pair =
      //     "BCC2Nng8OM9ePmk86-4iBTQbYFjdvDJGukNG-zh2rKY8MlfO2Nju4f9RRlNwOzbH30eYAoQX5kcsq9fSjKoFFBU";
      //   if (firebase.messaging.isSupported()) {
      //     this.$messaging
      //       .requestPermission()
      //       .then(() => {
      //         this.$messaging
      //           .getToken({ vapidKey: pair })
      //           .then((fcmToken) => {
      //             console.log("FCM TOKEN = " + fcmToken);
      //             this.client
      //               .setPushRegistrationId("fcm", fcmToken)
      //               .catch(this.handleError);
      //           })
      //           .catch(this.handleError);
      //       })
      //       .catch(this.handleError);
      //     this.$messaging.onMessage(this.handleMessage, this.handleError);
      //    this.$messaging.onMessage(()=>{
      //        console.log("HANDLE NOTIFICATION 2")
      //    }, this.handleError);
      //  const _this = this;
      // this.client.on("pushNotification", async (data) => {
      //   try {
      //     console.log("HANDELED NOTIFICATION");
      //     console.log(data);
      //     _this.body = data.body;
      //     _this.mail = _this.body.split(":")[0];
      //     _this.message = await this.$store.dispatch("chat/getMessageBody", {
      //       body: _this.body.split(":")[1],
      //     });
      //     if (_this.mail !== _this.user.email) {
      //       _this.noti = true;
      //       setTimeout(() => (_this.noti = false), 6000);
      //       _this.$store.dispatch("chat/onNotification", {
      //         email: _this.mail,
      //       });
      //     }
      //   } catch (error) {}
      // });
      // }
    },

    handleMessage(payload) {
      console.log("handle Message====", payload);
      this.client.handlePushNotification(payload).catch(this.handleError);
    },

    getAccessToken() {
      //console.log("CALLING API");
      axios
        .post("/api/get_chat_token", new FormData())
        .then((r) => r.data)
        .then((data) => Chat.create(data))
        .then(this.setChatClient)
        .catch(this.handleError);
    },

    getUnreadMessagesCountNew() {
      this.$store.dispatch("chat/unreadCountNew");
      //console.log("CALLING API");
      // axios
      //   .post("/api/get_unread_count", new FormData())
      //   .then((r) => r.data)

      //   .then((r) => this.setUnreadCount(r.data))
      //   .catch(this.handleError);
      //   console.log("Calling getUnreadMessagesCountNew");
    },

    handleError(error) {
      //console.log(error);
    },

    //TODO: Move logic to chatModule
    // async setUnreadCount(data)
    // {
    //     console.log("Calling setUnreadCount");
    //     console.log("Received Data "+JSON.stringify(data));
    //     this.$store.dispatch("chat/countReadyNew", true);
    //    this.$store.dispatch("chat/unreadCountNew", data);
    //   console.log("countReadyNew to true");
    // },

    async setChatClient(client) {
      this.$store.dispatch("chat/countReady", false);
      this.$store.commit("chat/CLEAR_LIST");
      //console.log("IN SET CHAT CLIENT");
      const _this = this;
      client.on("channelInvited", async function (channel) {
        console.log(
          "Invited to channel==Join the channel that you were invited to" +
            channel
        );
        // Join the channel that you were invited to
        await channel.join();
        await _this.addChannel(channel);
      });

      if (this.$store.state.chat.user.length === 0) {
        await this.$store.dispatch("chat/getChatUsers");
      }
      // this.$store.state.chat.user.forEach(async element => {
      //     const mail1 = this.user.email;
      //     const mail2= element.email
      //     const channelName = process.env.MIX_CURRENT_SERVER + "-" + (mail1 > mail2 ? mail1 + mail2 : mail2 + mail1);
      //     let channel = await client.getChannelByUniqueName(channelName)
      //     await _this.addChannel(channel);
      // });

      try {
        // client.getPublicChannelDescriptors().then(function (paginator) {
        //   for (i = 0; i < paginator.items.length; i++) {
        //     const channel = paginator.items[i];
        //     console.log("Public Channel found Channel: " + channel.friendlyName);
        //   }
        // });
        //TODO: Disabled temp
        let paginator = [];
        // let paginator = await client.getSubscribedChannels();
        console.log("Channels found -> ", paginator);
        for (let i = 0; i < paginator.items.length; i++) {
          var channel = paginator.items[i];
          console.log("Channel is*****************", paginator.items[i]);
          //   await channel.delete()
          await _this.addChannel(channel);
        }
      } catch (error) {}
      //TODO: Disabled temp
      // this.$store.dispatch("chat/countReady", true);
      //TODO: Disabled temp
      // this.$store.dispatch("chat/setClient", client).then((r) => {
      //   this.setUpMessanging();
      // });
    },

    async addChannel(channel) {
      console.log("Channel found -> ", "Channel: " + channel.uniqueName);
      await this.$store.dispatch("chat/addChannel", channel);
    },

    mouseMove(event) {
      this.mouseMoving = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.mouseMoving = false;
      }, 5000);
    },
  },
};
</script>


