import axios from "axios";
import Axios from "axios";
import Chat from "twilio-chat";
const state = {
    user: [],
    client: null,
    openId: 0,
    countReady: false,
    countReadyNew: false,
    snackbar: false,
    snackbarText: "",
    snackbarEmail: "",
    snackbarName: "",
    unreadCount: 0,
    unreadCountNew: 0,
    unhideMail: '',
};

const getters = {};

const actions = {

    setOpenId(context, id) {
        context.commit("SET_OPEN_ID", id)
    },
    updateReadIndex(context, email) {
        context.commit('UPDATE_READ_INDEX', email)
    },

    setClient(context, client) {
        context.commit('SET_CLIENT', client)
    },

    async addUserFromMail(context, email) {
        const data = new FormData();
        data.append('email', email);
        let user = (await Axios.post('/api/get_single_user', data)).data.data[0];
        context.dispatch('addUserFromUser', user);
    },

    async addChatUser(context, email) {
        const data = new FormData()
        data.append('email', email)
        const r = await Axios.post("/api/add_chat_user", data);
    },

    async getChatUsers(context) {
        const data = new FormData()
        const r = await Axios.post("/api/get_chat_users", data);
        const users = r.data.data;
        users.forEach(async user => {
            user.lastMessage = '';
            user.lastMessageTime = 0;
            user.readIndex = 0;
            user.messages = [];
            user.lastConsumedMessageIndex = 0
            await context.dispatch('addParticipant', user)
        });
    },


    async addUserFromUser(context, user) {
        //console.log('AddUserFromUser', user);
        user.lastMessage = '';
        user.lastMessageTime = 0;
        user.readIndex = 0;
        user.messages = [];
        user.lastConsumedMessageIndex = 0

        await context.dispatch('addParticipant', user)
        const mail1 = user.email;
        const mail2 = context.rootState.currentUser.user.email;
        const channelName = process.env.MIX_CURRENT_SERVER + "-" + (mail1 > mail2 ? mail1 + mail2 : mail2 + mail1);
        console.log('AddUserFromUser', "Channel name = " + channelName);
        let channel;

        try {
            channel = await context.state.client.getChannelByUniqueName(channelName)
        } catch (error) {
            if (error?.body?.code === 50300) {
                channel = await context.state.client.createChannel({ uniqueName: channelName });
            }
        }

        try {
            // if(channel.state.status !== "joined")
            await channel.join();
            //console.log('AddUserFromUser', "CHANNEL JOINED");

        } catch (error) {
            //console.log("failed to join channel", error);
            // alert("Failed to join channel")
        }

        //console.log('AddUserFromUser Channel created => ', channel);


        user.channel = channel;
        console.log("-------- TEST CHANNEL UNDEFINED OR NOT ----------", user.channel);

        if (user.channel != undefined) { //test condition

            let messages = await user.channel.getMessages()
            user.messagePage = messages
            user.messages = messages.items;
            if (user.messages.length >= 1) {
                user.lastMessage = await context.dispatch('getMessageBody', user.messages[user.messages.length - 1])
            } else {
                user.lastMessage = ""
            }
            user.lastMessageTime = 0//user.messages[user.messages.length - 1].dateCreated.valueOf()
            // await context.dispatch('addParticipant', user)

            const currentMembers = await channel.getMembers();

            //console.log('AddUserFromUser got members => ', currentMembers);

            const cm = currentMembers.find(r => r.identity === mail2)
            user.readIndex = (cm?.lastConsumedMessageIndex ?? 0) > user.messages.length ? user.messages.length : (cm?.lastConsumedMessageIndex ?? 0);

            user.typing = false;
            console.log('AddUserFromUser', "VArIALBLES SET ", user);
            //console.log("Members", currentMembers.map(r => r.identity));
            const member = currentMembers.find(r => r.identity === mail1)
            if (!member) {
                // try {
                //     await channel.invite(mail1)
                // } catch (error) {
                //         console.log("Failed to invite to channel");
                // }
                // channel.on('memberJoined', async function (member) {
                //     await context.dispatch('addChannel', channel)
                // });
                try {
                    const fData = new FormData();
                    fData.append('email', mail1)
                    const data = (await axios.post("/api/get_chat_token", fData)).data
                    let client = await Chat.create(data)
                    var c = await client.getChannelByUniqueName(channelName)
                    await c.join()
                    await context.dispatch('addChannel', channel)
                } catch (error) {

                }

            } else
                await context.dispatch('addChannel', channel)
        } else {
            console.log("USER CHANNEL_UNDEFINED");
        }
        return user;
    },

    getMessageBody(context, message) {
        try {
            console.log('getMessageBody-------------->', message);
            let p = JSON.parse(message.body);
            let file = p.type ? true : false;
            if (file) {
                return p.name
            } else return message.body
        } catch (error) {
            return message.body
        }
    },

    async addChannel(context, channel) {
        const me = context.rootState.currentUser.user;
        const myEmail = me.email;
        const currentMembers = await channel.getMembers();
        //console.log("Members", currentMembers.map(r => r.identity));
        const member = currentMembers.filter(r => r.identity !== myEmail)[0]
        if (!member) return;
        console.log('> >>> >>> >>> >>> >>> >>> >>> >>> >>> >>>', member.identity);
        const email = member.identity;
        let user;
        if (context.state.user.map(r => r.email).includes(email)) {
            user = context.state.user.find(r => r.email === email);
        }
        else {
            const data = new FormData();
            data.append('email', email);
            user = (await Axios.post('/api/get_single_user', data)).data.data[0];
        }
        // console.log(user);
        if (!user) return;
        context.dispatch('addChatUser', user.email)
        user.channel = channel
        let messages = await user.channel.getMessages()
        user.messagePage = messages
        // if(messages.hasPrevPage)
        // console.log("prev page for "+user.name,await messages.prevPage());
        // console.log("Messages Object = ", messages);
        user.messages = messages.items;
        //console.log(user.name, messages);
        if (user.messages.length >= 1) {
            console.log("Inside Add Channel===getMessageBody", user.messagePage);
            user.lastMessage = await context.dispatch('getMessageBody', user.messages[user.messages.length - 1])
        } else {
            user.lastMessage = ""

        }
        user.lastMessageTime = 0 // user.messages[user.messages.length - 1].dateCreated.valueOf()
        user.lastConsumedMessageIndex = member.lastConsumedMessageIndex
        const cm = currentMembers.filter(r => r.identity === myEmail)[0]
        user.readIndex = (cm?.lastConsumedMessageIndex ?? 0) > user.messages.length ? user.messages.length : (cm?.lastConsumedMessageIndex ?? 0);
        // console.log(user.name, `${user.readIndex} ${user.messages.length}`);
        user.typing = false;

        //Push Message Added on Channel(Total 4 Steps)
        user.channel.on("messageAdded", async (message) => {
            context.commit("UN_HIDE_MAIL", message.author)
            // console.log("Route name  = ", Vue.prototype);
            // console.log('In message added ', message.dateCreated.valueOf());
            // console.log("Message Author " + message.author);
            // console.log("My email " + myEmail);
            context.commit('unreadCount')
            if (message.author == myEmail)
                user.lastMessageTime = message.dateCreated.valueOf()
            console.log("addChannel Method:- message added on user channel event===getMessageBody", messages); //step-1
            user.lastMessage = await context.dispatch('getMessageBody', message) //step-2
            user.messages.push(message)
            if (message.author !== myEmail) {
                context.commit('SNACKBAR', { //step-3
                    body: user.lastMessage,
                    author: message.author
                });
            }
            else {
                user.readIndex = user.readIndex + 1
                // console.log("Notification skipped Message Author " + message.author);
                // console.log("Notification skipped My email " + myEmail);
                // console.log("Notification skipped");
            }
        });

        user.channel.on('typingStarted', function (member) {
            // if (member.identity == user.email) {
            //console.log(member.identity + 'is currently typing.');
            user.typing = true;
            // }
        });

        // Listen for members typing
        user.channel.on('typingEnded', function (member) {
            // if (member.identity == user.email) {
            //console.log(member.identity + 'has stopped typing.');
            user.typing = false;
            // }
        });

        user.channel.on('memberLeft', function (member) {
            if (myEmail !== member.identity) {
                // alert(member.identity +" Left")
            }
        });

        user.channel.on('memberUpdated', function (event) {
            if (event.member.identity === member.identity)
                user.lastConsumedMessageIndex = event.member.lastConsumedMessageIndex
        });

        await context.dispatch('addParticipant', user)

    },


    countReady(context, ready) {
        context.commit("COUNT_READY", ready)
    },

    unreadCountNew(context) {

        axios.post("/api/get_unread_count", new FormData())
            .then((r) => {
            //    console.log("UNREAD_COUNT_NEW-------------------",r.data.data);
                context.commit("COUNT_READY_NEW", true)
                context.commit("UNREAD_COUNT_NEW", r.data.data)
                //console.log("Calling getUnreadMessagesCountNew response " + JSON.stringify(r.data.data));
                // => this.setUnreadCount(r.data)
            })
            .catch((error) => {
                context.commit("UNREAD_COUNT_NEW", 0)
                context.commit("COUNT_READY_NEW", false)
               // console.log("Calling getUnreadMessagesCountNew Error " + JSON.stringify(error));

            });

        //console.log("Calling getUnreadMessagesCountNew from Chat Module");




    },


    countReadyNew(context, ready) {
        context.commit("COUNT_READY_NEW", ready)
    },


    async onNotification(context, { email }) {
        // let user;
        // if (context.state.user.map(r => r.email).includes(email)) {
        //     user = context.state.user.filter(r => r.email == email)[0];
        // } else {
        //     const data = new FormData();
        //     data.append('email', email);
        //     user = (await Axios.post('/api/get_single_user', data)).data.data[0];
        //     console.log(user);
        // }
        const member = context.state.user.filter(r => r.email === email)[0]
        if (!member)
            await context.dispatch('addUserFromMail', email)
        context.commit("SET_OPEN_ID", member.id)
    },


    addParticipant(context, user) {
        context.commit('ADD_USER', user)
    },

    async clearUser(context, user) {
        // alert((user.email))
        const data = new FormData();
        data.append('mail', user.email)
        const r = await Axios.post("/api/delete_chat_user", data);
        context.commit('CLEAR_USER', user);
    }
};

const mutations = {
    UPDATE_READ_INDEX(state, email) {
        state.user.find(r => r.email === email).readIndex = state.user.find(r => r.email === email).readIndex + 1
    },
    COUNT_READY(state, ready) {
        state.countReady = ready;
    },
    COUNT_READY_NEW(state, ready) {

        state.countReadyNew = ready;

    },
    UN_HIDE_MAIL(state, mail) {
        state.unhideMail = mail
    },
    unreadCount(state) {
        state.unreadCount = state.unreadCount + 1
    },
    UNREAD_COUNT_NEW(state, count) {
        state.unreadCountNew = count;

    },
    SET_SNACKBAR(state, val) {
        state.snackbar = val
    },
    SNACKBAR(state, message) {
        state.snackbar = true;
        state.snackbarText = message.body
        state.snackbarEmail = message.author

        //Use filter to get user_name
        try {
            state.snackbarName = state.user.filter(r => r.email == message.author)[0].name
        } catch (error) {

        }
        setTimeout(() => { //Hide snackbar after 5 seconds
            state.snackbar = false;
        }, 5000);
    },

    SET_OPEN_ID(state, id) {
        // console.log("in set open id id = " + id)
        state.openId = 0;
        state.openId = id;
    },
    SET_CLIENT(state, client) {
        state.client = client;
    },

    CLEAR_LIST(state) {
        state.user = [];
    },
    CLEAR_USER(state, user) {
        state.user.splice(state.user.indexOf(user), 1);
    },

    ADD_USER(state, user) {
        if (!user.lastMessage) {
            user.lastMessage = '-/-'
        }
        const newList = [...state.user]
        var index = 0;
        if (newList.find(r => r.email === user.email)) {
            index = newList.findIndex(r => r.email === user.email);
            newList.splice(index, 1)
        }
        if (index == 0)
            newList.push(user)
        else
            newList.splice(index, 0, user)
        //console.log('AddUserFromUser', "User added to state number of users = " + newList.length);
        state.user = [...newList]
        // if(!state.openId)
        // state.openId = user.id;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
