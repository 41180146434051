var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-data-table',{staticClass:"log-table-card",attrs:{"headers":_vm.headers,"items":_vm.logsbylevel,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"options":_vm.options,"server-items-length":_vm.totalServerItems,"item-key":"id","show-expand":"","footer-props":_vm.footerProps,"sort-by":"id"},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.stack_traces.length > 0)?_c('v-icon',{class:[
            'v-data-table__expand-icon',
            { 'v-data-table__expand-icon--active': isExpanded } ],on:{"click":function($event){$event.stopPropagation();return expand(!isExpanded)}}},[_vm._v("$expand")]):_vm._e()]}},{key:"item.date",fn:function(ref){
          var item = ref.item;
return [_vm._v("\n        "+_vm._s(_vm._f("moment")(item.date,"MM/DD/YYYY - h:mm a "))+"\n      ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","placeholder":"Search..."},on:{"input":_vm.searchChange},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-icon',{attrs:{"color":"primary","size":"30"},on:{"click":function($event){return _vm.RefreshList()}}},[_vm._v("mdi-sync")])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"json-container"},[_c('v-btn',{staticClass:"mb-4",attrs:{"depressed":"","small":""},on:{"click":function($event){return _vm.CopyToClipBoard(item)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-content-copy")]),_vm._v(" Copy")],1),_vm._v(" "),_c('vue-json-pretty',{attrs:{"path":'res',"data":item.stack_traces},on:{"click":_vm.handleClick}})],1)])]}}],null,true)})],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }