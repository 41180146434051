<template>
  <v-container>
    <v-card-actions class="py-0 mb-1">
      <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
    </v-card-actions>

    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
      show-arrows>
      <v-tab v-for="item in tab_items" :key="item">{{ item }}</v-tab>
    </v-tabs>

    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <Categories />
      </v-tab-item>
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <Reasons />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Categories from "./admin_notes_tabs/Categories";
import Reasons from "./admin_notes_tabs/Reasons";
export default {
  components: {
    Categories,
    Reasons,
  },
  data: () => ({
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Lookup Tables",
        disabled: false,
        to: "/lookup-tables",
      },

      {
        text: "Admin Notes",
        disabled: true,
        to: "",
      },
    ],
    tab_items: ["Categories", "Reason"],
  }),

  computed: {
    tab: {
      get: function () {
        return this.$store.state.global.tabs.adminReasonsTab;
      },
      set: function (value) {
        var setTab = {
          type: 4,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },
};
</script>
