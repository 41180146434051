<template>
  <v-card :loading="loading">
    <v-card-title>Video Call</v-card-title>
    <v-divider>

    </v-divider>
    <v-card-text class="mt-5">
      <v-card v-for="(item, i) in list" :key="i" flat class="en-appt-card mb-4">
        <v-row>
          <v-col class="py-0" cols="12" md="6" sm="6">
            <h3>{{ item.createdBy }}</h3>
            <p class="appt-loaction-text mb-1">
              <v-icon class="mr-1" size="15">mdi-map-marker</v-icon
              >{{ item.location }}
            </p>
          </v-col>
          <v-col class="py-0 text-end" cols="12" md="6" sm="6">
            <!-- <p class="date-time">
              <v-icon class="mr-1" size="15">mdi-clock-outline</v-icon>
              2:00 pm
            </p> -->
            <v-btn
              @click="toVideoCall(item.room_name)"
              x-small
              depressed
              color="primary"
              class="mt-2"
            >
              <v-icon small left>mdi-video</v-icon> Join
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="$emit('close')">Close</v-btn>
      <v-btn
        color="primary"
        depressed
        style="width: 100px"
        @click="createCall()"
        >Create New</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["SL_ID"],
  data: () => ({
    list: [],
    loading: false,
  }),

  created() {
    this.loading = true;
    const data = new FormData();
    data.append("SL_ID", this.SL_ID);
    axios.post("/api/get_location_rooms", data).then((r) => {
      this.list = r.data.data;
      this.loading = false;
      if (this.list.length === 0) {
        this.createCall();
      }
    });
  },
  methods: {
    toVideoCall(room) {
      this.$emit("joinCall", room);
    },
    createCall() {
      this.$emit("createCall", this.SL_ID);
    },
  },
};
</script>

<style>
</style>
