<template>
<v-card width="640px">
     <v-card-actions class="px-4 py-4">
            <v-card-title class="pa-0">Record video</v-card-title>
            <v-spacer></v-spacer>
            <v-icon @click="onClose()">mdi-close</v-icon>
          </v-card-actions>
          <div style="width:640px" id="myVideo"><video controls autoplay playsinline></video>
      </div>
      <div class="d-flex mt-5">


            <v-btn :disabled="disableStopButton" class="elevation-1 mx-3" @click="stopRecording()" fab light small color="white">
              <v-icon :color="disableStopButton ? 'grey': 'red'">mdi-stop</v-icon>
            </v-btn>

            <v-btn :disabled="disableRecButton" class="elevation-1 mx-3" @click="startRecording()" fab light small color="white">
              <v-icon color="primary">mdi-record</v-icon>
            </v-btn>

      </div>
       <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!blob" color="primary" block @click="stopRecording();onSave(blob)">Save</v-btn>
        </v-card-actions>
</v-card>
</template>

<script>
import RecordRTC from 'recordrtc'
export default {
    props:['onSave','onClose'],
    mounted() {
        this.video = document.getElementById("myVideo").firstChild;
    },
    data: () => ({
        video: null,
        recorder: null,
        disableStopButton: true,
        disableRecButton: false,
        blob:null
    }),
    methods: {
        captureCamera(callback) {

            const devices = navigator.mediaDevices;
            if(!devices)
            {
                alert("Unable to capture your camera");
                return ;
            }
            devices
                .getUserMedia({ audio: true, video: true })
                .then(function(camera) {
                    callback(camera);
                })
                .catch(function(error) {
                    alert(
                        "Unable to capture your camera. Please check console logs."
                    );
                    console.error(error);
                });
        },
        stopRecordingCallback() {
            this.video.src = this.video.srcObject = null;
            this.video.muted = false;
            this.video.volume = 1;
            this.blob = this.recorder.getBlob()
            this.video.src = URL.createObjectURL(this.blob);
            this.video.play()
            this.recorder.camera.stop();
            this.recorder.destroy();
            this.recorder = null;
            this.disableRecButton = false;
        },
        startRecording() {
            const _this = this;
            this.captureCamera(function(camera) {
                _this.disableRecButton = true;
                console.log(_this.video);
                _this.video.muted = true;
                _this.video.volume = 0;
                _this.video.srcObject = camera;
                _this.recorder = RecordRTC(camera, {
                    type: "video"
                });

                _this.recorder.startRecording();

                // release camera on stopRecording
                _this.recorder.camera = camera;

                _this.disableStopButton = false;
            });
        },
        stopRecording() {
            this.disableStopButton = true;
            this.recorder?.stopRecording(this.stopRecordingCallback);
        }
    }
};
</script>

<style></style>
