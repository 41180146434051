<template>
  <div>
    <div class="section-wrapper">
      <v-data-table
        id="table"
        :items="charges"
        :loading="loading"
        :headers="headers"
      >
        <template v-slot:top>
          <div id="header" class="d-flex px-3 pt-2" color="white">
            <v-toolbar-title>
              <img class="nav-logo" :src="'/assets/icon.png'" alt />
              <!-- <v-btn outlined color="primary" @click="getReports"
                >REFRESH</v-btn
              > -->
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <div class="text-center m-auto">
              <h5>Patient Encounter Detail</h5>
              <!-- <p>For use in billing Medicare Origination Site Fee Q3014</p> -->
              <p>{{ fromDate }} - {{ toDate }}</p>
              <p v-if="totalUnits">
                Total Patient Visits: <b>{{ totalUnits }}</b>
              </p>
              <p v-if="!isAdmin && hasLocation">
                Location: <b>{{ charges[0].ServiceLocationName }}</b>
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              class="elevation-0 ml-4"
              @click="getReports()"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
            <download-excel :name="`${fileName}.xls`" :data="excelCharges">
              <img class="excel-icon" :src="'/assets/excel_icon.svg'" alt="" />
            </download-excel>
            <div v-if="generatingPdf">
              <v-progress-circular width="2" indeterminate />
            </div>
            <div v-else @click="exportPdf2">
              <img class="excel-icon" :src="'/assets/pdf.svg'" alt="" />
            </div>
            <!-- <v-btn small color="primary" class="mr-1 mt-2" @click="SavePdf()"
              >Save Pdf</v-btn
            > -->
          </div>
        </template>

        <template v-slot:item.ServiceStartDate="{ item }">
          <p class="mb-0">
            {{ item.ServiceStartDate }}
          </p>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2PDF from "jspdf-html2canvas";
import { mapState } from "vuex";
export default {
  props: ["from_date", "to_date", "selectedPartners"],
  mounted() {
    if (!this.from_date || !this.to_date) {
      //   this.from_date = "2022-01-01";
      //   this.to_date = "2022-01-06";
      this.$router.go(-1);
    } else {
      if (!this.isAdmin) {
        this.$delete(this.headers, 1)
        // this.headers.push({
        //   text: "Location",
        //   value: "ServiceLocationName",
        //   sortable: false,
        // });
      }
      this.getReports();
      this.fromDate = this.formattedDate(this.from_date); //  new Date(this.from_date).toLocaleDateString("en-US");
      this.toDate = this.formattedDate(this.to_date); //  new Date(this.to_date).toLocaleDateString("en-US");
    }
  },
  data: () => ({
    generatingPdf: false,
    generatedPdf: false,
    hasLocation: false,
    fromDate: null,
    toDate: null,
    loading: false,
    totalUnits: 0,
    headers: [
      { text: "Service Date", value: "ServiceStartDate", sortable: false },
      { text: "Location", value: "ServiceLocationName", sortable: false },
      { text: "Patient Name", value: "PatientName", sortable: false },
      { text: "Procedure Code", value: "ProcedureCode", sortable: false },
      {
        text: "Provider Name",
        value: "RenderingProviderName",
        sortable: false,
      },
      { text: "Diagnosis D1", value: "EncounterDiagnosisID1", sortable: false },
      { text: "Diagnosis D2", value: "EncounterDiagnosisID2", sortable: false },
      { text: "Diagnosis D3", value: "EncounterDiagnosisID3", sortable: false },
      { text: "Diagnosis D4", value: "EncounterDiagnosisID4", sortable: false },
    ],
    charges: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
    isAdmin() {
      return this.user.account_type == this.$constants.ADMIN;
    },
    excelCharges() {
      return this.charges.map((e) => {
        return {
          "Service Date": e.ServiceStartDate,
          Location: e.ServiceLocationName,
          "Patient Name": e.PatientName,
          "Procedure Code": e.ProcedureCode,
          "Provider Name": e.RenderingProviderName,
          "Diagnosis D1": e.EncounterDiagnosisID1,
          "Diagnosis D2": e.EncounterDiagnosisID2,
          "Diagnosis D3": e.EncounterDiagnosisID3,
          "Diagnosis D4": e.EncounterDiagnosisID4,
          Units: 1,
        };
      });
    },
    fileName() {
      if (this.from_date) {
        return `Encounter_Detail_${this.from_date.replace(/-/g, "")}`;
      }
    },
  },

  methods: {
    formattedDate(date) {
      const list = date.split("-");
      return `${list[1]}/${list[2]}/${list[0]}`;
    },
    // TODO:not call this method
    exportPdf() {
      //   var doc = new jsPDF({ orientation: "landscape" });
      const _this = this;
      html2PDF(document.getElementById("header"), {
        jsPDF: {
          orientation: "landscape",
        },
        margin: {
          top: 10,
          right: 32,
          bottom: 0,
          left: 32,
        },
        output: "./pdf/generate.pdf",
        success: function (doc) {
          doc.autoTable({
            body: _this.charges,
            columns: [
              { header: "Location", dataKey: "ServiceLocationName" },
              { header: "Service Date", dataKey: "ServiceStartDate" },
              {
                header: "Provider Name",
                dataKey: "RenderingProviderName",
              },
              { header: "Diagnosis D1", dataKey: "EncounterDiagnosisID1" },
              { header: "Diagnosis D2", dataKey: "EncounterDiagnosisID2" },
              { header: "Diagnosis D3", dataKey: "EncounterDiagnosisID3" },
              { header: "Diagnosis D4", dataKey: "EncounterDiagnosisID4" },
              { header: "Units", dataKey: "Units" },
            ],
          });
          doc.output("dataurlnewwindow");
          //   doc.save("charges.pdf");
        },
      });
    },

    exportPdf2() {
      if (this.generatedPdf) {
        this.$store.dispatch(
          "global/snackBarState",
          {
            show: true,
            color: "red",
            message: "Pdf Already Generated !",
          },
          { root: true }
        );
        // return false;
      }

      if (
        this.fromDate &&
        this.toDate &&
        this.totalUnits &&
        this.charges.length > 0
      ) {
        this.generatingPdf = true;
        const data = new FormData();
        data.append("fromDate", this.fromDate);
        data.append("toDate", this.toDate);
        data.append("totalUnits", this.totalUnits);
        data.append("charges", JSON.stringify(this.charges));
        data.append("excelCharges", JSON.stringify(this.excelCharges));
        this.$store
          .dispatch("global/post", {
            url: "/api/to_pdf",
            body: data,
            headers: { responseType: "blob" },
          })
          .then((response) => {
            this.generatedPdf = true;
            this.generatingPdf = false;
            this.$store.dispatch(
              "global/snackBarState",
              {
                show: true,
                color: "primary",
                message: "Processing, check Reports > Dashboard shortly",
              },
              { root: true }
            );
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement("a");
            // link.href = url;
            // // alert(this.fileName);
            // link.setAttribute("download", `${this.fileName}.pdf`); //or any other extension
            // document.body.appendChild(link);
            // link.click();
          })
          .catch((e) => {
            this.generatingPdf = false;
          });

        // window.open(api_url);
      }
    },
    // TODO: not call this method
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map((item) => Object.values(item).join(";")),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },

    getReports() {
      this.loading = true;
      const data = new FormData();
      data.append("from_date", this.from_date);
      data.append("to_date", this.to_date);
      if (this.selectedPartners) {
        data.append("selectedPartners", this.selectedPartners);
      }
      this.$store
        .dispatch("global/post", {
          url: "/api/get_charges",
          body: data,
        })
        .then((r) => {
          this.loading = false;
          this.hasLocation = false;
          this.charges = r.data.data.map((e) => {
            if (!this.hasLocation && e.ServiceLocationName) {
              this.hasLocation = true;
            }
            return {
              ServiceStartDate: e.ServiceStartDate,
              ServiceLocationName: e.ServiceLocationName,
              PatientName: e.PatientName,
              ProcedureCode: e.ProcedureCode,
              RenderingProviderName: e.RenderingProviderName,
              EncounterDiagnosisID1: e.EncounterDiagnosisID1,
              EncounterDiagnosisID2: e.EncounterDiagnosisID2,
              EncounterDiagnosisID3: e.EncounterDiagnosisID3,
              EncounterDiagnosisID4: e.EncounterDiagnosisID4,
              Units: 1,
            };
          });
          this.totalUnits = this.charges.length;
        })
        .catch((e) => {
          console.log(e);
          this.totalUnits = 0;
          this.loading = false;
          this.hasLocation = false;
        });
    },
    SavePdf() {
      if (this.generatedPdf) {
        this.$store.dispatch(
          "global/snackBarState",
          {
            show: true,
            color: "red",
            message: "Pdf Already Generated !",
          },
          { root: true }
        );
      }
      if (
        this.fromDate &&
        this.toDate &&
        this.totalUnits &&
        this.charges.length > 0
      ) {
        const data = new FormData();
        data.append("fromDate", this.fromDate);
        data.append("toDate", this.toDate);
        data.append("totalUnits", this.totalUnits);
        data.append("charges", JSON.stringify(this.charges));
        data.append("savepdf", "save_pdf");
        this.$store
          .dispatch("global/post", {
            url: "/api/to_pdf",
            body: data,
            headers: { responseType: "blob" },
          })
          .then((response) => {
            this.$store.dispatch(
              "global/snackBarState",
              {
                show: true,
                color: "primary",
                message: "Processing, check Reports > Dashboard shortly",
              },
              { root: true }
            );
          })
          .catch((e) => {});
      }
    },
  },
};
</script>

<style></style>
