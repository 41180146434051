<template>
  <div>
    <!-- <div v-if="loading">
      <v-progress-linear indeterminate />
    </div> -->

    <div v-if="!loading">
      <beautiful-chat
        :participants="participants"
        :titleImageUrl="titleImageUrl"
        :messageList="messages"
        :onMessageWasSent="sendMessage"
        :new-messages-count="newMessagesCount"
        :isOpen="isChatOpen"
        :close="closeChat"
        :icons="icons"
        :open="openChat"
        :showEmoji="false"
        :showFile="true"
        :show-edition="false"
        :show-deletion="false"
        :show-launcher="true"
        :showTypingIndicator="showTypingIndicator"
        :showCloseButton="true"
        :colors="colors"
        :alwaysScrollToBottom="alwaysScrollToBottom"
        :messageStyling="messageStyling"
        @onType="handleOnType"
        @edit="editMessage"
        ><template v-slot:header>
          <p class="sc-header--title mb-0">{{ participants[0].name }}</p>
        </template>
        </beautiful-chat>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            @click="toggleChatThirdParty()"
            v-bind="attrs"
            v-on="on"
            class="tootip-div"
          ></div>
        </template>
        <span>{{ participants[0].name }}</span>
      </v-tooltip>

      <v-icon @click="close()" class="dismiss-user">mdi-close-circle</v-icon>
    </div>
    <v-btn v-else color="white" fab bottom right fixed class="v-btn--example">
      <v-progress-circular indeterminate />
    </v-btn>
  </div>
</template>



<script>
import Axios from "axios";
import Chat from "twilio-chat";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      client: (state) => state.chat.client,
      openID: (state) => state.chat.openId,
    }),
    isChatOpen() {
      return this.participant.id == this.openID;
    },
  },
  data: () => ({
    tool: true,
    icons: {
      open: {
        img:
          "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        name: " ",
      },
      close: {
        img:
          "https://images.unsplash.com/photo-1608682748497-3333282d9b6b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
        name: " ",
      },
    },
    colors: {},
    participants: [],
    titleImageUrl:
      "https://images.unsplash.com/photo-1554357395-dbdc356ca5da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80",
    channelName: "general",
    channel: null,
    messages: [],
    loading: false,
    messages: [],
    newMessagesCount: 0,
    showTypingIndicator: "",
    alwaysScrollToBottom: false,
    messageStyling: true,
  }),
  props: ["participant"],
  created() {
    this.getColors();
    this.channelName = this.makeChannelName(
      this.user.email,
      this.participant.email
    );
    const placeHolder = "/assets/plaeholder-user.png";
    if (this.participant.image === "") {
      this.participant.image = placeHolder;
    }
    this.participants.push({
      id: this.participant.email,
      name: this.participant.name,
      imageUrl: this.participant.image ?? placeHolder,
    });
    this.icons.open.img = this.participant.image ?? placeHolder;
    this.icons.close.img = this.participant.image ?? placeHolder;
    this.titleImageUrl = this.participant.image ?? placeHolder;
    this.setupChatClient();
  },

  methods: {
    toggleChatThirdParty() {
      if (this.isChatOpen) {
        this.closeChat();
      } else {
        this.openChat();
      }
    },
    close() {
      this.$store.dispatch("chat/clearUser", this.participant);
    },
    makeChannelName(mail1, mail2) {
      return process.env.MIX_CURRENT_SERVER+"-"+(mail1 > mail2 ? mail1 + mail2 : mail2 + mail1);
    },
    // getAccessoken() {
    //   this.loading = true;
    //   Axios.post("/api/get_chat_token", new FormData())
    //     .then((r) => r.data)
    //     .then((data) => Chat.create(data))
    //     .then(this.setupChatClient)
    //     .catch(this.handleError);
    // },
    setupChatClient() {
      //   this.client = client;

      this.client
        .getChannelByUniqueName(this.channelName)
        .then((channel) => channel)
        .catch((error) => {
          if (error.body.code === 50300) {
            return this.client.createChannel({ uniqueName: this.channelName });
          } else {
            this.handleError(error);
          }
        })
        .then((channel) => {
          this.channel = channel;
          return this.channel.join().catch(() => {});
        })
        .then(() => {
          // Success!
          this.loading = false;
          this.channel.getMessages().then(this.messagesLoaded);
          this.channel.on("messageAdded", this.messageAdded);
        })
        .catch(this.handleError);
    },

    async getFileUrl(sid) {
      const data = new FormData();
      data.append("uuid", sid);
      return (await Axios.post("/api/get_file_url", data)).data;
    },

    async messagesLoaded(messagePage) {
      this.messages = [];
      for (let i = 0; i < messagePage.items.length; i++) {
        const e = await this.mapMessage(messagePage.items[i]);
        this.messages.push(e);
      }

      //   this.messages = messagePage.items.map(async (r) => {
      //     return await this.mapMessage(r);
      //   });
    },

    async messageAdded(message) {
      this.messages.push(await this.mapMessage(message));
      if (!this.isChatOpen) this.newMessagesCount++;
      console.log(message);
    },

    async mapMessage(message) {
      const isJson = this.isJson(message.body ?? "");
      if (isJson) {
        console.log("MESSAGE => ", message);
        let m = JSON.parse(message.body ?? "");

        return {
          author: message.author == this.user.email ? "me" : message.author,
          type: 'file',
          id: message.sid,
          isEdited: false,
          data: {
            meta: message.timestamp,
            file: {
              name: m.name,
              url: m.sid ? await this.getFileUrl(m.sid) : "",
            }
          }
        };
      }
      return {
        author: message.author == this.user.email ? "me" : message.author,
        type: this.mapMessageType(message.type),
        id: message.sid,
        isEdited: false,
        data: {
          text: message.body ?? "",
          meta: message.timestamp,
        },
      };
    },

    mapMessageType(type) {
      if (type == "media") return "file";
      return "text";
    },

    getColors() {
      this.colors = {
        header: {
          bg: this.$vuetify.theme.themes.light.primary,
          text: "#ffffff",
        },
        launcher: {
          bg: this.$vuetify.theme.themes.light.primary,
        },
        messageList: {
          bg: "#ffffff",
        },
        sentMessage: {
          bg: this.$vuetify.theme.themes.light.primary,
          text: "#ffffff",
        },
        receivedMessage: {
          bg: "#eaeaea",
          text: "#222222",
        },
        userInput: {
          bg: "#f4f7f9",
          text: "#565867",
        },
      };
    },

    async sendMessage(message) {
      console.log("Sending Message1");
      console.log(message);

      if (message.type === "text") {
        if (message.data.text.length > 0) {
          this.newMessagesCount = this.isChatOpen
            ? this.newMessagesCount
            : this.newMessagesCount + 1;
          this.channel.sendMessage(message.data.text);
        }
      }
      if (message.type == "file") {
         const formData = new FormData();
        formData.append("file", message.data.file, message.data.file.name);
        try {
          const sid = (await Axios.post("/api/upload_file", formData)).data;
          const obj = {
            type: "file",
            sid: sid.id,
            type: sid.aggregate_type,
            size: sid.size,
            name: message.data.file.name,
          };
          const msg = JSON.stringify(obj);
          this.file = null;
          this.channel.sendMessage(msg);
        } catch (error) {}
      }
    },

    isJson(str) {
      try {
         console.log("to parse => ", str);
        let p= JSON.parse(str);
        console.log(p.type==='file')
        return (p.type)?true:false;
      } catch (error) {
        return false;
      }
    },
    openChat() {
      this.$store.dispatch("chat/setOpenId", this.participant.id);
      this.newMessagesCount = 0;
    },

    closeChat() {
      this.$store.dispatch("chat/setOpenId", 0);
    },

    handleScrollToTop() {},

    handleOnType() {
      console.log("Emit typing event");
    },

    editMessage(message) {
      const m = this.messages.find((m) => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
  },
};
</script>

<style scoped>
>>> .sc-launcher {
  z-index: 1000 !important;
  position: relative !important;
  margin-right: 8px !important;
  pointer-events: all;
}

>>> .sc-launcher:before {
  content: none !important;
}

>>> .sc-chat-window {
  z-index: 5000 !important;
  pointer-events: all;
  text-align: start;
}

>>> .sc-message--text-content {
  margin-bottom: 0px !important;
}

>>> .sc-message {
  width: 340px;
}

>>> .sc-message--meta {
  margin-bottom: 0px !important;
}

>>> .sc-launcher .sc-open-icon {
  width: 62px !important;
  height: 62px !important;
  right: 1px !important;
  bottom: 1px !important;
  border-radius: 100px;
  object-fit: cover !important;
  position: relative !important;
  border: 2px solid #bbb;
  padding: 0px !important;
}

>>> .sc-launcher.opened .sc-closed-icon {
  width: 63px !important;
  height: 63px !important;
  right: 1px !important;
  bottom: 1px !important;
  border-radius: 100px;
  object-fit: cover !important;
  position: relative !important;
  border: 3px solid #00cc00;
  transform: none !important;
  padding: 0px !important;
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  >>> .sc-chat-window {
    z-index: 2000 !important;
  }
}

>>> .sc-header--img {
  object-fit: cover;
  height: 60px;
  width: 60px;
}

.dismiss-user {
  position: absolute;
  top: 0;
  margin-top: 48px;
  margin-left: 17px;
  /* bottom: 20px;
  right: 20px; */
  z-index: 1200;
  color: white;
  background: black;
  border-radius: 100px;
  font-size: 16px !important;
  pointer-events: all;
}

.tootip-div {
  position: absolute;
  top: 0;
  height: 60px;
  width: 60px;
  z-index: 1200;
  color: white;
  margin-left: -26px;
  cursor: pointer;
  pointer-events: all;
}

>>> .sc-new-messsages-count {
  z-index: 500 !important;
  padding-top: 2px !important;
}

>>> .sc-message--content.received .sc-message--text {
  word-break: break-all !important;
}
>>> .sc-chat-window > input[type="file"] {
  display: block !important;
}

>>> .sc-user-input--text {
  width: 276px;
}

>>> .sc-user-input--file-icon {
  margin-top: 6px !important;
}
</style>
