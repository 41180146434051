var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.message.content !== '' || _vm.message.file !== null)?_c('ul',{staticClass:"list-unstyled mb-0",staticStyle:{"padding-left":"0px !important"}},[_c('li',{class:{ right: _vm.message.align === 'right' }},[_c('div',{ref:"imageRef",staticClass:"conversation-list",class:{
        'message-highlight': _vm.isMessageHover(_vm.message),
      },on:{"mouseover":function($event){return _vm.onHoverMessage(_vm.message)},"mouseleave":_vm.onLeaveMessage}},[_c('div',{staticClass:"user-chat-content"},[_c('div',{staticClass:"ctext-wrap"},[_c('div',{staticClass:"ctext-wrap-content"},[(
                _vm.roomUsers.length > 2 && _vm.message.sender_id !== _vm.currentUserId
              )?_c('div',{staticClass:"text-username",class:{
                'username-reply': !_vm.message.deleted && _vm.message.replyMessage,
              }},[_c('span',[_vm._v("yu"+_vm._s(_vm.message.username))])]):_vm._e(),_vm._v(" "),(!_vm.message.deleted && _vm.message.replyMessage)?_c('div',{staticClass:"reply-message bg-white"},[_c('div',{staticClass:"reply-username"},[_vm._v(_vm._s(_vm.replyUsername))]),_vm._v(" "),(_vm.isImageReply)?_c('div',{staticClass:"image-reply-container"},[_c('div',{staticClass:"message-image message-image-reply",style:({
                    'background-image': ("url('" + (_vm.message.replyMessage.file) + "')"),
                  })})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reply-content"},[_vm._v("\n                "+_vm._s(_vm.message.replyMessage.content)+"\n              ")])]):_vm._e(),_vm._v(" "),(_vm.message.deleted)?_c('div',[_c('i',{staticClass:"icon-deleted ri-chat-delete-fill"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.textMessages.MESSAGE_DELETED))])]):(!_vm.message.file)?_c('div',[_c('format-message',{attrs:{"content":this.message.content,"textFormatting":_vm.textFormatting}})],1):(_vm.isImage)?_c('div',{staticClass:"image-container",staticStyle:{"cursor":"pointer"},on:{"click":_vm.openFile}},[_c('div',{staticClass:"message-image",class:{
                  'image-loading':
                    _vm.isImageLoading && _vm.message.sender_id === _vm.currentUserId,
                },style:({
                  'background-image': ("url('" + (_vm.message.file) + "')"),
                  'max-height': ((_vm.imageResponsive.maxHeight) + "px"),
                })}),_vm._v(" "),_c('format-message',{attrs:{"content":_vm.message.content,"textFormatting":_vm.textFormatting}})],1):_c('div',{staticClass:"file-message"},[_c('div',{staticClass:"card p-2 mb-2"},[_c('div',{staticClass:"media align-items-center"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"media-body"},[_c('div',{staticClass:"text-left"},[_c('h5',{staticClass:"font-size-14 mb-1"},[_vm._v(_vm._s(_vm.message.content))]),_vm._v(" "),(false)?_c('p',{staticClass:"text-muted font-size-13 mb-0"},[_vm._v("\n                        "+_vm._s(_vm.message.file.size)+"\n                      ")]):_vm._e()])]),_vm._v(" "),_vm._m(1)])])]),_vm._v(" "),_c('p',{staticClass:"chat-time mb-0 d-flex align-center"},[_c('i',{staticClass:"ri-time-line mr-1"}),_vm._v(" "),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.message.time))])])]),_vm._v(" "),_c('div',{staticClass:"text-timestamp"},[(_vm.message.edited && !_vm.message.deleted)?_c('div',{staticClass:"icon-edited"},[_c('i',{staticClass:"ri-pencil-fill"})]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.message.timestamp))])]),_vm._v(" "),_c('b-dropdown',{staticClass:"align-self-start",staticStyle:{"display":"none"},attrs:{"variant":"white"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"ri-more-2-fill"})]},proxy:true}],null,false,2586269355)},[_vm._v(" "),_vm._l((_vm.messageActions),function(action){return _c('b-dropdown-item',{key:action.name,on:{"click":function($event){return _vm.messageActionHandler(action)}}},[_vm._v(_vm._s(action.name))])})],2),_vm._v(" "),_c('div',{staticClass:"options-container",class:{ 'options-image': _vm.isImage && !_vm.message.replyMessage },staticStyle:{"display":"none"},style:({
              width:
                _vm.filteredMessageActions.length && _vm.showReactionEmojis
                  ? '70px'
                  : '45px',
            })},[_c('transition-group',{attrs:{"name":"slide-left"}},[(_vm.isMessageActions || _vm.isMessageReactions)?_c('div',{key:"1",staticClass:"blur-container",class:{
                  'options-me': _vm.message.sender_id === _vm.currentUserId,
                }}):_vm._e(),_vm._v("\n              "+_vm._s(_vm.isMessageReactions)+"\n              "),(_vm.isMessageReactions)?_c('emoji-picker',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeEmoji),expression:"closeEmoji"}],key:"3",staticClass:"message-reactions",style:({ right: _vm.isMessageActions ? '30px' : '5px' }),attrs:{"emojiOpened":_vm.emojiOpened,"emojiReaction":true,"roomFooterRef":_vm.roomFooterRef,"positionRight":_vm.message.sender_id === _vm.currentUserId},on:{"addEmoji":_vm.sendMessageReaction,"openEmoji":_vm.openEmoji}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"conversation-name"},[_vm._v(_vm._s(_vm.message.name))]),_vm._v(" "),(!_vm.message.deleted)?_c('transition-group',_vm._l((_vm.message.reaction),function(reaction,key){return _c('button',{key:key + 0,staticClass:"button-reaction"},[_vm._v("\n            "+_vm._s(reaction.icon)+"\n          ")])}),0):_vm._e()],1)]),_vm._v(" "),(_vm.filteredMessageActions.length)?_c('transition',{attrs:{"name":_vm.message.sender_id === _vm.currentUserId ? 'slide-left' : 'slide-right'}},[(_vm.optionsOpened)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeOptions),expression:"closeOptions"}],ref:"menuOptions",staticClass:"menu-options",class:{ 'menu-left': _vm.message.sender_id !== _vm.currentUserId },style:({ top: (_vm.menuOptionsTop + "px") })},[_c('div',{staticClass:"menu-list"},_vm._l((_vm.messageActions),function(action){return _c('div',{key:action.name},[_c('div',{staticClass:"menu-item",on:{"click":function($event){return _vm.messageActionHandler(action)}}},[_vm._v("\n              "+_vm._s(action.title)+"\n            ")])])}),0)]):_vm._e()]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar-sm mr-3"},[_c('div',{staticClass:"avatar-title bg-soft-primary text-primary rounded font-size-20"},[_c('i',{staticClass:"ri-file-text-fill"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-4"},[_c('ul',{staticClass:"list-inline mb-0 font-size-20"},[_c('li',{staticClass:"list-inline-item"},[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:void(0);"}},[_c('i',{staticClass:"ri-download-2-line"})])]),_vm._v(" "),_c('li',{staticClass:"list-inline-item dropdown"},[_c('a',{staticClass:"dropdown-toggle text-muted",attrs:{"href":"javascript:void(0);","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"ri-more-fill"})]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0);"}},[_vm._v("\n                            Share\n                            "),_c('i',{staticClass:"ri-share-line float-right text-muted"})]),_vm._v(" "),_c('a',{staticClass:"dropdown-item",attrs:{"href":"javascript:void(0);"}},[_vm._v("\n                            Delete\n                            "),_c('i',{staticClass:"ri-delete-bin-line float-right text-muted"})])])])])])}]

export { render, staticRenderFns }