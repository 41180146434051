<template>
  <v-app>
    <div class="admin-login">
      <div class="login-card">
        <div class="text-center">
          <img v-if="settings.imageurl" :src="settings.imageurl" alt="" />
          <img v-else :src="'/assets/icon.png'" alt />

          <h4>{{welcomeMessage}}</h4>
          <p class="mb-8">Sign in by entering the information below.</p>
        </div>

        <v-text-field
          v-model="email"
          :error-messages="emailErrors"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          required
          label="Username / Email"
          outlined
          :disabled="loaderstate"
          @keyup.enter="login()"
        ></v-text-field>
        <v-text-field
          :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPass ? 'text' : 'password'"
          label="Password"
          outlined
          @click:append="showPass = !showPass"
          v-model="password"
          :error-messages="passwordErrors"
          @input="$v.password.$touch()"
          @blur="$v.password.$touch()"
          required
          class="my-3"
          :disabled="loaderstate"
          @keyup.enter="login()"
        ></v-text-field>

        <v-btn
          :loading="loaderstate"
          :disabled="loaderstate"
          depressed
          block
          large
          color="primary"
          @click="login()"
          >Login</v-btn
        >

        <div class="text-end mt-4">
          <v-btn
            small
            :to="{ name: 'forgotPassword' }"
            color="transparent"
            depressed
            >Forgot Password?</v-btn
          >
        </div>
      </div>

      <v-snackbar
        :timeout="3000"
        :color="snackbar.color"
        top
        right
        v-model="snackbar.show"
      >
        {{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </div>
     <v-footer

        style="border-top: 1px solid lightgrey !important"
        inset
        color="white"
        app
        >
        <div
          style="width: 100%"
          class="d-flex justify-space-between align-center">
          <span class="pa-3">&#169; Powered by Entouch | {{ new Date().getFullYear() }} Encounter Telehealth</span>

          <v-btn :to="{ name: 'sessionClear' }" depressed small>Clear</v-btn>
        </div>
      </v-footer>
  </v-app>
</template>


<script>
import axios from 'axios';
import { required, minLength } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  data: () => ({
      welcomeMessage:"Welcome",
    showPass: false,
    email: "",
    password: "",
    loaderstate: false,
  }),

  methods: {
    login() {
      if (this.$v.$invalid) {
        this.$v.$touch();

        this.$store.commit(
          "currentUser/LOGIN_FAIL",
          "Email and Password is required"
        );
      } else {
        this.loaderstate = true;
        this.$store
          .dispatch("currentUser/loginApi", {
            email: this.email,
            password: this.password,
          })
          .then((result) => {
            this.$router.replace({
              name: "dashboard",
            });
            // window.location.reload();
            this.loaderstate = false;
          })
          .catch((error) => {
            this.$store.commit(
              "currentUser/LOGIN_FAIL",
              "Email and Password is required"
            );

            this.loaderstate = false;
          });
      }
    },
  },

  validations: {
    email: { required },
    password: { required },
  },

  mounted() {
    const params = new URLSearchParams(window.location.search);
    if (params.has("uid")) {
        const data = new FormData();
      data.append('uid',params.get("uid"))
        axios.post("/api/get_location_from_email",data).then(r=>{
            this.welcomeMessage = r.data || 'Welcome' ;
            this.$nextTick(() => {
                  this.email = params.get("uid");
            });
        })
    }

    //TODO: Create a seprate api to fetch Login assets /getLoginUI
    this.$store.dispatch("generalSetting/getDashboardSetting");
  },

  computed: {
    ...mapState({
      settings: (state) => state.generalSetting.get_general_setting,
      snackbar: (state) => state.currentUser.showLoginSnackBar,
    }),

    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Username / Email is required");
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
  },
};
</script>

<style scoped>
/* Mobile devices */
@media screen and (max-width: 480px) {
  >>> .v-snack__wrapper {
    min-width: 285px !important;
  }
}
</style>
