<template>
  <div>
    <v-toolbar class="mt-3" flat color="white">
      <h4 class="ma-0 mr-3">Sync Recent Logs</h4>
       <v-chip v-if="last_sync_data" small>
          {{ last_sync_data.created_at | moment("MM/DD/YYYY - h:mm a") }}
        </v-chip>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="open_sync_dialog">Sync All</v-btn>
      <v-btn class="ml-4" @click="initialize(0)" fab light small color="white">
        <v-icon color="primary">mdi-sync</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row>
      <!--------------------------------- Chargers Sync Data ---------------------------------------->
      <v-col cols="12" md="6">
        <div class="download-reports-section">
          <v-card class="en-card mt-4" flat>
            <v-toolbar flat color="white">
              <v-toolbar-title>Recent 5 Charges Sync Logs</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-row> </v-row>
            <v-data-table
              hide-default-footer
              :headers="reportHeader"
              :items="recentReportLogs"
              :items-per-page="5"
              class="elevation-0 mt-4"
            >
              <template v-slot:item.start_date="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.start_date,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.end_date="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.end_date,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.status="{ item }">
                <v-btn x-small dark depressed :color="statusColors(item)">{{
                  statusName(item)
                }}</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>

      <!--------------------------------- Appointment Sync Data ---------------------------------------->
      <v-col cols="12" md="6">
        <div class="download-reports-section">
          <v-card class="en-card mt-4" flat>
            <v-toolbar flat color="white">
              <v-toolbar-title>Recent 5 Appointments Sync Logs</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-row> </v-row>
            <v-data-table
              hide-default-footer
              :headers="appHeader"
              :items="appointmentlogs"
              :items-per-page="5"
              class="elevation-0 mt-4"
            >
              <template v-slot:item.app_process="{ item }">
                <p class="mb-0">
                  {{ item.fetched + " / " + item.total }}
                </p>
              </template>

              <template v-slot:item.start_date="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.start_date,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.end_date="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.end_date,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                  v-if="item.status == 1 || item.status == 3"
                  @click="recall(item)"
                  >mdi-sync</v-icon
                >
              </template>

              <template v-slot:item.status="{ item }">
                <v-btn x-small dark depressed :color="statusColors(item)">{{
                  statusName(item)
                }}</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>

      <!--------------------------------- Patients Sync Data ---------------------------------------->
      <v-col cols="12" md="6">
        <div class="download-reports-section">
          <v-card class="en-card" flat>
            <v-toolbar flat color="white">
              <v-toolbar-title>Recent 5 Patients Sync Logs</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-row> </v-row>
            <v-data-table
              hide-default-footer
              :headers="patientHeader"
              :items="patientlogs"
              :items-per-page="5"
              class="elevation-0 mt-4"
            >
              <template v-slot:item.updated_at="{ item }">
                <p class="mb-0">
                  {{
                    moment
                      .tz(
                        item.start_date,
                        user.timezone ? user.timezone : default_timezon
                      )
                      .format("MM/DD/YYYY")
                  }}
                </p>
              </template>

              <template v-slot:item.status="{ item }">
                <v-btn x-small dark depressed :color="statusColors(item)">{{
                  statusName(item)
                }}</v-btn>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="sync_dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span>Sync Data</span>
        </v-card-title>

        <v-card-text
          >There are already some jobs in processing state , You can wait or
          Click Force Run , Force run will cancel any previous jobs and Start a
          New Job.</v-card-text
        >

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="sync_dialog = false"
            >Back</v-btn
          >
          <v-btn
            depressed
            @click="getSyncData()"
            color="primary darken-1"
            width="100px"
            >Force Run</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import axios from "axios";
export default {
  components: {},
  data: () => ({
    showDialog: false,
    sync_dialog: false,
    appointmentlogs: [],
    patientlogs: [],
    moment: moment,
    recentReportLogs: [],
    reportHeader: [
      { text: "Start Date", value: "start_date", align: "start" },
      { text: "End Date", value: "end_date" },
      { text: "Status", value: "status" },
      { text: "Message", value: "message", align: "start" },
    ],

    appHeader: [
      { text: "Start Date", value: "start_date", align: "start" },
      { text: "End Date", value: "end_date" },
      { text: "Status", value: "status" },
      { text: "Fetched/Total", value: "app_process", align: "center" },
      { text: "Message", value: "message", align: "start" },
      { text: "Action", value: "actions", align: "end" },
    ],

    patientHeader: [
      { text: "Status", value: "status" },
      { text: "Message", value: "message", align: "start" },
      { text: "Last Sync", value: "updated_at" },
    ],

    default_timezon: "America/Chicago",
    last_sync_data: null,
  }),

  created() {
    this.initialize(1);
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  methods: {
    recall(item) {
      this.$store.dispatch("global/actionloaderState", true, {
        root: true,
      });
      axios
        .post("/api/syncSingleAppointment", { id: item.id })
        .then((res) => {
          item.status = 1; // Processing
          item.message = "Sync in Processing";

          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((e) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },

    getSyncData() {
      this.$store.dispatch("global/actionloaderState", true, {
        root: true,
      });
      axios
        .get("/api/getAllSyncData")
        .then((res) => {
          this.sync_dialog = false;
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.initialize(0);
        })
        .catch((e) => {
          this.sync_dialog = false;
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },

    initialize(action) {
      this.$store.dispatch("global/actionloaderState", true, {
        root: true,
      });
      var fd = new FormData();
      fd.append("action", action);

      this.$store
        .dispatch("global/post", {
          url: "/api/get_recent_sync_logs",
          body: fd,
        })
        .then((r) => {
          var data = r.data.data;
          this.recentReportLogs = data.chargelogs;
          this.appointmentlogs = data.appointmentlogs;
          this.patientlogs = data.patientlogs;
          this.last_sync_data = data.last_sync_data;

          var check1 = this.recentReportLogs.some((el) => el.status == 1);
          var check2 = this.appointmentlogs.some((el) => el.status == 1);
          var check3 = this.patientlogs.some((el) => el.status == 1);
          if (check1 || check2 || check3) {
            this.showDialog = true;
          }

          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((error) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },

    open_sync_dialog() {
      if (this.showDialog) {
        this.sync_dialog = true;
      } else {
        this.getSyncData();
      }
    },

    // Status Colors
    statusColors(item) {
      switch (item.status) {
        case 1:
          return "orange";
          break;
        case 2:
          return "success";
          break;
        case 3:
          return "red";
          break;
        case 4:
          return "black";
          break;
        default:
      }
    },

    // Status Colors
    statusName(item) {
      switch (item.status) {
        case 1:
          return "Processing";
          break;
        case 2:
          return "Completed";
          break;
        case 3:
          return "Failed";
          break;
        case 4:
          return "Cancelled";
          break;
        default:
      }
    },
  },
};
</script>

<style scoped></style>
