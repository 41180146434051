var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{staticClass:"pa-3"},[_c('v-badge',{staticClass:"mr-4",attrs:{"bordered":"","bottom":"","color":_vm.participant.color,"dot":"","offset-x":"12","offset-y":"12","value":_vm.participant.color == '#00cc00'}},[_c('v-avatar',{attrs:{"size":"45"}},[(_vm.participant.smallImage)?_c('v-img',{attrs:{"src":_vm.participant.smallImage}}):_c('img',{attrs:{"src":'/assets/plaeholder-user.png'}})],1)],1),_vm._v(" "),_c('v-list-item-content',{staticClass:"py-2"},[_c('h3',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.participant.name))]),_vm._v(" "),_c('p',{staticClass:"en-sub-text"},[_vm._v("\n        "+_vm._s(_vm.lastMessage)+"\n      ")])]),_vm._v(" "),(_vm.showCount)?_c('p',{staticClass:"message-count-user"},[_vm._v(_vm._s(_vm.unreadCount))]):_vm._e(),_vm._v(" "),_c('v-list-item-action',{on:{"click":function($event){_vm.delete_dialog = true}}},[_c('v-menu',{attrs:{"top":"","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v("\n            mdi-dots-vertical\n          ")])]}}])},[_vm._v(" "),_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.hide = false;
              _vm.delete_dialog = true;}}},[_c('v-list-item-title',[_vm._v("Delete Chat")])],1),_vm._v(" "),_c('v-list-item',{on:{"click":function($event){_vm.hide = true;
              _vm.delete_dialog = true;}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.hidden ? "Un-" : "")+"Hide Chat")])],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.hide ? (_vm.hidden ? "Un-hide" : "Hide") : "Delete"))]),_vm._v(" "),_c('v-card-text',[_vm._v("Are you sure you want to\n        "+_vm._s(_vm.hide ? (_vm.hidden ? "un-hide" : "hide") : "delete")+" this\n        conversation?")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.delete_dialog = false}}},[_vm._v("No")]),_vm._v(" "),_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"primary","depressed":""},on:{"click":function($event){_vm.hide
              ? _vm.hidden
                ? _vm.unHideConversation()
                : _vm.hideConversation()
              : _vm.deleteConversation()}}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }