<template>
  <div>
    <v-container>
      <v-row id="link">
        <!-- {{logs}} -->
        <!-- All -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con all-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-format-list-text</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>All</h2>
              <p v-if="logs.all">
                {{ logs.all.entries }} entries -
                {{ logs.all.entries < 100 ? logs.all.entries : 100 }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Emergency -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con emergency-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-bell-alert</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Emergency</h2>
              <p v-if="logs.emergency">
                {{ logs.emergency.entries }} entries -
                {{ persentage(logs.emergency.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Alert -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con alert-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-bullhorn</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Alert</h2>
              <p v-if="logs.alert">
                {{ logs.alert.entries }} entries -
                {{ persentage(logs.alert.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Critical -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con critical-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-heart-pulse</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Critical</h2>
              <p v-if="logs.critical">
                {{ logs.critical.entries }} entries -
                {{ persentage(logs.critical.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Error -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con error-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-close-octagon</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Error</h2>
              <p v-if="logs.error">
                {{ logs.error.entries }} entries -
                {{ persentage(logs.error.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Warning -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con warning-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-alert</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Warning</h2>
              <p v-if="logs.warning">
                {{ logs.warning.entries }} entries -
                {{ persentage(logs.warning.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Notice -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con notice-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-alert-circle</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Notice</h2>
              <p v-if="logs.notice">
                {{ logs.notice.entries }} entries -
                {{ persentage(logs.notice.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Info -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con info-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-information</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Info</h2>
              <p v-if="logs.info">
                {{ logs.info.entries }} entries -
                {{ persentage(logs.info.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>

        <!-- Debug -->
        <v-col cols="12" sm="4" md="4" @click="OpenFileDetails()">
          <v-card class="logs-dash-cards">
            <div class="logs-icon-con debug-dash">
              <v-icon style="background-color: transparent !important"
                >mdi-bug</v-icon
              >
            </div>
            <div class="ml-3">
              <h2>Debug</h2>
              <p v-if="logs.debug">
                {{ logs.debug.entries }} entries -
                {{ persentage(logs.debug.entries) }} %
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  data: () => ({
    dialog: false,
  }),

  computed: {
    ...mapState({
      logs: (state) => state.admin_log_reader.logs,
    }),
  },

  created() {
    this.getlogs();
  },

  methods: {
    persentage(value) {
      return parseInt((value / this.logs.all.entries) * 100);
    },

    getlogs() {
      this.$store.dispatch("admin_log_reader/getdashboard", {});
    },

    //laravel-2021-07-30.log
    OpenFileDetails() {
      const d = new Date();
      const filename =
        "laravel-" +
        d.getFullYear() +
        "-" +
        moment(d).format("MM") +
        "-" +
        moment(d).format("DD") +
        ".log";
      localStorage.setItem("filename", filename);
      localStorage.setItem("level", "all");
      this.$router.push({ name: "logInfo" });
    },
  },
};
</script>

<style>
#link {
  cursor: pointer;
}
</style>
