import Vue from "vue";

require("./bootstrap");

window.Vue = require("vue");

import Vuetify from "../plugin/vuetify";
import router from "./router";
import VueMoment from "vue-moment";
import pusher from "vue-pusher";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);
Vue.use(VueMoment);

// import Bugsnag from "@bugsnag/js";
// import BugsnagPluginVue from "@bugsnag/plugin-vue";

// Vue.use(Bugsnag);
// Vue.use(BugsnagPluginVue);
Vue.use(pusher, {
    api_key: process.env.MIX_PUSHER_APP_KEY,
    options: {
        cluster: "ap2",
        encrypted: true
    }
});
// Bugsnag.start({
//     apiKey: "1480032896f81e75b3458cf0cf622e51",
//     plugins: [new BugsnagPluginVue()]
// });

// const bugsnagVue = Bugsnag.getPlugin('vue')
// bugsnagVue.installVueErrorHandler(Vue)

// Bugsnag.notify(new Error('Test error'));

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//     Vue,
//     dsn: "https://dc7de91666b9491ab517bbe150a77c7c@o529821.ingest.sentry.io/5648722",
//     integrations: [new Integrations.BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     logErrors: true,
//     tracesSampleRate: 1.0,
//     tracingOptions: {
//         trackComponents: true,
//       },
//   });
//   console.log("Calling serntry init");

import store from "./store/index";

import Vuelidate from "vuelidate";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(Vuelidate);
window.axios = require("axios");
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import i18n from "./i18n";

import VueParallaxView from "vue-parallax-view";
Vue.use(VueParallaxView);
import "vue-parallax-view/dist/vue-parallax-view.css";

import common_constants from "./constants/common_constants";
import Axios from "axios";
import firebaseApp from "./utils/firebseConfig";
import firebase from "firebase/app";

Vue.prototype.$constants = common_constants;

import Chat from "vue-beautiful-chat";
Vue.use(Chat);

import VueDragscroll from "vue-dragscroll";
Vue.use(VueDragscroll);

import drag from "v-drag";
Vue.use(drag, {
    eventClass: {
        down: "is-pressed",
        move: "is-moving"
    }
});

Vue.prototype.$db = firebaseApp.firestore();
const support = firebase.messaging.isSupported();

if (support) Vue.prototype.$messaging = firebaseApp.messaging();
// console.log("Check Firebase Supppor====", support);
Vue.prototype.$loginUser = function() {
    const userSting = localStorage.getItem("en_user");
    return JSON.parse(userSting);
};

const app = new Vue({
    el: "#app",
    vuetify: Vuetify,
    router,
    store,
    i18n,

    ...app,
    created: function() {
        Axios.defaults.headers.common["Authorization"] = `Bearer ${
            this.$loginUser() == null ? "" : this.$loginUser().access_token
        }`;
        Axios.defaults.headers.common["Content-Type"] = "application/json";
        Axios.interceptors.response.use(
            function(response) {
                // console.log("Interceptore==========",response);
                // alert(this.$loginUser());
                console.log("response status", response.status);
                return response;
            },
            function(error) {
               // alert("Error===",this.$loginUser());
                console.log("Axios Intercepter response error", error);
                if (error.response && error.response.status === 401) {
                    // console.log("401 Error");
                    store.dispatch("currentUser/logout", false).then(r => {
                        router.replace({ name: "login" });
                    });
                }
                else {
                    throw error;
                }
            }
        );
    }
});
