<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" md="12">
        <p class="en-fields-label">Twilio API Key :</p>
        <v-text-field
          v-model="item.twilio_video_key"
          dense
          outlined
          :error-messages="twilioKeyErrors"
          @input="$v.item.twilio_video_key.$touch()"
          @blur="$v.item.twilio_video_key.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" class="py-0">
        <v-btn
          depressed
          @click="saveAPISetting()"
          large
          color="primary"
          style="width: 170px"
          >Save Changes</v-btn
        >

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="elevation-1 ml-3"
              fab
              light
              small
              color="white"
              :loading="key_testing"
              @click="testing()"
            >
              <v-icon color="primary">mdi-flask</v-icon>
            </v-btn>
          </template>
          <span>Test</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, requiredIf, email } from "vuelidate/lib/validators";

export default {
  data: () => ({
    item: {
      twilio_video_key: "",
    },
    key_testing: false,
  }),

  validations: {
    item: {
      twilio_video_key: { required },
    },
  },

   created(){
    this.item.twilio_video_key= this.$store.state.generalSetting.get_general_setting.twilio_video_key

  },


  computed: {
    ...mapState({
      get_general_setting: (state) => state.generalSetting.get_general_setting,
    }),

    twilioKeyErrors() {
      const errors = [];
      if (!this.$v.item.twilio_video_key.$dirty) return errors;
      !this.$v.item.twilio_video_key.required &&
        errors.push("Twilio key is required.");
      return errors;
    },
  },

  methods: {
    testing() {
      this.key_testing = true;
      setTimeout(() => {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: "Api key is testing.",
        });
        this.key_testing = false;
      }, 2000);
    },

    saveAPISetting() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "Twilio API key is required.",
        });
      } else {
        this.$store.dispatch("generalSetting/setAPIVariable", this.item);
      }
    },
  },
};
</script>
