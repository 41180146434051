import Axios from "axios";

const state = {
    get_general_setting: [],
    showLoader: false,
    setNavbarStatus:false,
    errorMessage: "",
    showSnackBar: {
        show: false,
        message: "",
        color: ""
    },
    logo: "",
    colors: {
        primary: "#2D4859",
        darker: "#202431",
    }
};

const getters = {
    logo: state => state.logo,
    primary: state => state.colors.primary,
    darker: state => state.colors.darker
};

const actions = {

    getAllGeneralSetting(context, data) {

        return new Promise(async (resolve, reject) => {
        var api_url = "/api/getAllGeneralSetting";
        axios
            .get(api_url)
            .then(response => {
                var settings = response.data.data;
                resolve(settings);
                context.commit("GET_GENERAL_SETTING", settings)
            })
            .catch(e => {

              reject(e);
            })
        });
    },

    uiConfigrationSetting(context, data) {
        context.commit("SET_NAVBAR_STATUS", false);
        context.commit("SHOW_LOADER", true);
        const colors = data.colors;
        const formData = new FormData();
        formData.append("primary", data.colors.primary);
        formData.append("darker",data.colors.darker);
        formData.append("videoProfile",data.videoProfile);

        if (data.default_timezon) formData.append("default_timezon", data.default_timezon);
        if (data.appointment_interval) formData.append("appointment_interval", data.appointment_interval);
        if (data.image) formData.append("imageurl", data.image, data.image.name);
        if (data.reset) formData.append("reset", data.reset);
        var api_url = "/api/uiConfigrationSetting";

        Axios.post(api_url, formData)
            .then(r => {
                context.dispatch("global/snackBarState", {
                    show: true,
                    color: "primary",
                    message: r.data.message
                }, { root: true }
                );
                var settings = r.data.data;
                // console.log(settings);
                context.commit("GET_GENERAL_SETTING", settings);
                context.commit("SET_NAVBAR_STATUS", true);
                context.commit("SHOW_LOADER", false);
                context.commit("UPDATE_THEME", {
                    primary: colors.primary.hex,
                    darker: colors.darker.hex,
                    image:
                        r.data.data.filter(e => e.key === "image")?.value ||
                        context.state.logo
                });

                context.dispatch("global/sc", {}, { root: true });
            })
            .catch(e => {
                context.commit("SHOW_LOADER", false);
            });
    },

    updatePrimary({ commit }, color) {
        commit("updatePrimary", color);
    },

    updateDarker({ commit }, color) {
        commit("updateDarker", color);
    },
    updateLogo({ commit }, logo) {
        commit("updateLogo", logo);
    }
};

const mutations = {
    GET_GENERAL_SETTING(state, data) {
        state.get_general_setting = data;
    },

    SHOW_LOADER(state, status) {
        state.showLoader = status;
    },

    SET_NAVBAR_STATUS(state,status){
        state.setNavbarStatus = status;
    },

    UPDATE_THEME(state, colors) {
        state.colors.primary = colors.primary || "#2D4859";
        state.colors.darker = colors.darker || "#16191c";
        state.logo = colors.image;
    },

    updateLogo(state, logo) {
        state.logo = logo;
    },
    updatePrimary(state, color) {
        state.colors.primary = color;
    },
    updateDarker(state, color) {
        state.colors.darker = color;
    },
    SHOW_SNACKBAR(state, payload) {
        state.showSnackBar = payload;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
