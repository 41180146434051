// ACCOUNTS TYPES
const ADMIN = 1;
const PROVIDER = 2;
const COORDINATOR = 3;
const BASIC_USER = 4;

//Chat
const CHAT = 2;
const GROUP = 3;
const GROUP_NAME = "Health Services";

const PENDING = 1;
const IN_PROGRESS = 2;
const COMPLETED = 3;
const CLOSED = 4;

export default {
    ADMIN: ADMIN,
    PROVIDER: PROVIDER,
    COORDINATOR: COORDINATOR,
    BASIC_USER: BASIC_USER,
    CHAT: CHAT,
    GROUP: GROUP,
    GROUP_NAME: GROUP_NAME,
    PENDING: PENDING,
    IN_PROGRESS: IN_PROGRESS,
    COMPLETED: COMPLETED,
    CLOSED: CLOSED,
};


