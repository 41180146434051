<template>
  <div>
    <div class="section-wrapper">
      <v-card-actions class="py-0">
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-row>
        <!-- Downloads and Reports -->
        <v-col
          v-if="
            user.account_type == $constants.COORDINATOR ||
            user.account_type == $constants.ADMIN ||
            user.account_type == $constants.PROVIDER
          "
          cols="12"
          md="12"
        >
          <DownloadsAndReports />
        </v-col>

        <!-- <v-col cols="12" md="6"> -->
          <!-- <RecentReportLogs
            v-if="
              user.account_type == $constants.ADMIN
            "
          /> -->
          <!-- <PdfReport /> -->
        <!-- </v-col> -->
      </v-row>

      <PdfReport />
      <!-- <v-row>
        <v-col cols="12" md="6"> -->
      <RecentReportLogs v-if="user.account_type == $constants.ADMIN" />
      <!-- </v-col> -->
      <!-- <v-col cols="12" md="6">
          <RecentAppointmentsLogs v-if="user.account_type == $constants.ADMIN" />
        </v-col>
        <v-col cols="12" md="6">
          <RecentPatientsLogs v-if="user.account_type == $constants.ADMIN" />
        </v-col> -->
      <!-- </v-row> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DownloadsAndReports from "./partner_sections/DownloadsAndReports.vue";
import RecentReportLogs from "./partner_sections/RecentReportLogs.vue";
import PdfReport from "./partner_sections/PdfReports.vue";

export default {
  components: {
    DownloadsAndReports,
    RecentReportLogs,
    PdfReport,
  },
  data: () => ({
    bread: [
      {
        text: "Reports",
        disabled: true,
        to: "",
      },
    ],
  }),

  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  methods: {},
};
</script>
<style scoped></style>
