var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"download-reports-section"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.user.account_type == _vm.$constants.COORDINATOR ? '12' : '6'}},[_c('div',{staticClass:"en-card mt-4"},[_c('h4',[_vm._v("Facility Reports")]),_vm._v(" "),(_vm.user.account_type == _vm.$constants.ADMIN && _vm.providers)?_c('v-autocomplete',{attrs:{"single-line":"","items":_vm.users,"multiple":"","dense":"","filled":"","chips":"","label":"Select Partners"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v("Selected Items ("+_vm._s(_vm.selectedPartners.length)+")")])]):_vm._e()]}}],null,false,1017765669),model:{value:(_vm.selectedPartners),callback:function ($$v) {_vm.selectedPartners=$$v},expression:"selectedPartners"}},[(_vm.users.length != 0)?_c('v-list-item',{attrs:{"slot":"prepend-item","ripple":""},on:{"click":_vm.toggle},slot:"prepend-item"},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedPartners.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.iconSelectAll))])],1),_vm._v(" "),_c('v-list-item-title',[_c('h6',[_vm._v("All")])])],1):_vm._e(),_vm._v(" "),_c('v-divider',{staticClass:"mt-2",attrs:{"slot":"prepend-item"},slot:"prepend-item"})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"py-3"},[_c('hr')]),_vm._v(" "),_c('h6',{staticClass:"mb-5 mt-1"},[_vm._v("Patient Visit Detail")]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-dialog',{ref:"dialog1",attrs:{"return-value":_vm.date1,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date1=$event},"update:return-value":function($event){_vm.date1=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":"","label":"Start Date"},model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date1Modal),callback:function ($$v) {_vm.date1Modal=$$v},expression:"date1Modal"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","max":_vm.temDate2 + _vm.date},on:{"change":function($event){return _vm.StartDateFormat(_vm.temDate1)}},model:{value:(_vm.temDate1),callback:function ($$v) {_vm.temDate1=$$v},expression:"temDate1"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.date1Modal = false}}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog1.save(_vm.date1)}}},[_vm._v("\n              OK\n            ")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-dialog',{ref:"dialog2",attrs:{"return-value":_vm.date2,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date2=$event},"update:return-value":function($event){_vm.date2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.date2Modal),callback:function ($$v) {_vm.date2Modal=$$v},expression:"date2Modal"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","min":_vm.temDate1,"max":_vm.date},on:{"change":function($event){return _vm.EndDateFormat(_vm.temDate2)}},model:{value:(_vm.temDate2),callback:function ($$v) {_vm.temDate2=$$v},expression:"temDate2"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.date2Modal = false}}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog2.save(_vm.date2)}}},[_vm._v("\n              OK\n            ")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-btn',{attrs:{"depressed":"","block":"","color":"primary","disabled":!_vm.temDate1 ||
            !_vm.temDate2 ||
            (_vm.selectedPartners.length == 0 &&
              _vm.user.account_type == _vm.$constants.ADMIN)},on:{"click":_vm.getReports}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1)],1)],1),_vm._v(" "),_c('div',{staticClass:"py-3"},[_c('hr')]),_vm._v(" "),(_vm.user.account_type != _vm.$constants.PROVIDER)?_c('div',[_c('h6',{staticClass:"mb-5 mt-1"},[_vm._v("No-Show Appointment")]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-dialog',{ref:"dialog3",attrs:{"return-value":_vm.start_date1,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.start_date1=$event},"update:return-value":function($event){_vm.start_date1=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":"","label":"Start Date"},model:{value:(_vm.start_date1),callback:function ($$v) {_vm.start_date1=$$v},expression:"start_date1"}},'v-text-field',attrs,false),on))]}}],null,false,3767214831),model:{value:(_vm.start_date_Modal),callback:function ($$v) {_vm.start_date_Modal=$$v},expression:"start_date_Modal"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","max":_vm.end_date + _vm.date},on:{"change":function($event){return _vm.changeStartDateFormat(_vm.start_date)}},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.start_date_Modal = false}}},[_vm._v("\n                Cancel\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog3.save(_vm.start_date1)}}},[_vm._v("\n                OK\n              ")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-dialog',{ref:"dialog4",attrs:{"return-value":_vm.end_date2,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.end_date2=$event},"update:return-value":function($event){_vm.end_date2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.end_date2),callback:function ($$v) {_vm.end_date2=$$v},expression:"end_date2"}},'v-text-field',attrs,false),on))]}}],null,false,1371516876),model:{value:(_vm.end_date_Modal),callback:function ($$v) {_vm.end_date_Modal=$$v},expression:"end_date_Modal"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","min":_vm.start_date,"max":_vm.date},on:{"change":function($event){return _vm.changeEndDateFormat(_vm.end_date)}},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.end_date_Modal = false}}},[_vm._v("\n                Cancel\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog4.save(_vm.end_date2)}}},[_vm._v("\n                OK\n              ")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-btn',{attrs:{"depressed":"","block":"","color":"primary","disabled":!_vm.start_date ||
              !_vm.end_date ||
              (_vm.selectedPartners.length == 0 &&
                _vm.user.account_type == _vm.$constants.ADMIN)},on:{"click":function($event){return _vm.getNoShowAppointment()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1):_vm._e()],1)]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(
      _vm.user.account_type == _vm.$constants.PROVIDER ||
      _vm.user.account_type == _vm.$constants.ADMIN
    )?_c('div',{staticClass:"en-card mt-4"},[_c('h4',[_vm._v("Provider Reports")]),_vm._v(" "),(_vm.user.account_type == _vm.$constants.ADMIN)?_c('v-autocomplete',{attrs:{"single-line":"","items":_vm.all_provider,"multiple":"","dense":"","filled":"","chips":"","label":"Select Providers"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v("Selected Items ("+_vm._s(_vm.selectedProvider.length)+")")])]):_vm._e()]}}],null,false,4103861721),model:{value:(_vm.selectedProvider),callback:function ($$v) {_vm.selectedProvider=$$v},expression:"selectedProvider"}},[_c('v-list-tile',{attrs:{"slot":"prepend-item","ripple":""},on:{"click":_vm.toggle_provider},slot:"prepend-item"},[_c('v-list-tile-action',[_c('v-icon',{staticClass:"pl-4",attrs:{"color":_vm.selectedProvider.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.SelectAllProvider))])],1),_vm._v(" "),_c('v-list-tile-title',{staticClass:"pl-6"},[_vm._v(" All")])],1),_vm._v(" "),_c('v-divider',{staticClass:"mt-2",attrs:{"slot":"prepend-item"},slot:"prepend-item"})],1):_vm._e(),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h6',{staticClass:"mb-5 mt-1"},[_vm._v("No-Show Provider")]),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-dialog',{ref:"dialog5",attrs:{"return-value":_vm.start_date_provider1,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.start_date_provider1=$event},"update:return-value":function($event){_vm.start_date_provider1=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":"","label":"Start Date"},model:{value:(_vm.start_date_provider1),callback:function ($$v) {_vm.start_date_provider1=$$v},expression:"start_date_provider1"}},'v-text-field',attrs,false),on))]}}],null,false,2646829105),model:{value:(_vm.start_date_provider_Modal),callback:function ($$v) {_vm.start_date_provider_Modal=$$v},expression:"start_date_provider_Modal"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","max":_vm.end_date_provider + _vm.date},on:{"change":function($event){return _vm.changeStartDateFormatProvider(_vm.start_date_provider)}},model:{value:(_vm.start_date_provider),callback:function ($$v) {_vm.start_date_provider=$$v},expression:"start_date_provider"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.start_date_provider_Modal = false}}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog5.save(_vm.start_date_provider1)}}},[_vm._v("\n              OK\n            ")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-dialog',{ref:"dialog6",attrs:{"return-value":_vm.end_date_provider2,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.end_date_provider2=$event},"update:return-value":function($event){_vm.end_date_provider2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End Date","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.end_date_provider2),callback:function ($$v) {_vm.end_date_provider2=$$v},expression:"end_date_provider2"}},'v-text-field',attrs,false),on))]}}],null,false,499252274),model:{value:(_vm.end_date_provider_Modal),callback:function ($$v) {_vm.end_date_provider_Modal=$$v},expression:"end_date_provider_Modal"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","min":_vm.start_date_provider,"max":_vm.date},on:{"change":function($event){return _vm.changeEndDateFormatProvider(_vm.end_date_provider)}},model:{value:(_vm.end_date_provider),callback:function ($$v) {_vm.end_date_provider=$$v},expression:"end_date_provider"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.end_date_provider_Modal = false}}},[_vm._v("\n              Cancel\n            ")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog6.save(_vm.end_date_provider2)}}},[_vm._v("\n              OK\n            ")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('v-btn',{attrs:{"depressed":"","block":"","color":"primary","disabled":!_vm.start_date_provider ||
            !_vm.end_date_provider ||
            (_vm.selectedProvider.length == 0 &&
              _vm.user.account_type == _vm.$constants.ADMIN)},on:{"click":function($event){return _vm.getNoShowProvider()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-magnify")])],1)],1)],1)],1):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }