<template>
  <div>

    <v-list-item class="pa-3">
      <v-badge
        bordered
        bottom
        :color="participant.color"
        dot
        offset-x="12"
        offset-y="12"
        class="mr-4"
        :value="participant.color == '#00cc00'"
      >
        <v-avatar size="45">
          <v-img
            v-if="participant.smallImage"
            :src="participant.smallImage"
          ></v-img>
          <img v-else :src="'/assets/plaeholder-user.png'" />
        </v-avatar>
      </v-badge>

      <v-list-item-content class="py-2">
        <h3 class="mb-2">{{ participant.name }}</h3>
        <p  class="en-sub-text">
          {{lastMessage}}
        </p>
      </v-list-item-content>
      <p v-if="showCount" class="message-count-user">{{ unreadCount }}</p>
      <v-list-item-action @click="delete_dialog = true">
        <v-menu top :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" dark v-bind="attrs" v-on="on">
              mdi-dots-vertical
            </v-icon>
          </template>

          <v-list>
            <v-list-item
              @click="
                hide = false;
                delete_dialog = true;"
            >
              <v-list-item-title>Delete Chat</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="hide = true;
                delete_dialog = true;
              "
            >
              <v-list-item-title
                >{{ hidden ? "Un-" : "" }}Hide Chat</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-icon>mdi-delete-circle</v-icon> -->
      </v-list-item-action>
    </v-list-item>
    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>{{
          hide ? (hidden ? "Un-hide" : "Hide") : "Delete"
        }}</v-card-title>
        <v-card-text
          >Are you sure you want to
          {{ hide ? (hidden ? "un-hide" : "hide") : "delete" }} this
          conversation?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="delete_dialog = false">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="
              hide
                ? hidden
                  ? unHideConversation()
                  : hideConversation()
                : deleteConversation()
            "
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["participant", "showBadge", "hidden",'lm'],
  computed: {
      message(){
          console.log('PARTICIPANT UPDATED IN SIDEBAR USER',this.participant.lastMessage);
          return this.participant.lastMessage
      },
    showCount() {
      return this.showBadge && this.unreadCount > 0;
    },
    unreadCount() {
      return (
        (this.participant.messages?.length??0) -
        1 -
        (this.participant.readIndex)
      );
    },
  },
  data: () => ({
      lastMessage:"",
    hide: false,
    delete_dialog: false,
    side_drop: [{ title: "Open" }, { title: "Profile" }, { title: "Delete" }],
  }),

  created(){

      this.lastMessage=this.lm
    //    if(this.participant.channel)
    //         this.participant.channel.on("messageAdded", async (message) => {
    //             if(message.author === this.participant.email)
    //             this.unHideConversation();
    //         });
  },

  methods: {
    hideConversation() {
        this.$emit("chatHidden", this.participant);
        this.delete_dialog=false;
    },

    unHideConversation() {
        this.delete_dialog = false;
        this.$emit("chatUnhidden", this.participant.email);
    },

    deleteConversation(clearUser = true) {
      this.delete_dialog = false;
      this.unHideConversation();

      console.log("Participant Channel Name===",this.participant.channel);

      this.participant.channel.delete().then((r) => {
        console.log("Channel left");
        if (clearUser) this.$store.dispatch("chat/clearUser", this.participant);
      }).catch(e=>{
           if (clearUser) this.$store.dispatch("chat/clearUser", this.participant);
      });
    },
  },

  watch: {
      lm(val){
          this.lastMessage=val
      }
//   participant: {
//      handler(val){

//      },
//      deep: true
//   }
}
};
</script>

<style scoped>
</style>
