var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Status")]),_vm._v(" "),_c('v-btn',{attrs:{"dark":"","color":_vm.statusColors(_vm.app),"x-small":"","depressed":""}},[_vm._v(_vm._s(_vm.app.AppointmentStatus))])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Service Location")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v(_vm._s(_vm.app.ServiceLocationName))])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Name")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v(_vm._s(_vm.app.PatientFullName))])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Date Of Birth")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v("\n        "+_vm._s(_vm.moment
            .tz(
              _vm.app.patient ? _vm.app.patient.DateOfBirth : "01/01/1970",
              _vm.user.timezone ? _vm.default_timezone : _vm.default_timezone
            )
            .format("MM/DD/YYYY"))+"\n      ")])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Appointment Date")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v("\n        "+_vm._s(_vm.moment
            .tz(
              _vm.app.StartTime,
              _vm.user.timezone ? _vm.user.timezone : _vm.default_timezone
            )
            .format("MM/DD/YYYY"))+"\n      ")])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Appointment Time")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v("\n\n\n        "+_vm._s(_vm.moment
            .tz(
              _vm.app.StartTime,
              _vm.user.timezone ? _vm.user.timezone : _vm.default_timezone
            )
            .format("h:mm a"))+"\n      ")])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Reason")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v(_vm._s(_vm.app.AppointmentReason1))])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Provider Name")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v(_vm._s(_vm.app.provider.name))])]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('p',{staticClass:"en-p2"},[_vm._v("Appointment ID")]),_vm._v(" "),_c('p',{staticClass:"en-appt-info-val"},[_vm._v(_vm._s(_vm.app.A_ID))])])],1),_vm._v(" "),(_vm.user.account_type == 1 && _vm.user.callLogging == 1)?_c('p',{staticClass:"mt-3"},[_c('b',[_vm._v("Total Duration:")]),_vm._v(_vm._s(_vm.duration)+" seconds\n  ")]):_vm._e(),_vm._v(" "),(_vm.user.account_type == 1)?_c('v-expansion-panels',{staticClass:"mt-4"},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Call Log")]),_vm._v(" "),_c('v-expansion-panel-content',[_vm._l((_vm.callLogs),function(log,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex"},[_c('v-icon',{attrs:{"size":"20","color":"success"}},[_vm._v("mdi-phone-check")]),_vm._v(" "),_c('h5',{staticClass:"mb-0 ml-2"},[_vm._v(_vm._s(log.log))])],1),_vm._v(" "),_c('p',{staticClass:"calls-logs-value"},[_vm._v("\n            "+_vm._s(_vm.moment
                .tz(
                  log.create_at,
                  _vm.user.timezone ? _vm.user.timezone : _vm.default_timezone
                )
                .format("h:mm a"))+"\n          ")])])}),_vm._v(" "),(_vm.callLogs == 0)?_c('div',{staticClass:"text-center py-3"},[_c('span',[_vm._v("No Call logs yet")])]):_vm._e()],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }