<template>
  <div>
    <v-data-table
      :headers="headers"
      :options.sync="options"
      :server-items-length="totalItems"
      :items="appointments"
      height="500px"
      fixed-header
      :loading="loading"
      class="elevation-0 en-table-card"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>More Appointments</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-menu
            v-model="popover"
            :close-on-content-click="false"
            :nudge-width="400"
            offset-x
          >
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="elevation-1"
                fab
                light
                small
                color="white"
              >
                <v-icon color="dark">mdi-view-column</v-icon>
              </v-btn>
            </template>

            <v-card class="pt-1 px-3">
              <v-checkbox
                v-model="selected"
                dense
                hide-details
                label="Check All"
                @click.native.stop="selectAll()"
              />
              <v-divider class="mt-3 mb-0"></v-divider>
              <v-row>
                <v-col md="6" cols="12">
                  <v-checkbox
                    v-for="(head, i) in allHeaders.filter((r) => !r.hide)"
                    :key="head.value"
                    dense
                    v-if="i % 2 === 0"
                    v-model="head.selected"
                    hide-details
                    :label="head.text.length === 0 ? 'Actions' : head.text"
                    class="checkbox"
                  />
                </v-col>

                <v-col md="6" cols="12">
                  <v-checkbox
                    v-for="(head, i) in allHeaders.filter((r) => !r.hide)"
                    :key="head.value"
                    dense
                    v-if="i % 2 !== 0"
                    v-model="head.selected"
                    hide-details
                    :label="head.text.length === 0 ? 'Actions' : head.text"
                    class="checkbox"
                  />
                </v-col>
              </v-row>
              <v-card-actions>
                <v-btn
                  block
                  color="primary"
                  depressed
                  class="mt-4"
                  style="width: 100px"
                  @click="setHeaders()"
                  >Done</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar>
      </template>

      <template v-slot:item.StartDate="{ item }">
        <p class="mb-0">
          {{
            moment
              .tz(
                item.StartTime,
                user.timezone ? user.timezone : default_timezon
              )
              .format("MM/DD/YYYY")
          }}
        </p>
      </template>

      <template v-slot:item.StartTime="{ item }">
        <p class="mb-0">
          {{
            moment
              .tz(
                item.StartTime,
                user.timezone ? user.timezone : default_timezon
              )
              .format("h:mm a")
          }}
        </p>
      </template>

      <template v-slot:item.log="{ item }">
        <v-icon @click="openInfo(item)" color="grey">mdi-information</v-icon>
      </template>

      <template v-slot:item.AppointmentStatus="{ item }">
        <v-btn x-small dark depressed :color="statusColors(item)">{{
          item.AppointmentStatus
        }}</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="info_dialog" scrollable max-width="750px">
      <v-card>
        <v-card-title>Appointment Info</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <Logs :app="ap" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" depressed style="width: 100px" @click="close"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
import Logs from "./ApptLogDialog";
export default {
  components: {
    Logs,
  },
  props: ["more_appts", "moreDate"],
  data: () => ({
    options: {},
    loading: false,
    default_timezon: "America/Chicago",
    appointments: [],
    current_user: "",
    ap: {},
    statuses: [
      "Unknown",
      "Scheduled",
      "ReminderSent",
      "Confirmed",
      "CheckedIn",
      "Roomed",
      "CheckedOut",
      "NeedsReschedule",
      "ReadyToBeSeen",
      "NoShow",
      "Cancelled",
      "Rescheduled",
      "Tentative",
    ].sort(),

    appt_date: [],
    loading: false,
    appt_time_modal: false,
    totalItems: 0,
    appt_time: null,
    moment: moment,
    search: "",
    info_dialog: false,
    popover: false,

    selected: false,
    checkbox: {},
    headers: [],
    allHeaders: [
      {
        text: "Log",
        value: "log",
        align: "start",
        width: "70px",
        selected: true,
        sortable: false,
      },
      {
        text: "ID",
        value: "A_ID",
        width: "100px",
        sortable: false,
        selected: true,
        hide: true,
      },
      {
        text: "Status",
        value: "AppointmentStatus",
        width: "100px",
        sortable: false,
        selected: true,
        hide: true,
      },
      {
        text: "Provider Name",
        align: "start",
        value: "provider.name",
        width: "150px",
        selected: true,
      },

      {
        text: "Service Location",
        value: "ServiceLocationName",
        width: "250px",
        selected: true,
      },

      {
        text: "Patient Name",
        align: "start",
        value: "PatientFullName",
        width: "150px",
        selected: true,
      },

      {
        text: "DOB",
        value: "dob",
        width: "150px",
        selected: false,
      },

      {
        text: "Appt. Date",
        value: "StartDate",
        width: "150px",
        selected: true,
      },
      {
        text: "Appt. Time",
        value: "StartTime",
        width: "150px",
        selected: true,
      },

      {
        text: "Reason",
        value: "AppointmentReason1",
        width: "200px",
        selected: true,
      },
    ],
  }),

  computed: {
    ...mapState({
      opts: (state) => state.appointments.options,
      //   appointments: (state) => state.appointments.appointments,
      user: (state) => state.currentUser.user,
      getHeaderData: (state) => state.appointments.getHeaderData,
    }),
  },
  watch: {
    options: {
      handler() {
        this.getAppts(this.options);
      },
      deep: true,
    },
  },
  created() {
    this.options = { ...this.opts };
    this.options.filters.forEach((element) => {
      console.log(element);
      if (element.column == "StartDate") element.search = this.moreDate;
    });
    var data = localStorage.getItem("en_user");
    this.current_user = JSON.parse(data);
    var objs = {
      user_id: this.current_user.id,
      type: "appointment",
    };
    this.$store.dispatch("appointments/getUsersPreferencesData", objs);
    this.initialize();
    this.headers = this.allHeaders.filter((s) => s.selected);
  },

  methods: {
    checkDay(fDate) {
      let timezone = this.user.timezone
        ? this.user.timezone
        : this.default_timezon;
      const date = new Date(
        moment.tz(new Date(fDate), timezone).format("YYYY-MM-DD hh:mm")
      );
      //   const date = new Date();
      const moreDate = new Date(this.moreDate);
      const result =
        date.getFullYear() === moreDate.getFullYear() &&
        date.getMonth() === moreDate.getMonth() &&
        date.getDate() === moreDate.getDate();
      console.log(
        "Date = " + date + "More Date = " + moreDate + "Result = " + result
      );
      return result;
    },

    openInfo(item) {
      this.ap = item;
      this.info_dialog = true;
    },

    initialize() {
      //   this.refresh();
      //   this.appointments = this.more_appts.filter((r) =>
      //     this.checkDay(r.StartTime)
      //   );
      this.getAppts(this.options);
    },

    async getAppts(options) {


      const formData = new FormData();
      var dap = false;
      if (options.dashboard_appointments != undefined) {
        dap = options.dashboard_appointments;
      }

      formData.append("dashboard_appointments", dap);
      formData.append("calType", "day");
      formData.append("viewType", 1);

      const item = {
        url: "/api/get_appointments",
        body: formData,
        options: options,
      };
      this.loading = true;
      let res = await this.$store.dispatch("global/post", item, { root: true });
      this.loading = false;

      this.appointments = res.data.data;
      this.totalItems = res.data.total;

      return res;
    },

    refresh() {
      //   this.$store
      //     .dispatch("appointments/getAppointments", this.options)
      //     .then((response) => {
      //       this.totalItems = response.data.total;
      //     });
    },

    // Status Colors
    statusColors(item) {
      switch (item.AppointmentStatus) {
        case "Confirmed":
          return "success";
          break;
        case "CheckedIn":
          return "#3E63A3";
          break;
        case "Scheduled":
          return "orange";
          break;
        case "NoShow":
          return "grey";
          break;
        case "Cancelled":
          return "red";
          break;
        case "Rescheduled":
          return "secondary";
          break;
        case "CheckedOut":
          return "brown";
          break;
        case "ReminderSent":
          return "dark";
          break;
        case "Unknown":
          return "#933F64";
          break;
        case "Roomed":
          return "#234E59";
          break;
        case "NeedsReschedule":
          return "secondary";
          break;
        case "ReadyToBeSeen":
          return "#E07C7D";
          break;
        case "Tentative":
          return "#C7530E";
          break;

        default:
      }
    },

    setHeaders() {
      this.headers = this.allHeaders.filter((s) => s.selected);
      this.popover = false;
      var obj = {
        json_data: JSON.stringify(this.headers),
        user_id: this.current_user.id,
        type: "appointment",
      };
      this.$store.dispatch("appointments/usersPreferencesData", obj);
    },

    selectAll() {
      for (const i in this.allHeaders) {
        this.allHeaders[i].selected = this.selected;
      }
    },

    close() {
      this.info_dialog = false;
    },
  },
};
</script>
