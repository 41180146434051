<template>
  <div>
    <v-container>
      <v-data-table
        :headers="headers"
        :items="logstabledata"
        class="log-table-card"
        :options.sync="options"
        :server-items-length="totalServerItems"
        :footer-props="footerProps"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>All Logs</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="initialize()" class="elevation-1" fab small>
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.date="{ item }">
          <v-chip color="primary" label small>
            {{ item.date | moment("MM/DD/YYYY") }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="item.filename.indexOf('T') > -1 ? false : true"
            @click="openLogInfo(item.filename)"
            color="darker"
            small
            class="mr-2"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            color="primary"
            class="mr-2"
            @click="downloadFile(item.filename)"
          >
            mdi-download
          </v-icon>
          <v-icon color="red" small @click="openDeleteDialog(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog v-model="dialogDelete" max-width="350">
      <v-card>
        <v-card-title> Delete </v-card-title>

        <v-card-text> Are you Sure? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> Cancel </v-btn>

          <v-btn
            color="primary"
            depressed
            min-width="120"
            @click="DeleteFile()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <br /><br />
  </div>
</template>

<script>
import { mapState } from "vuex";
import LogInfoVue from "./LogInfo.vue";
export default {
  data: () => ({
    dialogDelete: false,
    headers: [
      { text: "Date", value: "date" },
      { text: "All", value: "LogDetails.all.entries" },
      { text: "Emergency", value: "LogDetails.emergency.entries" },
      { text: "Alert", value: "LogDetails.alert.entries" },
      { text: "Critical", value: "LogDetails.critical.entries" },
      { text: "Error", value: "LogDetails.error.entries" },
      { text: "Notice", value: "LogDetails.notice.entries" },
      { text: "Info", value: "LogDetails.info.entries" },
      { text: "Debug", value: "LogDetails.debug.entries" },
      { text: "FileSize", value: "fileSize" },
      { text: "Actions", align: "end", value: "actions", sortable: false },
    ],
    logsList: [],
    filename: "",

    //Pagination Properties
    options: {},
    itemsPerPage: 10, //set by default Item Per Page
    footerProps: { "items-per-page-options": [5, 10, 15, 20, 25] },
    page: 1,
  }),

  computed: {
    ...mapState({
      logstabledata: (state) => state.admin_log_reader.logstabledata,
      totalServerItems: (state) => state.admin_log_reader.totalServerItems,
    }),
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  methods: {
    initialize() {
      this.$store.dispatch("admin_log_reader/GetAllLogsFiles", {
        options: this.options,
        page: this.options.page,
      });
    },

    downloadFile(filename) {
      this.$store.dispatch("admin_log_reader/downloadLogFile", {
        filename: filename,
      });
    },

    openLogInfo(filename) {
      this.$store
        .dispatch("admin_log_reader/GetLogFileDetail", {
          filename: filename,
        })
        .then((r) => {
          if (r.data.data == 1) {
            // this.$store.dispatch("admin_log_reader/GetAllLogsFiles", {});
          } else {
            localStorage.setItem("filename", filename);
            localStorage.setItem("level", "all");
            this.$router.push({ name: "logInfo" });
          }
        });
    },

    openDeleteDialog(item) {
      this.filename = item.filename;
      this.dialogDelete = true;
      localStorage.setItem("index", this.logstabledata.indexOf(item));
    },

    DeleteFile() {
      this.$store
        .dispatch("admin_log_reader/DeleteLogFile", {
          filename: this.filename,
        })
        .then((r) => {
          let index = localStorage.getItem("index");
          this.logstabledata.splice(index, 1);
          localStorage.removeItem("index");
        });
      this.close();
    },

    close() {
      this.index = 0;
      this.filename = "";
      this.dialogDelete = false;
    },
  },
};
</script>
