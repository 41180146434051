<template>
  <div>
    <v-container>
      <v-card-actions class="py-0 mb-1">
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="patients"
        :options.sync="options"
        :server-items-length="totalItems"
        :search="search"
        style="border: none !important"
        class="elevation-0 en-table-card"
        :items-per-page="30"
        :footer-props="{
          'items-per-page-options': [10, 30, 50],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Patients</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-btn
              class="elevation-1"
              @click="refresh"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
          </v-toolbar>
          <div v-if="filters.length > 0" class="mx-4">
            <h6>Applied Filter{{ filters.length > 1 ? "s" : "" }}</h6>
            <div class="d-flex flex-wrap">
              <div v-for="(item, i) in filters" :key="i" class="filter-box">
                <v-chip
                  close
                  small
                  color="#c6c6c6"
                  @click:close="removeFilter(i)"
                  >{{ item.column }}</v-chip
                >
                <p class="filter-val">{{ item.search }}</p>
              </div>
            </div>
          </div>
          <v-row class="px-4 mr-0">
            <!-- SEARCH BY -->
            <v-col cols="12" :md="search_by == null ? '10' : '3'">
              <v-select
                hide-details
                v-model="search_by"
                @change="searchChange"
                :items="['Date of birth', 'Name']"
                outlined
                dense
                placeholder="Search by"
              ></v-select>
            </v-col>

            <!-- SEARCH COMPONENTS -->
            <v-col v-if="search_by !== null" cols="12" md="7">
              <v-text-field
                dense
                v-if="search_by == 'Name'"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                outlined
                hide-details
              ></v-text-field>

              <v-dialog
                ref="dialog1"
                v-if="search_by == 'Date of birth'"
                v-model="appt_modal"
                :return-value.sync="search"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="appt_date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker color="primary" v-model="appt_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="appt_modal = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(appt_date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-dialog>

              <v-dialog
                ref="dialog2"
                v-if="search_by == 'Appt. Time'"
                v-model="appt_time_modal"
                :return-value.sync="appt_time"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="appt_time"
                    prepend-icon="mdi-clock"
                    readonly
                    dense
                    outlined
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  color="primary"
                  v-if="appt_time_modal"
                  v-model="appt_time"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="appt_time_modal = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog2.save(appt_time)"
                    >OK</v-btn
                  >
                </v-time-picker>
              </v-dialog>

              <v-select
                v-if="search_by == 'Status'"
                hide-details
                :items="statuses"
                v-model="search"
                outlined
                dense
                placeholder="Status"
              ></v-select>
            </v-col>
            <v-col cols="6" md="1" sm="6">
              <v-btn block depressed color="primary" @click="addFilters"
                >ADD</v-btn
              >
            </v-col>
            <v-col cols="6" md="1" sm="6">
              <v-btn block depressed @click="clearFilters">clear</v-btn>
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.FullName="{ item }">
          <div class="d-flex align-center">
            <v-sheet
              class="mr-3 indicator-desk"
              color="red"
              elevation="0"
              height="47"
              width="5"
            ></v-sheet
            >{{ item.FullName }} ({{
              moment
                .tz(item.DOB, user.timezone ? user.timezone : default_timezone)
                .format("MM/DD/YYYY")
            }})

            <v-icon color="red" size="20" class="indicator-mobile ml-3"
              >mdi-checkbox-blank-circle</v-icon
            >
          </div></template
        >

        <template v-slot:item.actions="{ item }">
          <v-icon @click="openInfo(item)" color="grey" class="ml-2"
            >mdi-information</v-icon
          >
        </template>

        <template v-slot:item.status>
          <v-btn x-small depressed color="success">Active</v-btn>
        </template>

        <!-- <template v-slot:item.StartDate="{ item }">

          <p class="mb-0">
            {{
              getDate(item)
            }}
          </p>
        </template> -->
        
        <template v-slot:item.StartDate="{ item }">
          <p v-if="item.latest_appointment" class="mb-0">
            {{
              moment
                .tz(
                  item.latest_appointment.StartTime,
                  user.timezone ? user.timezone : default_timezon
                )
                .format("MM/DD/YYYY")
            }}
          </p>
        </template>

        <template v-slot:item.StartTime="{ item }">
          <p v-if="item.latest_appointment" class="mb-0">
            {{
              moment
                .tz(
                  item.latest_appointment.StartTime,
                  user.timezone ? user.timezone : default_timezon
                )
                .format("h:mm a")
            }}
          </p>
        </template>

        <!-- <template v-slot:item.StartTime="{ item }">
          <p class="mb-0">
            {{
              getDate(item,'h:mm a')
            }}
          </p>
        </template> -->

        <template v-slot:item.AppointmentStatus="{ item }">
          <v-btn v-if="item.latest_appointment" x-small dark depressed :color="statusColors(item)">{{
            item.latest_appointment.AppointmentStatus
          }}</v-btn>
        </template>

        <!-- <template v-slot:item.AppointmentStatus="{ item }">
          <v-btn x-small dark depressed :color="statusColors(getStatus(item))">{{
            getStatus(item)
          }}</v-btn>
        </template> -->
      </v-data-table>
    </v-container>

    <v-dialog v-model="info_dialog" scrollable max-width="600px">
      <v-card v-if="info_dialog">
        <v-card-title>Patient Info</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <p class="en-p2">Name</p>
              <p class="en-appt-info-val">{{ dp.FullName }}</p>
            </v-col>

            <v-col cols="12" md="12">
              <p class="en-p2">Appointment Date</p>
              <!-- <p class="en-appt-info-val">
                {{


                       getDate(dp),

                }}
              </p> -->
              <p v-if="dp.latest_appointment" class="mb-0">
                {{
                  moment
                    .tz(
                      dp.latest_appointment.StartTime,
                      user.timezone ? user.timezone : default_timezon
                    )
                    .format("MM/DD/YYYY")
                }}
              </p>
              <p v-else>N/A</p>
            </v-col>

            <v-col cols="12" md="12">
              <p class="en-p2">Appointment Time</p>
              <!-- <p class="en-appt-info-val">
                {{

                      getDate(dp,"h:mm a"),

                }}
              </p> -->
              <p v-if="dp.latest_appointment" class="mb-0">
                {{
                  moment
                    .tz(
                      dp.latest_appointment.StartTime,
                      user.timezone ? user.timezone : default_timezon
                    )
                    .format("h:mm a")
                }}
              </p>
              <p v-else>N/A</p>
            </v-col>

            <v-col cols="12" md="12">
              <p class="en-p2">Status</p>
                <v-btn v-if="dp.latest_appointment" x-small dark depressed :color="statusColors(dp)">{{
                  dp.latest_appointment.AppointmentStatus
                }}</v-btn>
              <p v-else>N/A</p>
              <!-- <v-btn
                x-small
                dark
                depressed
                :color="statusColors(getStatus(dp))"
                >{{ getStatus(dp) }}</v-btn
              > -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" depressed style="width: 100px" @click="close"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";

export default {
  data: () => ({
    patients: [],
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Patients",
        disabled: true,
        to: "",
      },
    ],
    dialog: false,
    moment: moment,
    default_timezon: "America/Chicago",

    filters: [],
    info_dialog: false,
    dp: {},
    timer: null,
    search_by: "Name",
    column: "FullName",
    appt_modal: false,
    statuses: [
      "Unknown",
      "Scheduled",
      "ReminderSent",
      "Confirmed",
      "CheckedIn",
      "Roomed",
      "CheckedOut",
      "NeedsReschedule",
      "ReadyToBeSeen",
      "NoShow",
      "Cancelled",
      "Rescheduled",
      "Tentative",
    ].sort(),
    options: {},
    appt_date: null,
    totalItems: 0,
    appt_time_modal: false,
    appt_time: null,
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "FullName",
      },
      { text: "Appt. Date", value: "StartDate" ,sortable: false},
      { text: "Appt. Time", value: "StartTime",sortable: false },
      { text: "", value: "AppointmentStatus", sortable: false },
      { text: "", align: "end", sortable: false, value: "actions" },
    ],
    desserts: [],
  }),

  watch: {
    options: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },

  created() {
    this.$store.dispatch("themes/getAllGeneralSetting");
    this.default_timezon = this.settings.default_timezon;
    // this.initialize();
  },

  computed: {
    ...mapState({
      appointments: (state) => state.appointments.appointments,
      user: (state) => state.currentUser.user,
      settings: (state) => state.themes.get_general_setting,
    }),
  },
  methods: {
    getStatus(item) {
      if (item.appointment.length) {
        const appts = item.appointment.filter(
          (a) =>  new Date(a.StartTime).getTime() > new Date().getTime()||true
        );
        appts.sort(
          (a, b) =>
            new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime()
        );
        if(appts.length)
        return appts[0].AppointmentStatus;
      }
    },

    getDate(item,format='MM/DD/YYYY') {
      let result;
        // console.log("IN GET DATE DP = ",item);
      if (item.appointment.length) {
        const appts = item.appointment.filter(
          (a) => new Date(a.StartTime).getTime() > new Date().getTime()|| true
        );
        appts.sort(
          (a, b) =>
            new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime()
        );
        if (appts.length) result= appts[0].StartTime;
      }
      // console.log("RETURNING FROM P_TABLE",item.LastAppointmentDate);
      if(!result) item.LastAppointmentDate;
      const date = this.moment
                .tz(
                  result,
                  this.user.timezone ? this.user.timezone : default_timezon
                );
                return date.isValid()?date.format(format):'NA'
    },

    removeFilter(i) {
      this.filters.splice(i, 1);
    },

    searchChange(item) {
      this.search = "";
      switch (item) {
        case "Status": {
          this.column = "AppointmentStatus";
          break;
        }
        case "Service Location": {
          this.column = "ServiceLocationName";
          break;
        }
        case "Name": {
          this.column = "FullName";
          break;
        }
        case "Date of birth": {
          this.column = "DOB";
          break;
        }
        case "Appt. Time": {
          this.column = "StartTime";
          break;
        }
        case "Reason": {
          this.column = "AppointmentReason1";
          break;
        }
      }
      this.filters.forEach((e) => {
        if (e.column === this.column) {
          this.search = e.search;
        }
      });
    },

    clearFilters() {
      (this.search = ""), (this.search_by = null);
      this.filters = [];
      this.refresh();
    },

    addFilters() {
      let included = false;
      this.filters.forEach((e) => {
        if (e.column === this.column) {
          e.search = this.search;
          included = true;
        }
      });
      if (!included)
        this.filters.push({
          column: this.column,
          search: this.search,
        });
      this.refresh();
    },

    initialize() {
      this.refresh();
    },

    refresh() {
      this.filters.forEach((e) => {
        if (e.search && e.search.text) e.search = e.search.text;
      });
      this.options.filters = this.filters;
      this.$store
        .dispatch("appointments/getPatients", this.options)
        .then((response) => {
          this.totalItems = response.data.total;
          this.patients = response.data.data;
        });
    },

    openInfo(item) {
      this.dp = (item);
      this.info_dialog = true;
    },

    // Status Colors
    statusColors(item) {
      switch (item.latest_appointment.AppointmentStatus) {
        case "Confirmed":
          return "success";
          break;
        case "CheckedIn":
          return "#3E63A3";
          break;
        case "Scheduled":
          return "orange";
          break;
        case "NoShow":
          return "grey";
          break;
        case "Cancelled":
          return "red";
          break;
        case "Rescheduled":
          return "secondary";
          break;
        case "CheckedOut":
          return "brown";
          break;
        case "ReminderSent":
          return "dark";
          break;
        case "Unknown":
          return "#933F64";
          break;
        case "Roomed":
          return "#234E59";
          break;
        case "NeedsReschedule":
          return "secondary";
          break;
        case "ReadyToBeSeen":
          return "#E07C7D";
          break;
        case "Tentative":
          return "#C7530E";
          break;
        default:
          return 'transparent'
      }
    },

    close() {
      this.info_dialog = false;
    },
  },
};
</script>

<style scoped>
>>> .v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0px 10px;
  background: #f1f3f6;
}

>>> .v-data-table > .v-data-table__wrapper > table > thead {
  background: white;
}

>>> .v-data-table__wrapper > table > tbody > tr > td {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0px 10px 0px 0px !important;
}

>>> .v-data-table__wrapper > table > tbody > tr {
  background: white;
}

.indicator-mobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .indicator-desk {
    display: none;
  }

  >>> .v-data-table__wrapper > table > tbody > tr > td {
    border-top: none;
    background-color: white !important;
  }

  >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 16px !important;
  }

  .indicator-mobile {
    display: block;
  }
}
</style>
