<template>
  <div>
    <v-row>
      <!-- {{app}} -->
      <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Status</p>
        <v-btn dark :color="statusColors(app)" x-small depressed>{{
          app.AppointmentStatus
        }}</v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Service Location</p>
        <p class="en-appt-info-val">{{ app.ServiceLocationName }}</p>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Name</p>
        <p class="en-appt-info-val">{{ app.PatientFullName }}</p>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Date Of Birth</p>
        <p class="en-appt-info-val">
          {{
            moment
              .tz(
                app.patient ? app.patient.DateOfBirth : "01/01/1970",
                user.timezone ? default_timezone : default_timezone
              )
              .format("MM/DD/YYYY")
          }}
        </p>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Appointment Date</p>
        <p class="en-appt-info-val">
          {{
            moment
              .tz(
                app.StartTime,
                user.timezone ? user.timezone : default_timezone
              )
              .format("MM/DD/YYYY")
          }}
        </p>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Appointment Time</p>
        <p class="en-appt-info-val">


          {{
            moment
              .tz(
                app.StartTime,
                user.timezone ? user.timezone : default_timezone
              )
              .format("h:mm a")
          }}
        </p>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Reason</p>
        <p class="en-appt-info-val">{{ app.AppointmentReason1 }}</p>
      </v-col>
        <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Provider Name</p>
        <p class="en-appt-info-val">{{ app.provider.name }}</p>
      </v-col>

       <v-col cols="12" md="4" sm="6">
        <p class="en-p2">Appointment ID</p>
        <p class="en-appt-info-val">{{ app.A_ID }}</p>
      </v-col>
    </v-row>

    <p v-if="user.account_type == 1 && user.callLogging == 1" class="mt-3">
      <b>Total Duration:</b>{{ duration }} seconds
    </p>
    <v-expansion-panels v-if="user.account_type == 1" class="mt-4">
      <v-expansion-panel>
        <v-expansion-panel-header>Call Log</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-for="(log, i) in callLogs" :key="i">
            <div class="d-flex">
              <v-icon size="20" color="success">mdi-phone-check</v-icon>
              <h5 class="mb-0 ml-2">{{ log.log }}</h5>
            </div>
            <p class="calls-logs-value">
              {{
                moment
                  .tz(
                    log.create_at,
                    user.timezone ? user.timezone : default_timezone
                  )
                  .format("h:mm a")
              }}
            </p>
          </div>

          <div class="text-center py-3" v-if="callLogs == 0">
            <span>No Call logs yet</span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>


<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
export default {
  props: ["app"],
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      settings: (state) => state.themes.get_general_setting,
    }),
  },
  created() {
    this.default_timezone = this.settings.default_timezone;
    const data = new FormData();
    data.append("id", this.app.A_ID);
    this.$store
      .dispatch("global/post", {
        url: "/api/get_appointment_logs",
        body: data,
      })
      .then((r) => {
        this.callLogs = r.data.data;
        this.duration = r.data.duration.data;
      });
  },
  data: () => ({
    moment: moment,
    duration: 0,
    default_timezone: "America/Chicago",
    callLogs: [
      {
        icon: "mdi-phone-check",
        color: "success",
        title: "Call Started at",
        value: "9:01 PM",
      },
    ],
  }),

  methods: {
    // Status Colors
    statusColors(app) {
      switch (app.AppointmentStatus) {
        case "Confirmed":
          return "success";
          break;
        case "CheckedIn":
          return "dark";
          break;
        case "Scheduled":
          return "orange";
          break;
        case "NoShow":
          return "grey";
          break;
        case "Cancelled":
          return "red";
          break;
        case "Rescheduled":
          return "secondary";
          break;
        case "CheckedOut":
          return "brown";
          break;
        default:
      }
    },
  },
};
</script>
