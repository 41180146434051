var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 en-table-card",attrs:{"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.totalItems,"items":_vm.appointments,"height":"500px","fixed-header":"","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("More Appointments")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":400,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-1",attrs:{"fab":"","light":"","small":"","color":"white"}},on),[_c('v-icon',{attrs:{"color":"dark"}},[_vm._v("mdi-view-column")])],1)]}}]),model:{value:(_vm.popover),callback:function ($$v) {_vm.popover=$$v},expression:"popover"}},[_vm._v(" "),_c('v-card',{staticClass:"pt-1 px-3"},[_c('v-checkbox',{attrs:{"dense":"","hide-details":"","label":"Check All"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.selectAll()}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_vm._v(" "),_c('v-divider',{staticClass:"mt-3 mb-0"}),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},_vm._l((_vm.allHeaders.filter(function (r) { return !r.hide; })),function(head,i){return (i % 2 === 0)?_c('v-checkbox',{key:head.value,staticClass:"checkbox",attrs:{"dense":"","hide-details":"","label":head.text.length === 0 ? 'Actions' : head.text},model:{value:(head.selected),callback:function ($$v) {_vm.$set(head, "selected", $$v)},expression:"head.selected"}}):_vm._e()}),1),_vm._v(" "),_c('v-col',{attrs:{"md":"6","cols":"12"}},_vm._l((_vm.allHeaders.filter(function (r) { return !r.hide; })),function(head,i){return (i % 2 !== 0)?_c('v-checkbox',{key:head.value,staticClass:"checkbox",attrs:{"dense":"","hide-details":"","label":head.text.length === 0 ? 'Actions' : head.text},model:{value:(head.selected),callback:function ($$v) {_vm.$set(head, "selected", $$v)},expression:"head.selected"}}):_vm._e()}),1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-4",staticStyle:{"width":"100px"},attrs:{"block":"","color":"primary","depressed":""},on:{"click":function($event){return _vm.setHeaders()}}},[_vm._v("Done")])],1)],1)],1)],1)]},proxy:true},{key:"item.StartDate",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n        "+_vm._s(_vm.moment
            .tz(
              item.StartTime,
              _vm.user.timezone ? _vm.user.timezone : _vm.default_timezon
            )
            .format("MM/DD/YYYY"))+"\n      ")])]}},{key:"item.StartTime",fn:function(ref){
            var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n        "+_vm._s(_vm.moment
            .tz(
              item.StartTime,
              _vm.user.timezone ? _vm.user.timezone : _vm.default_timezon
            )
            .format("h:mm a"))+"\n      ")])]}},{key:"item.log",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":"grey"},on:{"click":function($event){return _vm.openInfo(item)}}},[_vm._v("mdi-information")])]}},{key:"item.AppointmentStatus",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","dark":"","depressed":"","color":_vm.statusColors(item)}},[_vm._v(_vm._s(item.AppointmentStatus))])]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"scrollable":"","max-width":"750px"},model:{value:(_vm.info_dialog),callback:function ($$v) {_vm.info_dialog=$$v},expression:"info_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Appointment Info")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-text',[_c('Logs',{attrs:{"app":_vm.ap}})],1),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"primary","depressed":""},on:{"click":_vm.close}},[_vm._v("Done")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }