<template>
  <div>
    <div class="notes-file-widget">
      <div class="d-flex align-center">
        <v-icon color="green">mdi-file</v-icon>
        <p>{{ file.name }}</p>
        <v-icon v-if="file.uuid" color="primary" class="mr-2"
        @click="$emit('download',file.uuid)"
          >mdi-download</v-icon
        >

        <v-icon @click="deleteDialogOpen()" color="red">mdi-delete</v-icon>
      </div>
    </div>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="deleteAt"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: ["file"],
  data: () => ({
    delete_dialog: false,
  }),

  methods: {
    deleteAt() {
      this.$emit("delete");
      this.close();
    },
    deleteDialogOpen() {
      this.delete_dialog = true;
    },

    close() {
      this.delete_dialog = false;
    },
  },
};
</script>
