<template>
  <div>
    <!-- <div v-if="loading">
      <v-progress-linear indeterminate />
    </div> -->

    <div v-if="!loading">
      <beautiful-chat
        :participants="participants"
        :titleImageUrl="titleImageUrl"
        :messageList="messages"
        :onMessageWasSent="sendMessage"
        :new-messages-count="newMessagesCount"
        :isOpen="isChatOpen"
        :close="closeChat"
        :icons="icons"
        :open="openChat"
        :showEmoji="false"
        :showFile="true"
        :show-edition="false"
        :show-deletion="true"
        :show-launcher="true"
        :showTypingIndicator="showTypingIndicator"
        :showCloseButton="true"
        :colors="colors"
        :alwaysScrollToBottom="alwaysScrollToBottom"
        :messageStyling="messageStyling"
        @onType="handleOnType"
        @edit="editMessage"
        ref="test1"
      >
        <template v-slot:header>
          <div class="d-flex">
            <p class="sc-header--title mb-0">{{ participants[0].name }}</p>
          </div>

          <v-btn
            @click="openDeleteChatDialog"
            class="mr-3 mt-1"
            color="dark"
            :small="$vuetify.breakpoint.smAndUp"
            :x-small="$vuetify.breakpoint.mobile"
            dark
            fab
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </beautiful-chat>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            @click="toggleChatThirdParty()"
            v-bind="attrs"
            v-on="on"
            class="tootip-div"
          ></div>
        </template>
        <span>{{ participants[0].name }}</span>
      </v-tooltip>

      <v-icon @click="close()" class="dismiss-user">mdi-close-circle</v-icon>
    </div>
    <v-btn v-else color="white" fab bottom right fixed class="v-btn--example">
      <v-progress-circular indeterminate />
    </v-btn>

    <v-dialog :value="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title>Are you sure?</v-card-title>
        <v-divider />
        <v-card-text
          >This will delete all the messages and they cannot be recovered
          later.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small dark text color="primary" @click="deleteDialog = false"
            >CANCEL</v-btn
          >
          <v-btn small dark color="red" @click="deleteConversationApi"
            >DELETE</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      :timeout="2000"
      :color="snackbar.color"
      top
      v-model="snackbar.show"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>



<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      openID: (state) => state.chat.openId,
    }),
    isChatOpen() {
      return this.participant.id == this.openID;
    },
    currentUserId() {
      return this.user.id;
    },
  },
  data: () => ({
    tool: true,
    icons: {
      open: {
        img: "https://images.pexels.com/photos/3863793/pexels-photo-3863793.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        name: " ",
      },
      close: {
        img: "https://images.unsplash.com/photo-1608682748497-3333282d9b6b?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80",
        name: " ",
      },
    },
    colors: {},
    participants: [],
    titleImageUrl:
      "https://images.unsplash.com/photo-1554357395-dbdc356ca5da?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1351&q=80",
    channelName: "general",
    channel: null,
    messages: [],
    loading: false,
    messages: [],
    newMessagesCount: 0,
    showTypingIndicator: "",
    alwaysScrollToBottom: true,
    messageStyling: true,
    deleteDialog: false,

    snackbar: {
      show: false,
      message: "",
      color: "",
    },
  }),
  props: ["participant"],

  test: null,

  created() {
    this.getColors();

    const placeHolder = "/assets/plaeholder-user.png";
    if (this.participant.image === "") {
      this.participant.image = placeHolder;
    }
    this.participants.push({
      id: this.participant.email,
      name: this.participant.name,
      imageUrl: this.participant.image ?? placeHolder,
      user_id: this.participant.user_id,
    });
    this.icons.open.img = this.participant.image ?? placeHolder;
    this.icons.close.img = this.participant.image ?? placeHolder;
    this.titleImageUrl = this.participant.image ?? placeHolder;
    this.getMessages(); //all read 1
  },

  mounted() {
    var channel = this.$pusher.subscribe("my-channel");
    var my_id = this.currentUserId;
    const _this = this;
    this.test = _this;
    channel.bind("my-event", function (data) {
      //   console.log("PUSHERRRRRRRRRRRRRRRRRR", data);

      //Trigger Only When Route VideoCall
      if (_this.$route.name == "videoCall") {
        if (my_id == data.to_user) {
          if (_this.participant.user_id == data.from_user) {
            // console.log("chat widget pusher");
            //check if isChatScreen Opened or Closed According to Show UnReadCount

            if (_this.isChatOpen) {
              console.log("chat widget pusher if chat open", _this.isChatOpen);
              _this.getLastMessageApi(data.from_user); //all read 1
            } else {
              console.log("chat widget pusher else");
              axios
                .post("/api/get_unread_count_from_to", {
                  from_user: data.from_user,
                })
                .then((r) => {
                  _this.newMessagesCount = r.data.data;
                });

              //   console.log(
              //     "New Unread Message Count===",
              //     _this.newMessagesCount
              //   );
            }
          }
        }
      }
    });
  },

  watch: {},

  methods: {
    //For Open And Close Chat Bubble UI
    toggleChatThirdParty() {
      if (this.isChatOpen) {
        this.closeChat();
      } else {
        this.openChat();
      }
    },

    //Close Chat Bubble UI via(close icon)
    close() {
      this.$emit("closeChatWidget", {
        participant: this.participant,
      });
    },

    //Get Chat Bubble Participant Related Messages
    async getMessages() {
      //   console.log(
      //     "Step-1 get message call all messages read status=1 ",
      //     this.isChatOpen
      //   );
      if (this.isChatOpen) {
        // console.log("Step-2 if get Messages ");
        const res = await this.$store.dispatch("global/get", {
          url: `/api/messages?user_id=${this.participant.user_id}`,
        });
        //console.log("get participant messages====", res.data.data);
        if (res.data.data.messages) {
          this.messages = res.data.data.messages.map((m) => {
            return this.mapMessage(m);
          });
        }
      }

      //Get Unread Counting for specific(from_user and to_user)
      var r = await axios.post("/api/get_unread_count_from_to", {
        from_user: this.participant.user_id,
      });
      if (r) {
        // console.log(this.test.newMessagesCount);
        this.test.newMessagesCount = r.data.data;
        // console.log("participent unread scount=======", r.data.data);
      }
    },

    //Get Last Message of User(Initialize When Pusher event called)
    async getLastMessageApi(userId) {
      const res = await axios.get(`/api/lastmessage?user_id=${userId}`);
      //   console.log("Response API===============", res);
      const message = res.data.data;
      //   console.log("Last Message API===============", message);
      let lastMsg = await this.mapMessage(message);
      this.messages.push(lastMsg);
    },

    //Map Message Data According to Vue Library Package
    mapMessage(m) {
      if (m.file == null || m.file == "null") {
        return {
          author: m.align == "left" ? "me" : "support",
          type: "text",
          id: m.id,
          isEdited: false,
          data: {
            text: m.content ?? "",
            meta: m.time,
          },
        };
      } else {
        return {
          author: m.align == "left" ? "me" : "support",
          type: "file",
          id: m.id,
          isEdited: false,
          data: {
            meta: m.time,
            file: {
              text: m.content ?? "",
              url: m.file ?? "",
            },
          },
        };
      }
    },

    getColors() {
      this.colors = {
        header: {
          bg: this.$vuetify.theme.themes.light.primary,
          text: "#ffffff",
        },
        launcher: {
          bg: this.$vuetify.theme.themes.light.primary,
        },
        messageList: {
          bg: "#ffffff",
        },
        sentMessage: {
          bg: this.$vuetify.theme.themes.light.primary,
          text: "#ffffff",
        },
        receivedMessage: {
          bg: "#eaeaea",
          text: "#222222",
        },
        userInput: {
          bg: "#f4f7f9",
          text: "#565867",
        },
      };
    },

    //Send Message to Active Bubble Chat User
    async sendMessage(message) {
      //   console.log("==========================", message);
      if (message.type === "text") {
        if (message.data.text.length > 0) {
          this.sendMsgApi(this.participant.user_id, message.data.text);
        }
      }
      if (message.type == "file") {
        if (message.data.file) {
          this.sendMsgApi(
            this.participant.user_id,
            message.data.file.name,
            message.data.file
          );
        }
      }
    },

    //Common Send Message API
    async sendMsgApi(receiverId, message, file = null) {
      const fd = new FormData();
      fd.append("receiver_id", receiverId);
      fd.append("message", message);
      if (file) {
        const fileURL = URL.createObjectURL(file);
        const fileName = file.name;
        fd.append("file", file);
        var msgObj = {
          author: "me",
          type: "file",
          id: 0,
          isEdited: false,
          data: {
            meta: moment().fromNow(),
            file: {
              text: fileName ?? "",
              url: fileURL ?? "",
            },
          },
        };
      } else {
        var msgObj = {
          author: "me",
          type: "text",
          id: 0,
          isEdited: false,
          data: {
            text: message ?? "",
            meta: moment().fromNow(),
          },
        };
      }
      this.messages.push(msgObj);

      const item = {
        url: "/api/message",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
    },

    //Open Chat Bubble
    openChat() {
      // this.newMessagesCount = 0;
      this.$store.dispatch("chat/setOpenId", this.participant.id);
      this.test.newMessagesCount = 0;
      // console.log("Open Chat this.test.newMessagesCount=========",this.test.newMessagesCount);
      // console.log("Open Chat this.newMessagesCount=========",this.newMessagesCount);
      this.getMessages();
    },

    //Close Current Participant Chat Bubble
    closeChat() {
      // this.newMessagesCount = 0;
      this.test.newMessagesCount = 0;
      // console.log("Close Chat this.test.newMessagesCount=========",this.test.newMessagesCount);
      // console.log("Close Chat this.newMessagesCount=========",this.newMessagesCount);
      this.$store.dispatch("chat/setOpenId", 0);
    },

    handleScrollToTop() {},

    handleOnType() {},

    editMessage(message) {
      //   const m = this.messages.find((m) => m.id === message.id);
      //   m.isEdited = true;
      //   m.data.text = message.data.text;
    },

    openDeleteChatDialog() {
      if (this.messages.length == 0) {
        this.snackbar = {
          message: "No Chat Conversation Found.",
          color: "red",
          show: true,
        };
      } else {
        this.deleteDialog = true;
      }
    },

    async deleteConversationApi() {
      const fd = new FormData();
      fd.append("user_id", this.participant.user_id);
      const item = {
        url: "/api/deleteConversation",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        this.deleteDialog = false;
        this.snackbar = {
          message: "Chat Deleted Successfully.",
          color: "primary",
          show: true,
        };
        this.getMessages();
      }
    },
  },
};
</script>

<style scoped>
>>> .sc-launcher {
  z-index: 1000 !important;
  position: relative !important;
  margin-right: 8px !important;
  pointer-events: all;
}

>>> .sc-launcher:before {
  content: none !important;
}

>>> .sc-chat-window {
  z-index: 5000 !important;
  pointer-events: all;
  text-align: start;
}

>>> .sc-message--text-content {
  margin-bottom: 0px !important;
}

>>> .sc-message {
  width: 340px;
}

>>> .sc-message--meta {
  margin-bottom: 0px !important;
}

>>> .sc-launcher .sc-open-icon {
  width: 62px !important;
  height: 62px !important;
  right: 1px !important;
  bottom: 1px !important;
  border-radius: 100px;
  object-fit: cover !important;
  position: relative !important;
  border: 2px solid #bbb;
  padding: 0px !important;
}

>>> .sc-launcher.opened .sc-closed-icon {
  width: 63px !important;
  height: 63px !important;
  right: 1px !important;
  bottom: 1px !important;
  border-radius: 100px;
  object-fit: cover !important;
  position: relative !important;
  border: 3px solid #00cc00;
  transform: none !important;
  padding: 0px !important;
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  >>> .sc-chat-window {
    z-index: 2000 !important;
  }
}

>>> .sc-header--img {
  object-fit: cover;
  height: 60px;
  width: 60px;
}

.dismiss-user {
  position: absolute;
  top: 0;
  margin-top: 48px;
  margin-left: 17px;
  /* bottom: 20px;
  right: 20px; */
  z-index: 1200;
  color: white;
  background: black;
  border-radius: 100px;
  font-size: 16px !important;
  pointer-events: all;
}

.tootip-div {
  position: absolute;
  top: 0;
  height: 60px;
  width: 60px;
  z-index: 1200;
  color: white;
  margin-left: -26px;
  cursor: pointer;
  pointer-events: all;
}

>>> .sc-new-messsages-count {
  z-index: 500 !important;
  padding-top: 2px !important;
}

>>> .sc-message--content.received .sc-message--text {
  word-break: break-all !important;
}
>>> .sc-chat-window > input[type="file"] {
  display: block !important;
}

>>> .sc-user-input--text {
  width: 276px;
}

>>> .sc-user-input--file-icon {
  margin-top: 6px !important;
}
</style>
