<template>
  <v-card style="width: 350px" class="pt-1 px-3">
    <v-row>
      <v-col md="6" cols="6">
        <p class="en-p2 pt-1">NOTIFICATIONS</p>
      </v-col>
      <v-col class="text-end" md="6" cols="6">
        <v-btn small @click="openClearDialog()" depressed>Clear</v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <div class="en-notific-scroller">
      <div v-for="(item, i) in notifications" :key="i" class="en-notific-pop">
        <p class="ml-0 time-text">
          {{
            moment
              .tz(
                item.created_at,
                user.timezone ? user.timezone : settings.default_timezon
              )
              .fromNow()
          }}
        </p>
        <p class="en-notific-name">{{ item.title }}</p>
        <p style="font-size: 12px; color: grey;" class="mb-0">{{ item.body }}</p>
        <v-btn
          :disabled="
            new Date().valueOf() -
              moment
                .tz(
                  item.created_at,
                  user.timezone ? user.timezone : settings.default_timezon
                )
                .valueOf() >=
            10 * 60 * 1000
          "
          v-if="item.type == 1"
          x-small
          @click="joinVideoCall(item.body)"
          color="primary"
          depressed
          >Join now</v-btn
        >
        <p v-else class="en-notific-message">{{ item.body }}</p>
      </div>
      <div
        v-if="(notifications)&&notifications.length == 0"
        class="text-center d-flex justify-center align-center"
        style="height: 100%"
      >
        <p>No Notifications</p>
      </div>
    </div>
    <v-card-actions>
      <v-btn
        block
        color="primary"
        depressed
        class="mt-0"
        @click="resetNotificationCount"
        :to="{ name: 'allNotifications' }"
        >View All</v-btn
      >
    </v-card-actions>

    <v-dialog v-model="clear_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Clear</v-card-title>
        <v-card-text
          >Are you sure you want to clear all notifications?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn color="primary" depressed style="width: 100px" @click="clearNotifications()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Axios from "axios";
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  data: () => ({
    moment: moment,
    clear_dialog: false,
  }),

  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      notifications: (state) => state.notifications.notifications.length>4?state.notifications.notifications.slice(0,4):state.notifications.notifications,
      loading: (state) => state.notifications.loading,
      settings: (state) => state.themes.get_general_setting,
    }),
  },

  created() {
    this.$store.dispatch("themes/getAllGeneralSetting");
    this.$store.dispatch("notifications/getNotifications");
  },

  methods: {
      clearNotifications(){
          const ids=this.notifications.map(r=>r.id).join(',');
        this.$store.dispatch('notifications/deleteNotifications',{
            ids:ids
        }).then(r=>{
            this.resetNotificationCount();
            this.close();
        })
      },
    openClearDialog() {
      this.clear_dialog = true;
    },

    resetNotificationCount() {
      this.$store.dispatch("currentUser/resetNotificationsCount");
      this.$emit("closeMenu")
    },

    joinVideoCall(link) {
      window.open(link, "_blank");
    },
    close() {
      this.$emit("closeMenu");
      this.clear_dialog = false;
    },
  },
};
</script>

<style>
</style>
