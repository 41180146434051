<template>
  <div>
    <v-container>
      <v-toolbar v-if="user ? user.account_type == 1 : ''">
        <h5 v-if="user ? user.account_type == 1 : ''">Attachment</h5>
        <v-spacer></v-spacer>
        <v-icon
          color="primary"
          size="30"
          v-if="user ? user.account_type == 1 : ''"
          class="mr-2"
          @click="RefreshList()"
          >mdi-sync</v-icon
        >
        <v-text-field
          class="mr-2 width"
          dense
          v-if="user ? user.account_type == 1 : ''"
          v-model="search"
          outlined
          @input="initialize()"
          hide-details=""
          placeholder="Search..."
        ></v-text-field>

        <v-btn
          depressed
          v-if="user ? user.account_type == 1 : ''"
          @click="add_attachment()"
          color="primary"
        >
          Add Attachment</v-btn
        >
      </v-toolbar>
      <!-- :single-expand="singleExpand"
        :expanded.sync="expanded" -->
      <!-- show-expand -->
      <v-data-table
        :headers="headers"
        :items="Attachmentlist"
        :options.sync="options"
        :server-items-length="totalServerItems"
        item-key="id"
        class="log-table-card"
        :footer-props="footerProps"
        sort-by="id"
      >
        <template v-slot:item.created_at="{ item }">
          <v-chip color="primary" label small>
            {{ item.created_at | moment("MM/DD/YYYY - h:mm a") }}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            small
            color="primary"
            @click="downloadAttachment(item.media_id)"
            >mdi-download</v-icon
          >
          <v-icon
            v-if="user ? user.account_type == 1 : ''"
            color="primary"
            small
            @click="edit_Attachment(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            v-if="user ? user.account_type == 1 : ''"
            color="red"
            small
            @click="deleteDialogOpen(item)"
            >mdi-delete</v-icon
          >
        </template>
      </v-data-table>
      <!-- dialog all -->
      <!--add_Attachment_dialog  -->
      <v-dialog v-model="add_Attachment_dialog" max-width="500px" persistent>
        <v-card>
          <v-card-title>
            <span>{{ title }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row class="mt-1">
              <v-col cols="12" sm="12" md="12">
                <h6>File Name *</h6>
                <v-text-field
                  dense
                  v-model="Attachment.filename"
                  outlined
                  placeholder="File Name"
                  :error-messages="filenameErrors"
                  @input="$v.Attachment.filename.$touch()"
                  @blur="$v.Attachment.filename.$touch()"
                ></v-text-field>
              </v-col>
              <v-col v-show="add_attachment_field" cols="12" sm="12" md="12">
                <h6>Select File *</h6>
                <!-- @change="onFileChange(Attachment.file)" -->
                <v-file-input
                  show-size
                  counter
                  multiple
                  ref="myfile"
                  id="fileInput"
                  outlined
                  dense
                  v-model="Attachment.file"
                  accept=".pdf,.doc,.docx,.rtf"
                  placeholder="Select PDF"
                  :error-messages="fileErrors"
                  @input="$v.Attachment.file.$touch()"
                  @blur="$v.Attachment.file.$touch()"
                ></v-file-input>
              </v-col> </v-row
          ></v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="close()">Close</v-btn>
            <v-btn
              :loading="locLoading"
              :disabled="addLoading"
              depressed
              color="primary darken-1"
              @click="upload_Attachment_pdf()"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="delete_dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span>Delete</span>
          </v-card-title>
          <v-card-text>Are you sure you want to delete Attachment?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="delete_dialog = false"
              >Cencel</v-btn
            >
            <v-btn
              color="primary"
              style="width: 100px"
              @click="delete_attacment()"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { log } from "../agora/utils/utils";

export default {
  components: {
    VueJsonPretty,
  },
  data: () => ({
    locLoading: false,
    addLoading: false,
    delete_dialog: false,
    add_attachment_field: true,
    add_Attachment_dialog: false,
    title: "Create Attachment",
    search: "",
    id: "0",
    // expanded: [],
    // singleExpand: false,
    headers: [
      { text: "Name", align: "start", value: "file" },
      { text: "File Type", align: "center", value: "media_url" },
      { text: "Created At", align: "center", value: "created_at" },
      { text: "Action", align: "end", value: "action" },
    ],
    Attachment: {
      filename: "",
      file: null,
    },
    options: {},
    itemsPerPage: 10,
    footerProps: { "items-per-page-options": [10, 20, 30, 40, 50] },
    page: 1,
  }),
  validations: {
    Attachment: {
      filename: { required },
      file: {
        required: requiredIf(function (abc) {
          return this.add_attachment_field == true;
        }),
      },
    },
  },

  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  created() {
    //this.initialize();
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,

      Attachmentlist: (state) => state.admin_log_reader.Attachmentlist,
      totalServerItems: (state) => state.admin_log_reader.totalServerItems,
    }),
    filenameErrors() {
      const errors = [];
      if (!this.$v.Attachment.filename.$dirty) return errors;
      !this.$v.Attachment.filename.required &&
        errors.push("File Name is required");
      return errors;
    },
    fileErrors() {
      const errors = [];
      if (!this.$v.Attachment.file.$dirty) return errors;
      !this.$v.Attachment.file.required && errors.push("File is required");
      return errors;
    },
  },

  methods: {
    initialize() {
      this.$store.dispatch("admin_log_reader/GetAttachmentlist", {
        options: this.options,
        page: this.options.page,
        search: this.search,
      });
    },
    RefreshList() {
      this.initialize();
    },
    upload_Attachment_pdf() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "All form fields are required.",
        });
      } else {
        this.locLoading = true;
        this.addLoading = true;
        let formData = new FormData();
        try {
          for (let file of this.Attachment.file) {
            formData.append("file", file);
            formData.append("filename", this.Attachment.filename);
            formData.append("type", this.user.account_type);
          }
        } catch (err) {}

        if (this.title == "Edit Attachment") {
          formData.append("filename", this.Attachment.filename);
          formData.append("type", this.user.account_type);
          formData.append("id", this.id);
        }
        let url = "/api/upload_attachment_file";
        axios
          .post(url, formData)
          .then((response) => {
            this.initialize();
            this.close();
            this.$v.$reset();
            this.$store.dispatch(
              "global/snackBarState",
              {
                show: true,
                color: "primary",
                message: response.data.message,
              },
              { root: true }
            );
          })
          .catch((error) => {
            this.close();
            this.$store.dispatch("global/actionloaderState", false, {
              root: true,
            });
            this.$store.dispatch(
              "global/snackBarState",
              {
                show: true,
                color: "red",
                message: error.response
                  ? error.response.data.message
                  : "Something went wrong!",
              },
              { root: true }
            );
          });
      }
    },
    deleteDialogOpen(item, type) {
      this.id = item.id;
      this.delete_dialog = true;
    },
    delete_attacment() {
      let obj = {
        id: this.id,
        type: this.user.account_type,
      };
      let url = "/api/attachment_delete";
      axios
        .post(url, obj)
        .then((response) => {
          this.initialize();
          this.close();
        })
        .catch((error) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.$store.dispatch(
            "global/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response
                ? error.response.data.message
                : "Something went wrong!",
            },
            { root: true }
          );
        });
    },
    add_attachment() {
      this.title = "Create Attachment";
      this.add_Attachment_dialog = true;
      this.add_attachment_field = true;
    },
    edit_Attachment(item) {
      this.title = "Edit Attachment";
      this.Attachment.filename = item.file;
      this.id = item.id;
      this.add_Attachment_dialog = true;
      this.add_attachment_field = false;
    },
    downloadAttachment(media_id) {
      let obj = {
        media_id: media_id,
      };
      let url = "/api/downloadAttachmentFile";
      axios
        .post(url, obj)
        .then((response) => {
          window.open(response.data.data);
        })
        .catch((error) => {
          this.close();
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.$store.dispatch(
            "global/snackBarState",
            {
              show: true,
              color: "red",
              message: error.response
                ? error.response.data.message
                : "Something went wrong!",
            },
            { root: true }
          );
        });
    },
    close() {
      this.Attachment.filename = "";
      this.Attachment.file = null;
      this.add_Attachment_dialog = false;
      this.delete_dialog = false;
      this.locLoading = false;
      this.addLoading = false;
    },
    // expandAll: function () {
    //   this.expanded = this.Attachmentlist.filter((item) => item.stack_traces);
    // },
    // collapseAll: function () {
    //   this.expanded = [];
    // },
  },
};
</script>
<style scoped>
>>> .width {
  width: 100px;
}
</style>
