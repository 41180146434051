<template>
  <div>
    <v-container>
      <!-- Data Table -->
      <v-data-table
        :headers="headers"
        :items="Logs"
        sort-by="name"
        class="table-card mt-4"
        :search="search"
        :footer-props="{
          'items-per-page-options': [50, 100, 150, 200],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              dense
              outlined
              hide-details=""
              placeholder="Search.."
              v-model="search"
            ></v-text-field>

            <v-spacer></v-spacer>
            <!-- Refresh Button -->
            <v-icon size="30" @click="refresh()" color="primary"
              >mdi-sync</v-icon
            >
          </v-toolbar>
        </template>

        <template v-slot:item.status="{ item }">
          <v-checkbox
            dense
            v-model="item.status"
            @click="updateStatus(item)"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Sr no.", width: "50px", value: "id" },
      { text: "File", width: "200px", value: "file_name" },
      { text: "Status", width: "200px", value: "status" },
    ],
    Logs: [],
  }),

  created() {
    this.refresh();
  },

  methods: {
    refresh() {
      this.$store
        .dispatch("admin_log_reader/getLogSettings")
        .then((response) => {
          this.Logs = response.data.data;
        })
        .catch((err) => {});
    },

    updateStatus(item) {
      this.$store
        .dispatch("admin_log_reader/updateLogSettings", {
          status: item.status,
          id: item.id,
        })
        .then((response) => {
          this.Logs = response.data.data;
        })
        .catch((err) => {});
    },
  },
};
</script>
