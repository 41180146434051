import Axios from "axios";
import Vue from "vue";

const state = {
    showSnackBar: {
        show: false,
        message: "",
        color: ""
    },

    showLoader: false,

    tabs: {
        generalSettingsTab: 0,
        usersTab: 0,
        notificationsTab: 0,
        appointmentsToggle: 0,
        adminReasonsTab: 0,
        emailTabs: 0,
        smsTabs: 0,
        apisTabs: 0,
        myProfileTabs: 0,
        logsTabs: 0,
        calendarTypeTab: "month" // 1 = day , 2 = week , 3 = month
    }
};

const getters = {};

const actions = {
    post(context, item) {
       // console.log("Options >>>" + JSON.stringify(item.options));
        if (item.options != undefined) {
            item.body.append(
                "page",
                item.options.page == undefined ? 1 : item.options.page
            );
            item.body.append(
                "per_page",
                item.options.itemsPerPage == undefined
                    ? 15
                    : item.options.itemsPerPage
            );

            if (item.options.column) {
                item.options.filters = [
                    {
                        search: item.options.search,
                        column: item.options.column
                    }
                ];
            } else {
                if (!item.options.filters) item.options.filters = [];
            }
            item.body.append("filters", JSON.stringify(item.options.filters));
            if (!item.options.sortBy) item.options.sortBy = [];
            if (item.options.sortBy.length > 0) {
                item.body.append("sort_by", item.options.sortBy[0]);
                item.body.append(
                    "order",
                    item.options.sortDesc[0] ? "desc" : "asc"
                );
            }
        }

        // other url auto appended to headers
        if (item.url == "/api/get_dashboard") {
            Axios.defaults.headers.common["Authorization"] = `Bearer ${
                Vue.prototype.$loginUser().access_token
            }`;
            Axios.defaults.headers.common["Content-Type"] = "application/json";
        }

        return Axios.post(item.url, item.body,item.headers);
    },
    get(context, item) {
        return Axios.get(item.url);
    },

    actionloaderState(context, status) {
        context.commit("SHOW_LOADER", status);
    },

    snackBarState(context, snackProp) {
        context.commit("SHOW_SNACKBAR", {
            show: snackProp.show,
            color: snackProp.color,
            message: snackProp.message
        });
    }
};

const mutations = {
    SHOW_SNACKBAR(state, snack) {
        state.showSnackBar.message = snack.message;
        state.showSnackBar.color = snack.color;
        state.showSnackBar.show = snack.show;
    },

    SHOW_LOADER(state, status) {
        state.showLoader = status;
    },

    UPDATE_TABS(state, tab) {
        switch (tab.type) {
            // General Settings
            case 0:
                {
                    state.tabs.generalSettingsTab = tab.value;
                }
                break;
            case 1: //Users Tab
                {
                    state.tabs.usersTab = tab.value;
                }
                break;
            case 2: //Notification Tab
                {
                    state.tabs.notificationsTab = tab.value;
                }
                break;
            case 3: //Appointment Toggle
                {
                    state.tabs.appointmentsToggle = tab.value;
                }
                break;
            case 4: //Admin Reason (Lookup tables)
                {
                    state.tabs.adminReasonsTab = tab.value;
                }
                break;
            case 5: //notification => email tabs (General settings)
                {
                    state.tabs.emailTabs = tab.value;
                }
                break;
            case 6: //notification => sms tabs (General settings)
                {
                    state.tabs.smsTabs = tab.value;
                }
                break;
            case 7: //apis tabs (General settings)
                {
                    state.tabs.apisTabs = tab.value;
                }
                break;
            case 8: //My Profile (General settings)
                {
                    state.tabs.myProfileTabs = tab.value;
                }
                break;
            case 9: //Appointments (Calendar Type Tab)
                {
                   // console.log("Updating Ctype tab to : " + tab.value);
                    state.tabs.calendarTypeTab = tab.value;
                }
                break;
            case 10: //Logs Tabs
                {
                    state.tabs.logsTabs = tab.value;
                }
                break;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
