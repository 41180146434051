<template>
  <v-app>
    <div class="admin-login">
      <div class="login-card">
        <div class="text-center">
          <img :src="'/assets/icon.png'" alt />

          <div class="mb-2">
            <v-icon>mdi-lock</v-icon>
          </div>

          <h4 class="mb-5">Enter Pincode</h4>
        </div>

        <v-text-field
          :append-icon="showPin ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPin ? 'text' : 'password'"
          label="Pincode"
          outlined
          v-model="pin"
          @click:append="showPin = !showPin"
          @keyup.enter="submit()"
        ></v-text-field>

        <v-btn block depressed style="width: 180px" large color="primary" @click="submit()">Submit</v-btn>
      </div>
    </div>
  </v-app>
</template>


<script>
import { mapState } from 'vuex';
export default {
  computed:{
    ...mapState({
      user: (state) => state.currentUser.user
    })
  },
  data: () => ({
    showPin: false,
    retries:0,
    pin:"",
  }),
  methods: {

      async logout() {
          //alert("Lock Screen");
           localStorage.setItem("checkPin", 0);
      await this.$store.dispatch("currentUser/logout");
      this.$router.replace({ name: "login" });
    },
    submit() {
         this.retries++;
          if(this.retries>=5){
              this.logout();
              return;
          }
        console.log("USER PIN = "+this.user.pin);
      if(this.pin == this.user.pin || !this.user.pin){
        localStorage.setItem("checkPin", 0);
        this.$router.replace({ name: "appointments" });
      }else{
        console.log(this.user.pin);
      }
    },
  },
};
</script>
