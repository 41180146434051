<template>
  <div>
    <div class="logs-header">
      <v-container class="pb-0">
        <v-breadcrumbs class="px-0" :items="bread" large></v-breadcrumbs>

        <v-row>
          <v-col cols="12" sm="6" md="6">
            <h2>Log [{{ fileDetail.log_date }}]</h2>
          </v-col>

          <v-col cols="12" sm="6" md="6" class="text-md-end text-sm-end">
            <v-btn color="primary" dark depressed @click="DownloadFile()"
              ><v-icon left>mdi-download</v-icon> Download</v-btn
            >

            <v-btn @click="openDeleteDialog()" color="red" dark depressed>
              <v-icon left>mdi-delete</v-icon> Delete</v-btn
            >
          </v-col>
        </v-row>

        <div class="log-detail-card">
          <v-row class="ma-0">
            <v-col cols="12" sm="12" md="12">
              <h4>
                File Path :
                <span>{{ fileDetail.filepath }}</span>
              </h4>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <p>
                Log Entries :
                <v-chip x-small label dark color="primary">{{
                  fileDetail.totalLogEntries
                }}</v-chip>
              </p>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <p>
                Size :
                <v-chip x-small label dark color="primary">{{
                  fileDetail.filesize
                }}</v-chip>
              </p>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <p>
                Created at :
                {{ fileDetail.date | moment("MM/DD/YYYY") }}
              </p>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <p>
                Updated at :
                {{ fileDetail.date | moment("MM/DD/YYYY") }}
              </p>
            </v-col>
          </v-row>
        </div>

        <v-tabs
          class="mt-4"
          v-model="tab"
          background-color="transparent"
          color="primary"
        >
          <v-tab
            v-for="item in tab_items"
            :key="item"
            @click="tabSelected(item)"
          >
            {{ item }}
            <!-- {{item.length}} -->
            <v-chip label color="primary" class="ml-2" x-small>{{
              TotalCountModifier(item)
            }}</v-chip>
          </v-tab>
        </v-tabs>
      </v-container>
    </div>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard @initializeFromChild="initialize" ref="c1" />
      </v-tab-item>
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard ref="c1" />
      </v-tab-item>

      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard ref="c1" />
      </v-tab-item>

      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard ref="c1" />
      </v-tab-item>
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard ref="c1" />
      </v-tab-item>

      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard ref="c1" />
      </v-tab-item>
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard />
      </v-tab-item>
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard ref="c1" />
      </v-tab-item>
      <v-tab-item
        transition="scroll-y-transition"
        reverse-transition="scroll-y-transition"
      >
        <InfoCard ref="c1" />
      </v-tab-item>
    </v-tabs-items>

    <v-dialog v-model="dialogDelete" max-width="350">
      <v-card>
        <v-card-title> Delete </v-card-title>

        <v-card-text> Are you Sure? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> Cancel </v-btn>

          <v-btn
            color="primary"
            depressed
            min-width="120"
            @click="DeleteFile()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { mapState } from "vuex";
import { log } from "../agora/utils/utils";
import InfoCard from "./InfoCard";
export default {
  components: {
    InfoCard,
  },
  data: () => ({
    dialogDelete: false,
    tab: null,
    tab_items: [
      "All",
      "Emergency",
      "Alert",
      "Critical",
      "Error",
      "Warning",
      "Notice",
      "Info",
      "Debug",
    ],

    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/log-app",
      },
      {
        text: "Logs",
        disabled: false,
        to: "/log-app",
        exact: true,
      },

      {
        text: "Log Info",
        disabled: true,
        to: "#",
      },
    ],
  }),

  computed: {
    ...mapState({
      fileDetail: (state) => state.admin_log_reader.fileDetail,
    }),
  },

  created() {
    this.routeRedirection();
  },

  methods: {
    routeRedirection() {
      let filename = localStorage.getItem("filename");
      if (!filename) {
        this.$router.push({ name: "logApp" });
      } else {
        this.initialize();
        this.loginfoInitialize();
      }
    },

    loginfoInitialize() {
      if (this.$refs.c1 != undefined) {
        this.$refs.c1.initialize();
      }
    },

    openDeleteDialog() {
      this.dialogDelete = true;
    },

    initialize() {
      this.$store.dispatch("admin_log_reader/GetLogFileDetail", {
        filename: localStorage.getItem("filename"),
      });
    },

    DeleteFile() {
      this.$store
        .dispatch("admin_log_reader/DeleteLogFile", {
          filename: localStorage.getItem("filename"),
        })
        .then((response) => {
          localStorage.removeItem("filename");
          this.$router.push({ name: "logApp" });
        });
      this.close();
    },

    DownloadFile() {
      this.$store.dispatch("admin_log_reader/downloadLogFile", {
        filename: localStorage.getItem("filename"),
      });
    },

    //============ Call Parent to Child Method==================
    tabSelected(level) {
      //   console.log("Level is================>>", level);
      localStorage.setItem("level", level);
      //console.log("Ref===============",this.$refs.c1);   // The returned is a vue object, so you can call its method directly
      if (this.$refs.c1 != undefined) {
        this.$refs.c1.initialize();
      }

      //   this.$refs.c1.initialize();
    },

    TotalCountModifier(level_name) {
      switch (level_name) {
        case "All":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.all.entries;
          }
          break;

        case "Emergency":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.emergency.entries;
          }
          break;

        case "Alert":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.alert.entries;
          }
          break;

        case "Critical":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.critical.entries;
          }
          break;

        case "Error":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.error.entries;
          }
          break;

        case "Warning":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.warning.entries;
          }
          break;

        case "Notice":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.notice.entries;
          }
          break;

        case "Info":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.info.entries;
          }
          break;

        case "Debug":
          if (
            Object.keys(this.fileDetail).length !== 0 &&
            this.fileDetail.constructor === Object
          ) {
            return this.fileDetail.levelCounts.debug.entries;
          }
          break;

        default:
          break;
      }
    },

    close() {
      this.dialogDelete = false;
    },
  },
};
</script>

<style></style>
