<script>
import Room from "./main";
import locales from "../locale";
import Setting from "./tabs/setting";
import Contacts from "./tabs/contacts";
import Profile from "./tabs/profile";
import RoomsList from "./tabs/userlist";
import Groups from "./tabs/groups";

import { chatMessages } from "./data";
import { mapState } from "vuex";

export default {
  components: {
    Profile,
    Setting,
    Groups,
    Room,
    RoomsList,
    Contacts,
  },
  props: {
    height: {
      type: String,
      default: "600px",
    },
    theme: {
      type: String,
      default: "light",
    },
    styles: {
      type: Object,
      default: () => ({}),
    },
    responsiveBreakpoint: {
      type: Number,
      default: 900,
    },
    singleRoom: {
      type: Boolean,
      default: false,
    },
    textMessages: {
      type: Object,
      default: null,
    },

    loadingRooms: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [String, Number],
      default: null,
    },
    messages: {
      type: Array,
      default: () => [],
    },
    messagesLoaded: {
      type: Boolean,
      default: false,
    },
    menuActions: {
      type: Array,
      default: () => [],
    },
    messageActions: {
      type: Array,
      default: () => [
        {
          name: "Reply",
          title: "Reply",
        },
        {
          name: "Edit",
          title: "Edit Message",
          onlyMe: true,
        },
        {
          name: "Delete",
          title: "Delete Message",
          onlyMe: true,
        },
      ],
    },
    showAddRoom: {
      type: Boolean,
      default: true,
    },
    showFiles: {
      type: Boolean,
      default: true,
    },
    showEmojis: {
      type: Boolean,
      default: true,
    },
    showReactionEmojis: {
      type: Boolean,
      default: true,
    },
    textFormatting: {
      type: Boolean,
      default: true,
    },
    newMessage: {
      type: Object,
      default: null,
    },
    roomMessage: {
      type: String,
      default: "",
    },
    currentParticipant: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      timer: {},
      groupTimer: {},
      groupTyping: 0,
      typing: false,
      groupUsers: [],
      rooms: [],
      chatMessages: chatMessages,
      message: "",
      input: "",
      search: "",
      activetab: 2,
      emojiPickerHeight: 320,
      emojiPickerTop: 0,
      editedMessage: {},
      emojiPickerRight: "",
      emojiOpened: false,
      emojisList: {},
      imageDimensions: null,
      file: null,
      room: {},
      showRoomsList: true,
      isMobile: false,

      icon: "justify",
    
      //Chat Group Section
      groups: [],
      usersList: [],

      currentParticipant1: this.currentParticipant,

      currentPage: 1, //For pagonation
    };
  },
  watch: {
    rooms(newVal, oldVal) {
      if (newVal[0] && newVal.length !== oldVal.length) {
        if (this.id) {
          const room = newVal.find((r) => r.id === this.id);
          this.fetchRoom({
            room,
          });
        } else if (!this.isMobile) {
          this.fetchRoom({
            room: this.orderedRooms[0],
          });
        } else {
          this.showRoomsList = true;
        }
      }
    },
    id: {
      immediate: true,
      handler(val) {
        if (val && !this.loadingRooms && this.rooms.length) {
          const room = this.rooms.find((r) => r.id === val);
          this.fetchRoom({
            room,
          });
        }
      },
    },
    room(val) {
      if (!val) return;
      if (Object.entries(val).length === 0) return;
    },
    newMessage(val) {
      this.$set(this.messages, val.index, val.message);
    },

    //Chat and Group(UI) Tab Initialization According to Tab Change(navi)
    activetab(curTab) {
      if (curTab == this.$constants.CHAT) {
         //Note Start: Additional Check isGroupChatScr is used for when partner need an appointent btn click(From Dashboard)
        // if (this.currentParticipant1) {
        //   if (this.currentParticipant1.isGroupChatScr == true) {
        //     this.currentParticipant1.isGroupChatScr = false;
        //   }
        // }
        //Note End: 

        this.getChatUsersApi();
        // console.log("Current Particpant", this.room.id);
        console.log("currrr tab user chat ", curTab);
      } else if (curTab == this.$constants.GROUP) {
        console.log("currrr tab group chat ", curTab);
        this.getGroupsApi(); //initialize all user related groups
        this.getusersListApi(); //data used for create group
      }
      this.currentPage = 1;
      console.log("Current Page=======", this.currentPage);
    },
  },
  mounted() {
    this.updateResponsive();
    window.addEventListener("resize", (ev) => {
      if (ev.isTrusted) this.updateResponsive();
    });

    this.getChatUsersApi();

    //TODO: Test if working
    this.getUnreadMessagesCountNew();

    var channel = this.$pusher.subscribe("my-channel");

    /**
     *-------------------------------------------------------------
     * My Event
     *-------------------------------------------------------------
     */
    var my_id = this.currentUserId;
    const _this = this;
    channel.bind("my-event", function (data) {
      console.log("IN CHANNEL EVENT ", data);
      if (_this.$route.name !== "chat") {
        return;
      }
      if (my_id == data.to_user) {
        if (_this.room.id == data.from_user) {
          // if receiver is selected, reload the selected user ...
          if (data.typing) {
            _this.addtyping();
          } else {
            _this.removeTyping();
            _this.getLastMessageApi(data.from_user);
            console.log("Received a message probably");
          }
        } else if (!data.typing) {
          _this.getChatUsersApi();
          // if receiver is not selected, add notification for that user
          //   var pending = parseInt(
          //     $("#user-" + data.from_user)
          //       .find(".pending")
          //       .html()
          //   );
          //   if (pending) {
          // $("#user-" + data.from_user)
          //   .find(".pending")
          //   .html(pending + 1);
          //   } else {
          // $("#user-" + data.from_user).append(
          //   '<div class="unread-message"><span class="badge badge-soft-danger badge-pill pending">1</span></div>'
          // );
          //   }
        } else {
          // console.log('my-event');
        }
      }
    });

    /**
     *-------------------------------------------------------------
     * Group Message Event
     *-------------------------------------------------------------
     */
    channel.bind("my-group", function (data) {
      // console.log("Group Message Event Pusher========================", data);
      if (_this.$route.name !== "chat") {
        return;
      }
      // _this.getUnreadMessagesCountNew();
      if (data.group_id == _this.room.id) {
        // console.log("pusher if group id", data.group_id);
        if (my_id != data.from_user_id) {
          if (!data.typing) {
            _this.removeGroupTyping();
            _this.getGroupLastMessageApi(data.group_id);
          } else {
            _this.addGroupTyping(data.from_user_id);
          }
        }

        //real time group unreadCount Updates(Not Implemented)
      } else {
        // _this.groups.filter((e) =>
        //   e.id == 3 ? (e.users.unread_count = 11) : e.users.unread_count
        // );
        // console.log("Dataaaaaa===", data);
      }
    });

    //Add To SideBar Current User
  },

  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
    currentUserId() {
      return this.user.id;
    },

    t() {
      return {
        ...locales,
        ...this.textMessages,
      };
    },
    orderedRooms() {
      return this.rooms.slice().sort((a, b) => {
        const aVal = a.lastMessage || {
          date: 0,
        };
        const bVal = b.lastMessage || {
          date: 0,
        };
        return aVal.date > bVal.date ? -1 : bVal.date > aVal.date ? 1 : 0;
      });
    },
  },
  methods: {
    makeVideoChatId(item) {
      let id;
      if (item.id > this.user.id) id = `${item.id}_${this.user.id}`;
      else id = `${this.user.id}_${item.id}`;
      const hash = id.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
      return hash;
    },

    startVideoCall(item) {
      const roomId = this.makeVideoChatId(item);

      this.$store.dispatch("users/addUserToCall", {
        sendTo: item.id,
        roomId: roomId,
        mail: item.email,
        name: item.name ? item.name : "",
      });
    },

    loadMore() {
      this.currentPage = this.currentPage + 1;
      this.getMessagesApi(this.room.id);
    },

    getUnreadMessagesCountNew() {
      //console.log("Calling unreadCountNew from chat_new/index.vue");
      this.$store.dispatch("chat/unreadCountNew");
    },
    addtyping() {
      //   console.log("In add Typing");
      this.typing = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.removeTyping();
      }, 1000);
    },
    removeTyping() {
      this.typing = false;
    },

    removeGroupTyping() {
      this.groupTyping = 0;
    },

    addGroupTyping(id) {
      //   console.log("In add group typing ", id);
      this.groupTyping = id;
      clearTimeout(this.groupTimer);
      this.groupTimer = setTimeout(() => {
        this.removeGroupTyping();
      }, 1000);
    },

    updateResponsive() {
      this.isMobile = window.innerWidth < this.responsiveBreakpoint;
    },

    fetchRoom({ room }) {
      //   console.log(room);
      room.unread = 0;
      this.room = room;

      this.fetchMessages({
        reset: true,
      });

      this.getUnreadMessagesCountNew();
      if (this.isMobile) this.showRoomsList = false;
    },

    roomInfo() {
      this.$emit("roomInfo", this.room);
    },

    fetchMessages(options) {
      console.log("fetchMessages called");
      this.$emit("fetchMessages", {
        room: this.room,
        options,
      });

      //if current active UI is CHAT then fetch messages
      if (this.activetab == this.$constants.CHAT) {
        this.currentPage = 1;
        this.getMessagesApi(this.room.id);
      }
    },

    sendMessage(message) {
      this.$emit("sendMessage", {
        ...message,
        id: this.room.id,
      });
      this.sendMessageApi({
        receiverId: this.room.id,
        message: message.content,
        file: message.file,
      });
      message.file = message.fileUrl;

      //   console.log("IN SEND MESSAGE ", message);
      this.chatMessages.push(message);
    },

    editMessage(message) {
      this.$emit("editMessage", {
        message,
        id: this.room.id,
      });
      let msgInfo = message;
      let data = this.chatMessages.find((x) => x.id === msgInfo.messageId);
      data.content = msgInfo.newContent;
    },

    deleteMessage(messageId) {
      this.$emit("deleteMessage", {
        messageId,
        id: this.room.id,
      });

      let data = this.chatMessages.find((x) => x.id === messageId);
      data.content = "";
      data.file = null;
      this.deleted = true;
    },

    openFile(message) {
      //   console.log(message);
      window.open(message.file, "_blank");
      this.$emit("openFile", message);
    },

    menuActionHandler(ev) {
      this.$emit("menuActionHandler", {
        action: ev,
        id: this.room.id,
      });
    },
    messageActionHandler(ev) {
      this.$emit("messageActionHandler", {
        ...ev,
        id: this.room.id,
      });
    },
    sendMessageReaction(messageReaction) {
      this.$emit("sendMessageReaction", {
        ...messageReaction,
        id: this.room.id,
      });
    },

    typingMessage(message) {
      this.$emit("typingMessage", {
        message,
        id: this.room.id,
      });
      this.sendTypingApi(this.room.id);
    },

    /// API CALLS FOR CHAT MESSAGES
    async getMessagesApi(userId) {
      const res = await this.$store.dispatch("global/get", {
        url: `/api/messages?user_id=${userId}&currentPage=${this.currentPage}`,
      });
      //   console.log("get room message====", res.data.data);
      const messages = res.data.data.messages;

      //Bug=when user has not any messages then load more then duplicate values show in chat
      //currentPage=1 means first time load page
      if (this.currentPage != 1) {
        console.log("iff");
        this.chatMessages = [...messages, ...(this.chatMessages ?? [])];
      } else {
        console.log("else");
        this.chatMessages = messages;
      }

      this.room.avatar = res.data.data.chatUser.image; //Add Chat User Image
      this.currentPage = res.data.data.currentPage;
    },

    async getLastMessageApi(userId) {
      const res = await axios.get(`api/lastmessage?user_id=${userId}`);
      const message = res.data.data;
      this.chatMessages.push(message);
      this.room.message = message.content;
    },

    async sendMessageApi({ receiverId, message, file }) {
      //   console.log("image file========", file);
      const fd = new FormData();
      fd.append("receiver_id", receiverId);
      fd.append("message", message);
      if (file) {
        fd.append("file", file);
      }

      const item = {
        url: "/api/message",
        body: fd,
      };

      let res = await this.$store.dispatch("global/post", item);
    },

    async sendTypingApi(receiverId) {
      const fd = new FormData();
      fd.append("receiver_id", receiverId);
      const item = {
        url: "/api/typing",
        body: fd,
      };

      await this.$store.dispatch("global/post", item);
    },

    getChatUsersApi() {
      const item = {
        url: "/api/recentsearch",
        body: new FormData(),
      };

      this.$store
        .dispatch("global/post", item, { root: true })
        .then((response) => {
          //   console.log("------=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=",response.data.data );
          this.rooms = response.data.data || [];

          //Delete User and Its all Conversations Filter according to recent search api
          if (this.rooms) {
            this.rooms = this.rooms.filter(
              (r) => r.for_delete != this.currentUserId
            );
          }

          //This if used for add local user to chat
          if (this.currentParticipant1) {
            // console.log("if currentParticipant1 ", this.currentParticipant1);

            const local = this.rooms.find(
              (r) => r.id == this.currentParticipant1.id
            );

            if (local) {
              // console.log("iff local");
              const index = this.rooms.findIndex(
                (r) => r.id == this.currentParticipant1.id
              );
              this.rooms.splice(index, 1);
              this.rooms.unshift(local);

              //This Condional Based Snippet Means That if any room user found (local or database) initialize that user related chat
              if (this.rooms.length > 0) {
                this.room.id = this.rooms[0].id;
                this.getMessagesApi(this.room.id);
              } else {
                this.room = {};
                this.chatMessages = [];
                this.currentParticipant1 = null;
              }
            } else {
              // console.log("iff unshift");

               //Note Start: Additional Check isGroupChatScr is used for when partner need an appointent btn click(From Dashboard)
              // if (this.currentParticipant1.isGroupChatScr == true) {
              //   this.activetab = this.$constants.GROUP;
              //   this.icon = "center"; //For Group v-btn-toggle Overlay
              //   this.getGroupsApi(true);
               //Note End:
                
             // } else {
                
                //Note Start: Additional Check
                // let isGroupChatScr = this.currentParticipant1.hasOwnProperty('isGroupChatScr');
                // if(!isGroupChatScr){
                  const localRoom = {
                    id: this.currentParticipant1.id,
                    from_user: this.currentUserId,
                    to_user: this.currentParticipant1.id,
                    message: "",
                    file: null,
                    is_read: 0,
                    created_at: "2021-10-16 08:33:55",
                    updated_at: "2021-10-16 08:33:55",
                    userid: this.currentParticipant1.id,
                    unread: "0",
                    email: this.currentParticipant1.email,
                    name: this.currentParticipant1.name,
                    avatar: this.currentParticipant1.image,
                    image: this.currentParticipant1.image,
                    status:
                      this.currentParticipant1.color == "#00cc00"
                        ? "online"
                        : "away",
                };
                  this.rooms.unshift(localRoom);
                //}
                //Note End:
              //}

              // console.log("after ubshift rooms===", this.rooms);

              //This Condional Based Snippet Means That if any room user found (local or database) initialize that user related chat
              if (this.rooms.length > 0) {
                this.room.id = this.rooms[0].id;
                this.getMessagesApi(this.room.id);
              } else {
                this.room = {};
                this.chatMessages = [];
                this.currentParticipant1 = null;
              }
            }
          } else {
            // console.log("partipent else");
            //This Condional Based Snippet Means That if any room user found (local or database) initialize that user related  chat
            if (this.rooms.length > 0) {
              this.room.id = this.rooms[0].id;
              this.getMessagesApi(this.room.id);
            } else {
              this.room = {};
              this.chatMessages = [];
              this.currentParticipant1 = null;
            }
          }
        })
        .catch((response) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },

    async deleteConversationApi() {
      const fd = new FormData();
      fd.append("user_id", this.room.id);
      const item = {
        url: "/api/deleteConversation",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        this.room = {};
        this.currentParticipant1 = null;
        this.getChatUsersApi();
      }
    },

    //Group Section Start=========
    async getGroupsApi(isGroupChatScr = false) {
      const res = await axios.post("api/getGroups");
      //   console.log("Group Lists=========", res.data.data);
      if (res.data.data.length > 0) {
        //Note Start: Additional Check isGroupChatScr is used for when partner need an appointent btn click(From Dashboard)
        // if (isGroupChatScr) {
        //   //Fetch Entouch Support Group Messages
        //   let message = {
        //     content: "Partner needs an appointment",
        //     file: null,
        //   };
        //   this.groups = res.data.data;
        //   let EthSupportGroup = this.groups.find(
        //     (r) => r.group_name == "Entouch Support"
        //   );
        //   if (EthSupportGroup) {
        //     this.room.id = EthSupportGroup.id;
        //   }
        //   // console.log("Groups list", this.groups);
        //   // console.log("Entouch Support Group", EthSupportGroup);
        //   // console.log("Entouch Support Group Room ID===",this.room.id);
        //   this.sendGroupMessageApi(message);
        //   //Note End:
        // } else {
          //else simply fetch first group related messages
          this.groups = res.data.data;
          this.getGroupMessageApi(this.groups[0].id);
      //  }
      } else {
        // console.log("not group");
        this.groups = [];
        this.chatMessages = [];
        this.room = {};
        this.currentParticipant1 = null;
      }
    },

    async getusersListApi() {
      const res = await axios.post("api/getUsers");
      res ? (this.usersList = res.data.data) || [] : [];
    },

    async createGroupAPI(groupData) {
      const fd = new FormData();
      fd.append("group_name", groupData.name);
      fd.append("user_ids", JSON.stringify(groupData.user_ids) || []);
      fd.append("description", groupData.desc);
      const item = {
        url: "/api/createGroup",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      res ? (this.groups = res.data.data) || [] : [];
      if (this.groups) {
        this.getGroupsApi();
      }
    },

    async groupSearchApi(search_txt) {
      const fd = new FormData();
      if (search_txt) {
        fd.append("search", search_txt);
      }
      const item = {
        url: "/api/groupSearch",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        this.groups = res.data.data;
      }
    },

    //Group Related Message
    async getGroupMessageApi(group_id) {
      const fd = new FormData();
      fd.append("group_id", group_id);
      const item = {
        url: "/api/getGroupMessage",
        body: fd,
      };
      var res = await this.$store.dispatch("global/post", item);
      //console.log("single group detail===", res.data.data);
      if (res.data.data.groupdata) {
        // console.log("if single group");
        this.rooms = [];
        this.room.id = res.data.data.groupdata.id;
        const localRoom = {
          id: res.data.data.groupdata.id,
          from_user: this.currentUserId,
          to_user: res.data.data.groupdata.id,
          message: "",
          file: null,
          is_read: 0,
          created_at: "2021-10-16 08:33:55",
          updated_at: "2021-10-16 08:33:55",
          userid: res.data.data.groupdata.id,
          unread: "0",
          name: res.data.data.groupdata.group_name,
          description: res.data.data.groupdata.description,
          avatar: null,
          image: null,
          group_admin: res.data.data.groupdata.group_admin,
        };
        this.rooms.push(localRoom);
      }
      this.chatMessages = res.data.data.messages ?? [];
      this.groupUsers = res.data.data.chatUser ?? [];
      // console.log("Group users list====",this.groupUsers);

      //Update unread group message count to 0 for specific group and member
      this.groups.filter((e) =>
        e.id == res.data.data.groupdata.id
          ? (e.users.unread_count = 0)
          : e.users.unread_count
      );
      if (res) this.getUnreadMessagesCountNew();
    },

    async getGroupLastMessageApi(group_id) {
      const res = await axios.get(
        `api/getGroupLastMessage?group_id=${group_id}`
      );
      const message = res.data.data;
      this.chatMessages.push(message);
      this.room.message = message.content;
    },

    async typingGroupMessageApi(message) {
      //   console.log(message);
      const fd = new FormData();
      fd.append("group_id", this.room.id);
      const item = {
        url: "/api/groupTyping",
        body: fd,
      };
      await this.$store.dispatch("global/post", item);
    },

    async sendGroupMessageApi(message) {
      const fd = new FormData();
      fd.append("group_id", this.room.id);
      fd.append("message", message.content);
      if (message.file) {
        fd.append("file", message.file);
      }
      const item = {
        url: "/api/sendGroupMessage",
        body: fd,
      };
      message.file = message.fileUrl;
      this.chatMessages.push(message);
      let res = await this.$store.dispatch("global/post", item);
    },

    async deleteGroupConversationApi() {
      const fd = new FormData();
      fd.append("group_id", this.room.id);
      const item = {
        url: "/api/deleteGroupConversation",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      //   this.getGroupsApi();
      this.chatMessages = [];
      this.getGroupMessageApi(this.room.id);
    },

    //Group Owner can remove user from group
    async removeGroupUserApi(user) {
      const fd = new FormData();
      fd.append("group_id", this.room.id);
      fd.append("user_id", user.user_id);
      const item = {
        url: "/api/removeGroupUser",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: res.data.message,
        });
      }
      this.chatMessages = [];
      this.getGroupMessageApi(this.room.id);
    },

    //Group can be edited by group Owner
    async editGroupApi(group) {
      const fd = new FormData();
      fd.append("group_id", this.room.id);
      fd.append("group_name", group.name);
      fd.append("group_desc", group.desc);
      fd.append("user_ids", JSON.stringify(group.user_ids));

      const item = {
        url: "/api/editGroup",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: res.data.message,
        });
      }
      this.getGroupsApi();
      this.getGroupMessageApi(this.room.id);
    },

    //Delete Group Parmanantely By Group Owner
    async deleteGroupApi() {
      const fd = new FormData();
      fd.append("group_id", this.room.id);
      const item = {
        url: "/api/deleteGroup",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: res.data.message,
        });
      }
      this.getGroupsApi();
    },

    //User Can Leave From Group
    async leaveGroupApi() {
      const fd = new FormData();
      fd.append("group_id", this.room.id);
      const item = {
        url: "/api/leaveGroup",
        body: fd,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: res.data.message,
        });
      }
      this.getGroupsApi();
    },

    //Group Section End=========
  },
};
</script>

<template>
  <div>
    <!-- {{ room }}
    {{ currentParticipant1 }} -->
    <div class="layout-wrapper d-lg-flex">
      <!-- Side menu user start  For Testing:- style="display:none"; -->
      <!-- <div class="side-menu flex-lg-column mr-lg-1"> -->
      <!-- LOGO -->
      <!-- <div class="navbar-brand-box">
          <router-link to="/" tag="a" class="logo logo-dark">
            <span class="logo-sm">
              <img src="http://assets/images/logo.svg" alt height="30" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="http://assets/images/logo.svg" alt height="30" />
            </span>
          </router-link>
        </div> -->
      <!-- end navbar-brand-box -->

      <!-- Start side-menu nav -->
      <!-- <div class="flex-lg-column my-auto">
          <ul
            class="nav nav-pills side-menu-nav justify-content-center"
            role="tablist"
          >
             <li
              class="nav-item"
              data-placement="top"
              title="Profile"
              v-b-tooltip.hover
            >
              <a
                class="nav-link"
                id="pills-user-tab"
                data-toggle="pill"
                v-on:click="activetab = 1"
                v-bind:class="[activetab === 1 ? 'active' : '']"
                role="tab"
                href="javascript: void(0);"
              >
                <i class="ri-user-2-line"></i>
              </a>
            </li>
            <li
              class="nav-item"
              data-placement="top"
              title="Chats"
              v-b-tooltip.hover
            >
              <a
                class="nav-link"
                id="pills-chat-tab"
                data-toggle="pill"
                v-on:click="activetab = 2"
                v-bind:class="[activetab === 2 ? 'active' : '']"
                role="tab"
                href="javascript: void(0);"
              >
                <i class="ri-message-3-line"></i>
              </a>
            </li>
            <li
              class="nav-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Groups"
            >
              <a
                class="nav-link"
                id="pills-groups-tab"
                data-toggle="pill"
                v-on:click="activetab = 3"
                v-bind:class="[activetab === 3 ? 'active' : '']"
                role="tab"
                href="javascript: void(0);"
              >
                <i class="ri-group-line"></i>
              </a>
            </li>
            <li
              class="nav-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Contacts"
            >
              <a
                class="nav-link"
                id="pills-contacts-tab"
                data-toggle="pill"
                v-on:click="activetab = 4"
                v-bind:class="[activetab === 4 ? 'active' : '']"
                role="tab"
                href="javascript: void(0);"
              >
                <i class="ri-contacts-line"></i>
              </a>
            </li>
            <li
              class="nav-item"
              v-b-tooltip.hover
              data-placement="top"
              title="Settings"
            >
              <a
                class="nav-link"
                id="pills-setting-tab"
                data-toggle="pill"
                v-on:click="activetab = 5"
                v-bind:class="[activetab === 5 ? 'active' : '']"
                role="tab"
                href="javascript: void(0);"
              >
                <i class="ri-settings-2-line"></i>
              </a>
            </li>
            <b-dropdown
              class="nav-item profile-user-dropdown d-inline-block d-lg-none"
              toggle-class="nav-link"
              variant="white"
              dropup
            > -->
      <!-- <template v-slot:button-content> -->
      <!-- <img
                  src="http://assets/images/users/avatar-1.jpg"
                  alt
                  class="profile-user rounded-circle"
                /> -->
      <!-- </template>
              <b-dropdown-item href="javascript:void(0);">
                Profile
                <i class="ri-profile-line float-right text-muted"></i>
              </b-dropdown-item>
              <b-dropdown-item href="javascript:void(0);">
                Setting
                <i class="ri-settings-3-line float-right text-muted"></i>
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item href="/logout">
                Log out
                <i class="ri-logout-circle-r-line float-right text-muted"></i>
              </b-dropdown-item>
            </b-dropdown>
          </ul>
        </div> -->
      <!-- end side-menu nav -->

      <!-- <div class="flex-lg-column d-none d-lg-block">
          <ul class="nav side-menu-nav justify-content-center">
            <li class="nav-item">
              <a
                class="nav-link"
                id="light-dark"
                target="_blank"
                href="http://chatvia-dark.vue.themesbrand.com/"
                v-b-tooltip.hover
                title="Dark Mode"
              >
                <i class="ri-sun-line theme-mode-icon"></i>
              </a>
            </li>
            <b-dropdown
              class="nav-item btn-group dropup profile-user-dropdown"
              variant="white"
            >
              <template v-slot:button-content> -->
      <!-- <img
                  src="http://assets/images/users/avatar-1.jpg"
                  alt
                  class="profile-user rounded-circle"
                /> -->
      <!-- </template>
              <b-dropdown-item href="javascript:void(0);">
                Profile
                <i class="ri-profile-line float-right text-muted"></i>
              </b-dropdown-item>
              <b-dropdown-item href="javascript:void(0);">
                Setting
                <i class="ri-settings-3-line float-right text-muted"></i>
              </b-dropdown-item>
              <b-dropdown-item href="/logout">
                Log out
                <i class="ri-logout-circle-r-line float-right text-muted"></i>
              </b-dropdown-item>
            </b-dropdown>
          </ul>
        </div> -->
      <!-- Side menu user end -->
      <!-- </div> -->

      <div class="chat-leftsidebar mr-lg-1">
        <div class="tab-content">
          <!-- Start Profile tab-pane -->
          <!-- <div
            class="tab-pane"
            id="pills-user"
            role="tabpanel"
            aria-labelledby="pills-user-tab"
            v-if="activetab === 1"
            v-bind:class="[activetab === 1 ? 'active' : '']"
          > -->
          <!-- Start profile content -->
          <!-- <Profile /> -->
          <!-- End profile content -->
          <!-- </div> -->

          <!-- Start chats tab-pane -->
          <div
            class="tab-pane fade show active"
            id="pills-chat"
            role="tabpanel"
            aria-labelledby="pills-chat-tab"
            v-if="activetab == 2"
            v-bind:class="[activetab == 2 ? 'active' : '']"
          >
            <!-- Custom Group and Chat Btn(navi) -->
            <v-col cols="12" class="py-2">
              <v-btn-toggle v-model="icon" borderless mandatory>
                <v-btn
                  value="left"
                  id="pills-chat-tab"
                  data-toggle="pill"
                  v-on:click="activetab = 2"
                  v-bind:class="[activetab === 2 ? 'active' : '']"
                  role="tab"
                  href="javascript: void(0);"
                >
                  <span class="hidden-sm-and-down">Chats</span>
                  <v-icon right> mdi-chat-processing-outline </v-icon>
                </v-btn>

                <v-btn
                  value="center"
                  id="pills-groups-tab"
                  data-toggle="pill"
                  v-on:click="activetab = 3"
                  v-bind:class="[activetab === 3 ? 'active' : '']"
                  role="tab"
                  href="javascript: void(0);"
                >
                  <span class="hidden-sm-and-down">Groups</span>
                  <v-icon right> mdi-google-classroom </v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <!-- Custom Group and Chat Btn(navi) -->
            <!-- Start chats content -->
            <RoomsList
              v-if="!singleRoom"
              :currentUserId="currentUserId"
              :rooms="orderedRooms"
              :loadingRooms="loadingRooms"
              :room="room"
              :textMessages="t"
              :showAddRoom="showAddRoom"
              :showRoomsList="showRoomsList"
              :textFormatting="textFormatting"
              :isMobile="isMobile"
              @fetchRoom="fetchRoom"
            />
          </div>
          <!-- End chats tab-pane -->

          <!-- Start groups tab-pane -->
          <div
            class="tab-pane fade show active"
            id="pills-groups"
            role="tabpanel"
            aria-labelledby="pills-groups-tab"
            v-if="activetab == 3"
            v-bind:class="[activetab == 3 ? 'active' : '']"
          >
            <!-- Custom Group and Chat Btn(navi) -->
            <v-col cols="12" class="py-2">
              <v-btn-toggle v-model="icon" borderless mandatory>
                <v-btn
                  value="left"
                  id="pills-chat-tab"
                  data-toggle="pill"
                  v-on:click="activetab = 2"
                  v-bind:class="[activetab === 2 ? 'active' : '']"
                  role="tab"
                  href="javascript: void(0);"
                >
                  <span class="hidden-sm-and-down">Chats</span>
                  <v-icon right>mdi-chat-processing-outline </v-icon>
                </v-btn>

                <v-btn
                  value="center"
                  id="pills-groups-tab"
                  data-toggle="pill"
                  v-on:click="activetab = 3"
                  v-bind:class="[activetab === 3 ? 'active' : '']"
                  role="tab"
                  href="javascript: void(0);"
                >
                  <span class="hidden-sm-and-down">Groups</span>
                  <v-icon right> mdi-google-classroom </v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <!-- Custom Group and Chat Btn(navi) -->

            <!-- Start Groups content -->
            <Groups
              @createGroupAPI="createGroupAPI"
              @getGroupMessageApi="getGroupMessageApi"
              @groupSearchApi="groupSearchApi"
              :room="room"
              v-bind:groups="groups"
              v-bind:usersList="usersList"
              ref="groupContainer"
            />
            <!-- End Groups content -->
          </div>
          <!-- End groups tab-pane -->

          <!-- Start contacts tab-pane -->
          <div
            class="tab-pane"
            id="pills-contacts"
            role="tabpanel"
            aria-labelledby="pills-contacts-tab"
            v-if="activetab === 4"
            v-bind:class="[activetab === 4 ? 'active' : '']"
          >
            <!-- Start Contact content -->
            <Contacts />
            <!-- Start Contact content -->
          </div>
          <!-- End contacts tab-pane -->

          <!-- Start settings tab-pane -->
          <div
            class="tab-pane"
            id="pills-setting"
            role="tabpanel"
            aria-labelledby="pills-setting-tab"
            v-if="activetab === 5"
            v-bind:class="[activetab === 5 ? 'active' : '']"
          >
            <!-- Start Settings content -->
            <Setting />
            <!-- Start Settings content -->
          </div>
          <!-- End settings tab-pane -->
        </div>
      </div>

      <!-- Rooms UI Showing According to Current Active Tab (Chat and Group) -->
      <Room
        v-if="room.id && activetab == $constants.CHAT"
        ref="chatContainer"
        :currentUserId="currentUserId"
        :rooms="rooms"
        :id="room.id || ''"
        :messages="chatMessages"
        :roomMessage="roomMessage"
        :typing="typing"
        :messagesLoaded="messagesLoaded"
        :menuActions="menuActions"
        :messageActions="messageActions"
        :showFiles="showFiles"
        :showEmojis="showEmojis"
        :showReactionEmojis="showReactionEmojis"
        :textMessages="t"
        :singleRoom="singleRoom"
        :showRoomsList="showRoomsList"
        :textFormatting="textFormatting"
        :isMobile="isMobile"
        :loadingRooms="loadingRooms"
        :roomInfo="$listeners.roomInfo"
        @roomInfo="roomInfo"
        @fetchMessages="fetchMessages"
        @sendMessage="sendMessage"
        @editMessage="editMessage"
        @deleteMessage="deleteMessage"
        @openFile="openFile"
        @menuActionHandler="menuActionHandler"
        @messageActionHandler="messageActionHandler"
        @sendMessageReaction="sendMessageReaction"
        @typingMessage="typingMessage"
        @startVideoCall="startVideoCall"
        @deleteConversationApi="deleteConversationApi"
        @loadMore="loadMore"
      />

      <Room
        v-else-if="room.id && activetab == $constants.GROUP"
        ref="chatContainer"
        :currentUserId="currentUserId"
        :rooms="rooms"
        :id="room.id || ''"
        :messages="chatMessages"
        :groupUsers="groupUsers"
        :usersList="usersList"
        :roomMessage="roomMessage"
        :groupTyping="groupTyping"
        :typing="false"
        :messagesLoaded="messagesLoaded"
        :menuActions="menuActions"
        :messageActions="messageActions"
        :showFiles="showFiles"
        :showEmojis="showEmojis"
        :showReactionEmojis="showReactionEmojis"
        :textMessages="t"
        :singleRoom="singleRoom"
        :showRoomsList="showRoomsList"
        :textFormatting="textFormatting"
        :isMobile="isMobile"
        :loadingRooms="loadingRooms"
        :roomInfo="$listeners.roomInfo"
        @roomInfo="roomInfo"
        @fetchMessages="fetchMessages"
        @sendMessage="sendGroupMessageApi"
        @editMessage="editMessage"
        @deleteMessage="deleteMessage"
        @openFile="openFile"
        @menuActionHandler="menuActionHandler"
        @messageActionHandler="messageActionHandler"
        @sendMessageReaction="sendMessageReaction"
        @typingMessage="typingGroupMessageApi"
        @deleteConversationApi="deleteGroupConversationApi"
        @removeGroupUserApi="removeGroupUserApi"
        @editGroupApi="editGroupApi"
        @deleteGroupApi="deleteGroupApi"
        @leaveGroupApi="leaveGroupApi"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
