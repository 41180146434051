<template>
    <div>
        <p v-show="false">{{ showOnlyName }}</p>
        <v-data-table
            :headers="headers"
            :items="all_list"
            :options.sync="options"
            :loading="loading"
            :server-items-length="totalItems"
            :search="search"
            style="border: none !important"
            class="elevation-0 en-table-card"
            :items-per-page="30"
            :footer-props="{
                'items-per-page-options': [30, 50]
            }"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>All</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn
                        class="elevation-1"
                        @click="refresh"
                        fab
                        light
                        small
                        color="white"
                    >
                        <v-icon color="primary">mdi-sync</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-row class="px-4">
                    <v-col cols="12" md="12">
                        <v-text-field
                            dense
                            clearable
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.name="{ item }">
                <div class="d-flex align-center w-100">
                    <div class="d-flex align-center w-100">
                        <v-icon
                            @click="startVideoCall(item)"
                            :disabled="
                                item.color !== '#00cc00' &&
                                    item.color !== '#ffd801'
                            "
                            size="20"
                            color="green"
                            class="mr-3 ml-md-3 ml-sm-3"
                            >mdi-video</v-icon
                        >

                        <v-icon
                            class="mr-3 chat-icon"
                            @click="addForChat(item)"
                            size="20"
                            color="primary"
                            >mdi-android-messages</v-icon
                        >

                        <v-sheet
                            class="mr-3"
                            :color="item.color"
                            elevation="0"
                            height="47"
                            width="5"
                        ></v-sheet>
                        <span class="name-hover" @click="addForChat(item)">{{
                            item.name
                        }}</span>
                    </div>

                    <div class="show-only-mobile">
                        <!-- Show Only Mobile -->
                        
                    </div>
                </div>
            </template>

            <template v-slot:item.account_type="{ item }">
                <v-chip>{{ getType(item.account_type) }}</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
                <!-- Video Call Icon -->
                <!-- <v-icon
          @click="startVideoCall(item)"
          v-if="item.color == '#00cc00' || item.color == '#ffd801'"
          size="20"
          color="green"
          class="ml-3"
          >mdi-video</v-icon
        > -->

                <!-- Message Icon -->
                <!-- <v-icon class="mr-3" @click="addForChat(item)" size="20" color="primary"
          >mdi-android-messages</v-icon
        > -->
            </template>
        </v-data-table>
    </div>
</template>

<script>
import Axios from "axios";
import { mapState } from "vuex";

export default {
    data: () => ({
        switch1: false,
        loading: false,
        totalItems: 10,
        dialog: false,
        timer: null,
        options: {},
        show1: false,
        search: "",
        headers: [
            {
                text: "Name",
                align: "start",
                value: "name",
                width: "400px"
            },

            {
                text: "Type",
                align: "start",
                value: "account_type",
                width: "140px"
            },
            { text: "Email", value: "email", width: "350px" }

            // { text: "", align: "end", sortable: false, value: "actions" },
        ],
        all_list: []
    }),
    computed: {
        ...mapState({
            user: state => state.currentUser.user
        }),

        showOnlyName() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.headers.splice(1);
            }
            return "";
        }
    },
    created() {
        this.initialize();
    },

    watch: {
        search: function() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.refresh();
            }, 500);
        },
        options: {
            handler() {
                this.refresh();
                // console.log("Options Handler Called" + JSON.stringify(this.options));
            }
        }
    },

    methods: {
        addForChat(item) {
            console.log("Participant Item======", item);
            //   this.$store.dispatch("chat/addParticipant", item);
            this.$router.push({
                name: "chat",
                params: {
                    currentParticipant: item
                }
            });
        },

        startVideoCall(item) {
            const roomId = this.makeVideoChatId(item);
            const _this = this;
            //TestComment
            this.$db
                .collection("users")
                .doc(item.email)
                .onSnapshot(function(doc) {
                    _this.refresh();
                });
            this.$store.dispatch("users/addUserToCall", {
                sendTo: item.id,
                roomId: roomId,
                mail: item.email,
                name: item.name ? item.name : ""
            });
        },

        makeVideoChatId(item) {
            let id;
            if (item.id > this.user.id) id = `${item.id}_${this.user.id}`;
            else id = `${this.user.id}_${item.id}`;
            const hash = id.split("").reduce(function(a, b) {
                a = (a << 5) - a + b.charCodeAt(0);
                return a & a;
            }, 0);
            return hash;
        },

        getType(type) {
            if (type == 1) return "Admin";
            if (type == 2) return "Provider";
            if (type == 3) return "Partner";
            if (type == 4) return "Eth-Office";
        },
        clearFilters() {
            this.search = "";
        },
        refresh() {
            this.$store.dispatch("global/actionloaderState", true, {
                root: true
            });
            this.options.search = this.search;
            this.options.column = "name";

            const item = {
                url: "/api/get_all_users",
                body: new FormData(),
                options: this.options
            };

            this.$store
                .dispatch("global/post", item, { root: true })
                .then(response => {
                    this.totalItems = response.data.total;
                    this.all_list = response.data.data;
                    this.$store.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                })
                .catch(response => {
                    this.$store.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                });
        },

        initialize() {
            this.refresh();
        }
    }
};
</script>

<style scoped>
>>> .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0px 10px;
    background: #f1f3f6;
}

>>> .v-data-table > .v-data-table__wrapper > table > thead {
    background: white;
}

>>> .v-data-table__wrapper > table > tbody > tr > td {
    border-top: thin solid rgba(0, 0, 0, 0.12);
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0px 10px 0px 0px !important;
}

>>> .v-data-table__wrapper > table > tbody > tr {
    background: white;
}

@media screen and (max-width: 480px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__cell {
        width: 100% !important;
    }
}

/*  iPads, Tablets (Portrait)*/
@media (min-width: 481px) and (max-width: 768px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }
}
</style>
