<template>
  <v-container>
    <v-card-actions class="py-0 mb-1">
      <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
    </v-card-actions>

    <!-- :search="search" -->

    <v-data-table
      :headers="headers"
      :items="aptRequests"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItems"
      class="elevation-0 en-table-card"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [10, 30, 50],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Inbox</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            class="elevation-1 mr-3"
            @click="refresh()"
            fab
            light
            small
            color="white"
          >
            <v-icon color="primary">mdi-sync</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- <v-row class="px-4">
          <v-col cols="12" md="12">
            <v-text-field
              dense
              clearable
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row> -->
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip :color="getColor(item.status)" label small class="text-white">
          {{ statusName(item.status) }}</v-chip
        >
      </template>

      <template v-slot:item.created_at="{ item }">
        <a href="javascript:;">
          <v-chip color="primary" label small @click="replyChat(item)">
            {{ item.created_at | moment("MM/DD/YYYY - h:mm a") }}
          </v-chip>
        </a>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon
            v-if="user ? user.support_team == 1 : ''"
            small
            color="red"
            @click="deleteDialogOpen(item)"
            class="mr-3"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="deleteAppointmentReq()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    addLoading: false,
    loading: false,
    search: "",
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Inbox",
        disabled: true,
        to: "",
      },
    ],

    dialog: false,
    delete_dialog: false,
    editedIndex: -1,

    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        sortable: true,
      },
      {
        text: "Date",
        value: "created_at",
      },
      {
        text: "Message",
        align: "start",
        value: "message",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "",
        align: "end",
        sortable: false,
        value: "actions",
      },
    ],
    aptRequests: [],
    loading: false,
    totalItems: 10,
    options: {},
    search: "",
    apt_id: 0,
  }),

  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },

  watch: {
    //  search: function () {
    //   clearTimeout(this.timer);
    //   this.timer = setTimeout(() => {
    //     this.refresh();
    //   }, 500);
    // },
    options: {
      handler() {
        this.refresh();
      },
      // deep: true,
    },
  },

  methods: {
    async refresh() {
      //   this.options.search = this.search;
      // this.options.column = "message";
      const item = {
        url: "/api/get_req_appointment",
        body: new FormData(),
        options: this.options,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        // console.log("===========", res.data.data);
        this.aptRequests = res.data.data.data;
        this.totalItems = res.data.data.total;
      }
    },

    deleteDialogOpen(item) {
      this.delete_dialog = true;
      this.apt_id = item.id;
    },

    async deleteAppointmentReq() {
      //   this.options.search = this.search;
      //   this.options.column = "message";
      const fd = new FormData();
      fd.append("apt_id", this.apt_id);
      const item = {
        url: "/api/delete_req_appointment",
        body: fd,
        options: this.options,
      };
      let res = await this.$store.dispatch("global/post", item);
      if (res) {
        // console.log("===========", res.data.data);
        this.aptRequests = res.data.data.data;
        this.totalItems = res.data.data.total;
      }
      this.close();
    },

    async replyChat(item) {
      // console.log(item);
      this.$store.commit("apt_req/SET_APPOINTMENT_REQUEST", {
        name: item.user.name,
        email: item.user.email,
        image: item.user.image,
        partner_id: item.partner_id,
        apt_id: item.id,
        status: item.status,
      });
      this.$router.push({
        name: "AppointmentChat",
      });
    },

    statusName(status) {
      switch (status) {
        case 1:
          return "PENDING";
          break;
        case 2:
          return "IN-PROGRESS";
          break;
        case 3:
          return "COMPLETED";
          break;
        case 4:
          return "CLOSED";
          break;
      }
    },

    getColor(status) {
      switch (status) {
        case 1:
          return "orange";
          break;
        case 2:
          return "primary";
          break;
        case 3:
          return "green";
          break;
        default:
          return "primary";
          break;
      }
    },

    close() {
      this.dialog = false;
      this.delete_dialog = false;
      this.editedIndex = -1;
      this.apt_id = 0;
    },
  },
};
</script>
