import Axios from "axios";

const state = {
    providers: [],
    teleProviders:[]
};

const getters = {};

const actions = {
    async getTeleProviders(context, options) {
        var fd = new FormData();
        if (options != undefined && options.is_hidden_action) {
            fd.append("is_hidden_action", options.is_hidden_action);
        }
        const item = {
            url: "/api/get_tele_providers",
            body: fd,
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, { root: true });
        context.commit("SET_TELE_PROVIDERS", res.data.data);
        return res;
    },
    async getProviders(context, options) {
        var fd = new FormData();
        if (options != undefined && options.is_hidden_action) {
            fd.append("is_hidden_action", options.is_hidden_action);
        }
        const item = {
            url: "/api/get_providers",
            body: fd,
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, { root: true });
        context.commit("SET_PROVIDERS", res.data.data);
        return res;
    },
};

const mutations = {
    SET_TELE_PROVIDERS(state, data) {
        state.teleProviders = data;
    },
    SET_PROVIDERS(state, data) {
        state.providers = data;
    },
    REFRESH_PROVIDER(state, data) {
        state.providers = data;
    },
    REFRESH_TELE_PROVIDER(state, data) {
        state.teleProviders = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
