import Axios from "axios";

const state = {
    locations: []
};

const getters = {};

const actions = {
    async getLocations(context, options) {
        const item = {
            url: "/api/get_locations",
            body: new FormData(),
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, {
            root: true
        });
        context.commit("SET_LOCATIONS", res.data.data);
        return res;
    }
};

const mutations = {
    SET_LOCATIONS(state, data) {
       // console.log(JSON.stringify(data));
        state.locations = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
