import Axios from "axios";
import router from "../../router/index"
import Vue from "vue";

const state = {
    getBasicUserData: [],
    callTo: {},
};

const getters = {};

const actions = {

    async addUserToCall(context, item) {
        const data = new FormData();
        data.append("send_to", item.sendTo);
        data.append("room", item.roomId);
        data.append('type', item.type || 1) //Type 1 are notification invites
        const r = (await Axios.post("/api/add_user_to_call", data)).data;
        if (r == true) {

            const user = context.rootState.currentUser.user;
            const obj = {
                'is_calling': true,
                'room': item.roomId,
                'name': user.name,
                'email': user.email,
                'location': user.location ?? "",
                "image": user.image,
                'call_to': item.mail ?? item.email,
                'id': user.id
            };
            //console.log('obj>>>>>>>>>>>>>>>>>>>', obj);
           try {
            await Vue.prototype.$db.collection('users').doc(item.mail).set(obj);
           } catch (error) {

           }
            if (item.sendCall) { }
            else {

                console.log("IN ACTION OBJECT  = " + obj);
                context.commit("SET_CALL_TO", obj)
                let rData = router.resolve({
                    name: 'videoCall',
                    // query:{
                    //     id:item.roomId
                    // },
                    // path: `/video-calling/${item.roomId}`,
                    params: {
                        id: item.roomId,
                        callTo: obj,
                    }
                })
                window.open(rData.href, "_blank");

                // router.push({
                //     name: 'videoCall',
                //     // query:{
                //     //     id:item.roomId
                //     // },
                //     // path: `/video-calling/${item.roomId}`,
                //     params: {
                //         id: item.roomId,
                //         callTo: obj,
                //     }
                // }, () => {
                //     console.log("ROUTE PUSH COMPLETED -------------------- ");
                // }, (e) => {
                //     console.log("ROUTE PUSH ABORTED -------------------- ");
                // });
            }
            Vue.prototype.$db.collection('users').doc(item.mail).onSnapshot(function (doc) {
                const data = doc.data();
                if (data) {
                    if (data.rejected) {
                        context.dispatch('makeWebNotification', {
                            title: "Call Declined by " + item.name,
                            body: "Video call declined by " + item.mail,
                            sendTo: item.sendTo
                        });
                    }
                }
            });
        }else{
            context.dispatch('global/snackBarState',{
                show: true,
                color: 'red',
                message: 'User is offline',
            },{root:true})
        }
    },
    makeWebNotification(context, item) {
        const data = new FormData();
        data.append("title", item.title);
        data.append("body", item.body);
        data.append("sendTo", item.sendTo);
        Axios.post('/api/make_notification', data);;
    },
    create_user(context, data) {
        var api_url = "/api/create_user";

        const fd = new FormData();
        fd.append("email", data.email);
        fd.append("phone", data.phone);
        fd.append("name", data.name);
        fd.append("username", data.username);
        fd.append("type", data.type);
        fd.append("password", data.password);
        fd.append("support_team", data.support_team);

        Axios.post(api_url, fd)
            .then(response => {
                // console.log("======basic users====>",response.data);
                var data = response.data.data;
                context.commit("GET_BASIC_USER", data);
            })
            .catch(error => {


                context.dispatch(
                    "global/snackBarState",
                    {
                        show: true,
                        color: "primary",
                        message: error.message
                    },
                    { root: true }
                );


                console.log(error);
            });
    },

    update_user(context, data) {
        var api_url = "/api/update_user";

        const fd = new FormData();
        fd.append("id", data.id);
        fd.append("email", data.email);
        fd.append("phone", data.phone);
        fd.append("name", data.name);
        fd.append("username", data.username);
        fd.append("support_team", data.support_team ? 1 : 0);
        fd.append("type", data.type);
        if (data.new_password)
            fd.append("password", data.new_password);

        fd.append("timezone", data.timezone);



        Axios.post(api_url, fd)
            .then(response => {
                // console.log("======basic users====>",response.data);
                var data = response.data.data;
                context.commit("GET_BASIC_USER", data);


                context.dispatch(
                    "global/snackBarState",
                    {
                        show: true,
                        color: "primary",
                        message: response.data.message
                    },
                    { root: true }
                );
            })
            .catch(error => {

                context.dispatch(
                    "global/snackBarState",
                    {
                        show: true,
                        color: "red",
                        message: "There needs to be atleast one Admin"
                    },
                    { root: true }
                );

                console.log(error);
            });
    },

    getBasicUser(context, data) {
        var api_url = "/api/getBasicUser";
        Axios.get(api_url)
            .then(response => {
                // console.log("======basic users====>",response.data);
                var data = response.data.data;
                context.commit("GET_BASIC_USER", data);
            })
            .catch(error => {
                console.log(error);
            });
    },

    delete_user(context, data) {
        var api_url = "/api/delete_user";
        Axios.post(api_url, data)
            .then(response => {
                // console.log("======basic users====>",response.data);
                var data = response.data.data;
                context.commit("GET_BASIC_USER", data);
                context.dispatch(
                    "global/snackBarState",
                    {
                        show: true,
                        color: "primary",
                        message: response.data.message
                    },
                    { root: true }
                );
            })
            .catch(error => {
                console.log(error);
            });
    }
};

const mutations = {
    SET_CALL_TO(state, data) {
        state.callTo = data
    },
    GET_BASIC_USER(state, data) {
        state.getBasicUserData = data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
