<template>
  <div>
    <div
      :class="[
        this.$route.name == 'videoCall' ? 'notes-card-panel' : 'notes-card',
      ]"
    >
      <div
        :class="[
          this.$route.name == 'videoCall'
            ? 'notes-avatar-panel'
            : 'notes-avatar',
        ]"
      >
        <img :src="note.postedBy.image" alt />
        <div style="width: 100%">
          <p class="notes-person-name">{{ note.postedBy.name }}</p>

          <p
            :class="[
              this.$route.name == 'videoCall'
                ? 'notes-timing-panel'
                : 'notes-timing',
            ]"
            class=""
          >
            {{ note.created_at | moment("from", true) }}
          </p>
        </div>

        <v-icon @click="editNote(note)" size="20" color="primary"
          >mdi-pencil</v-icon
        >
      </div>

      <!-- Desc Widget -->
      <p class="mt-4">{{ note.note }}</p>
      <div v-for="(att, i) in note.attachments" :key="i">
        <!-- Voice Widget -->
        <VoiceWidget
          v-if="att.type == 'A'"
          :file="att"
          @download="downloadAtt"
        />

        <!-- File Widget -->
        <FileWidget
          v-if="att.type == 'F'"
          :file="att"
          @download="downloadAtt"
        />

        <!-- Image Widget -->
        <ImgWidget v-if="att.type == 'I'" :file="att" @download="downloadAtt" />

        <!-- Video Widget -->
        <VideoWidget
          v-if="att.type == 'V'"
          :file="att"
          @download="downloadAtt"
        />
      </div>

      <div class="text-end">
        <v-btn @click="openComments()" class="mt-4" depressed>
          <v-icon size="20" left>mdi-comment-outline</v-icon>Comment
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="dialog" max-height="600px" persistent width="550px">
      <CreateNote :onClose="close" @noteCreated="noteSaved" :note="note" />
    </v-dialog>

    <v-dialog v-model="comments_dialog" scrollable persistent max-width="800">
      <v-card>
        <v-card-actions>
          <v-btn
            class="elevation-1 mr-4"
            @click="comments_dialog = false"
            fab
            light
            small
            color="white"
          >
            <v-icon color="primary">mdi-arrow-left</v-icon>
          </v-btn>
          <v-card-title class="pa-0">Comments</v-card-title>
        </v-card-actions>
        <v-divider class="mx-6"></v-divider>
        <v-card-text style="height: 600px">
          <CommentDialog :noteId="note.id" @back="comments_dialog = false" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-text-field
            class="my-2 mx-3"
            v-model="message"
            append-outer-icon="mdi-send"
            outlined
            rounded
            clear-icon="mdi-close-circle"
            clearable
            placeholder="Type your message..."
            @click:append-outer="sendMessage"
            hide-details
            @click:clear="clearMessage"
          ></v-text-field>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ImgWidget from "./ImageWidget";
import VoiceWidget from "./VoiceWidget";
import FileWidget from "./FileWidget";
import VideoWidget from "./VideoWidget";
import CommentDialog from "./CommentsDialog";
import CreateNote from "./CreateNotes";
export default {
  components: {
    VoiceWidget,
    FileWidget,
    VideoWidget,
    ImgWidget,
    CommentDialog,
    CreateNote,
  },
  props: ["note"],
  data: () => ({
    comments_dialog: false,
    dialog: false,
    message: "",
  }),

  methods: {
    downloadAtt(item) {
      var url = `/api/download_file?uuid=${item}`;
      window.open(url);
    },
    close() {
      this.dialog = false;
    },
    editNote(val) {
      localStorage.setItem("imageBackup", JSON.stringify(val.attachments));
      this.dialog = true;
      this.$store.dispatch("notes/checkDeleteStatus");
      this.$store.dispatch("notes/checkImageStatus", val.attachments);
    },
    noteSaved() {
      this.dialog = false;
      this.$emit("noteEdited");
    },
    openComments() {
      this.$store.dispatch("notes/getComments", this.note.id);
      this.comments_dialog = true;
    },

    sendMessage() {
      this.$store.dispatch("notes/createComment", {
        comment: this.message,
        note_id: this.note.id,
      });
      this.clearMessage();
    },
    clearMessage() {
      this.message = "";
    },
  },
};
</script>

<style>
</style>
