var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card-actions',{staticClass:"py-0 mb-1"},[_c('v-breadcrumbs',{staticClass:"pl-0",attrs:{"items":_vm.bread}})],1),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-0 en-table-card",attrs:{"headers":_vm.headers,"items":_vm.aptRequests,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItems,"items-per-page":10,"footer-props":{
      'items-per-page-options': [10, 30, 50],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Inbox")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"elevation-1 mr-3",attrs:{"fab":"","light":"","small":"","color":"white"},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-sync")])],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"text-white",attrs:{"color":_vm.getColor(item.status),"label":"","small":""}},[_vm._v("\n        "+_vm._s(_vm.statusName(item.status)))])]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":"javascript:;"}},[_c('v-chip',{attrs:{"color":"primary","label":"","small":""},on:{"click":function($event){return _vm.replyChat(item)}}},[_vm._v("\n          "+_vm._s(_vm._f("moment")(item.created_at,"MM/DD/YYYY - h:mm a"))+"\n        ")])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end"},[(_vm.user ? _vm.user.support_team == 1 : '')?_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteDialogOpen(item)}}},[_vm._v("mdi-delete")]):_vm._e()],1)]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete")]),_vm._v(" "),_c('v-card-text',[_vm._v("Are you sure you want to delete?")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.close()}}},[_vm._v("No")]),_vm._v(" "),_c('v-btn',{staticStyle:{"width":"100px"},attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.deleteAppointmentReq()}}},[_vm._v("Yes")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }