<template>
  <v-container>
    <v-card-actions class="py-0 mb-1">
      <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
    </v-card-actions>

    <v-simple-table>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Lookup Tables</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-btn
            @click="reloadList()"
            class="elevation-1"
            fab
            light
            small
            color="white"
          >
            <v-icon color="primary">mdi-sync</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <v-text-field
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:default>
        <tbody>
          <tr v-for="item in list" :key="item.name">
            <td>
              <router-link :to="{ name: item.go_to }">
                {{ item.name }}
              </router-link>
            </td>
            <td>{{ item.count }}</td>
            <td>
              {{
                moment
                  .tz(
                    item.updated_at,
                    user.timezone ? user.timezone : default_timezon
                  )
                  .format("MM/DD/YYYY")
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
import Axios from "axios";
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
  data: () => ({
    default_timezon: "America/Chicago",
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Lookup Tables",
        disabled: true,
        to: "",
      },
    ],
    list: [],
    moment: moment,
  }),

  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },

  created() {
    this.getLookups();
  },

  methods: {
    reloadList() {
      this.getLookups();
    },

    getLookups() {
      this.$store.dispatch("global/actionloaderState", true, { root: true });
      Axios.post("/api/get_lookup_tables", new FormData())
        .then((r) => {
          this.list = r.data;
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((r) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },
  },
};
</script>
