import Axios from "axios";

const state = {
    appointments: []
};

const getters = {};

const actions = {
    async getPatients(context, options) {
        const item = {
            url: "/api/get_patients",
            body: new FormData(),
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });

        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, {
            root: true
        });
        context.commit("SET_PATIENTS", res.data.data);
        return res;
    }
};

const mutations = {
    SET_PATIENTS(state, data) {
        state.appointments = data;
        // console.log(
        //     "APPOINTMENTS =>>>>>>>>" + JSON.stringify(state.appointments)
        // );
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
