<template>
    <div>
        <p v-show="false">{{ showOnlyName }}</p>
        <v-data-table
            :headers="headers"
            :items="providers"
            :options.sync="options"
            :loading="loading"
            :server-items-length="totalItems"
            :search="search"
            style="border: none !important"
            class="elevation-0 en-table-card"
            :items-per-page="30"
            :footer-props="{
                'items-per-page-options': [10, 30, 50]
            }"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Providers</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn
                        class="elevation-1"
                        @click="refresh"
                        fab
                        light
                        small
                        color="white"
                    >
                        <v-icon color="primary">mdi-sync</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-row class="px-4">
                    <v-col cols="12" md="12">
                        <v-text-field
                            dense
                            clearable
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            outlined
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.locations="{ item }">{{
                item.locations.map(e => e.Name).join(",")
            }}</template>

            <template v-slot:item.name="{ item }">
                <div class="d-flex align-center w-100">
                    <div class="d-flex align-center w-100">
                        <v-icon
                            @click="startVideoCall(item)"
                            :disabled="
                                item.color !== '#00cc00' &&
                                    item.color !== '#ffd801'
                            "
                            size="20"
                            color="green"
                            class="mr-3 ml-md-3 ml-sm-3"
                            >mdi-video</v-icon
                        >

                        <v-icon
                            class="mr-3 chat-icon"
                            @click="addForChat(item)"
                            size="20"
                            color="primary"
                            >mdi-android-messages</v-icon
                        >

                        <v-sheet
                            class="mr-3"
                            :color="item.color"
                            elevation="0"
                            height="47"
                            width="5"
                        ></v-sheet>
                        <span class="name-hover" @click="addForChat(item)">{{
                            item.name
                        }}</span>
                    </div>

                    <div class="show-only-mobile">
                        <!-- Show Only Mobile -->
                        <v-icon
                            v-if="user.account_type == 1"
                            color="primary"
                            small
                            class="ml-3"
                            @click="editDialogOpen(item)"
                            >mdi-pencil</v-icon
                        >
                    </div>
                </div>
                </template>

            <template v-slot:item.actions="{ item }">
                <!-- <v-icon
          v-if="item.color == '#00cc00' || item.color == '#ffd801'"
          color="green"
          size="22"
          @click="startVideoCall(item)"
          >mdi-video</v-icon
        > -->

                <!-- <v-icon class="ml-3" @click="addForChat(item)" size="20" color="primary"
          >mdi-android-messages</v-icon
        > -->

                <v-icon
                    v-if="user.account_type == 1"
                    color="primary"
                    small
                    class="ml-3"
                    @click="editDialogOpen(item)"
                    >mdi-pencil</v-icon
                >
            </template>
        </v-data-table>

        <!-- Update dialog -->
        <v-dialog v-model="dialog" persistent scrollable max-width="800px">
            <v-card>
                <v-card-title>
                    <span>Edit Provider</span>
                    <v-spacer></v-spacer>
                    <v-switch
                        v-model="pr.is_active"
                        inset
                        label="Is Active"
                    ></v-switch>
                    <v-switch
                        class="ml-3"
                        v-model="pr.is_hidden"
                        inset
                        label="Is Hidden"
                    ></v-switch>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    <v-tabs
                        v-model="tab"
                        background-color="transparent"
                        color="primary"
                        show-arrows
                    >
                        <v-tab v-for="tabs in tab_items" :key="tabs">{{
                            tabs
                        }}</v-tab>
                    </v-tabs>
                    <v-divider></v-divider>

                    <v-tabs-items v-model="tab">
                        <v-tab-item
                            transition="fade-transition"
                            reverse-transition="fade-transition"
                        >
                            <v-row class="mt-4">

                                <v-col cols="12" md="12">
                                    <div class="d-block d-md-flex mt-5">
                                      <div class="picked-image-con-round">
                                        <img :src="imageUrl" v-if="imageUrl" />
                                      </div>
                                      <div>
                                        <label class="custom-file-upload ml-5">
                                          <input type="file" @change="onFileChange" />
                                        </label>
                                      </div>
                                    </div>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="pr.name"
                                        outlined
                                        dense
                                        label="Name"
                                        :error-messages="nameErrors"
                                        @input="$v.pr.name.$touch()"
                                        @blur="$v.pr.name.$touch()"
                                        required
                                        @keyup.enter="save()"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="12" class="mt-4">
                                    <v-text-field
                                        v-model="pr.email"
                                        outlined
                                        dense
                                        label="Email"
                                        :error-messages="emailErrors"
                                        @input="$v.pr.email.$touch()"
                                        @blur="$v.pr.email.$touch()"
                                        required
                                        @keyup.enter="save()"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="12" class="mt-4">
                                    <v-textarea
                                        v-model="pr.description"
                                        outlined
                                        dense
                                        label="Description"
                                        :error-messages="descriptionErrors"
                                        @input="$v.pr.description.$touch()"
                                        @blur="$v.pr.description.$touch()"
                                        required
                                        counter="500"
                                    ></v-textarea>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="pr.username"
                                        outlined
                                        dense
                                        label="Username"
                                        :error-messages="usernameErrors"
                                        @input="$v.pr.username.$touch()"
                                        @blur="$v.pr.username.$touch()"
                                        required
                                        @keyup.enter="save()"
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-autocomplete
                                        v-model="pr.timezone"
                                        :error-messages="timezoneErrors"
                                        @input="$v.pr.timezone.$touch()"
                                        @blur="$v.pr.timezone.$touch()"
                                        :items="timezoneList"
                                        :loading="timezoneLoading"
                                        :disabled="timezoneLoading"
                                        outlined
                                        label="Timezones"
                                        persistent-hint
                                        required
                                        dense
                                    >
                                        <template v-slot:no-data>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        No results matching " .
                                                        Press
                                                        <kbd>enter</kbd> to
                                                        create a new one
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <h5 class="mb-3">Available Days</h5>
                                    <div class="checkbox-row">
                                        <div
                                            v-for="item in days"
                                            :key="item.value"
                                            class="check-box-divs"
                                            id="testing"
                                        >
                                            <v-checkbox
                                                multiple
                                                class="mt-0"
                                                hide-details=""
                                                v-model="available_days"
                                                :label="
                                                    item.label.substring(0, 3)
                                                "
                                                :value="item.value"
                                            ></v-checkbox>
                                        </div>
                                    </div>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <v-switch
                                        v-model="pr.use_pin_toggle"
                                        inset
                                        dense
                                        class="mt-0 ml-2"
                                        label="Use pin after video call"
                                    ></v-switch>

                                    <v-text-field
                                        label="Set your pin"
                                        v-model="pr.pin_code"
                                        :error-messages="pincodeErrors"
                                        @input="$v.pr.pin_code.$touch()"
                                        @blur="$v.pr.pin_code.$touch()"
                                        dense
                                        outlined
                                        type="number"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <!-- Tab Item 2 -->
                        <v-tab-item
                            transition="fade-transition"
                            reverse-transition="fade-transition"
                        >
                            <v-row class="mt-3">
                                <!-- <v-col cols="12" md="12">
                  <v-text-field
                    v-model="pr.old_password"
                    :error-messages="oldPasswordErrors"
                    @input="$v.pr.old_password.$touch()"
                    @blur="$v.pr.old_password.$touch()"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    label="Old Password"
                    outlined
                    dense
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col> -->

                                <v-col cols="12" md="12">
                                    <v-text-field
                                        v-model="pr.new_password"
                                        :error-messages="newPasswordErrors"
                                        @input="$v.pr.new_password.$touch()"
                                        @blur="$v.pr.new_password.$touch()"
                                        :append-icon="
                                            show2 ? 'mdi-eye' : 'mdi-eye-off'
                                        "
                                        :type="show2 ? 'text' : 'password'"
                                        label="New Password"
                                        outlined
                                        dense
                                        @click:append="show2 = !show2"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-switch
                        dense
                        class="mt-0 mb-1 ml-4"
                        inset
                        hide-details=""
                        v-model="switch1"
                        label="Call Log"
                    ></v-switch>

                    <v-switch
                        dense
                        class="mt-0 mb-1 ml-4"
                        inset
                        hide-details=""
                        v-model="is_psychiatric"
                        label="Psychiatric"
                    ></v-switch>

                    <v-switch
                        dense
                        class="mt-0 mb-1 ml-4"
                        inset
                        hide-details=""
                        v-model="is_talk_therepy"
                        label="Talk Therapy"
                    ></v-switch>

                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">Cancel</v-btn>

                    <v-btn
                        color="primary"
                        depressed
                        style="width: 100px"
                        @click="save()"
                        >Save</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {
    required,
    minLength,
    maxLength,
    email,
    requiredIf
} from "vuelidate/lib/validators";
import { log } from "../agora/utils/utils";

export default {
    data: () => ({
        file: null,
        imageUrl: null,
        timezoneList: [],
        timezoneLoading: false,

        tab: null,
        tab_items: ["General", "Password"],
        switch1: false,
        is_psychiatric: false,
        is_talk_therepy: false,
        loading: false,
        totalItems: 10,
        dialog: false,
        timer: null,
        options: {},
        show1: false,
        show2: false,
        search: "",
        headers: [
            {
                text: "Name",
                align: "start",
                value: "name",
                width: "400px"
            },
            {
                text: "Username",
                align: "start",
                value: "username",
                width: "200px"
            },
            { text: "Email", value: "email", width: "350px" },

            {
                text: "",
                align: "end",
                sortable: false,
                value: "actions",
                width: "100px"
            }
        ],

        pr: {
            name: "",
            email: "",
            description: "",
            username: "",
            smallImage: "",
            is_hidden: 0,
            is_active: 1,
            // old_password: "",
            new_password: "",
            timezone: "",
            use_pin_toggle: false,
            pin_code: ""
        },

        days: [
            { label: "Sunday", value: 1 },
            { label: "Monday", value: 2 },
            { label: "Tuesday", value: 3 },
            { label: "Wednesday", value: 4 },
            { label: "Thursday", value: 5 },
            { label: "Friday", value: 6 },
            { label: "Saturday", value: 7 }
        ],
        available_days: []
    }),

    validations: {
        pr: {
            name: { required },
            email: { required, email },
            description: { required, maxLength: maxLength(500) },
            username: { required },

            // old_password: {
            //   required: requiredIf(function (abc) {
            //     return this.tab == 1;
            //   }),
            // },
            new_password: {
                required: requiredIf(function(abc) {
                    return this.tab == 1;
                })
            },
            timezone: { required },
            pin_code: {
                required: requiredIf(function(abc) {
                    return this.pr.use_pin_toggle == true;
                })
            }
        }
    },

    created() {
        this.initialize();
        this.timezoneLoading = true;
        this.$store
            .dispatch("global/get", {
                url: "/api/get_timezones"
            })
            .then(r => {
                // this.timezoneList = r.data.map(e => {
                //     e.value = e.original_value;
                //     return e;
                // });
                this.timezoneList = r.data;
                this.timezoneLoading = false;
            });

        // if (this.user.account_type != 1) {
        //   this.headers.pop();
        // }
    },

    watch: {
        search: function() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.refresh();
            }, 500);
        },
        options: {
            handler() {
                this.refresh();
                //console.log("Options Handler Called" + JSON.stringify(this.options));
            }
            // deep: true,
        }
    },

    computed: {
        ...mapState({
            user: state => state.currentUser.user,

            providers: state => state.teleProviders.providers
        }),

        showOnlyName() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.headers.splice(1);
            }
            return "";
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.pr.name.$dirty) return errors;
            !this.$v.pr.name.required && errors.push("Name is required");
            return errors;
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.pr.email.$dirty) return errors;
            !this.$v.pr.email.email && errors.push("Must be valid e-mail");
            !this.$v.pr.email.required && errors.push("E-mail is required");
            return errors;
        },

        descriptionErrors() {
            const errors = [];
            if (!this.$v.pr.description.$dirty) return errors;
            !this.$v.pr.description.required &&
                errors.push("Description is required");
            !this.$v.pr.description.maxLength &&
                errors.push("Description must be 500 characters or less");
            return errors;
        },

        usernameErrors() {
            const errors = [];
            if (!this.$v.pr.username.$dirty) return errors;
            !this.$v.pr.username.required &&
                errors.push("Username is required");
            return errors;
        },

        // oldPasswordErrors() {
        //   const errors = [];
        //   if (!this.$v.pr.old_password.$dirty) return errors;
        //   !this.$v.pr.old_password.required && errors.push("Password is required");
        //   return errors;
        // },

        timezoneErrors() {
            const errors = [];
            if (!this.$v.pr.timezone.$dirty) return errors;
            !this.$v.pr.timezone.required &&
                errors.push("Timezone is required");
            return errors;
        },

        pincodeErrors() {
            const errors = [];
            if (!this.$v.pr.pin_code.$dirty) return errors;

            !this.$v.pr.pin_code.required &&
                errors.push("Pin code is required");
            return errors;
        },

        newPasswordErrors() {
            const errors = [];
            if (!this.$v.pr.new_password.$dirty) return errors;

            !this.$v.pr.new_password.required &&
                errors.push("New Password is required");
            return errors;
        }
    },
    methods: {
        addForChat(item) {
            //   this.$store.dispatch("chat/addParticipant", item);
            this.$router.push({
                name: "chat",
                params: {
                    currentParticipant: item
                }
            });
        },
        clearFilters() {
            this.search = "";
        },
        refresh() {
            this.options.search = this.search;
            this.options.column = "name";
            this.options.is_hidden_action = 1;
            this.$store
                .dispatch("teleProviders/getProviders", this.options)
                .then(response => {
                    this.totalItems = response.data.total;
                });
        },

        initialize() {
            this.refresh();
        },

        editDialogOpen(item) {
            // console.log(item);
            this.pr = item;
            this.imageUrl = item.smallImage;

            this.switch1 = item.callLogging == 1 ? true : false;
            this.is_psychiatric = item.is_psychiatric == 1 ? true : false;
            this.is_talk_therepy = item.is_talk_therepy == 1 ? true : false;
            this.pr.use_pin_toggle = item.use_pin == 1 ? true : false;
            this.pr.pin_code = item.pin;

            this.available_days = item.available_days;
            //   this.switch1 =
            //     item.user_setting == null
            //       ? false
            //       : item.user_setting.callLogging == 1
            //       ? true
            //       : false;
            this.dialog = true;
        },

        save() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$store.dispatch("global/snackBarState", {
                    show: true,
                    color: "red",
                    message: "Please check all form fields."
                });
            } else {
                if (this.pr.new_password && this.pr.new_password.length <= 8) {
                    this.$store.dispatch("global/snackBarState", {
                        show: true,
                        color: "red",
                        message: "New Password should be atleast 8 letters"
                    });
                    return;
                }
                // if (this.pr.new_password && !this.pr.old_password) {
                //   this.$store.dispatch("global/snackBarState", {
                //     show: true,
                //     color: "red",
                //     message: "Please enter old password also",
                //   });
                //   return;
                // }
                const data = new FormData();
                data.append("user_id", this.pr.id);
                data.append("name", this.pr.name);
                data.append("email", this.pr.email);
                data.append("description", this.pr.description);
                data.append("username", this.pr.username);
                data.append("callLogging", this.switch1 ? "1" : "0");
                data.append("is_psychiatric", this.is_psychiatric ? "1" : "0");
                data.append("is_active", this.pr.is_active ? 1 : 0);
                data.append("is_hidden", this.pr.is_hidden ? 1 : 0);
                data.append(
                    "is_talk_therepy",
                    this.is_talk_therepy ? "1" : "0"
                );
                data.append(
                    "available_days",
                    JSON.stringify(this.available_days)
                );
                data.append("timezone", this.pr.timezone);
                data.append("use_pin", this.pr.use_pin_toggle ? "1" : "0");

                if (this.pr.use_pin_toggle) {
                    data.append("pin", this.pr.pin_code);
                }

                if (this.pr.new_password) {
                    //   data.append("old_password", this.pr.old_password);
                    data.append("new_password", this.pr.new_password);
                }

                if (this.file) {
                  data.append("image", this.file, this.file.name);
                }

                this.$store
                    .dispatch("global/post", {
                        url: "/api/edit_provider",
                        body: data
                    })
                    .then(async response => {
                        this.pr.smallImage = this.imageUrl;
                        // var index = this.providers.indexOf(this.pr);
                        // this.providers[index].user_setting.callLogging = this.switch1;
                        this.$store.commit(
                            "teleProviders/REFRESH_PROVIDER",
                            this.providers
                        );

                        this.$store.dispatch("global/snackBarState", {
                            show: true,
                            color: "primary",
                            message: "Updated successfully "
                        });
                    })
                    .catch(error => {
                        this.$store.dispatch("global/snackBarState", {
                            show: true,
                            color: "red",
                            message: "Something went wrong !!" + error.message
                        });
                    });
                this.close();
            }
        },

        startVideoCall(item) {
            const roomId = this.makeVideoChatId(item);
            const _this = this;
            this.$db
                .collection("users")
                .doc(item.email)
                .onSnapshot(function(doc) {
                    _this.refresh();
                });
            this.$store
                .dispatch("users/addUserToCall", {
                    sendTo: item.id,
                    roomId: roomId,
                    mail: item.email,
                    name: item.name ? item.name : ""
                })
                .then(r => {});

            //   this.$router.push({
            //     path: `/video-calling/${roomId}`,
            //   });
        },

        makeVideoChatId(item) {
            let id;
            if (item.id > this.user.id) id = `${item.id}_${this.user.id}`;
            else id = `${this.user.id}_${item.id}`;
            const hash = id.split("").reduce(function(a, b) {
                a = (a << 5) - a + b.charCodeAt(0);
                return a & a;
            }, 0);
            return hash;
        },

        onFileChange(e) {
            this.file = e.target.files[0];
            this.imageUrl = URL.createObjectURL(this.file);
        },

        close() {
            this.$v.$reset();
            this.info_dialog = false;
            this.dialog = false;
            this.available_days = [];
        }
    }
};
</script>

<style scoped>
>>> .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0px 10px;
    background: #f1f3f6;
}

>>> .v-data-table > .v-data-table__wrapper > table > thead {
    background: white;
}

>>> .v-data-table__wrapper > table > tbody > tr > td {
    border-top: thin solid rgba(0, 0, 0, 0.12);
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0px 10px 0px 0px !important;
}

>>> .v-data-table__wrapper > table > tbody > tr {
    background: white;
}

@media screen and (max-width: 480px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__cell {
        width: 100% !important;
    }
}

/*  iPads, Tablets (Portrait)*/
@media (min-width: 481px) and (max-width: 768px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }
}

>>> #testing {
    display: flex !important;
    flex-wrap: wrap !important;
}
</style>
