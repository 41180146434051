<template>
  <v-app>
    <v-btn :to="{name: 'dashboard'}" color="primary" small fixed class="ml-5" fab dark top>
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <div class="error-con">
      <vue-parallax-view :layers="layers" :width="'100%'" :height="'100vh'" />
    </div>

     <div class="error-con-mobile">
       <img :src="'/assets/error-mobile.png'" alt="">
    </div>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    layers: [
      {
        name: "bg_start",
        horizontalDisplacement: 0.05,
        verticalDisplacement: 0.05,
        image: require("../../../public/assets/layer-1.png"),
      },
      {
        name: "bg_404",
        horizontalDisplacement: 0.15,
        verticalDisplacement: 0.15,
        image: require("../../../public/assets/layer-2.png"),
      },
      {
        name: "bg_rocket",
        horizontalDisplacement: 0.3,
        verticalDisplacement: 0.3,
        image: require("../../../public/assets/layer-3.png"),
      },
    ],
  }),
};
</script>

<style scoped>

</style>