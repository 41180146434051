import axios from "axios";
const state = {
    logs: [],
    logstabledata: [],
    fileDetail: {},
    logsbylevel: [],
    Attachmentlist: [],
    totalServerItems: 0
};
const getters = {};

const actions = {
    /**
     * @description Get DashBoard List
     * @example It will get all Dashboard Logs Details from Database 🙂
     * @requires null
     * @param null
     * @default context
     * @return json
     */
    getdashboard(context, data) {
        context.dispatch("global/actionloaderState", true, { root: true });
        const url = "/api/GetTotalDashboardLogs";
        axios
            .get(url)
            .then(response => {
                context.dispatch("global/actionloaderState", false, {
                    root: true
                });
                context.commit("UPDATE_DASHBOARD", response.data.data);
            })
            .catch(error => {
                context.dispatch("global/actionloaderState", false, {
                    root: true
                });
                context.dispatch(
                    "global/snackBarState",
                    {
                        show: true,
                        color: "red",
                        message: error.response.data.message
                    },
                    { root: true }
                );
            });
    },

    /**
     * @description Get Logs By Date
     * @example It will get all logs by date 🙂
     * @requires null
     * @param null
     * @default context
     * @return json
     */
    GetAllLogsFiles(context, data) {
        context.dispatch("global/actionloaderState", true, { root: true });
        const url = "/api/GetAllLogsFiles";
        axios.post(url, data).then(response => {
            context.dispatch("global/actionloaderState", false, {
                root: true
            });
            //means file is optimizing
            if (response.data.data == 1) {
                context.dispatch(
                    "global/snackBarState",
                    {
                        show: true,
                        color: "primary",
                        message: response.data.message
                    },
                    { root: true }
                );
            } else {
                // console.log("===================", response);
                context.commit(
                    "UPDATE_LOGS_TABLE_DATA",
                    response.data.data.data
                );
                let total = Number(response.data.data.total);
                context.commit("SET_TOTAL_SERVER_ITEM", total);
                // console.log("Test Data===", response.data.data.data);
                // console.log("Test Total===", response.data.data.total);
            }
        });
    },

    /**
     * @description Delete Log File
     * @example  It will delete log file 🙂
     * @requires filename
     * @param [string] filename
     * @default context
     * @return json
     */
    DeleteLogFile(context, data) {
        return new Promise((resolve, reject) => {
            context.dispatch("global/actionloaderState", true, { root: true });
            const url = "/api/DeleteLogFile";
            axios
                .post(url, data)
                .then(response => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "primary",
                            message: response.data.message
                        },
                        { root: true }
                    );
                    resolve(true);
                })
                .catch(error => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "red",
                            message: error.response.data.message
                        },
                        { root: true }
                    );
                    reject(false);
                });
        });
    },

    /**
     * @description Download Log File
     * @example  It will download log file 🙂
     * @requires filename
     * @param [string] filename
     * @default context
     * @return json
     */
    downloadLogFile(context, data) {
        var api_url = "/api/download_log_file?filename=" + data.filename;
        window.open(api_url, data);
    },

    /**
     * @description Get Log File Detail
     * @example  It WIll Get Log File Details For Specific file🙂
     * @requires filename
     * @param [string] filename
     * @default context
     * @return json
     */
    GetLogFileDetail(context, data) {
        return new Promise((resolve, reject) => {
            context.dispatch("global/actionloaderState", true, { root: true });
            const url = "/api/GetLogFileDetail";
            axios
                .post(url, data)
                .then(response => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    //if file is optimizing
                    if (response.data.data == 1) {
                        context.dispatch(
                            "global/snackBarState",
                            {
                                show: true,
                                color: "primary",
                                message: response.data.message
                            },
                            { root: true }
                        );
                        //else file already optimized
                    } else {
                        context.commit(
                            "UPDATE_FILE_DETAIL",
                            response.data.data
                        );
                        context.dispatch(
                            "global/snackBarState",
                            {
                                show: true,
                                color: "primary",
                                message: response.data.message
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "red",
                            message: error.response.data.message
                        },
                        { root: true }
                    );
                });
        });
    },

    /**
     * @description  Get Logs By Level
     * @example It will get logs file by level 🙂
     * @requires filename,level,page,options
     * @param [string] filename
     * @param [string] level
     * @param [string] page
     * @param [string] options
     * @default context
     * @return json
     */
    GetLogsByLevel(context, data) {
        return new Promise((resolve, reject) => {
            context.dispatch("global/actionloaderState", true, { root: true });
            const url = "/api/GetLogsByLevel";
            axios
                .post(url, data)
                .then(response => {
                    var total = Number(response.data.data.total);
                    context.commit("SET_TOTAL_SERVER_ITEM", total);

                    console.log( response.data.data.data);
                    var arrayOfObj = Object.entries(
                        response.data.data.data
                    ).map(e => e[1]);
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.commit("UPDATE_LOGS_BY_LEVEL", arrayOfObj);
                    resolve(true);
                })
                .catch(error => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "red",
                            message: error.response.data.message
                        },
                        { root: true }
                    );
                    context.commit("UPDATE_LOGS_BY_LEVEL", []);
                    reject(false);
                });
        });
    },
    /**
     * @description Get Attachment list
     * @example It will get Attachment file by level 🙂
     * @param [string] filename
     * @param [string] media_url
     * @param [string] page
     * @param [string] options
     * @default context
     * @return json
     */
     GetAttachmentlist(context, data) {
        return new Promise((resolve, reject) => {
            context.dispatch("global/actionloaderState", true, { root: true });
            const url = "/api/get_attachment_file";
            axios
                .post(url, data)
                .then(response => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    var total = Number(response.data.data.total);
                    context.commit("SET_TOTAL_SERVER_ITEM", total);
                    var arrayOfObj = Object.entries(
                        response.data.data.data
                    ).map(e => e[1]);
                    context.commit("UPDATE_ATTACHMENT_INFO", arrayOfObj);
                    resolve(true);
                })
                .catch(error => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "red",
                            message: error.response.data.message
                        },
                        { root: true }
                    );
                    context.commit("UPDATE_ATTACHMENT_INFO", []);
                    reject(false);
                });
        });
    },


    getLogSettings(context, data) {
        return new Promise((resolve, reject) => {
            context.dispatch("global/actionloaderState", true, {
                root: true
            });
            const url = "/api/get_log_settings";
            axios
                .post(url, data)
                .then(response => {
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "red",
                            message: error.response
                                ? error.response.data.message
                                : "Something went wrong!"
                        },
                        { root: true }
                    );
                });
        });
    },

    updateLogSettings(context, data) {
        return new Promise((resolve, reject) => {
            context.dispatch("global/actionloaderState", true, {
                root: true
            });
            const url = "/api/update_log_settings";
            axios
                .post(url, data)
                .then(response => {
                    resolve(response);
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "primary",
                            message: response.data.message
                        },
                        { root: true }
                    );
                })
                .catch(error => {
                    reject(response);
                    context.dispatch("global/actionloaderState", false, {
                        root: true
                    });
                    context.dispatch(
                        "global/snackBarState",
                        {
                            show: true,
                            color: "red",
                            message: error.response
                                ? error.response.data.message
                                : "Something went wrong!"
                        },
                        { root: true }
                    );
                });
        });
    }
};

const mutations = {
    UPDATE_DASHBOARD(state, logs) {
        state.logs = logs;
    },

    UPDATE_LOGS_TABLE_DATA(state, data) {
        state.logstabledata = data;
    },

    UPDATE_FILE_DETAIL(state, detail) {
        state.fileDetail = detail;
    },

    UPDATE_LOGS_BY_LEVEL(state, logsbylevel) {
        state.logsbylevel = logsbylevel;
    },
    UPDATE_ATTACHMENT_INFO(state, Attachmentlist) {
        state.Attachmentlist = Attachmentlist;
    },

    SET_TOTAL_SERVER_ITEM(state, totalServerItems) {
        state.totalServerItems = totalServerItems;
    },
    UPDATE_TABS(state, tab) {
        switch (tab.type) {
            // General Settings Tabs
            case 0:
                {
                    state.tabs.generalTabs = tab.value;
                }
                break;
            // Company Tabs
            case 1:
                {
                    state.tabs.companyTabs = tab.value;
                }
                break;
            // Employee Tabs
            case 2:
                {
                    state.tabs.employeeTabs = tab.value;
                }
                break;

            // Logs Tabs
            case 3:
                {
                    state.tabs.logsTabs = tab.value;
                }
                break;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
