<template>
  <div>
    <v-container>
      <v-card-actions class="py-0 mb-1">
        <v-breadcrumbs class="pl-0" :items="bread">

        </v-breadcrumbs>
      </v-card-actions>

      <v-card class="en-card elevation-0">
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="editedItem.title"
              :error-messages="titleErrors"
              @input="$v.editedItem.title.$touch()"
              @blur="$v.editedItem.title.$touch()"
              required
              label="Title"
              outlined
              dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <v-chip
              v-for="(item, i) in chipsList"
              :key="i"
              small
              class="mr-1 mb-1"
              @click="addChip(item.meta)"
              >{{ item.meta }}</v-chip
            >
          </v-col>

          <v-col cols="12" md="12">
            <editor
              :initial-value="editedItem.description"
              v-model="editedItem.description"
              :init="{
                height: 400,
                content_style: 'body {font-family: Arial;}',
                branding: false,
                selector: 'textarea#full-featured-non-premium',
                menubar: 'file edit view insert format tools table tc',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                  'textcolor',
                ],
                toolbar:
                  'undo redo | bold italic underline strikethrough removeformat | forecolor backcolor  | fontselect | fontsizeselect | formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | fullscreen  preview save print | insertfile image template link codesample | ltr rtl',
                toolbar_sticky: true,
                toolbar_mode: 'sliding',
              }"
            />
          </v-col>

          <v-col cols="12" md="12">
            <v-combobox
              v-model="editedItem.locations"
              :items="locations"
              :loading="locLoading"
              :disabled="addLoading"
              :search-input.sync="locSearch"
              hide-selected
              @change="comboChange"
              @focus="onFocus()"
              outlined
              label="Locations"
              multiple
              persistent-hint
              small-chips
              :error-messages="locationErrors"
              @blur="$v.editedItem.locations.$touch()"
              required
            >
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  :color="`${item.color} lighten-3`"
                  :input-value="selected"
                  label
                  small
                >
                  <span class="pr-2">{{ item.text }}</span>
                  <v-icon small @click="parent.selectItem(item)"
                    >mdi-close</v-icon
                  >
                </v-chip>
              </template>

              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>No results Found</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" style="width: 100px" @click="update"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-container>

    <!-- Add More dialog -->
    <v-dialog v-model="add_more" max-width="500px">
      <v-card>
        <v-card-title>
          <span>Add More</span>
        </v-card-title>

        <v-card-text>Want to add more Template?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="close">No</v-btn>
          <v-btn
            depressed
            color="primary darken-1"
            width="100px"
            @click="addMore()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import tinymce from "vue-tinymce-editor";

import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    tinymce,
    editor: Editor,
  },
  data: () => ({
    company_id: 0,
    locations: [],

    locSearch: "a",
    addLoading: false,
    loading: false,
    search: "",
    locLoading: false,
    locations: [],

    bread: [
      {
        text: "Admin",
        disabled: true,
        to: "",
      },
      {
        text: "Fax Templates",
        disabled: false,
        to: "/faxTemplates",
      },

      {
        text: "Add New",
        disabled: true,
        to: "",
      },
    ],

    add_more: false,
  }),

  validations: {
    editedItem: {
      title: { required },
      locations: { required },
    },
  },
  mounted() {},
  computed: {
    ...mapState({
      serviceLocations: (state) => state.faxTemplates.serviceLocations,
      editedItem: (state) => state.faxTemplates.tinymc_obj,
      chipsList: (state) => state.faxTemplates.chipsList,
    }),

    locationErrors() {
      const errors = [];
      if (!this.$v.editedItem.locations.$dirty) return errors;
      !this.$v.editedItem.locations.required &&
        errors.push("Locations are required");
      return errors;
    },

    // Validations
    titleErrors() {
      const errors = [];
      if (!this.$v.editedItem.title.$dirty) {
        return errors;
      }
      !this.$v.editedItem.title.required &&
        errors.push("Document Name is required.");
      return errors;
    },



  },

  watch: {

    locSearch() {
      if (this.locSearch) this.comboInput();
    },
  },

  methods: {
    addChip(item) {
      this.editedItem.description =
        this.editedItem.description +
        " " +
        `[[${item.toLowerCase().replaceAll(" ", "_")}]]`;
    },


 onFocus(){
      this.comboInput('a')
    },

    comboInput(search) {
      this.locLoading = true;
    //   console.log(this.locSearch);
      const data = new FormData();
      data.append("search", search??this.locSearch);
      this.$store
        .dispatch("global/post", {
          url: "/api/get_locations_search",
          body: data,
        })
        .then((res) => {
          this.locations = res.data.map(function (r) {
            return {
              text: r.Name,
              value: r.SL_ID,
            };
          });
          this.locLoading = false;
          //   console.log(JSON.stringify(res.data));
        });
    },
    comboChange() {
      this.locSearch = "";
      //   console.log(this.tc.location);
    },

    addMore() {
      this.$store.commit("faxTemplates/SET_TINYMC_OBJECT", {
        id: 0,
        title: "",
        locations: [],
        description: "",
      });
      this.add_more = false;
    },

    update() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "All form fields are required.",
        });
      } else {
        const data = new FormData();
        data.append("id", this.editedItem.id);
        data.append("title", this.editedItem.title);
        data.append("description", this.editedItem.description);
        // data.append("locations", this.editedItem.locations);
        data.append(
          "locations",
          JSON.stringify(
            this.editedItem.locations.map((e) => {
              return e.value;
            })
          )
        );

        this.$store
          .dispatch("global/post", {
            url: "/api/update_fax_template",
            body: data,
          })
          .then((r) => {
            this.$store.dispatch("global/snackBarState", {
              show: true,
              color: "primary",
              message: "Saved Successfully",
            });

            this.timer = setTimeout(() => {
              this.$router.replace({ name: "faxTemplates" });
            }, 1000);
          })
          .catch((error) => {
            this.$store.dispatch("global/snackBarState", {
              show: true,
              color: "red",
              message: "Something Went Wrong",
            });
          });
      }
    },

    close() {
      this.$router.push({ name: "dynamicDocuments" });
    },
  },
};
</script>


