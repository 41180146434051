<template>
    <div class>
        <p v-show="false">{{ showOnlyName }}</p>

        <v-data-table
            :headers="headers"
            :items="providers"
            :options.sync="options"
            :loading="loading"
            :server-items-length="totalItems"
            class="elevation-0 en-table-card"
            style="border: none !important"
            :items-per-page="30"
            :footer-props="{
                'items-per-page-options': [10, 30, 50]
            }"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Partners</v-toolbar-title>

                    <v-spacer></v-spacer>
                    <v-btn
                        class="elevation-1 mr-3"
                        @click="refresh"
                        fab
                        light
                        small
                        color="white"
                    >
                        <v-icon color="primary">mdi-sync</v-icon>
                    </v-btn>

                    <!-- dialog -->
                    <v-dialog
                        v-model="dialog"
                        persistent
                        scrollable
                        max-width="900px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-if="user.account_type == 1"
                                color="primary"
                                dark
                                depressed
                                v-bind="attrs"
                                v-on="on"
                                >Add New</v-btn
                            >
                        </template>
                        <v-card>
                            <v-card-title>
                                <span>{{ formTitle }}</span>
                                <v-spacer></v-spacer>
                            <v-switch
                                v-model="tc.is_active"
                                inset
                                label="Is Active"
                            ></v-switch>
                            <v-switch
                                class="ml-3"
                                v-model="tc.is_hidden"
                                inset
                                label="Is Hidden"
                            ></v-switch>
                            </v-card-title>
                            <v-divider></v-divider>

                            <v-card-text>
                                <v-tabs
                                    v-model="tab"
                                    background-color="transparent"
                                    color="primary"
                                    show-arrows
                                >
                                    <v-tab>General</v-tab>
                                    <v-tab v-if="editedIndex != -1"
                                        >Password</v-tab
                                    >
                                </v-tabs>
                                <v-divider></v-divider>

                                <!-- Tab Item 1 -->
                                <v-tabs-items v-model="tab">
                                    <v-tab-item
                                        transition="fade-transition"
                                        reverse-transition="fade-transition"
                                    >
                                        <v-row class="mt-4">
                                            <v-col cols="12" md="12">
                                                <v-autocomplete
                                                    v-model="tc.location"
                                                    :items="locations"
                                                    :loading="locLoading"
                                                    :disabled="addLoading"
                                                    :search-input.sync="
                                                        locSearch
                                                    "
                                                    @change="comboChange"
                                                    @focus="onFocus"
                                                    outlined
                                                    label="Locations"
                                                    persistent-hint
                                                    :error-messages="
                                                        locationErrors
                                                    "
                                                    @blur="
                                                        $v.tc.location.$touch()
                                                    "
                                                    required
                                                >
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title
                                                                    >No results
                                                                    Found</v-list-item-title
                                                                >
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="tc.username"
                                                    outlined
                                                    dense
                                                    :disabled="addLoading"
                                                    label="Username"
                                                    :error-messages="
                                                        usernameErrors
                                                    "
                                                    @input="
                                                        $v.tc.username.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.username.$touch()
                                                    "
                                                    required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="6">
                                                <v-text-field
                                                    v-model="tc.email"
                                                    outlined
                                                    :disabled="addLoading"
                                                    dense
                                                    label="Email"
                                                    :error-messages="
                                                        emailErrors
                                                    "
                                                    @input="
                                                        $v.tc.email.$touch()
                                                    "
                                                    @blur="$v.tc.email.$touch()"
                                                    required
                                                    @keyup.enter="
                                                        editedIndex == -1
                                                            ? add()
                                                            : save()
                                                    "
                                                ></v-text-field>
                                            </v-col>

                                            <v-col
                                                v-if="editedIndex == -1"
                                                cols="12"
                                                md="6"
                                            >
                                                <v-text-field
                                                    v-model="tc.password"
                                                    :error-messages="
                                                        passwordErrors
                                                    "
                                                    @input="
                                                        $v.tc.password.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.password.$touch()
                                                    "
                                                    :disabled="addLoading"
                                                    :append-icon="
                                                        show1
                                                            ? 'mdi-eye'
                                                            : 'mdi-eye-off'
                                                    "
                                                    :type="
                                                        show1
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    label="Password"
                                                    outlined
                                                    dense
                                                    @click:append="
                                                        show1 = !show1
                                                    "
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <!-- <v-text-field
                          v-model="tc.phone"
                          outlined
                          dense
                          :disabled="addLoading"
                          label="Phone"
                          type="number"
                          :error-messages="phoneErrors"
                          @input="$v.tc.phone.$touch()"
                          @blur="$v.tc.phone.$touch()"
                          required
                          @keyup.enter="editedIndex == -1 ? add() : save()"
                        ></v-text-field> -->

                                                <VuePhoneNumberInput
                                                    :disabled="addLoading"
                                                    show-code-on-list
                                                    color="#2d4859"
                                                    valid-color="#2d4859"
                                                    v-model="tc.phone"
                                                    :default-phone-number="
                                                        tc.phone
                                                    "
                                                    :no-example="true"
                                                />
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <v-autocomplete
                                                    v-model="tc.timezone"
                                                    :error-messages="
                                                        timezoneErrors
                                                    "
                                                    @input="
                                                        $v.tc.timezone.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.timezone.$touch()
                                                    "
                                                    :items="timezoneList"
                                                    :loading="timezoneLoading"
                                                    :disabled="timezoneLoading"
                                                    outlined
                                                    label="Timezones"
                                                    persistent-hint
                                                    required
                                                    dense
                                                >
                                                    <template v-slot:no-data>
                                                        <v-list-item>
                                                            <v-list-item-content>
                                                                <v-list-item-title>
                                                                    No results
                                                                    matching " .
                                                                    Press
                                                                    <kbd
                                                                        >enter</kbd
                                                                    >
                                                                    to create a
                                                                    new one
                                                                </v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </template>
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col
                                                cols="12"
                                                :md="
                                                    editedIndex == -1
                                                        ? '6'
                                                        : '12'
                                                "
                                            >
                                                <v-text-field
                                                    v-model="tc.name"
                                                    outlined
                                                    dense
                                                    :disabled="addLoading"
                                                    label="Contact"
                                                    :error-messages="nameErrors"
                                                    @input="$v.tc.name.$touch()"
                                                    @blur="$v.tc.name.$touch()"
                                                    required
                                                    @keyup.enter="
                                                        editedIndex == -1
                                                            ? add()
                                                            : save()
                                                    "
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <!-- :error-messages="psychiatricErrors"
                          @input="$v.tc.psychiatricProviderID.$touch()"
                          @blur="$v.tc.psychiatricProviderID.$touch()" -->
                                                <v-autocomplete
                                                    v-model="
                                                        tc.psychiatricProviderID
                                                    "
                                                    :items="psychiatricList"
                                                    outlined
                                                    label="Psychiatric Provider"
                                                    persistent-hint
                                                    required
                                                    dense
                                                >
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" md="6">
                                                <!-- :error-messages="talkTherapyErrors"
                          @input="$v.tc.talkTherapyProviderID.$touch()"
                          @blur="$v.tc.talkTherapyProviderID.$touch()" -->
                                                <v-autocomplete
                                                    v-model="
                                                        tc.talkTherapyProviderID
                                                    "
                                                    :items="talkTherapyList"
                                                    outlined
                                                    label=" Talk Therapy Provider"
                                                    persistent-hint
                                                    required
                                                    dense
                                                >
                                                </v-autocomplete>
                                            </v-col>

                                            <v-col cols="12" md="12">
                                                <v-switch
                                                    v-model="tc.use_pin_toggle"
                                                    inset
                                                    dense
                                                    class="mt-0 ml-2"
                                                    label="Use pin after video call"
                                                ></v-switch>

                                                <v-text-field
                                                    label="Set your pin"
                                                    v-model="tc.pin_code"
                                                    :error-messages="
                                                        pincodeErrors
                                                    "
                                                    @input="
                                                        $v.tc.pin_code.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.pin_code.$touch()
                                                    "
                                                    dense
                                                    outlined
                                                    type="number"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-tab-item>

                                    <!-- Tab Item 2 -->
                                    <v-tab-item
                                        transition="fade-transition"
                                        reverse-transition="fade-transition"
                                    >
                                        <v-row class="mt-3">
                                            <v-col
                                                cols="12"
                                                md="12"
                                                v-if="
                                                    user.account_type !=
                                                        $constants.ADMIN
                                                "
                                            >
                                                <v-text-field
                                                    v-model="tc.old_password"
                                                    :error-messages="
                                                        oldPasswordErrors
                                                    "
                                                    @input="
                                                        $v.tc.old_password.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.old_password.$touch()
                                                    "
                                                    :append-icon="
                                                        show2
                                                            ? 'mdi-eye'
                                                            : 'mdi-eye-off'
                                                    "
                                                    :type="
                                                        show2
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    label="Old Password"
                                                    outlined
                                                    dense
                                                    @click:append="
                                                        show2 = !show2
                                                    "
                                                ></v-text-field>
                                            </v-col>

                                            <v-col cols="12" md="12">
                                                <v-text-field
                                                    v-model="tc.new_password"
                                                    :error-messages="
                                                        newPasswordErrors
                                                    "
                                                    @input="
                                                        $v.tc.new_password.$touch()
                                                    "
                                                    @blur="
                                                        $v.tc.new_password.$touch()
                                                    "
                                                    :append-icon="
                                                        show3
                                                            ? 'mdi-eye'
                                                            : 'mdi-eye-off'
                                                    "
                                                    :type="
                                                        show3
                                                            ? 'text'
                                                            : 'password'
                                                    "
                                                    label="New Password"
                                                    outlined
                                                    dense
                                                    @click:append="
                                                        show3 = !show3
                                                    "
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-tab-item>
                                </v-tabs-items>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-switch
                                    dense
                                    class="mt-0 mb-1 ml-4"
                                    inset
                                    hide-details=""
                                    v-model="switch1"
                                    label="Call Log"
                                ></v-switch>

                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="close"
                                    >Cancel</v-btn
                                >

                                <v-btn
                                    color="primary"
                                    depressed
                                    style="width: 100px"
                                    @click="
                                        editedIndex == -1 ? add() : update()
                                    "
                                    >Save</v-btn
                                >
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>

                <v-row class="px-4">
                    <v-col cols="4" md="4">
                        <v-select
                            :items="columns"
                            v-model="column"
                            dense
                            @change="search = ''"
                            prepend-inner-icon="mdi-magnify"
                            outlined
                            hide-details
                        ></v-select>
                    </v-col>

                    <v-col cols="8" md="8">
                        <v-text-field
                            v-if="column === 'username'"
                            dense
                            clearable
                            v-model="search"
                            prepend-inner-icon="mdi-magnify"
                            placeholder="Search"
                            outlined
                            hide-details
                        />

                        <v-autocomplete
                            v-else
                            clearable
                            dense
                            :items="locations"
                            :loading="locLoading"
                            :search-input.sync="locSearch"
                            v-model="search"
                            @change="refresh"
                            @focus="onFocus"
                            outlined
                            label="Locations"
                            persistent-hint
                            required
                        >
                            <template v-slot:no-data>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >No results Found</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="d-flex justify-end align-center">
                    <!-- Edit Icon -->
                    <div v-if="user.account_type == 1">
                        <v-icon
                            small
                            color="primary"
                            @click="edit(item)"
                            class="ml-3"
                            >mdi-pencil</v-icon
                        >
                    </div>

                    <!-- Delete Icon -->
                    <div v-if="user.account_type == 1">
                        <v-icon
                            small
                            color="red"
                            @click="deleteDialogOpen()"
                            class="ml-3"
                            >mdi-delete</v-icon
                        >
                    </div>
                </div>
            </template>

            <template v-slot:item.locations="{ item }">
                <div class="d-flex align-center w-100">
                    <div class="d-flex align-center w-100">
                        <v-icon
                            @click="startVideoCall(item)"
                            :disabled="
                                item.color !== '#00cc00' &&
                                    item.color !== '#ffd801'
                            "
                            size="20"
                            color="green"
                            class="mr-3 ml-md-3 ml-sm-3"
                            >mdi-video</v-icon
                        >

                        <v-icon
                            class="mr-3 chat-icon"
                            @click="addForChat(item)"
                            size="20"
                            color="primary"
                            >mdi-android-messages</v-icon
                        >
                        <v-sheet
                            class="mr-3"
                            :color="item.color"
                            elevation="0"
                            height="47"
                            width="5"
                        ></v-sheet>

                        <span class="name-hover" @click="addForChat(item)">{{
                            item.locations ? item.locations.Name : "N/A"
                        }}</span>
                    </div>

                    <div class="show-only-mobile d-flex">
                        <!-- Show Only Mobile -->
                        <div v-if="user.account_type == 1">
                            <v-icon
                                small
                                color="primary"
                                @click="edit(item)"
                                class="ml-3"
                                >mdi-pencil</v-icon
                            >
                        </div>

                        <!-- Delete Icon -->
                        <div v-if="user.account_type == 1">
                            <v-icon
                                small
                                color="red"
                                @click="deleteDialogOpen()"
                                class="ml-3"
                                >mdi-delete</v-icon
                            >
                        </div>
                    </div>
                </div>
            </template>
        </v-data-table>

        <v-dialog v-model="delete_dialog" persistent max-width="290">
            <v-card>
                <v-card-title>Delete</v-card-title>
                <v-card-text>Are you sure you want to delete?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close()">No</v-btn>
                    <v-btn
                        color="primary"
                        depressed
                        style="width: 100px"
                        @click="close()"
                        >Yes</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Axios from "axios";
import {
    required,
    minLength,
    email,
    requiredIf
} from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
    components: {
        VuePhoneNumberInput
    },
    data: () => ({
        column: "location",
        columns: [
            { text: "Username", value: "username" },
            { text: "Location", value: "location" }
        ],
        timezoneList: [],
        psychiatricList: [],
        talkTherapyList: [],
        timezoneLoading: false,
        switch1: false,
        tab: null,
        dialog: false,
        delete_dialog: false,
        locSearch: "a",
        timer: null,
        addLoading: false,
        loading: false,
        options: {},
        totalItems: 0,
        show1: false,
        show3: false,
        show2: false,
        search: "",
        locLoading: false,
        locations: [],
        headers: [
            {
                text: "Location",
                align: "start",
                value: "locations",
                width: "200px"
            },

            { text: "Username", value: "username", width: "200px" },
            { text: "Email", value: "email", width: "200px" },

            {
                text: "",
                align: "end",
                sortable: false,
                value: "actions",
                width: "70px"
            }
        ],
        desserts: [],
        editedIndex: -1,
        tc: {
            name: "",
            username: "",
            email: "",
            phone: "",
            is_hidden: 0,
            is_active: 1,
            location: "",
            timezone: "",
            password: "",
            old_password: "",
            new_password: "",
            use_pin_toggle: false,
            pin_code: "",
            psychiatricProviderID: "",
            talkTherapyProviderID: ""
        }
    }),

    validations: {
        tc: {
            timezone: {
                required: requiredIf(function(abc) {
                    return this.tab != 1;
                })
            },
            name: {
                required
            },
            pin_code: {
                required: requiredIf(function(abc) {
                    return this.use_pin_toggle == true;
                })
            },
            username: { required },
            email: { required, email },
            phone: { required, minLength: minLength(10) },
            location: { required },
            password: {
                required: requiredIf(function(abc) {
                    return this.editedIndex == -1;
                }),
                minLength: minLength(8)
            },

            old_password: {
                required: requiredIf(function(abc) {
                    return (
                        this.update_dialog == true &&
                        this.tab == 1 &&
                        this.user.account_type != this.$constants.ADMIN
                    );
                })
            },
            new_password: {
                required: requiredIf(function(abc) {
                    return this.update_dialog == true && this.tab == 1;
                }),
                minLength: minLength(8)
            }

            // psychiatricProviderID:{
            //   required,
            // },
            // talkTherapyProviderID:{
            //   required,
            // },
        }
    },
    watch: {
        search: function() {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.refresh();
            }, 500);
        },
        options: {
            handler() {
                this.refresh();
                //console.log("Options Handler Called" + JSON.stringify(this.options));
            }
            // deep: true,
        },
        locSearch() {
            if (this.locSearch) this.comboInput();
        }
    },
    created() {
        this.timezoneLoading = true;
        this.$store
            .dispatch("global/get", {
                url: "/api/get_timezones"
            })
            .then(r => {
                // this.timezoneList = r.data.map(e => {
                //     e.value = e.original_value;
                //     return e;
                // });
                this.timezoneList = r.data;
                this.timezoneLoading = false;
            });
        this.getPsyTalkProviders();
    },

    computed: {
        ...mapState({
            providers: state => state.teleProviders.teleProviders,
            user: state => state.currentUser.user
        }),

        showOnlyName() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.headers.splice(1);
            }
            return "";
        },

        formTitle() {
            return this.editedIndex === -1 ? "Add new partner" : "Edit Partner";
        },

        nameErrors() {
            const errors = [];
            if (!this.$v.tc.name.$dirty) return errors;
            !this.$v.tc.name.required && errors.push("Name is required");
            return errors;
        },

        timezoneErrors() {
            const errors = [];
            if (!this.$v.tc.timezone.$dirty) return errors;
            !this.$v.tc.timezone.required &&
                errors.push("Timezone is required");
            return errors;
        },

        pincodeErrors() {
            const errors = [];
            if (!this.$v.tc.pin_code.$dirty) return errors;

            !this.$v.tc.pin_code.required &&
                errors.push("Pin code is required");
            return errors;
        },

        usernameErrors() {
            const errors = [];
            if (!this.$v.tc.username.$dirty) return errors;
            !this.$v.tc.username.required && errors.push("Name is required");
            return errors;
        },

        emailErrors() {
            const errors = [];
            if (!this.$v.tc.email.$dirty) return errors;
            !this.$v.tc.email.email && errors.push("Must be valid e-mail");
            !this.$v.tc.email.required && errors.push("E-mail is required");
            return errors;
        },

        passwordErrors() {
            const errors = [];
            if (!this.$v.tc.password.$dirty) return errors;
            !this.$v.tc.password.minLength &&
                errors.push("Password must be at least 8 characters");
            !this.$v.tc.password.required &&
                errors.push("Password is required");
            return errors;
        },

        phoneErrors() {
            const errors = [];
            if (!this.$v.tc.phone.$dirty) return errors;
            !this.$v.tc.phone.minLength &&
                errors.push("Phone must be at least 10 numbers");
            !this.$v.tc.phone.required && errors.push("Phone is required");
            return errors;
        },

        locationErrors() {
            const errors = [];
            if (!this.$v.tc.location.$dirty) return errors;
            !this.$v.tc.location.required &&
                errors.push("Locations is required");
            return errors;
        },

        oldPasswordErrors() {
            const errors = [];
            if (!this.$v.tc.old_password.$dirty) return errors;

            !this.$v.tc.old_password.required &&
                errors.push("Old Password is required");
            return errors;
        },

        newPasswordErrors() {
            const errors = [];
            if (!this.$v.tc.new_password.$dirty) return errors;

            !this.$v.tc.new_password.required &&
                errors.push("New Password is required");
            return errors;
        }

        // psychiatricErrors() {
        //   const errors = [];
        //   if (!this.$v.tc.psychiatricProviderID.$dirty) return errors;
        //   !this.$v.tc.psychiatricProviderID.required && errors.push("Psychiatric Provider is required");
        //   return errors;
        // },

        // talkTherapyErrors() {
        //   const errors = [];
        //   if (!this.$v.tc.talkTherapyProviderID.$dirty) return errors;
        //   !this.$v.tc.talkTherapyProviderID.required && errors.push("talk Therapy Provider is required");
        //   return errors;
        // },
    },

    methods: {
        //     comboInput(search) {
        //     console.log('combo input getting called');
        //   this.locLoading = true;
        //   const data = new FormData();
        //   data.append("search", search ?? this.locSearch);

        //   this.$store
        //     .dispatch("global/post", {
        //       url: "/api/get_locations_search",
        //       body: data,
        //     })
        //     .then((res) => {
        //       this.locations = res.data.map(function (r) {
        //         return {
        //           text: r.Name,
        //           value: r.SL_ID,
        //         };
        //       });
        //       this.locLoading = false;
        //     });
        // },
        startVideoCall(item) {
            const roomId = this.makeVideoChatId(item);
            const _this = this;
            this.$db
                .collection("users")
                .doc(item.email)
                .onSnapshot(function(doc) {
                    _this.refresh();
                });
            this.$store.dispatch("users/addUserToCall", {
                sendTo: item.id,
                roomId: roomId,
                mail: item.email,
                name: item.name ? item.name : ""
            });

            //   this.$router.push({
            //     path: `/video-calling/${roomId}`,
            //   });
        },

        makeVideoChatId(item) {
            let id;
            if (item.id > this.user.id) id = `${item.id}_${this.user.id}`;
            else id = `${this.user.id}_${item.id}`;
            const hash = id.split("").reduce(function(a, b) {
                a = (a << 5) - a + b.charCodeAt(0);
                return a & a;
            }, 0);
            return hash;
        },
        addForChat(item) {
            //   this.$store.dispatch("chat/addParticipant", item);

            this.$router.push({
                name: "chat",
                params: {
                    currentParticipant: item
                }
            });
        },
        refresh() {
            this.options.search = this.search;
            this.options.column = this.column;
            this.options.is_hidden_action = 1;
            // }
            this.$store
                .dispatch("teleProviders/getTeleProviders", this.options)
                .then(response => {
                    this.totalItems = response.data.total;
                });
        },

        onFocus() {
            this.comboInput("a");
        },

        comboInput(search, item = null) {
            if (!search && !item) return;
            console.log(
                "COMBO INPUT CALLED WITH SEARCH = " + search + " Item = " + item
            );
            if (item != null) {
                this.locations.push({
                    text: item.locations.Name,
                    value: item.locations.SL_ID
                });
                this.tc.location = item.locations.SL_ID;
                return;
            }

            this.locLoading = true;
            //   console.log(this.locSearch);
            const data = new FormData();
            data.append("search", search ?? this.locSearch);
            data.append("filter", 1);
            this.$store
                .dispatch("global/post", {
                    url: "/api/get_locations_search",
                    body: data
                })
                .then(res => {
                    this.locations = res.data.map(function(r) {
                        return {
                            text: r.Name,
                            value: r.SL_ID
                        };
                    });

                    this.locLoading = false;
                    //   console.log(JSON.stringify(res.data));
                });
        },
        comboChange() {
            this.locSearch = "";
            //   console.log(this.tc.location);
        },

        deleteDialogOpen() {
            this.delete_dialog = true;
        },

        edit(item) {
            console.log("============", item);
            this.editedIndex = this.providers.indexOf(item);
            this.tc = item;
            this.tc.use_pin_toggle = item.use_pin == 1 ? true : false;
            this.tc.pin_code = item.pin;

            this.switch1 =
                item.user_setting == null
                    ? false
                    : item.user_setting.callLogging == 1
                    ? true
                    : false;
            if (item.locations) {
                this.tc.location = item.locations.SL_ID;
                this.comboInput(item.locations.Name, item);
            }

            if (item.psychiatric_provider) {
                this.tc.psychiatricProviderID =
                    item.psychiatric_provider.provider_id;
            }

            if (item.talk_therapy_provider) {
                this.tc.talkTherapyProviderID =
                    item.talk_therapy_provider.provider_id;
            }

            this.dialog = true;
        },

        add() {
            if (this.$v.$invalid || !this.tc.password) {
                this.$v.$touch();
                this.$store.dispatch("global/snackBarState", {
                    show: true,
                    color: "red",
                    message: "All form fields is required."
                });
            } else {
                this.addLoading = true;
                const data = new FormData();
                data.append("email", this.tc.email);
                data.append("phone", this.tc.phone);
                data.append("name", this.tc.name);
                data.append("username", this.tc.username);
                data.append("callLogging", this.switch1 ? "1" : "0");
                data.append("timezone", this.tc.timezone);
                data.append("is_active", this.tc.is_active ? 1 : 0);
                data.append("is_hidden", this.tc.is_hidden ? 1 : 0);

                if (this.tc.psychiatricProviderID) {
                    data.append(
                        "psychiatricProviderID",
                        this.tc.psychiatricProviderID
                    );
                }
                if (this.tc.talkTherapyProviderID) {
                    data.append(
                        "talkTherapyProviderID",
                        this.tc.talkTherapyProviderID
                    );
                }

                data.append("use_pin", this.tc.use_pin_toggle ? "1" : "0");

                if (this.tc.use_pin_toggle) {
                    data.append("pin", this.tc.pin_code);
                } else data.append("pin", 0);
                data.append("locations", this.tc.location);
                data.append("password", this.tc.password);
                this.$store
                    .dispatch("global/post", {
                        url: "/api/create_tele_provider",
                        body: data
                    })
                    .then(r => {
                        this.addLoading = false;
                        this.dialog = false;
                        this.refresh();

                        this.$store.dispatch("global/snackBarState", {
                            show: true,
                            color: "primary",
                            message: "Create Successfully"
                        });
                    })
                    .catch(e => {
                        console.log(JSON.stringify(e));
                        this.addLoading = false;
                        this.$store.dispatch("global/snackBarState", {
                            show: true,
                            color: "red",
                            message: "Create Failed!"
                        });
                    });
            }
        },

        update() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                this.$store.dispatch("global/snackBarState", {
                    show: true,
                    color: "red",
                    message: "All form fields are required."
                });
            } else {
                const data = new FormData();
                data.append("id", this.tc.id);
                data.append("email", this.tc.email);
                data.append("phone", this.tc.phone);
                data.append("name", this.tc.name);
                data.append("username", this.tc.username);
                data.append("callLogging", this.switch1 ? "1" : "0");
                data.append("locations", this.tc.location);
                data.append("timezone", this.tc.timezone);
                data.append("is_active", this.tc.is_active ? 1 : 0);
                data.append("is_hidden", this.tc.is_hidden ? 1 : 0);

                if (this.tc.psychiatricProviderID) {
                    data.append(
                        "psychiatricProviderID",
                        this.tc.psychiatricProviderID
                    );
                }
                if (this.tc.talkTherapyProviderID) {
                    data.append(
                        "talkTherapyProviderID",
                        this.tc.talkTherapyProviderID
                    );
                }

                data.append("use_pin", this.tc.use_pin_toggle ? "1" : "0");

                if (this.tc.use_pin_toggle) {
                    data.append("pin", this.tc.pin_code);
                }

                if (
                    this.tc.new_password != undefined &&
                    this.tc.new_password != ""
                )
                    data.append("password", this.tc.new_password);
                this.$store
                    .dispatch("global/post", {
                        url: "/api/update_tele_provider",
                        body: data
                    })
                    .then(r => {
                        this.close();
                        this.refresh();
                        this.$store.dispatch("global/snackBarState", {
                            show: true,
                            color: "primary",
                            message: "Update Successfully"
                        });
                    });
            }
        },

        close() {
            this.dialog = false;
            this.update_dialog = false;
            this.info_dialog = false;
            this.delete_dialog = false;
            this.editedIndex = -1;
            this.$v.$reset();
            this.tc = {
                name: "",
                email: "",
                username: "",
                phone: "",
                location: [],
                password: ""
            };
        },

        getPsyTalkProviders() {
            this.$store
                .dispatch("global/post", {
                    url: "/api/get_psy_talk_providers",
                    body: new FormData()
                })
                .then(async r => {
                    this.psychiatricList = r.data.data.psychiatricList;
                    this.talkTherapyList = r.data.data.talkTherapyList;
                });
        }
    }
};
</script>

<style scoped>
>>> .v-data-table > .v-data-table__wrapper > table {
    border-spacing: 0px 10px;
    background: #f1f3f6;
}

>>> .v-data-table > .v-data-table__wrapper > table > thead {
    background: white;
}

>>> .v-data-table__wrapper > table > tbody > tr > td {
    border-top: thin solid rgba(0, 0, 0, 0.12);
}

>>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0px 10px 0px 0px !important;
}

>>> .v-data-table__wrapper > table > tbody > tr {
    background: white;
}

@media screen and (max-width: 480px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }

     >>> .v-data-table__mobile-row__cell {
        width: 100% !important;
    }
}

/*  iPads, Tablets (Portrait)*/
@media (min-width: 481px) and (max-width: 768px) {
    >>> .v-data-table__wrapper > table > tbody > tr > td {
        border-top: none;
        background-color: white !important;
    }

    >>> .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
        padding: 0 16px !important;
    }

    .chat-icon {
        display: none !important;
    }

    >>> .v-data-table__mobile-row__header {
        display: none !important;
    }
}
</style>
