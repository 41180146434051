import axios from "axios";

const state = {
    get_general_setting: {
        default_timezon: "America/Chicago"
    },
    timezones: [],
    videoProfiles:[
        { value: "120p", text: "120p  (15fps, 65 Kbps)" },
        { value: "120p_1", text: "120p1  (15fps, 65 Kbps)" },
        { value: "120p_3", text: "120p  (15fps, 50 Kbps)" },
        { value: "180p", text: "180p  (15fps, 140 Kbps)" },
        { value: "180p_1", text: "180p1  (15fps, 140 Kbps)" },
        { value: "180p_3", text: "180p  (15fps, 100 Kbps)" },
        { value: "180p_4", text: "180p  (15fps, 120 Kbps)" },
        { value: "240p", text: "240p  (15fps, 200 Kbps)" },
        { value: "240p_1", text: "240p1  (15fps, 200 Kbps)" },
        { value: "240p_3", text: "240p  (15fps, 140 Kbps)" },
        { value: "240p_4", text: "240p  (15fps, 220 Kbps)" },
        { value: "360p", text: "360p  (15fps, 400 Kbps)" },
        { value: "360p_1", text: "360p1  (15fps, 400 Kbps)" },
        { value: "360p_3", text: "360p  (15fps, 260 Kbps)" },
        { value: "360p_4", text: "360p  (30fps, 600 Kbps)" },
        { value: "360p_6", text: "360p  (30fps, 400 Kbps)" },
        { value: "360p_7", text: "360p  (15fps, 320 Kbps)" },
        { value: "360p_8", text: "360p  (30fps, 490 Kbps)" },
        { value: "360p_9", text: "360p  (15fps, 800 Kbps)" },
        { value: "360p_10", text: "360p  (24fps, 800 Kbps)" },
        { value: "360p_11", text: "360p  (24fps, 1000 Kbps)" },
        { value: "480p", text: "480p  (15fps, 500 Kbps)" },
        { value: "480p_1", text: "480p1  (15fps, 500 Kbps)" },
        { value: "480p_2", text: "480p  (30fps, 1000 Kbps)" },
        { value: "480p_3", text: "480p  (15fps, 400 Kbps)" },
        { value: "480p_4", text: "480p  (30fps, 750 Kbps)" },
        { value: "480p_6", text: "480p  (30fps, 600 Kbps)" },
        { value: "480p_8", text: "480p  (15fps, 610 Kbps)" },
        { value: "480p_9", text: "480p  (30fps, 930 Kbps)" },
        { value: "480p_10", text: "480p  (10fps, 400 Kbps)" },
        { value: "720p", text: "720p  (15fps, 1130 Kbps)" },
        { value: "720p_1", text: "720p1  (15fps, 1130 Kbps)" },
        { value: "720p_2", text: "720p  (30fps, 2000 Kbps)" },
        { value: "720p_3", text: "720p  (30fps, 1710 Kbps)" },
        { value: "720p_5", text: "720p  (15fps, 910 Kbps)" },
        { value: "720p_6", text: "720p  (30fps, 1380 Kbps)" },
        { value: "1080p", text: "1080p (15fps, 2080 Kbps)" },
        { value: "1080p_1", text: "1080p1 (15fps, 2080 Kbps)" },
        { value: "1080p_2", text: "1080p (30fps, 3000 Kbps)" },
        { value: "1080p_3", text: "1080p (30fps, 3150 Kbps)" },
        { value: "1080p_5", text: "1080p (60fps, 4780 Kbps)" },
        { value: "1440p", text: "1440p (30fps, 4850 Kbps)" },
        { value: "1440p_1", text: "1440p1 (30fps, 4850 Kbps)" },
        { value: "1440p_2", text: "1440p (60fps, 7350 Kbps)" },
        { value: "4K", text: "4K    (30fps, 8910 Kbps)" },
        { value: "4K_1", text: "4K1    (30fps, 8910 Kbps)" },
        { value: "4K_3", text: "4K    (60fps, 13500 Kbps)" },
      ]
};

const getters = {

    // {{  $store.getters['generalSetting/user_timezone'](item.created_at) }}

    //     {{
    //         moment
    //           .tz(
    //             item.created_at,
    //             user.timezone ? user.timezone : settings.default_timezon
    //           )
    //           .format("MM/DD/YYYY h:mm a")
    // }}
    // user_timezone: ({ state, getters, dispatch}) => (date , user) =>{

    //     if(!state.get_general_setting.length){

    //         dispatch("getAllGeneralSetting")
    //     }

    //    return moment
    //       .tz(
    //         date,
    //         user.timezone ? user.timezone : get_general_setting.default_timezon
    //       )
    //       .format("MM/DD/YYYY h:mm a")

    // }

};
const actions = {


    getAllGeneralSetting(context, data) {

        var api_url = "/api/getAllGeneralSetting";
        axios
            .get(api_url)
            .then(response => {
                var settings = response.data.data;
                context.commit("GET_GENERAL_SETTING", settings);
            })
            .catch(e => {

            })
    },

    getDashboardSetting(context, data) {

        var api_url = "/api/getDashboardSetting";
        axios
            .get(api_url)
            .then(response => {
                var settings = response.data.data;
                context.commit("GET_GENERAL_SETTING", settings);
            })
            .catch(e => {

            })
    },


    get_timezones(context, data) {
        var api_url = "/api/get_timezones";
        axios
            .get(api_url)
            .then(response => {
                console.log(JSON.stringify(response.data));
                context.commit("SET_TIMEZONES", response.data);
            })
            .catch(e => {

            })
    },

    setAPIVariable(context, data) {

        console.log(Object.entries(data))
        const entries = Object.entries(data);
        var formData = new FormData();
        entries.forEach(e => {
            formData.append(e[0], e[1]);
        });


        var api_url = "/api/setAPIVariable";
        axios
            .post(api_url, formData)
            .then(response => {
                var settings = response.data.data;
                // console.log(settings);
                context.commit("GET_GENERAL_SETTING", settings)

                context.dispatch("global/snackBarState", {
                    show: true,
                    color: "primary",
                    message: response.data.message
                }, { root: true }
                );
            })
            .catch(error => {

            })
    }
};
const mutations = {
    GET_GENERAL_SETTING(state, data) {
        state.get_general_setting = data;
    },
    SET_TIMEZONES(state, data) {
        state.timezones = data;
    }

};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
