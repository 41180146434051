<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" md="12">
        <p class="en-fields-label">Twilio API Key :</p>
        <v-text-field
          v-model="item.twilioKey"
          dense
          outlined
          :error-messages="twilioKeyErrors"
          @input="$v.item.twilioKey.$touch()"
          @blur="$v.item.twilioKey.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <p class="en-fields-label">Account SID :</p>
        <v-text-field
          v-model="item.account_sid"
          dense
          outlined
          :error-messages="accountSidErrors"
          @input="$v.item.account_sid.$touch()"
          @blur="$v.item.account_sid.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" md="6">
        <p class="en-fields-label">Twilio Number :</p>
        <v-text-field
          v-model="item.twilio_number"
          dense
          outlined
          :error-messages="twilioNumberErrors"
          @input="$v.item.twilio_number.$touch()"
          @blur="$v.item.twilio_number.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <p class="en-fields-label">Auth Token :</p>
        <v-text-field
          v-model="item.auth_token"
          dense
          outlined
          :error-messages="authTokenErrors"
          @input="$v.item.auth_token.$touch()"
          @blur="$v.item.auth_token.$touch()"
          required
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="12" class="py-0">
        <v-btn
          depressed
          @click="saveAPISetting()"
          large
          color="primary"
          style="width: 170px"
          >Save Changes</v-btn
        >

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="elevation-1 ml-3"
              fab
              light
              small
              color="white"
              @click="openPhoneDialog()"
            >
              <v-icon color="primary">mdi-flask</v-icon>
            </v-btn>
          </template>
          <span>Test</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-dialog v-model="phone_dialog" scrollable max-width="400px">
      <v-card>
        <v-card-title>Enter Your Phone</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <p class="en-fields-label">Phone</p>
              <v-text-field
                type="number"
                v-model="phone"
                dense
                outlined
                :error-messages="phoneErrors"
                @input="$v.phone.$touch()"
                @blur="$v.phone.$touch()"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> Close </v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 120px"
            @click="close()"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, minLength, requiredIf } from "vuelidate/lib/validators";

export default {
  data: () => ({
    item: {
      twilioKey: "",
      account_sid: "",
      twilio_number: "",
      auth_token: "",
    },
    phone: "",
    phone_dialog: false,
  }),
   created(){
    this.item.twilioKey= this.$store.state.generalSetting.get_general_setting.twilioKey
    this.item.account_sid= this.$store.state.generalSetting.get_general_setting.account_sid
    this.item.twilio_number= this.$store.state.generalSetting.get_general_setting.twilio_number
    this.item.auth_token= this.$store.state.generalSetting.get_general_setting.auth_token
  },

  validations: {
    item: {
      twilioKey: { required },
      account_sid: { required },
      twilio_number: { required },
      auth_token: { required },
    },

    phone: {
      required: requiredIf(function (abc) {
        return this.phone_dialog == true;
      }),
      minLength: minLength(10),
    },
  },


  computed: {
    ...mapState({
      get_general_setting: (state) => state.generalSetting.get_general_setting,
    }),

    twilioKeyErrors() {
      const errors = [];
      if (!this.$v.item.twilioKey.$dirty) return errors;
      !this.$v.item.twilioKey.required &&
        errors.push("Twilio Key is required.");
      return errors;
    },

    accountSidErrors() {
      const errors = [];
      if (!this.$v.item.account_sid.$dirty) return errors;
      !this.$v.item.account_sid.required &&
        errors.push("Account SID is required.");
      return errors;
    },

    twilioNumberErrors() {
      const errors = [];
      if (!this.$v.item.twilio_number.$dirty) return errors;
      !this.$v.item.twilio_number.required &&
        errors.push("Twilio Number is required.");
      return errors;
    },

    authTokenErrors() {
      const errors = [];
      if (!this.$v.item.auth_token.$dirty) return errors;
      !this.$v.item.auth_token.required &&
        errors.push("Auth Token is required.");
      return errors;
    },

    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone number is required.");
      !this.$v.phone.minLength &&
        errors.push("Phone number must be at least 10 digits.");
      return errors;
    },
  },

  methods: {
    openPhoneDialog() {
      this.phone_dialog = true;
      this.$v.$reset();
    },

    saveAPISetting() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "Please check all form fields.",
        });
      } else {
        this.$store.dispatch("generalSetting/setAPIVariable", this.item);
      }
    },
    close() {
      this.phone_dialog = false;
      this.$v.$reset();
    },
  },
};
</script>
