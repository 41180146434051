<template>
  <v-card>
    <v-card-title v-if="edit">Edit Appointment</v-card-title>
    <v-card-title v-else> Create Appointment</v-card-title>
    <!-- {{dateObj}} -->
    <!-- {{appointment}} -->
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="mt-3">
        <v-col cols="12" md="7" sm="6">
          <h3>Details</h3>

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <p class="en-fields-label">Patient</p>

              <v-autocomplete
                @input="getPatients"
                :search-input.sync="patSearch"
                outlined
                label="Patient"
                :items="patients"
                :loading="patientsLoading"
                dense
                v-model="patientId"
                :error-messages="patientErrors"
                @keypress="inputing"
                @blur="blurPatient"
                required
              >
                <template v-slot:item="{ item }">
                  <v-sheet color="white">
                    <v-row>
                      <v-col cols="12">
                        <v-card flat>
                          <v-card-title>
                            {{ item.text.split("\n")[0] }}
                          </v-card-title>
                          <v-card-subtitle>
                            {{ item.text.split("\n")[1] }}
                          </v-card-subtitle>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <v-autocomplete
                v-model="status"
                :items="statuses"
                outlined
                dense
                label="Appointment status"
              ></v-autocomplete>
            </v-col>
            <v-col class="pt-0" cols="12" md="12" sm="12">
              <v-autocomplete
                :items="reasons"
                outlined
                dense
                label="Select a reason"
                v-model="reasonId"
                :error-messages="reasonErrors"
                @input="$v.reasonId.$touch()"
                @blur="$v.reasonId.$touch()"
                required
              >
              </v-autocomplete>
              <!-- <v-text-field
               label="Appointment Name"
                dense
                v-model="name"
                outlined
              ></v-text-field> -->

              <!-- <v-combobox
                label="Select a visit reason"
                dense
                outlined
                :items="['Demo 1', 'Demo 2']"
                v-model="visit_reason"
                :error-messages="visitReasonErrors"
                @input="$v.visit_reason.$touch()"
                @blur="$v.visit_reason.$touch()"
                required
              ></v-combobox> -->
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <p class="en-fields-label">Appt. Date</p>
              <v-menu
                v-model="start_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    :error-messages="dateErrors"
                    @input="$v.date.$touch()"
                    @blur="$v.date.$touch()"
                    required
                  ></v-text-field>
                </template>
                <!-- previous v-model="date" -->
                <v-date-picker
                  v-model="temDate2"
                  scrollable
                  :allowed-dates="disablePastDates"
                  @input="start_menu = false"
                  color="primary"
                  @change="changeFormat(temDate2)"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <p class="en-fields-label">Appt. Time</p>
              <v-select
                :items="times"
                v-model="time"
                :error-messages="timeErrors"
                @input="$v.time.$touch()"
                @blur="$v.time.$touch()"
                outlined
                dense
              ></v-select>
              <v-menu
                v-if="false"
                ref="menu2"
                v-model="time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    label="Time"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="timeErrors"
                    @input="$v.time.$touch()"
                    @blur="$v.time.$touch()"
                    required
                  ></v-text-field>
                </template>
                <v-time-picker
                  color="primary"
                  v-if="time_menu"
                  v-model="time"
                  full-width
                  ampm-in-title
                  @click:minute="$refs.menu2.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <p class="en-fields-label">Duration</p>
              <!--  <v-text-field
                type="number"
                v-model="duration"
                label="Duration in minutes"
                dense
                outlined
              ></v-text-field>
              -->
              <v-select
                label="Duration"
                dense
                outlined
                v-model="duration"
                :items="durationItems"
                :error-messages="durationErrors"
                @input="$v.duration.$touch()"
                @blur="$v.duration.$touch()"
                required
              ></v-select>
            </v-col>

            <v-col class="pt-0" cols="12" md="12" sm="12">
              <v-select
                :disabled="!date"
                label="Recurrence"
                dense
                outlined
                v-model="recurrence"
                :items="recurrenceValues"
                @change="recurrenceChange"
                :error-messages="recurrenceErrors"
                @input="$v.recurrence.$touch()"
                @blur="$v.recurrence.$touch()"
                required
              ></v-select>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-autocomplete
                :items="locations"
                v-model="locationId"
                :loading="locLoading"
                :search-input.sync="locSearch"
                label="Service Location"
                dense
                outlined
                :error-messages="locationIdErrors"
                @input="$v.locationId.$touch()"
                @blur="$v.locationId.$touch()"
                required
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <v-textarea
                label="Notes"
                outlined
                v-model="patientSummary"
                required
              ></v-textarea>
              <!-- :error-messages="patientSummaryErrors"
                @input="$v.patientSummary.$touch()"
                @blur="$v.patientSummary.$touch()" -->
            </v-col>

            <!-- <v-col cols="12" sm="6">
          <v-text-field v-model="name" label="Appointment name" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            type="number"
            v-model="atCount"
            label="Attendees count"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            :items="practices"
            :loading="practiceLoading"
            v-model="practiceId"
            label="Practice"
          ></v-autocomplete>
        </v-col>



        <v-col cols="12" sm="6">
          <v-text-field v-model="patientSummary" label="Patient summary" />
        </v-col>
        <v-col cols="12" sm="6">
          <v-autocomplete
            :items="types"
            v-model="type"
            label="Appointment type"
          ></v-autocomplete>
        </v-col> -->
          </v-row>
        </v-col>

        <!-- Resources -->
        <v-col cols="12" md="5" sm="6">
          <h3>Resources</h3>

          <p v-if="providerName" class="en-fields-label mt-2">Provider</p>

          <v-card v-if="providerName" flat class="en-card">
            <div class="d-flex">
              <div style="width: 100%">
                <h3>{{ providerName.categoryName || providerName }}</h3>
                <v-progress-circular
                  v-if="status_indc"
                  indeterminate
                  color="primary"
                  :size="20"
                  :width="3"
                ></v-progress-circular>
                <p v-if="provider_status" class="mb-0">Available</p>
              </div>
            </div>
          </v-card>

          <v-autocomplete
            v-else
            class="mt-10"
            :items="providers"
            v-model="providerId"
            outlined
            dense
            label="Select provider"
            :error-messages="providerIdErrors"
            @input="$v.providerId.$touch()"
            @blur="$v.providerId.$touch()"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="primary" text @click="close(false)"> Close </v-btn>

      <v-btn
        color="primary"
        :disabled="is_running"
        :loading="is_running"
        depressed
        style="width: 100px"
        @click="save()"
        >Save</v-btn
      >
    </v-card-actions>

    <v-dialog v-model="custom_rec" scrollable max-width="600px">
      <v-card>
        <v-card-title>Custom Recurrence</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <p class="en-fields-label mt-2">Repeat every</p>
          <v-row>
            <v-col cols="6" md="4" sm="6">
              <v-text-field
                hide-details=""
                outlined
                v-model="recInterval"
                dense
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" sm="6">
              <v-select
                v-model="recIntervalType"
                outlined
                dense
                hide-details=""
                :items="['Day', 'Month']"
              ></v-select>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <p class="en-fields-label mt-2">Ends</p>
              <v-radio-group v-model="recEndRadio" class="mt-2" dense row>
                <v-radio label="Never"></v-radio>
                <v-radio label="On"></v-radio>
                <v-radio label="After"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col v-if="recEndRadio == 1" cols="6" md="8" sm="6">
              <v-menu
                ref="menu3"
                v-model="custom_rec_date_menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="recDate"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="recDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu3.save(recDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col v-if="recEndRadio == 2" cols="6" md="4" sm="6">
              <v-text-field
                outlined
                dense
                v-model="recNumber"
                label="Appointments"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close(false)"> Close </v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 120px"
            @click="closeRc()"
          >
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment-timezone";

import { mapState } from "vuex";
import Vue from "vue";

export default {
  props: ["appointment", "edit"],
  created() {
    //MAKE TIME LIST
    var d = new Date();
    d.setHours(0, 0, 0, 0);
    for (let i = 0; i < 24 * 6; i++) {
      this.times.push({
        text: moment(d).format("h:mm a"),
        value: d.toTimeString().substring(0, 5),
      });
      d = moment(d).add(10, "m").toDate();
    }

    this.$store.dispatch("themes/getAllGeneralSetting");

    var i = 0;
    while (i < 60) {
      i = i + 10;
      this.durationItems.push({ text: i, value: i }); // what to push unto the rows array?
    }
    // this.getPatients();

    if (!this.edit) this.loadLocations();

    this.loadReasons();
    this.getProviders();
    this.getPractices();
    if (this.appointment) {
      this.providerId = this.appointment.ProviderId;
      if (this.appointment.StartTime) {
        // this.convertTimezone();

        // console.log("edit_date appointment StartTime======",this.appointment.StartTime);
        const date = new Date(this.appointment.StartTime);
        //console.log( date.getFullYear() + "/" + parseInt(date.getMonth()+1) + "/" + date.getDate());
        this.date = `${parseInt(
          date.getMonth() + 1
        )}/${date.getDate()}/${date.getFullYear()}`;
        // this.date = "12/30/2021"; //for test

        // console.log("edit_date ",date, this.date);
        this.time = date.toTimeString().substring(0, 5);

        console.log("TIME = ", this.time);
        if (!this.edit)
          this.duration = this.toMinutes(
            this.appointment.EndTime - this.appointment.StartTime
          );
        else
          this.duration = this.toMinutes(
            Date.parse(this.appointment.EndTime) -
              Date.parse(this.appointment.StartTime)
          );
      }
      this.providerName =
        this.appointment.provider == null ? "" : this.appointment.provider.name;
      if (this.edit) {
        // console.log("Appointment  JSON.stringify(this.appointment));
        this.patientId = this.appointment.PatientID;
        this.getPatientInput(this.patientId);
        this.locSearch = this.appointment.ServiceLocationName;
        // this.loadLocations();

        this.name = this.appointment.AppointmentName;
        this.locationId = this.appointment.ServiceLocationId;
        this.providerId = this.appointment.ProviderId;
        this.reasonId = this.appointment.AppointmentReasonId;
        this.patientSummary = this.appointment.Notes;
        this.status = this.appointment.AppointmentStatus;
      }
    }
    // this.getPatients();
  },
  data: () => ({
    firstLocSearch : "",
    patientSet2: false,
    patientSet: false,
    isRec: false,
    recDate: "",
    recNumber: 0,
    weekDays: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    recInterval: "1",
    recIntervalType: "Day",
    recEndRadio: 0,
    times: [],
    is_running: false,
    reasonId: null,
    reasons: [],
    durationItems: [],

    //V-MODELS
    providers: [],
    providerId: 0,
    providerName: "",
    name: "",
    duration: 20,
    patientId: "",
    visit_reason: "",
    date: "",
    time: "",
    recurrence: "",
    temDate2: "",
    locationId: "",
    patientSummary: "",

    atCount: 0,

    // name: "",
    // practiceId: "",
    status: "",
    // type: "",
    // types: ["Unknown", "Patient", "Other"],

    time_menu: false,
    start_menu: false,
    //LISTS
    patients: [],
    locations: [],
    practices: [],
    statuses: [
      "Unknown",
      "Scheduled",
      "ReminderSent",
      "Confirmed",
      "CheckedIn",
      "Roomed",
      "CheckedOut",
      "NeedsReschedule",
      "ReadyToBeSeen",
      "NoShow",
      "Cancelled",
      "Rescheduled",
      "Tentative",
    ].sort(),

    //LOADING
    practiceLoading: false,
    locLoading: false,
    patientsLoading: false,

    //SEARCH
    locSearch: "",
    patSearch: "",
    provider_status: false,
    status_indc: false,
    custom_rec: false,
    custom_rec_date_menu: false,
  }),

  validations: {
    patientId: { required },
    reasonId: { required },
    // visit_reason: { required },
    date: { required },
    time: { required },
    duration: { required },
    recurrence: { required },

    locationId: { required },
    // patientSummary: { required },
    providerId: { required },
  },

  watch: {
    locSearch() {
      if (this.locSearch != "" && this.locSearch != null) this.loadLocations();
    },

    patSearch() {
      console.log("Patsearch change");
      //   if (this.patSearch) this.getPatientInput(this.patSearch);
    },
  },

  computed: {
    recurrenceValues() {
      if (!this.dateObj) return [];
      let day = this.weekDays[this.dateObj.getDay()];
      let month = this.months[this.dateObj.getMonth()];
      const week = this.getWeekOfMonth(this.dateObj);
      const unit =
        week == 1 ? "st" : week == 2 ? "nd" : week == 3 ? "rd" : "th";
      const weekNumber = week < 5 ? `${week}${unit}` : "last";
      return [
        {
          text: `Does not repeat`,
          value: 0,
        },
        {
          text: `Weekly on ${day}`,
          value: 1,
        },
        {
          text: `Every other week on ${day}`,
          value: 2,
        },
        {
          text: `Monthly on ${weekNumber} ${day}`,
          value: 3,
        },
        {
          text: `Annually on ${weekNumber} ${day} of ${month}`,
          value: 4,
        },
        {
          text: `Custom`,
          value: 5,
        },
      ];
    },

    dateObj() {
      if (this.date) {
        //  alert(this.date);
        const list = this.date.split("/");
        console.log("new Date selected", this.date);
        const date = new Date();
        date.setDate(list[1]);
        date.setMonth(list[0] - 1);
        date.setFullYear(list[2]);
        // alert(date);
        return date;
      }
    },
    ...mapState({
      user: (state) => state.currentUser.user,
      settings: (state) => state.themes.get_general_setting,
    }),

    patientErrors() {
      const errors = [];
      if (!this.$v.patientId.$dirty) return errors;
      !this.$v.patientId.required && errors.push("Patient is required");
      return errors;
    },

    reasonErrors() {
      const errors = [];
      if (!this.$v.reasonId.$dirty) return errors;
      !this.$v.reasonId.required && errors.push("Reason is required");
      return errors;
    },

    visitReasonErrors() {
      const errors = [];
      if (!this.$v.visit_reason.$dirty) return errors;
      !this.$v.visit_reason.required && errors.push("Visit reason is required");
      return errors;
    },

    dateErrors() {
      const errors = [];
      if (!this.$v.date.$dirty) return errors;
      !this.$v.date.required && errors.push("Date is required");
      return errors;
    },

    timeErrors() {
      const errors = [];
      if (!this.$v.time.$dirty) return errors;
      !this.$v.time.required && errors.push("Time is required");
      return errors;
    },

    durationErrors() {
      const errors = [];
      if (!this.$v.duration.$dirty) return errors;
      !this.$v.duration.required && errors.push("Duration is required");
      return errors;
    },

    recurrenceErrors() {
      const errors = [];
      if (!this.$v.recurrence.$dirty) return errors;
      !this.$v.recurrence.required && errors.push("Recurrence is required");
      return errors;
    },

    locationIdErrors() {
      const errors = [];
      if (!this.$v.locationId.$dirty) return errors;
      !this.$v.locationId.required &&
        errors.push("Service Location is required");
      return errors;
    },

    providerIdErrors() {
      const errors = [];
      if (!this.$v.providerId.$dirty) return errors;
      !this.$v.providerId.required && errors.push("Provider is required");
      return errors;
    },

    patientSummaryErrors() {
      const errors = [];
      if (!this.$v.patientSummary.$dirty) return errors;
      !this.$v.patientSummary.required &&
        errors.push("Patient Summary is required");
      return errors;
    },
  },

  methods: {
    init() {},
    changeFormat(date2) {
      this.date = moment(String(date2)).format("MM/DD/YYYY");
    },

    blurPatient(val) {
      Vue.nextTick(() => {
        this.patientSet2 = false;
      });
    },

    inputing(val) {
      if (this.patientSet2) {
        //   this.patientSet=false;
        return;
      }
      setTimeout(() => {
        if (val) this.getPatientInput(this.patSearch);
      }, 200);
      console.log("INPUTING " + val);
    },

    getWeekOfMonth(date = new Date()) {
      const temp = new Date(date.getTime());
      let week = 1;
      temp.setDate(1);
      const weekarray = [0, 1, 2, 3, 4, 5, 6];
      let currentIndex = weekarray.indexOf(temp.getDay());
      if (currentIndex > date.getDay()) week = 0;
      for (let index = 0; index < date.getDate(); index++) {
        if (currentIndex === weekarray.length) {
          week++;
          currentIndex = 0;
        }
        currentIndex++;
      }
      return week;

      // // date.getDay()
      //   let adjustedDate = date.getDate() + date.getDay();
      //   let prefixes = ["0", "1", "2", "3", "4", "5"];
      //   return parseInt(prefixes[(adjustedDate / 7)]) + 1;
    },
    convertTimezone() {
      var userData = localStorage.getItem("en_user");
      var objData = JSON.parse(userData);

      const date = new Date(this.appointment.StartTime);

      //   this.date = date.toISOString().substring(0, 10);
      //   this.time = date.toTimeString().substring(0, 5);

      this.date = moment
        .tz(
          this.appointment.StartTime,
          objData.timezone ? objData.timezone : this.settings.default_timezon
        )
        .format("YYYY-MM-DD");

      this.time = moment
        .tz(
          this.appointment.StartTime,
          objData.timezone ? objData.timezone : this.settings.default_timezon
        )
        .format("HH:mm");
    },

    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    loadReasons() {
      const data = new FormData();
      data.append("archived", 0);
      Axios.post("/api/get_kareo_reasons", data).then((r) => {
        this.reasons = r.data.map((r) => {
          return {
            value: r.R_ID.toString(),
            text: r.reason,
          };
        });

        // console.log("Reason list >>>>>>>" + JSON.stringify(this.reasons));
      });
    },
    getProviders() {
      const options = {
        search: "",
        column: "name",
      };
      this.$store
        .dispatch("teleProviders/getProviders", options)
        .then((response) => {
          this.providers = response.data.data.map((e) => {
            return {
              text: e.name,
              value: e.user_id,
            };
          });
        });
    },
    toMinutes(time, down = false) {
      const roundDownTime = 60 * 1000; // minutes
      const rounded = time - (time % roundDownTime);

      return time / roundDownTime;
    },
    saveDate(date) {
      this.$refs.menu1.save(date);
      this.status_indc = true;
      setTimeout(() => {
        this.provider_status = true;
        this.status_indc = false;
      }, 2000);
    },

    recurrenceChange() {
      if (this.recurrence === 5) this.custom_rec = true;
    },

    save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        this.is_running = true;
        const data = new FormData();
        if (this.appointment && this.edit) {
          data.append("AppointmentId", this.appointment.A_ID);
          data.append("edit", this.edit);
        }

        var userData = localStorage.getItem("en_user");
        var objData = JSON.parse(userData);
        console.log("Selected Date " + this.date);
        console.log("Selected Time " + this.time);
        const date = new Date(this.date + " " + this.time);
        console.log("Created Date " + date);
        const endDate = new Date(
          date.valueOf() + parseInt((this.duration ?? 0).toString()) * 60 * 1000
        );
        console.log("Crated EndDate " + endDate);
        var momentDate = moment(date).format("DD-MMM-YYYY");
        console.log("Moment Date" + momentDate);
        var EndMomentDate = (momentDate =
          moment(endDate).format("DD-MMM-YYYY"));
        console.log("Moment End Date" + EndMomentDate);
        // return;
        const test = true;
        var apiSDate = date;
        var apiSendDate = endDate;
        console.log("APi Start Date" + apiSDate);
        console.log("APi apiSendDate Date" + apiSendDate);
        const entries = Object.entries(this.makeReccuranceRule());
        for (const [key, value] of entries) {
          data.append(key, value);
        }

        data.append("AppointmentName", this.name);
        data.append("PracticeId", "1");
        data.append("ServiceLocationId", test ? 94 : this.locationId);
        data.append("AppointmentStatus", this.status);
        data.append("StartTime", moment(date).format("DD-MM-YYYY H:mm"));
        // endDate.toISOString()
        data.append("EndTime", moment(endDate).format("DD-MM-YYYY H:mm"));
        data.append(
          "TimeZone",
          objData.timezone ? objData.timezone : this.settings.default_timezon
        );
        data.append("PatientId", test ? 1 : this.patientId);
        data.append("AppointmentType", "P");
        data.append("ForRecare", 0);
        data.append("InsurancePolicyAuthorizationId", 0);
        data.append("IsDeleted", 0);
        data.append("IsGroupAppointment", 0);
        data.append("IsRecurring", this.isRec);
        data.append("ProviderId", test ? 27 : this.providerId);
        data.append("AppointmentReasonId", this.reasonId);

        data.append("Notes", this.patientSummary);

        this.$store
          .dispatch("global/post", {
            url: "/api/create_appointment",
            body: data,
          })
          .then((r) => {
            this.is_running = false;
            const resp = JSON.parse(r.data.data);
            if (resp.ErrorResponse.IsError) {
              this.$store.dispatch("global/snackBarState", {
                show: true,
                color: "red",
                message: resp.ErrorResponse.ErrorMessage,
              });
            } else {
              this.$store.dispatch("global/snackBarState", {
                show: true,
                color: "primary",
                message: "Create Successfully",
              });
              this.close(true);
            }
          })
          .catch((error) => {
            this.is_running = false;
            // this.close();
            this.$store.dispatch("global/snackBarState", {
              show: true,
              color: "red",
              message: "Create Failed!",
            });
          });
      }
    },

    makeReccuranceRule() {
      this.isRec = true;
      var e = {
        startDate: this.dateObj.valueOf(),
      };

      var binary = "";
      for (let i = 0; i < 7; i++) {
        if (this.dateObj.getDay() === i) binary += "1";
        else binary += "0";
      }
      var dayFlag = parseInt(binary, 2);

      const week = this.getWeekOfMonth(this.dateObj);
      binary = "";
      for (let i = 5; i > 0; i--) {
        if (week == i) binary += "1";
        else binary += "0";
      }
      const weekFlag = parseInt(binary, 2);

      switch (this.recurrence) {
        case 0: {
          //"Does not repeat"
          this.isRec = false;
          break;
        }
        case 1: {
          //"Weekly on Tuesday"
          e.dayOfWeekFlags = dayFlag;
          break;
        }
        case 2: {
          //"Every other week on Tuesday"
          e.dayOfWeekFlags = dayFlag;
          e.DayInterval = 14;
          //   e.dayOfWeekMonthlyOrdinalFlags = weekFlag

          break;
        }
        case 3: {
          //"Monthly on 2nd Tuesday"
          e.dayOfWeekFlags = dayFlag;
          e.MonthInterval = 1;
          e.dayOfWeekMonthlyOrdinalFlags = weekFlag;
          break;
        }
        case 4: {
          //"Annually on 2nd Tuesday of February"
          e.dayOfWeekFlags = dayFlag;
          e.dayOfWeekMonthlyOrdinalFlags = weekFlag;
          e.monthOfYear = this.dateObj.getMonth() + 1;
          break;
        }
        case 5: {
          //"Custom"
          if (this.recInterval) {
            if (this.recIntervalType === "Day")
              e.DayInterval = this.recInterval;
            else e.MonthInterval = this.recInterval;
          }
          if (this.recEndRadio === 1) {
            e.EndDate = new Date(this.recDate).valueOf();
          }
          if (this.recEndRadio === 2) {
            e.NumOccurrences = this.recNumber;
          }
          break;
        }

        default:
          break;
      }
      return e;
    },
    close(save) {
      this.$emit("close", save);
    },
    getPractices() {
      this.practiceLoading = true;
      const data = new FormData();

      this.$store
        .dispatch("global/post", {
          url: "/api/get_practices",
          body: data,
        })
        .then((res) => {
          this.practices = (res.data ?? []).map(function (r) {
            return {
              text: r.PracticeName,
              value: r.PRAC_ID,
            };
          });
          this.practiceLoading = false;
        });
    },

    getPatients() {
      this.patientSet = true;
      this.patientSet2 = true;
      //   return;
      this.patientsLoading = true;
      const data = new FormData();
      data.append("search", this.patSearch);
      this.$store
        .dispatch("global/post", {
          url: "/api/get_patients",
          body: data,
        })
        .then((res) => {
          this.patients = res.data.data.map(function (r) {
            return {
              text: r.FirstName + r.MiddleName + r.LastName,
              value: r.P_ID,
            };
          });
          this.patientsLoading = false;
        });
    },

    loadLocations() {

      this.locLoading = true;
      const data = new FormData();
      data.append("search", this.locSearch);
      this.$store
        .dispatch("global/post", {
          url: "/api/get_locations_search",
          body: data,
        })
        .then((res) => {
          this.locations = res.data.map(function (r) {
            return {
              text: r.Name,
              value: r.SL_ID,
            };
          });
          this.locLoading = false;
        });
    },

    closeRc() {
      this.custom_rec = false;
    },

    // onFocus() {
    //   this.getPatientInput("a");
    // },

    getPatientInput(search) {
      if (this.patientSet) {
        if (!this.patientSet2) this.patientSet = false;
        return;
      }
      //   alert(search);

      this.patientsLoading = true;
      const data = new FormData();

      console.log("get patient input called with search = " + search);
      data.append("search", search || this.patSearch);

      this.$store
        .dispatch("global/post", {
          url: "/api/get_patients",
          body: data,
        })
        .then((res) => {
          this.patients = res.data.data.map(function (r) {
            return {
              text:
                r.FirstName +
                " " +
                r.MiddleName +
                " " +
                r.LastName +
                "\n (" +
                r.DOB +
                ")",
              value: r.P_ID,
            };
          });
          console.log(this.patients);
          this.patientsLoading = false;
          //   console.log(JSON.stringify(res.data));
        });
    },
    patientChange() {
      //   this.patSearch = "";
    },
  },
};
</script>

<style>
</style>
