var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"mt-3",attrs:{"flat":"","color":"white"}},[_c('h4',{staticClass:"ma-0 mr-3"},[_vm._v("Sync Recent Logs")]),_vm._v(" "),(_vm.last_sync_data)?_c('v-chip',{attrs:{"small":""}},[_vm._v("\n        "+_vm._s(_vm._f("moment")(_vm.last_sync_data.created_at,"MM/DD/YYYY - h:mm a"))+"\n      ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.open_sync_dialog}},[_vm._v("Sync All")]),_vm._v(" "),_c('v-btn',{staticClass:"ml-4",attrs:{"fab":"","light":"","small":"","color":"white"},on:{"click":function($event){return _vm.initialize(0)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-sync")])],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"download-reports-section"},[_c('v-card',{staticClass:"en-card mt-4",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Recent 5 Charges Sync Logs")]),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-row'),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"hide-default-footer":"","headers":_vm.reportHeader,"items":_vm.recentReportLogs,"items-per-page":5},scopedSlots:_vm._u([{key:"item.start_date",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.moment
                    .tz(
                      item.start_date,
                      _vm.user.timezone ? _vm.user.timezone : _vm.default_timezon
                    )
                    .format("MM/DD/YYYY"))+"\n              ")])]}},{key:"item.end_date",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.moment
                    .tz(
                      item.end_date,
                      _vm.user.timezone ? _vm.user.timezone : _vm.default_timezon
                    )
                    .format("MM/DD/YYYY"))+"\n              ")])]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","dark":"","depressed":"","color":_vm.statusColors(item)}},[_vm._v(_vm._s(_vm.statusName(item)))])]}}])})],1)],1)]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"download-reports-section"},[_c('v-card',{staticClass:"en-card mt-4",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Recent 5 Appointments Sync Logs")]),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-row'),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"hide-default-footer":"","headers":_vm.appHeader,"items":_vm.appointmentlogs,"items-per-page":5},scopedSlots:_vm._u([{key:"item.app_process",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(item.fetched + " / " + item.total)+"\n              ")])]}},{key:"item.start_date",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.moment
                    .tz(
                      item.start_date,
                      _vm.user.timezone ? _vm.user.timezone : _vm.default_timezon
                    )
                    .format("MM/DD/YYYY"))+"\n              ")])]}},{key:"item.end_date",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.moment
                    .tz(
                      item.end_date,
                      _vm.user.timezone ? _vm.user.timezone : _vm.default_timezon
                    )
                    .format("MM/DD/YYYY"))+"\n              ")])]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(item.status == 1 || item.status == 3)?_c('v-icon',{on:{"click":function($event){return _vm.recall(item)}}},[_vm._v("mdi-sync")]):_vm._e()]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","dark":"","depressed":"","color":_vm.statusColors(item)}},[_vm._v(_vm._s(_vm.statusName(item)))])]}}])})],1)],1)]),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"download-reports-section"},[_c('v-card',{staticClass:"en-card",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Recent 5 Patients Sync Logs")]),_vm._v(" "),_c('v-spacer')],1),_vm._v(" "),_c('v-row'),_vm._v(" "),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"hide-default-footer":"","headers":_vm.patientHeader,"items":_vm.patientlogs,"items-per-page":5},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
                    var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v("\n                "+_vm._s(_vm.moment
                    .tz(
                      item.start_date,
                      _vm.user.timezone ? _vm.user.timezone : _vm.default_timezon
                    )
                    .format("MM/DD/YYYY"))+"\n              ")])]}},{key:"item.status",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","dark":"","depressed":"","color":_vm.statusColors(item)}},[_vm._v(_vm._s(_vm.statusName(item)))])]}}])})],1)],1)])],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.sync_dialog),callback:function ($$v) {_vm.sync_dialog=$$v},expression:"sync_dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Sync Data")])]),_vm._v(" "),_c('v-card-text',[_vm._v("There are already some jobs in processing state , You can wait or\n        Click Force Run , Force run will cancel any previous jobs and Start a\n        New Job.")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.sync_dialog = false}}},[_vm._v("Back")]),_vm._v(" "),_c('v-btn',{attrs:{"depressed":"","color":"primary darken-1","width":"100px"},on:{"click":function($event){return _vm.getSyncData()}}},[_vm._v("Force Run")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }