<template>
  <div class="mx-4 mt-5">
       <v-chip class="mr-2 mb-1" @click="nameClick">Name</v-chip>
    <v-chip class="mr-2 mb-1" @click="phoneClick">Provider</v-chip>
    <v-chip class="mr-2 mb-1" @click="emailClick">Appointment Time</v-chip>
    <v-chip class="mr-2 mb-1" @click="locationClick">Appointment Location</v-chip>

    <div class="mt-5 mb-4">
      <editor
        :initial-value="coordinator_email"
        v-model="coordinator_email"
        :init="{
          images_upload_url: 'postAcceptor.php',
          images_upload_base_path: '/some/basepath',
          images_upload_credentials: true,
          height: 300,
          content_style: 'body {font-family: Arial;}',
          branding: false,
          selector: 'textarea#full-featured-non-premium',
          menubar: 'file edit view insert format tools table tc',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'textcolor',
          ],
          toolbar:
            'undo redo | bold italic underline strikethrough removeformat | forecolor backcolor  | fontselect | fontsizeselect | formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | fullscreen  preview save print | insertfile image template link codesample | ltr rtl',
          toolbar_sticky: true,
          toolbar_mode: 'sliding',
        }"
      />
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="save()"
        depressed
        large
        style="width: 170px"
        >Save Changes</v-btn
      >
    </v-card-actions>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";
import tinymce from "vue-tinymce-editor";

import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    tinymce,
    editor: Editor,
  },
  data: () => ({
    coordinator_email: "",
  }),
  created(){
     this.coordinator_email= this.$store.state.generalSetting.get_general_setting.coordinator_email
  },
  methods:{
           nameClick(){
          this.coordinator_email=(this.coordinator_email??"")+"[[**name**]]"
      },
         phoneClick(){
          this.coordinator_email=(this.coordinator_email??"")+"[[**provider_name**]]"
      },
         emailClick(){
          this.coordinator_email=(this.coordinator_email??"")+"[[**start_time**]]"
      },
       locationClick(){
        this.coordinator_email = (this.coordinator_email ?? "") + "[[**apppointment_location**]]";
    },
       save() {

          this.$store.dispatch("generalSetting/setAPIVariable", {coordinator_email:this.coordinator_email});
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: "Save Successfully.",
        });

    },
  }
};
</script>
