const state = {
    chatTo: {
        name: "",
        email: "",
        image: "",
        partner_id: "",
        apt_id: "",
        status: ""
    },
    messages: [],
    // unreadCount:0,
};

const getters = {};

const actions = {
    getReqAppointmentChat(context, chatTo) {
        return new Promise((resolve, reject) => {
            context.commit("LOADING", true)
            const fd = new FormData();
            fd.append("apt_id", chatTo.apt_id);
            fd.append("partner_id", chatTo.partner_id);
            context.dispatch('global/post', {
                url: "/api/get_req_appointment_chat",
                body: fd
            }, { root: true }).then(r => {
                resolve(r);
                // console.log("APPOINTMENT REQUEST_CHAT >>>>>>>>>>>>>> " + JSON.stringify(r.data.data));
                context.commit("LOADING", false)
                context.commit("SET_APPOINTMENT_REQUEST_CHAT", r.data.data.messages)
                context.commit("SET_STATUS", r.data.data.status)
            })
        });
    },

    sendMessage(context, item) {
        return new Promise((resolve, reject) => {
            context.commit("LOADING", true)
            const fd = new FormData();
            fd.append("apt_id", item.apt_id);
            fd.append("message", item.message);
            context.dispatch('global/post', {
                url: "/api/send_message",
                body: fd
            }, { root: true }).then(r => {
                context.commit("LOADING", false)
                context.commit("SET_APPOINTMENT_REQUEST_CHAT", r.data.data.messages)
                context.commit("SET_STATUS", r.data.data.status)
                resolve(r);
            })
        });
    },

    changeStatus(context, item) {
        context.commit("LOADING", true)
        const fd = new FormData();
        fd.append("apt_id", item.apt_id);
        fd.append("status", item.status);
        context.dispatch('global/post', {
            url: "/api/change_status",
            body: fd
        }, { root: true }).then(r => {
            context.dispatch(
                "global/snackBarState",
                {
                    show: true,
                    color: "primary",
                    message: r.data.message
                },
                { root: true }
            );
            context.commit("LOADING", false)
            context.commit("SET_STATUS", r.data.data)
        })
    },

};

const mutations = {
    LOADING(state, loading) {
        state.loading = loading;
    },
    SET_APPOINTMENT_REQUEST(state, data) {
        state.chatTo = data;
    },
    SET_APPOINTMENT_REQUEST_CHAT(state, data) {
        state.messages = data;
    },
    SET_STATUS(state, status) {
        state.chatTo.status = status;
    },

    RESET_APPOINTMENT_REQUEST(state, item) {
        state.chatTo.name = "";
        state.chatTo.email = "";
        state.chatTo.image = "";
        state.chatTo.partner_id = "";
        state.chatTo.apt_id = "";
        state.chatTo.status = "";
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};


// /assets/default_user.png