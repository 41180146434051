<template>
  <div class="mt-5 mx-4">
        <v-chip class="mr-2 mb-1" @click="nameClick">Name</v-chip>
    <v-chip class="mr-2 mb-1" @click="phoneClick">Provider</v-chip>
    <v-chip class="mr-2 mb-1" @click="emailClick">Appointment Time</v-chip>
    <v-chip class="mr-2 mb-1" @click="locationClick">Appointment Location</v-chip>
    <v-textarea
      class="mt-4"
      outlined
      label="Message"
      v-model="provider_sms"
      :error-messages="smsErrors"
      @input="$v.provider_sms.$touch()"
      @blur="$v.provider_sms.$touch()"
    >
    </v-textarea>

    <v-card-actions class="px-0">
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        @click="save()"
        depressed
        large
        style="width: 170px"
        >Save Changes</v-btn
      >
    </v-card-actions>
  </div>
</template>


<script>
import { required } from "vuelidate/lib/validators";

export default {
  data: () => ({
    provider_sms: "",
  }),
  validations: {
    provider_sms: { required },
  },

  computed: {
    smsErrors() {
      const errors = [];
      if (!this.$v.provider_sms.$dirty) return errors;
      !this.$v.provider_sms.required && errors.push("Message is required");
      return errors;
    },
  },
 created(){
     this.provider_sms= this.$store.state.generalSetting.get_general_setting.provider_sms
  },
  methods: {
                   nameClick(){
          this.provider_sms=this.provider_sms+"[[**name**]]"
      },
         phoneClick(){
          this.provider_sms=this.provider_sms+"[[**provider_name**]]"
      },
         emailClick(){
          this.provider_sms=this.provider_sms+"[[**start_time**]]"
      },
      locationClick(){
          this.provider_sms=this.provider_sms+"[[**appointment_location**]]"
      },
    save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "Message is required.",
        });
      } else {
          this.$store.dispatch("generalSetting/setAPIVariable", {provider_sms:this.provider_sms});
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: "Save Successfully.",
        });
      }
    },
  },
};
</script>
