import Vue from "vue";
import Vuex from "vuex";

import themes from "./modules/ui/theme";
import global from './modules/global';
import currentUser from './modules/CurrentUser'
import appointments from './modules/appointments'
import patients from './modules/patients';
import teleProviders from './modules/teleProviders';
import faxTemplates from './modules/faxTemplates';
import locations from './modules/locations';
import notes from './modules/notes'
import generalSetting from './modules/generalSetting';
import users from './modules/users';
import notifications from './modules/notifications'
import chat from "./modules/chatModule"
import admin_log_reader from "./modules/admin_log_reader"
import apt_req from "./modules/apt_req"


import createPersistedState from "vuex-persistedstate";
import router from "../router";

Vue.use(Vuex);


const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
        // if(key =='client') return;
        if(key =='showLoginSnackBar') return;
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};


const store = new Vuex.Store({
    plugins: [
        createPersistedState({
            getState: (function(key) {
                try {
                    return JSON.parse(localStorage.getItem(key));
                } catch (err) {

                        // localStorage.removeItem('en_user');
                        // localStorage.removeItem('vuex');
                        localStorage.clear();
                        window.location = "";
                        // var cookies = document.cookie.split(";");
                        // for (var i = 0; i < cookies.length; i++)
                        // {
                        //     var spcook =  cookies[i].split("=");

                        //     var d = new Date();
                        //     d.setDate(d.getDate() - 1);
                        //     var expires = ";expires="+d;
                        //     var name=spcook[0];
                        //     //alert(name);
                        //     var value="";
                        //     document.cookie = name + "=" + value + expires + "; path=/acc/html";

                        // }

                        // window.location = ""; // TO REFRESH THE PAGE
                        // this.route.replace({ name: "login" });

                }
            }),
            setState: (key, state) => localStorage.setItem(key, JSON.stringify(state,getCircularReplacer()))
        })
    ],
    modules: {
        themes,
        global,
        currentUser,
        appointments,
        patients,
        teleProviders,
        faxTemplates,
        locations,
        notes,
        generalSetting,
        users,
        notifications,
        chat,
        admin_log_reader,
        apt_req
    },

    state: {},
    getters: {},
    mutations: {},
    actions: {}
});

export default store;
