import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyDqP-4Xcc-ktDc-UugTcGIxZQGNIR3FA38",
    authDomain: "entouchvue.firebaseapp.com",
    projectId: "entouchvue",
    storageBucket: "entouchvue.appspot.com",
    messagingSenderId: "142416483116",
    appId: "1:142416483116:web:b16f132221b4514bec1289"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
