import Axios from "axios";

const state = {
    notes: [],
    comments: [],
    loading: false,
    get_Delete_Status: false,
    setImageData: [],
    deletedImageStatus: false
};

const getters = {};

const actions = {
    getNotes(context, appId) {
        const data = new FormData();
        data.append('appointment_id', appId);
        context.dispatch('global/post', {
            url: "/api/get_notes",
            body: data
        }, { root: true }).then(r => {
            context.commit("SET_NOTES", r.data.data)
        })
    },
    getComments(context, noteId) {
        context.commit("LOADING", true)
        const data = new FormData();
        data.append('note_id', noteId);
        context.dispatch('global/post', {
            url: "/api/get_comments",
            body: data
        }, { root: true }).then(r => {
            // console.log("NOTES RESPONSE >>>>>>>>>>>>>> " + JSON.stringify(r.data.data));
            context.commit("LOADING", false)
            context.commit("SET_COMMENTS", r.data.data)
        })
    },
    createComment(context, item) {
        const data = new FormData();
        data.append('comment', item.comment);
        data.append('note_id', item.note_id);
        context.dispatch('global/post', {
            url: "/api/create_comment",
            body: data
        }, { root: true }).then(r => {
            context.commit("SET_COMMENTS", r.data.data)
        })

    },
    deleteNotesImage(context, data) {
        context.dispatch('global/post', {
            url: "/api/deleteNotesImage",
            body: data
        }, { root: true }).then(response => {
            context.commit("IMAGE_DELETED", true);
        })
    },
    checkDeleteStatus(context, status) {
        context.commit("SET_DELETE_STATUS", true);
    },
    checkImageStatus(context, data) {
        if (data.createNewNote == 0) {
            context.commit("SET_IMAGE_STATUS", []);
        } else {
            context.commit("SET_IMAGE_STATUS", data);
        }
    },
};

const mutations = {
    LOADING(state, loading) {
        state.loading = loading;
    },
    SET_NOTES(state, notes) {
        state.notes = notes;
    },
    SET_COMMENTS(state, comments) {
        state.comments = comments
    },
    SET_DELETE_STATUS(state, status) {
        state.get_Delete_Status = status;
    },
    IMAGE_DELETED(state, status) {
        state.deletedImageStatus = status;
    },
    SET_IMAGE_STATUS(state, data) {
        state.setImageData = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
