import Axios from "axios";

const state = {
    notifications:[],
    loading:false,
    timer:null
};

const getters = {};

const actions = {
    getNotifications(context){
        context.commit("setLoader",true);
        // clearInterval(context.state.timer);
        // context.commit("setTimer",setInterval(() => {
            Axios.post("/api/get_web_notifications", new FormData()).then((r) => {
                context.commit("setNotifications",r.data);
                context.commit("setLoader",false);
        });
        // }, 2000));

    },
    deleteNotifications(context,item){
        const data = new FormData();
        if(item.all)
        data.append('all',1)
        else
        data.append('ids',item.ids)
        Axios.post('/api/delete_web_notifications',data).then(r=>{
            context.dispatch('getNotifications');
        })
    }
};

const mutations = {
    setTimer(state,timer){
        state.timer=timer;
    },
    setLoader(state,val){
        state.loading=val
    },

    setNotifications(state,list){
        state.notifications = list
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
