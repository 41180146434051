<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="reasonsList"
      :search="search"
      class="elevation-0 en-table-card"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Reason</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            class="elevation-1 mr-3"
            @click="reloadList"
            fab
            light
            small
            color="white">
            <v-icon color="primary">mdi-sync</v-icon>
          </v-btn>

          <!-- Add new dialog -->
          <v-dialog v-model="dialog" persistent scrollable max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark depressed v-bind="attrs" v-on="on"
                >Add New</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12" md="12" sm="12">
                    <v-autocomplete
                      outlined
                      dense
                      label="Category"
                      v-model="category.category"
                      :items="categoiesList"
                      :error-messages="categoryErrors"
                      @input="$v.category.category.$touch()"
                      @blur="$v.category.category.$touch()"
                      required
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="category.name"
                      outlined
                      dense
                      label="Title"
                      :error-messages="titleErrors"
                      @input="$v.category.name.$touch()"
                      @blur="$v.category.name.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      v-model="category.description"
                      outlined
                      label="Description"
                      :error-messages="descErrors"
                      @input="$v.category.description.$touch()"
                      @blur="$v.category.description.$touch()"
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  :disabled="addLoading"
                  :loading="addLoading"
                  style="width: 100px"
                  @click="save()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <v-text-field
              dense
              clearable
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon
            small
            color="primary"
            @click="editDialogOpen(item)"
            class="mr-3"
            >mdi-pencil</v-icon
          >
          <v-icon small color="red" @click="deleteDialogOpen()" class="mr-3"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn color="primary" depressed style="width: 100px" @click="close()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  data: () => ({
      id:0,
      editedItem:{},
      categoiesList:[],
    addLoading: false,
    search: "",
    headers: [
      {
        text: "Title",
        value: "name",
      },
      { text: "Description", value: "description" },
      { text: "Category", value: "category.name" },
      {
        text: "Actions",
        align: "end",
        sortable: false,
        value: "actions",
        width: "70px",
      },
    ],

    category: {
      name: "",
      description: "",
      category: 0,
    },
    dialog: false,
    delete_dialog: false,
    editedIndex: -1,
    reasonsList: [],
  }),

  validations: {
    category: {
      name: { required },
      description: { required },
      category: { required },
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New" : "Update";
    },

    titleErrors() {
      const errors = [];
      if (!this.$v.category.name.$dirty) return errors;
      !this.$v.category.name.required && errors.push("Title is required");
      return errors;
    },

    descErrors() {
      const errors = [];
      if (!this.$v.category.description.$dirty) return errors;
      !this.$v.category.description.required &&
        errors.push("Description is required");
      return errors;
    },

    categoryErrors() {
      const errors = [];
      if (!this.$v.category.category.$dirty) return errors;
      !this.$v.category.category.required &&
        errors.push("Category is required");
      return errors;
    },
  },

  methods: {
    getCategories() {
      const data = new FormData();
      data.append("archived", 0);
      Axios.post("/api/get_categories", data).then((r) => {
        this.categoiesList = (r.data??[]).map(r=>{
            return {
                text:r.name,
                value:r.id,
                id:r.id
            }
        });
        this.loading = false;
      });
    },
    reloadList() {
      this.loading = true;
      const data = new FormData();
      data.append("archived", 0);
      Axios.post("/api/get_admin_notes", data).then((r) => {
        this.reasonsList = r.data??[];
        this.loading = false;
      });
    },
    initialize() {
        this.getCategories();
      this.reloadList();
    },
    save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        const data = new FormData();
        data.append("name", this.category.name);
        data.append("id", this.id);
        data.append("description", this.category.description);
        data.append("category_id", this.category.category);

        Axios.post("/api/create_admin_note", data).then((r) => {
          this.close();
        });
      }
    },

    editDialogOpen(item) {
        console.log("ITEM =   "+JSON.stringify(item));
      this.editedIndex = this.reasonsList.indexOf(item);
      this.category = JSON.parse(JSON.stringify(item));
      this.category.category = item.category_id
      this.id=item.id
      this.dialog = true;
    },

    deleteDialogOpen() {
      this.delete_dialog = true;
    },

    close() {
      this.dialog = false;
      this.delete_dialog = false;
      this.editedIndex = -1;
      this.$v.$reset();
      this.category.name = "";
      this.category.description = "";
      this.category.category = "";
      this.reloadList();
    },
  },
};
</script>
