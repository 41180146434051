<template>
  <v-container>
    <v-card-actions class="py-0 mb-1">
      <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
    </v-card-actions>

    <v-data-table
      :headers="headers"
      :items="reasonList"
      :search="search"
      class="elevation-0 en-table-card"
      :footer-props="{
        'items-per-page-options': [10, 30, 50],
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Visit Reason</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            class="elevation-1 mr-3"
            @click="reloadList"
            fab
            light
            small
            color="white"
          >
            <v-icon color="primary">mdi-sync</v-icon>
          </v-btn>

          <!-- Add new dialog -->
          <v-dialog v-model="dialog" persistent scrollable max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark depressed v-bind="attrs" v-on="on"
                >Add New</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="reason.R_ID"
                      outlined
                      dense
                      type="number"
                      label="Reason ID"
                      :error-messages="reasonIdErrors"
                      @input="$v.reason.R_ID.$touch()"
                      @blur="$v.reason.R_ID.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="reason.reason"
                      outlined
                      dense
                      label="Name"
                      :error-messages="nameErrors"
                      @input="$v.reason.reason.$touch()"
                      @blur="$v.reason.reason.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <p class="en-fields-label">Select Color</p>
                    <v-swatches
                      v-model="reason.color"
                      inline
                      :swatches="swatches"
                    ></v-swatches>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-switch
                  dense
                  class="mt-0 mb-1 ml-4"
                  inset
                  hide-details=""
                  v-model="entouch_only"
                  label="Entouch Only"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  :disabled="addLoading"
                  :loading="addLoading"
                  style="width: 100px"
                  @click="save()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <v-text-field
              dense
              clearable
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.color="{ item }">

        <v-icon class="ml-3" v-if="item.color == null" color="primary">mdi-alert</v-icon>
        <v-icon v-else :color="item.color" size="50">mdi-checkbox-blank</v-icon>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon
            small
            color="primary"
            @click="editDialogOpen(item)"
            class="mr-3"
            >mdi-pencil</v-icon
          >
          <v-icon small color="red" @click="deleteDialogOpen(item)" class="mr-3"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn color="primary" depressed style="width: 100px" @click="delete_item()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Axios from "axios";
import { required } from "vuelidate/lib/validators";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
  components: { VSwatches },
  data: () => ({
    entouch_only: false,
    addLoading: false,
    loading: false,
    search: "",
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Lookup Tables",
        disabled: false,
        to: "/lookup-tables",
      },

      {
        text: "Visit Reason",
        disabled: true,
        to: "",
      },
    ],

    headers: [
      {
        text: "Reason ID",
        value: "R_ID",
        width: "200px",
      },
      {
        text: "Name",
        value: "reason",
      },
      {
        text: "Color",
        value: "color",
      },
      {
        text: "Actions",
        align: "end",
        sortable: false,
        value: "actions",
        width: "70px",
      },
    ],
    dialog: false,
    delete_dialog: false,
    editedIndex: -1,

    reason: {
        id:0,
      R_ID: "",
      reason: "",
      color: "",
    },
    reasonList: [],
    swatches: [
      "#444444",
      "#04D0B8",
      "#FE9334",
      "#808285",
      "#00A0CF",
      "#8F441C",
      "#FFA8E5",
      "#80EE97",
      "#006582",
      "#D01182",
      "#FEFE45",
      "#078B5A",
      "#D3D3D3",
    ],
  }),

  validations: {
    reason: {
      R_ID: { required },
      reason: { required },
    },
  },
  created() {
    this.initialize();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New" : "Update";
    },

    reasonIdErrors() {
      const errors = [];
      if (!this.$v.reason.R_ID.$dirty) return errors;
      !this.$v.reason.R_ID.required && errors.push("Reason ID is required");
      return errors;
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.reason.reason.$dirty) return errors;
      !this.$v.reason.reason.required && errors.push("Name is required");
      return errors;
    },
  },

  methods: {
    initialize() {
      this.reloadList();
    },
    reloadList() {
      this.loading = true;
      const data = new FormData();
      data.append("archived", 0);
      this.$store.dispatch("global/actionloaderState", true, { root: true });

      Axios.post("/api/get_kareo_reasons", data)
        .then((r) => {
          this.reasonList = r.data;
          this.loading = false;
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((r) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },
    save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        const data = new FormData();
        data.append("R_ID", this.reason.R_ID);
        data.append("reason", this.reason.reason);
        data.append("color", this.reason.color);
        data.append("id", this.id);
        Axios.post("/api/create_kareo_reason", data).then((r) => {
          this.close();
          this.reloadList();
        });
      }
    },

    editDialogOpen(item) {
      this.id = item.id;
      this.editedIndex = this.reasonList.indexOf(item);
      this.reason = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    deleteDialogOpen(item) {
         this.reason.id = item.id;
      this.delete_dialog = true;
    },
    delete_item(){
    let obj={
        id:this.reason.id,
    };
      Axios
        .post("/api/reason_delete_item", obj)
        .then((r) => {
          this.delete_dialog = false;
          this.$store.dispatch(
            "global/snackBarState",
            {
              show: true,
              color: "primary",
              message: "Delete successfully",
            },
            { root: true }
          );
          this.reloadList();
          this.close();
        })
        .catch((e) => {
          this.$store.dispatch(
            "global/snackBarState",
            {
              show: true,
              color: "red",
              message: "Failed Visit Reason Delete. Please try again",
            },
            { root: true }
          );
          this.delete_dialog = false;
          this.close();
        });
    },

    close() {
      this.dialog = false;
      this.delete_dialog = false;
      this.editedIndex = -1;
      this.$v.$reset();
      this.reason = {
          id:0,
        R_ID: "",
        reason: "",
        color: "",
      };
    },
  },
};
</script>
