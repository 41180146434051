import AgoraRTC from 'agora-rtc-sdk'
import EventEmitter from 'events'

export default class RTCClient {
    constructor() {
        // Options for joining a channel
        this.option = {
            appId: '',
            channel: '',
            uid: '',
            token: '',
        }
        this.client = null;
        this.localStream = null;
        this._eventBus = new EventEmitter();
    }

    //init client and Join a channel
    joinChannel(option) {
        return new Promise((resolve, reject) => {

            this.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
            this.client.init(option.appid, () => {
                console.log("AST-2 init success")
                this.clientListener()
                this.client.join(option.token ? option.token : null, option.channel, option.uid, (uid) => {
                    console.log("AST-4 join channel: " + this.option.channel + " success, uid: ", uid)
                    this.option = {
                        appid: option.appid,
                        token: option.token,
                        channel: option.channel,
                        uid: uid,
                    }
                    resolve()
                }, (err) => {
                    console.error("client join failed", err)
                })
            }, (err) => {
                reject(err)
                console.error(err)
            })
            console.log("AST-3 [agora-vue] appId", option.appid);
        })
    }
    getDevices(callback){
        AgoraRTC.getDevices(callback);
    }

    publishStream(cameraId,micId,videoProfile) {
        return new Promise((resolve, reject) => {
            // Create a local stream
            const constraints = {
                streamID: this.option.uid,
                audio: true,

                video: true,
                screen: false,
            }
            if(cameraId)    constraints.cameraId = cameraId;
            if(micId)    constraints.microphoneId= micId;
            this.localStream = AgoraRTC.createStream(constraints)
// video Qualities -> https://docs.agora.io/en/faq/API%20Reference/web/interfaces/agorartc.videoencoderconfiguration.html
            if(videoProfile)
           this.localStream.setVideoProfile(videoProfile);

            // Initialize the local stream
            this.localStream.init(() => {
                console.log("AST-5 init local stream success")
                resolve(this.localStream)

                // Publish the local stream
                this.client.unpublish(this.localStream)
                this.client.publish(this.localStream, (err) => {
                    console.log("publish failed")
                    console.error(err)
                })
            }, (err) => {
                reject(err)
                console.error("init local stream failed ", err)
            })
        })
    }

    pause() {
        this.client.unpublish(this.localStream)
    }
    resume() {
        this.client.publish(this.localStream)
    }

    clientListener() {
        const client = this.client

        client.on('stream-added', (evt) => {
            // The stream is added to the channel but not locally subscribed
            this._eventBus.emit('stream-added', evt)
        })
        client.on('stream-subscribed', (evt) => {
            this._eventBus.emit('stream-subscribed', evt)
        })
        client.on('stream-removed', (evt) => {
            this._eventBus.emit('stream-removed', evt)
        })
        client.on('peer-online', (evt) => {
            this._eventBus.emit('peer-online', evt)
        })
        client.on('peer-leave', (evt) => {
            this._eventBus.emit('peer-leave', evt)
        })


        client.on('mute-audio', (evt) => {
            this._eventBus.emit('mute-audio', evt)
        })
        client.on('unmute-audio', (evt) => {
            this._eventBus.emit('unmute-audio', evt)
        })
        client.on('mute-video', (evt) => {
            this._eventBus.emit('mute-video', evt)
        })
        client.on('unmute-video', (evt) => {
            this._eventBus.emit('unmute-video', evt)
        })
    }

    on(eventName, callback) {
        this._eventBus.on(eventName, callback)
    }


    leaveChannel() {
        return new Promise((resolve, reject) => {
            // Leave the channel
            this.client.unpublish(this.localStream, (err) => {
                console.log(err)
            })
            this.client.leave(() => {
                // Stop playing the local stream
                if (this.localStream.isPlaying()) {
                    this.localStream.stop()
                }
                // Close the local stream
                this.localStream.close()
                this.client = null
                resolve()
                console.log("client leaves channel success");
            }, (err) => {
                reject(err)
                console.log("channel leave failed");
                console.error(err);
            })
        })
    }
}
