<template>
  <div>
    <!-- {{ settings }} -->

    <div class="d-flex mb-5">
      <v-icon size="25" color="primary">mdi-shape-plus</v-icon>
      <h5 class="mb-0 ml-2">Change Logo</h5>
    </div>

    <!-- <input type="file" @change="onFileChange" /> -->
    <v-row>
      <v-col cols="12" sm="6" md="5">
        <div class="picked-image-con">
          <img v-if="settings.imageurl" :src="settings.imageurl" />
          <img :src="'/assets/et_icon_dark.png'" v-else />
        </div>
        <div>
          <label class="custom-file-upload">
            <input type="file" @change="onFileChange" />
            <!-- <v-icon>mdi-file-image</v-icon>Upload Logo -->
          </label>
        </div>
      </v-col>
      <v-col cols="12" sm="6" md="7">
        <p class="en-fields-label">Default Global Timezone</p>
        <v-select
          placeholder="Select default timezone"
          dense
          outlined
          v-model="default_timezon"
          :items="timezones"
        ></v-select>

        <p class="en-fields-label">Min Appointment Interval (in Minutes)</p>
        <v-text-field
          v-model="appointment_interval"
          placeholder="Enter time"
          dense
          outlined
          type="number"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider class="my-5"></v-divider>

    <div class="d-flex mb-5">
      <v-icon size="25" color="primary">mdi-format-color-fill</v-icon>
      <h5 class="mb-0 ml-2">Change Colors</h5>
    </div>

    <v-row>
      <v-col cols="12" md="4">
        <p class="en-fields-label">Sidebar Color</p>
        <v-card class="en-card mb-0 d-flex elevation-0">
          <!-- <sketch-picker
            style="z-index: 4"
            v-model="colors.darker"
            @input="updateDarker()"
          /> -->

          <v-swatches
            v-model="colors.darker"
            :color="colors.darker"
            @input="updateDarker()"
            show-fallback
            fallback-input-type="color"
            popover-x="right"
          ></v-swatches>
          <p class="en-p2 ml-3">{{ colors.darker }}</p>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <p class="en-fields-label">Primary Color</p>
        <v-card class="en-card mb-0 d-flex elevation-0">
          <!-- <sketch-picker
            style="z-index: 4"
            v-model="colors.primary"
            @input="updatePrimary()"
          /> -->
          <v-swatches
            v-model="colors.primary"
            :color="colors.primary"
            @input="updatePrimary()"
            show-fallback
            fallback-input-type="color"
            popover-x="right"
          ></v-swatches>
          <p class="en-p2 ml-3">{{ colors.primary }}</p>
        </v-card>
      </v-col>

      <v-col cols="12" md="12">
        <v-divider class="my-5"></v-divider>

        <div class="d-flex mb-5">
          <v-icon size="25" color="primary">mdi-video</v-icon>
          <h5 class="mb-0 ml-2">Video Quality</h5>
        </div>
        <v-select
          label="Select video profile"
          outlined
          v-model="videoProfile"
          :items="videoProfiles"
        ></v-select>
      </v-col>

      <v-col cols="12" md="12">
        <v-btn
          color="primary"
          @click="save()"
          large
          depressed
          style="width: 150px"
          >Save</v-btn
        >
        <v-btn
          class="ml-5"
          @click="reset()"
          large
          depressed
          style="width: 150px"
          >Reset</v-btn
        >
      </v-col>
    </v-row>

    <v-snackbar
      :timeout="1500"
      :color="snackbar.color"
      top
      right
      v-model="snackbar.show"
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// import { Sketch } from "vue-color-sketch";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import { mapState } from "vuex";

export default {
  components: {
    VSwatches,
    // "sketch-picker": Sketch,
  },
  data: () => ({
    videoProfile: "",
    default_timezon: "",
    appointment_interval: "",
    file: null,
    sidebar_color: false,
    primary_color: false,
    callLoggingSwitch: false,
    defaultColors: {
      primary: "#2D4859",
      darker: "#202431",
    },
    colors: {
      primary: "",
      darker: "",
    },
  }),

  computed: {
    ...mapState({
      snackbar: (state) => state.themes.showSnackBar,
      url: (state) => state.themes.logo,
      settings: (state) => state.themes.get_general_setting,
      timezones: (state) => state.generalSetting.timezones,
      videoProfiles: (state) => state.generalSetting.videoProfiles,
    }),
  },

  created() {
    var this1 = this;

    this.$store
      .dispatch("themes/getAllGeneralSetting")
      .then((response) => {
        this1.colors.primary = response.primary
          ? response.primary
          : this1.$store.state.themes.colors.primary;
        this1.colors.darker = response.darker
          ? response.darker
          : this1.$store.state.themes.colors.darker;
        this1.default_timezon = response.default_timezon
          ? response.default_timezon
          : this1.default_timezon;
        this1.appointment_interval = response.appointment_interval
          ? response.appointment_interval
          : this1.appointment_interval;

        this1.videoProfile = response.videoProfile
          ? response.videoProfile
          : this1.videoProfile;

        if (response.primary) {
          this1.$store.dispatch("themes/updatePrimary", response.primary);
          this1.$vuetify.theme.themes.light.primary = response.primary;
        }
        if (response.darker) {
          this1.$store.dispatch("themes/updateDarker", response.darker);
          this1.$vuetify.theme.themes.light.darker = response.darker;
        }
      })
      .catch((error) => {});

    this.$store.dispatch("generalSetting/get_timezones");
  },

  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      this.settings.imageurl = URL.createObjectURL(this.file);
      this.$store.dispatch("themes/updateLogo", URL.createObjectURL(this.file));
    },

    updatePrimary() {
      this.$store.dispatch("themes/updatePrimary", this.colors.primary);
      this.$vuetify.theme.themes.light.primary = this.colors.primary;
    },

    updateDarker() {
      this.$store.dispatch("themes/updateDarker", this.colors.darker);
      this.$vuetify.theme.themes.light.darker = this.colors.darker;
    },

    callLoggingToggle() {
      this.callLoggingSwitch = !this.callLoggingSwitch;
    },

    reset() {
      this.colors.primary = this.defaultColors.primary;
      this.colors.darker = this.defaultColors.darker;
      this.$store.dispatch("themes/updatePrimary", this.colors.primary);
      this.$store.dispatch("themes/updateDarker", this.colors.darker);
      this.$vuetify.theme.themes.light.primary = this.colors.primary;
      this.$vuetify.theme.themes.light.darker = this.colors.darker;
      this.$store.dispatch("themes/uiConfigrationSetting", {
        colors: this.colors,
        // image: this.file,
        reset: 1,
      });
    },

    save() {
      this.$store.dispatch("themes/uiConfigrationSetting", {
        colors: this.colors,
        image: this.file,
        default_timezon: this.default_timezon,
        appointment_interval: this.appointment_interval,
        videoProfile: this.videoProfile,
      });
    },
  },
};
</script>

<style scoped>
/* Mobile devices */
@media screen and (max-width: 480px) {
  >>> .v-snack__wrapper {
    min-width: 285px !important;
  }
}
</style>
