import Axios from "axios";

const state = {
    appointments: [],
    getHeaderData: [],
    appointments_filter_date: "",
    providers: [],
    picker: new Date().toISOString().substr(0, 10),
    counts: [],
    options: {},
    filters: {
        search_by: "Patient Name", //
        provider_model2: [],
        location_model2: []
    },
};

const getters = {};

const actions = {

    async getPatients(context, options) {
        context.commit('SET_OPTIONS', options);

        const formData = new FormData();

        const item = {
            url: "/api/get_patients",
            body: formData,
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, {
            root: true
        });


        return res;
    },
    async getAppointments(context, options) {


        context.commit('SET_OPTIONS', options);

        const formData = new FormData();
        var dap = false;
        if (options.dashboard_appointments != undefined) {
            dap = options.dashboard_appointments;
        }
        // formData.append('dashboard_appointments' , options.dashboard_appointments);
        formData.append('dashboard_appointments', dap);

        formData.append('calType', options.calType ?? 0);
        formData.append('viewType', options.viewType ?? 'day');
        formData.append('timezone', options.timezone ?? 'UTC');

        // formData.append('is_group_location' , this.options.is_group_location || false);
        const item = {
            url: "/api/get_appointments",
            body: formData,
            options: options
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, {
            root: true
        });
        // console.log(JSON.stringify(res.data.data));
        context.commit("SET_APPOINTMENTS", res.data.data);
        context.commit("SET_APPOINTMENTS_COUNT", res.data.counts);
        return res;
    },

    async getFilterProviders(context, ids) {


        const formData = new FormData();
        formData.append('provider_ids', JSON.stringify(ids));
        // formData.append('is_group_location' , this.options.is_group_location || false);
        const item = {
            url: "/api/get_filter_providers",
            body: formData
        };
        context.dispatch("global/actionloaderState", true, { root: true });
        let res = await context.dispatch("global/post", item, { root: true });
        context.dispatch("global/actionloaderState", false, {
            root: true
        });
        // console.log(JSON.stringify(res.data.data));
        context.commit("SET_PROVIDERS", res.data.data);
        return res;
    },

    getUsersPreferencesData(context, data) {
        var api_url = "/api/getUsersPreferencesData";
        Axios
            .post(api_url, data)
            .then(response => {
                // console.log(response.data.data);
                var data = response.data.data;
                context.commit("GET_HEADER_DATA", data);
            })
            .catch(error => {

            })
    },

    usersPreferencesData(context, data) {
        var api_url = "/api/usersPreferencesData";
        Axios
            .post(api_url, data)
            .then(response => {
                // console.log(response.data);
                var data = response.data.data;
                context.commit("GET_HEADER_DATA", data);
            })
            .catch(error => {

            })
    }
};

const mutations = {
    SET_OPTIONS(state, options) {
        state.options = options
    },
    SET_APPOINTMENTS_COUNT(state, count) {
        state.counts = count
    },
    SET_APPOINTMENTS(state, data) {
         state.appointments = [];
        // console.log("APPOINTMENTS = "+JSON.stringify(data.map(e=>e.PatientFullName)));
        state.appointments = data ?? [];
    },

    SET_PROVIDERS(state, data) {
        // console.log("APPOINTMENTS = "+JSON.stringify(data.map(e=>e.PatientFullName)));
        // console.log("PROVIDERS ------------------>", data);
        if (data)
            state.providers = data;
    },

    GET_HEADER_DATA(state, data) {
        state.getHeaderData = data;
    },

    SET_APPOINTMENTS_DATE(state, date) {
        // console.log("APPOINTMENTS = "+JSON.stringify(data.map(e=>e.PatientFullName)));
        state.appointments_filter_date = date;
        state.picker = date;
    },

    SET_FILTERS(state, data) {
        state.filters = data;
    },



};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
