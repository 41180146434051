<template>
  <div>
    <div class="d-flex mb-5">
      <v-icon size="25" color="primary">mdi-account-circle</v-icon>
      <h5 class="mb-0 ml-2">User Settings</h5>
    </div>

    <v-switch
      class="mt-5"
      inset
      dense
      :disabled="user.phone.length < 10"
      @click="smsToggle"
      :label="
        user.phone.length < 10
          ? 'Receive SMS Notifications (Valid (Min 10 digits) Phone number)'
          : 'Receive SMS Notifications'
      "
      v-model="smsSwitch"
    ></v-switch>

    <v-switch
      inset
      dense
      @click="emailToggle"
      label="Receive Email Notifications"
      v-model="emailSwitch"
    ></v-switch>

    <v-switch v-model="ringtoneSwitch" inset dense label="Call Ringtone"></v-switch>

    <div class="d-md-flex d-block align-center mt-5">
      <p class="mb-0 mr-2">Recieve notifications</p>

      <div class="user-minute-field">
        <v-select
          outlined
          dense
          hide-details=""
          v-model="minutes"
          :items="minutesArray"
        ></v-select>
      </div>

      <p class="mb-0 ml-2">minutes before appointment</p>
    </div>

    <div v-if="enable_use_pin">
      <v-list-item v-if="user.account_type == 2" @click="pinToggle">
        <v-switch @click="pinToggle" v-model="usePin"></v-switch>
        <v-list-item-title>Use pin after video call</v-list-item-title>
      </v-list-item>
      <v-row v-if="usePin">
        <v-col cols="12" md="4" sm="6" class="mt-4">
          <v-text-field
            label="Set your pin"
            dense
            hide-details
            outlined
            type="number"
            v-model="pin"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-card-actions class="px-0 mt-5">
      <v-btn
        @click="save()"
        depressed
        large
        color="primary"
        style="width: 170px"
        >Save Changes</v-btn>
    </v-card-actions>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  methods: {
    getUserPrefs() {
      this.$store.dispatch("global/actionloaderState", true, { root: true });
      const data = new FormData();
      this.$store
        .dispatch("global/post", {
          url: "/api/get_user_prefs",
          body: data,
        })
        .then((r) => {
          const d = r.data.data;
          this.smsSwitch = d.sms;
          this.emailSwitch = d.email;
          this.ringtoneSwitch = d.ringtone;
          this.minutes = d.minutes;
          this.usePin = d.use_pin;
          this.pin = d.pin;

          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        })
        .catch((e) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
        });
    },

    save() {
      this.$store.dispatch("global/actionloaderState", true, { root: true });
      const data = new FormData();
      data.append("sms", this.smsSwitch ? "1" : "0");
      data.append("email", this.emailSwitch ? "1" : "0");
      data.append("ringtone", this.ringtoneSwitch ? "1" : "0");

      data.append("minutes", this.minutes);

      if (this.pin) data.append("pin", this.pin);
      data.append("use_pin", this.usePin ? "1" : "0");
      this.$store
        .dispatch("global/post", {
          url: "/api/set_prefs",
          body: data,
        })
        .then((r) => {
          const d = r.data.data;

          //   this.smsSwitch = d.sms;
          //   this.emailSwitch = d.email;
          //   this.callLoggingSwitch = d.callLogging;
          //   this.minutes = d.minutes;
          //   this.usePin = d.use_pin;
          //   this.pin = d.pin;
        this.user.ringtone = this.ringtoneSwitch?1:0

          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.$store.dispatch("global/snackBarState", {
            show: true,
            color: "primary",
            message: "Save Successfully",
          });
        })
        .catch((e) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });

          this.$store.dispatch("global/snackBarState", {
            show: true,
            color: "red",
            message: "Save Failed!",
          });
        });
    },

    smsToggle() {
      this.smsSwitch = !this.smsSwitch;
    },
    pinToggle() {
      this.usePin = !this.usePin;
    },
    emailToggle() {
      this.emailSwitch = !this.emailSwitch;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
  },
  created() {
    this.getUserPrefs();
  },

  data() {
    return {
        ringtoneSwitch:false,
      enable_use_pin: false,
      usePin: true,
      pin: "",
      timezone: "",
      timezoneList: [],
      timezoneLoading: false,
      minutes: 15,
      smsSwitch: false,
      emailSwitch: false,
      minutesArray: [
        { text: 15, value: 15 },
        { text: 30, value: 30 },
        { text: 60, value: 60 },
      ],
    };
  },
};
</script>

<style scoped>
/deep/ .user-minute-field .v-text-field {
  width: 100px;
}

/deep/ .timezone .v-text-field {
  width: 100px;
}
</style>
