<template>
  <v-container>
    <v-card-actions class="py-0 mb-1">
      <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
    </v-card-actions>
    <!-- Appointment Chat -->

    <!-- Start Chat Area -->
    <div class="chat-box">
      <!-- Chat box Top Bar -->
      <div class="intro-y chat-top-bar">
        <div class="partic-detail w-100">
          <v-icon @click="$router.go(-1)" size="25" color="primary" class="mr-2"
            >mdi-arrow-left</v-icon
          >
          <img v-if="chatTo.image" :src="chatTo.image" alt="avatar" />
          <img v-else :src="'/assets/default_user.png'" alt="avatar" />

          <div class="ml-2 overflow-hidden w-100">
            <a href="javascript:;">{{ chatTo.name }}</a>
            <p class="mail">
              {{ chatTo.email }}
            </p>
            <div class="mail">
              <a href="javascript:;">Status</a>
              <v-chip
                :color="getColor(chatTo.status)"
                label
                small
                class="text-white"
                >{{ statusName(chatTo.status) }}</v-chip
              >
            </div>
          </div>

          <div class="ml-auto">
            <v-btn
              v-if="user ? user.support_team == 1 : ''"
              @click="openChangeStatusDialog()"
              small
              color="primary"
              depressed
              >Change Status</v-btn
            >
          </div>

          <div class="ml-auto">
            <v-icon size="30" @click="refresh" color="primary">mdi-sync</v-icon>
          </div>
        </div>
      </div>
      <!--END Chat box Top Bar -->

      <!-- Chat Area For user -->
      <div class="chat-area" ref="chat_area">
        <div v-for="(item, index) in messages" :key="index">
          <div
            :class="
              item.posted_by == user.id
                ? 'chat-box-widget outgoing-chat'
                : 'chat-box-widget'
            "
          >
            <div class="w-full">
              <div>
                <div class="chat-text-box">
                  {{ item.message }}
                </div>

                <div class="clear-both mb-2"></div>
                <p class="reply-time">
                  {{ item.created_at | moment("from", true) }}
                  ago
                  <span id="ByUser">By {{ item.user.name }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="clear-both mb-2"></div>
        </div>
      </div>

      <div
        class="reply-area"
        v-if="
          chatTo.status == $constants.PENDING ||
          chatTo.status == $constants.IN_PROGRESS
        "
      >
        <!-- <v-icon size="22">mdi-paperclip</v-icon> -->
        <v-textarea
          v-model="message"
          :rows="1"
          placeholder="Type your message..."
          solo
          flat
          hide-details=""
          :error-messages="MessageErrors"
          required
          @input="$v.message.$touch()"
          @blur="$v.message.$touch()"
        ></v-textarea>
        <v-btn
          fab
          small
          dark
          class="elevation-1"
          color="primaryDark"
          @click="ReplyMessage()"
        >
          <v-icon small>mdi-send</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- End Chat Area -->

    <!-- Change Status Dialog Start -->
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title>Change Status</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-autocomplete
            outlined
            dense
            class="mt-3"
            label="Select Status"
            :items="statusList"
            v-model="chatTo.status"
            :error-messages="StatusErrors"
            @input="$v.chatTo.status.$touch()"
            @blur="$v.chatTo.status.$touch()"
            required
          >
          </v-autocomplete>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> Cancel </v-btn>

          <v-btn
            color="primary"
            depressed
            min-width="120"
            @click="changeStatus()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Change Status Dialog End -->
  </v-container>
</template>

<script>
import { required, maxLength, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";

export default {
  data: () => ({
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Inbox",
        disabled: false,
        to: "/appointment-requests",
      },
      {
        text: "Chat",
        disabled: true,
        to: "",
      },
    ],

    statusList: [
      { text: "PENDING", value: 1 },
      { text: "IN-PROGRESS", value: 2 },
      { text: "COMPLETED", value: 3 },
      { text: "CLOSED", value: 4 },
    ],

    message: "",
    dialog: false,
  }),
  validations: {
    message: { required },
    chatTo: {
      status: { required },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      chatTo: (state) => state.apt_req.chatTo,
      messages: (state) => state.apt_req.messages,
    }),

    MessageErrors() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required && errors.push("Message is required");
      return errors;
    },

    StatusErrors() {
      const errors = [];
      if (!this.$v.chatTo.status.$dirty) return errors;
      !this.$v.chatTo.status.required && errors.push("Status is required.");
      return errors;
    },
  },

  created() {
    this.refresh();
  },

  methods: {
    refresh() {
      this.$store
        .dispatch("apt_req/getReqAppointmentChat", {
          apt_id: this.chatTo.apt_id,
          partner_id: this.chatTo.partner_id,
        })
        .then((response) => {
          if (!response.data.data.status) {
            this.redirect();
          }
        });
    },

    ReplyMessage() {
      if (this.$v.message.$invalid) {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "primary",
          message: "please type any message",
        });
        return;
      } else {
        let params = {
          apt_id: this.chatTo.apt_id,
          message: this.message,
        };
        this.$store.dispatch("apt_req/sendMessage", params).then((response) => {
          if (!response.data.data.status) {
            this.redirect();
          }
          setTimeout(() => {
            this.scrollToEnd();
          }, 500);
        });
        this.close();
      }
    },

    scrollToEnd() {
      var content = this.$refs.chat_area;
      if (content) content.scrollTop = content.scrollHeight;
    },

    close() {
      this.message = "";
      this.dialog = false;
    },

    openChangeStatusDialog() {
      this.dialog = true;
    },

    changeStatus() {
      if (this.$v.chatTo.status.$invalid) {
        this.$v.$touch();
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "error",
          message: "Please select any status.",
        });
      } else {
        let params = {
          apt_id: this.chatTo.apt_id,
          status: this.chatTo.status,
        };
        this.$store
          .dispatch("apt_req/changeStatus", params)
          .then((response) => {});
        this.close();
      }
    },

    statusName(status) {
      switch (status) {
        case 1:
          return "PENDING";
          break;
        case 2:
          return "IN-PROGRESS";
          break;
        case 3:
          return "COMPLETED";
          break;
        case 4:
          return "CLOSED";
          break;
      }
    },

    getColor(status) {
      switch (status) {
        case 1:
          return "orange";
          break;
        case 2:
          return "primary";
          break;
        case 3:
          return "green";
          break;
      }
    },

    redirect() {
      this.$store.commit("apt_req/RESET_APPOINTMENT_REQUEST");
      this.$router.push({
        name: "AppointmentRequests",
      });
    },
  },
};
</script>

<style scoped></style>

