<template>
  <div>
    <v-card>
      <v-card-actions class="px-4 py-4">
        <v-card-title class="pa-0">{{ dialogTitle }}</v-card-title>
        <v-spacer></v-spacer>
        <v-icon @click="closeDialog()">mdi-close</v-icon>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-text>
        <div class="notes-add-avatar">
          <img :src="note ? note.postedBy.image : user.image" alt />
          <p>{{ note ? note.postedBy.name : user.name }}</p>
        </div>
        <!-- TEXT FIELD -->
        <v-textarea
          v-model="noteText"
          hide-details
          rows="3"
          class="mt-3"
          solo
          placeholder="Type Something..."
        ></v-textarea>

        <div v-for="(att, i) in attachments" :key="i" class="mt-4">
          <VoiceWidget
            v-if="att.type === 'A'"
            :file="att"
            @delete="deleteAt(i)"
          />
          <FileWidget
            v-if="att.type === 'F'"
            :file="att"
            @delete="deleteAt(i)"
          />
          <ImgWidget
            v-if="att.type === 'I'"
            :file="att"
            @delete="deleteAt(i)"
          />
          <VideoWidget
            v-if="att.type === 'V'"
            :file="att"
            @delete="deleteAt(i)"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card class="px-4 py-3 text-center">
        <v-icon class="mx-2" @click="chooseImage" color="blue"
          >mdi-image</v-icon
        >
        <v-icon
          v-if="!on_video_call"
          class="mx-2"
          @click="recordVideo"
          color="primary"
          >mdi-video</v-icon
        >
        <v-icon
          v-if="!on_video_call"
          class="mx-2"
          @click="recordVoice"
          color="red"
          >mdi-microphone</v-icon
        >
        <v-icon id="file-input" class="mx-2" @click="chooseFile" color="green"
          >mdi-file</v-icon
        >
        <v-file-input id="docPicker" v-show="false" @change="onFilePicked" />
        <v-file-input
          id="imagePicker"
          :accept="acceptedFormats"
          v-show="false"
          v-model="testImage"
          @change="onImagePicked"
        />
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" block style="width: 120px" @click="createNote">{{
          note ? "update" : "Create"
        }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="rec_voice_dialog" persistent max-width="290">
      <RecordAudio :onSave="saveAudio" :onClose="closeAudio" />
    </v-dialog>
    <v-dialog v-model="rec_video_dialog" persistent max-width="640">
      <RecordVideo :onSave="saveVideo" :onClose="closeVideo" />
    </v-dialog>
    <span v-if="d.length > 0">{{ openfunction() }}</span>
    <span v-else>{{ openfunction() }}</span>
  </div>
</template>

<script>
import ImgWidget from "./ImageWidget";
import VoiceWidget from "./VoiceWidget";
import FileWidget from "./FileWidget";
import VideoWidget from "./VideoWidget";
import CommentDialog from "./CommentsDialog";
import RecordAudio from "./RecordAudio";
import RecordVideo from "./RecordVideo";
import { mapState } from "vuex";
 import Axios from "axios";
export default {
  components: {
    VoiceWidget,
    FileWidget,
    VideoWidget,
    ImgWidget,
    CommentDialog,
    RecordAudio,
    RecordVideo,
  },

  props: ["note", "onClose", "app_id"],

  data: () => ({
    rec_voice_dialog: false,
    rec_video_dialog: false,
    on_video_call: false,
    attachments: [],
    appId: null,
    noteText: "",
    testImage: null,
  }),

  created() {
    this.appId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    if (this.note) {
      this.noteText = this.note.note;
      this.attachments = this.note.attachments;
    }
    if (this.$route.name == "videoCall") {
      this.on_video_call = true;
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
      notes: (state) => state.notes.notes,
      d: (state) => state.notes.setImageData,
      deletedImageStatus: (state) => state.notes.deletedImageStatus,
    }),
    acceptedFormats: function () {
      return ["image/jpeg", "image/png", "image/gif"].join(",");
    },
    dialogTitle() {
      return !this.note ? "Create Note" : "Edit Note";
    },
  },

  methods: {
    openfunction() {
      if (this.d) {
        this.attachments = this.d;
      } else {
        this.attachments = [];
      }
    },

    deleteAt(i) {
      this.attachments.splice(i, 1);
    },

    onFilePicked(file) {
      this.attachments.push({
        type: "F",
        file: file,
        name: file.name,
      });
    },

    onImagePicked(file) {
      this.attachments.push({
        type: "I",
        file: file,
        name: file.name,
      });
    },

    createNote() {
      if (this.noteText=="") {
        this.$store.dispatch("global/snackBarState", {
          show: true,
          color: "red",
          message: "Please fill all required fields",
        });
      } else {
        var arr = [];
        const data = new FormData();
        var indexOfimg = 0;
        this.attachments.forEach((e) => {
          if (e.file) {
            data.append("file" + indexOfimg, e.file, e.name);
          }
          indexOfimg++;
        });

        data.append(
          "keys",
          JSON.stringify(
            this.attachments.map((e) => {
              return {
                key: e.name,
                type: e.type,
              };
            })
          )
        );

        data.append("note", this.noteText);
        data.append("id", this.note ? this.note.id : 0);
        data.append("appointment_id", this.appId);
        Axios.post("/api/create_note", data).then((r) => {
          if (!this.note) {
            this.noteText = "";
            this.attachments = [];
          } else {
            this.$store.commit("notes/IMAGE_DELETED", false);
            this.$store.commit("notes/SET_DELETE_STATUS", false);
          }
          this.$emit("noteCreated");
        });
      }
    },

    recordVoice() {
      this.rec_voice_dialog = true;
    },

    recordVideo() {
      this.rec_video_dialog = true;
    },

    saveAudio(file) {
      //   console.log("GOT THE FILE IN SAVE" + file);
      this.attachments.push({
        type: "A",
        file: file,
        name: "Audio_00" + this.attachments.length,
      });
      this.rec_voice_dialog = false;
    },

    closeAudio() {
      this.rec_voice_dialog = false;
    },

    closeVideo() {
      this.rec_video_dialog = false;
    },

    saveVideo(file) {
      //   console.log("GOT THE FILE IN SAVE" + file);
      this.attachments.push({
        type: "V",
        file: file,
        name: "Video_00" + this.attachments.length,
      });
      this.rec_video_dialog = false;
    },

    chooseFile() {
      document.getElementById("docPicker").click();
    },

    chooseImage() {
      document.getElementById("imagePicker").click();
    },

    closeDialog() {
      if (!this.note) {
        this.noteText = "";
        this.attachments = [];
        this.onClose();
      } else {
        if (this.deletedImageStatus == true) {
          this.$store.dispatch("global/snackBarState", {
            show: true,
            color: "red",
            message: "Image Deleted so Click on update.",
          });
        } else {
          this.attachments = [];
          var d = localStorage.getItem("imageBackup");
          var q = JSON.parse(d);
          this.attachments = q;
          this.note.attachments = q;
          this.$store.commit("notes/SET_DELETE_STATUS", false);
          this.onClose();
        }
      }
    },
  },
};
</script>

<style>
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}
</style>
