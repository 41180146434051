<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="categoiesList"
      :search="search"
      class="elevation-0 en-table-card">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Categories</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
            class="elevation-1 mr-3"
            @click="reloadList"
            fab
            light
            small
            color="white"
          >
            <v-icon color="primary">mdi-sync</v-icon>
          </v-btn>

          <!-- Add new dialog -->
          <v-dialog v-model="dialog" persistent scrollable max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark depressed v-bind="attrs" v-on="on"
                >Add New</v-btn
              >
            </template>
            <v-card>
              <v-card-title>
                <span>{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field
                      v-model="category.name"
                      outlined
                      dense
                      label="Name"
                      :error-messages="nameErrors"
                      @input="$v.category.name.$touch()"
                      @blur="$v.category.name.$touch()"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">Cancel</v-btn>
                <v-btn
                  color="primary"
                  depressed
                  :disabled="addLoading"
                  :loading="addLoading"
                  style="width: 100px"
                  @click="save()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="px-4">
          <v-col cols="12" md="12">
            <v-text-field
              dense
              clearable
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <v-icon
            small
            color="primary"
            @click="editDialogOpen(item)"
            class="mr-3"
            >mdi-pencil</v-icon
          >
          <v-icon small color="red" @click="deleteDialogOpen()" class="mr-3"
            >mdi-delete</v-icon
          >
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn color="primary" depressed style="width: 100px" @click="close()"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Axios from "axios";
import { required } from "vuelidate/lib/validators";
export default {
  data: () => ({
    addLoading: false,
    loading: false,
    search: "",
    headers: [
      {
        text: "Name",
        value: "name",
      },

      {
        text: "Actions",
        align: "end",
        sortable: false,
        value: "actions",
        width: "70px",
      },
    ],
    id: 0,

    category: {
      name: "",
    },
    dialog: false,
    delete_dialog: false,
    editedIndex: -1,
    categoiesList: [],
  }),

  validations: {
    category: {
      name: { required },
    },
  },

  created() {
    this.initialize();
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New" : "Update";
    },

    nameErrors() {
      const errors = [];
      if (!this.$v.category.name.$dirty) return errors;
      !this.$v.category.name.required && errors.push("Title is required");
      return errors;
    },
  },

  methods: {
    initialize() {
      this.reloadList();
    },
    reloadList() {
      this.loading = true;
      const data = new FormData();
      data.append("archived", 0);
      Axios.post("/api/get_categories", data).then((r) => {
        this.categoiesList = r.data;
        this.loading = false;
      });
    },
    save() {
      if (this.$v.$invalid) {
        this.$v.$touch();
      } else {
        const data = new FormData();
        data.append("name", this.category.name);
        data.append("id", this.id);
        Axios.post("/api/create_category", data).then((r) => {
          this.close();
          this.reloadList();
        });
      }
    },

    editDialogOpen(item) {
      this.editedIndex = this.categoiesList.indexOf(item);
      this.editedItem = JSON.parse(JSON.stringify(item));
      this.dialog = true;
    },

    deleteDialogOpen() {
      this.delete_dialog = true;
    },

    close() {
      this.dialog = false;
      this.delete_dialog = false;
      this.editedIndex = -1;
      this.$v.$reset();
    },
  },
};
</script>
