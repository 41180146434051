<template>
  <div class="appt-base">
      <v-card-actions>
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="toggle_appoint"
          mandatory
          @change="changeToggle()"
        >
          <v-btn>
            <v-icon>mdi-format-list-text</v-icon>
          </v-btn>

          <v-btn>
            <v-icon>mdi-calendar-month</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-card-actions>
      <v-card class="en-card" flat>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-toolbar-title>Appointments</v-toolbar-title>
          </v-col>
          <v-col
            class="mb-3 text-start text-sm-end text-md-end"
            cols="12"
            sm="6"
            md="6"
          >
            <v-btn
              @click="addAppointments"
              depressed
              class="mr-3"
              color="primary"
              >New Appointment</v-btn
            >

            <v-btn
              class="elevation-1 mr-3"
              @click="refresh"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>

            <!-- Open menu when list -->
            <v-menu
              v-if="toggle_appoint == 0"
              v-model="popover"
              :close-on-content-click="false"
              offset-y
              :max-width="450"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="elevation-1"
                  fab
                  light
                  small
                  color="white"
                >
                  <v-icon color="dark">mdi-view-column</v-icon>
                </v-btn>
              </template>

              <v-card class="pt-1 px-3">
                <v-row class="py-0">
                  <v-col class="py-0" cols="12" md="6">
                    <v-checkbox
                      dense
                      v-model="selected"
                      hide-details
                      label="Check All"
                      @click.native.stop="selectAllHeaders()"
                    />
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-checkbox
                      dense
                      @click.native.stop="selectAllHeadersReasons()"
                      v-model="patient_reasons"
                      hide-details
                      label="Patient - Reason"
                    />
                  </v-col>
                </v-row>

                <v-divider class="mt-3 mb-0"></v-divider>
                <v-row>
                  <v-col
                    class="py-0"
                    v-for="(item, i) in headers"
                    :key="i"
                    md="6"
                    cols="12"
                  >
                    <v-checkbox
                      v-model="item.selected"
                      dense
                      @change="checkChange(item.name)"
                      hide-details
                      :label="item.name"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    block
                    color="primary"
                    depressed
                    class="mt-4"
                    style="width: 100px"
                    @click="setHeaders()"
                    >Done</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>

            <!-- Open menu when calendar -->
            <v-menu
              v-else
              v-model="popover2"
              :close-on-content-click="false"
              offset-y
              :max-width="450"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  class="elevation-1"
                  fab
                  light
                  small
                  color="white"
                >
                  <v-icon color="dark">mdi-calendar-text</v-icon>
                </v-btn>
              </template>

              <v-card class="pt-1 px-3">
                <v-row class="py-0">
                  <v-col class="py-0" cols="12" md="6">
                    <v-checkbox
                      dense
                      v-model="selected2"
                      hide-details
                      label="Check All"
                      @click.native.stop="selectAllHeaders2()"
                    />
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-checkbox
                      dense
                      @click.native.stop="selectAllHeaders2Reasons()"
                      v-model="patient_reasons2"
                      hide-details
                      label="Patient - Reason"
                    />
                  </v-col>
                </v-row>

                <v-divider class="mt-3 mb-0"></v-divider>
                <v-row>
                  <v-col
                    class="py-0"
                    v-for="(item, i) in headers2"
                    :key="i"
                    md="6"
                    cols="12"
                  >
                    <v-checkbox
                      v-model="item.selected"
                      dense
                      @change="checkChange()"
                      hide-details
                      :label="item.name"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    block
                    color="primary"
                    depressed
                    class="mt-4"
                    style="width: 100px"
                    @click="setHeaders2()"
                    >Done</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <div v-if="filters.length > 0 && filer_chipset">
          <h5>Applied Filter{{ filters.length > 1 ? "s" : "" }}</h5>
          <div class="d-flex flex-wrap">
            <div
              v-for="(item, i) in filters.filter(
                (r) => r.column !== 'StartDate'
              )"
              :key="i"
              class="filter-box"
            >
              <v-chip
                close
                small
                color="#c6c6c6"
                @click:close="removeFilter(i)"
                >{{ filterName(item.column) }}</v-chip
              >
              <p class="filter-val">{{ item.search }}</p>
            </div>
          </div>
        </div>
        <!-- SEARCH FIELDS AREA START -->
        <div class="search-field-con sticky-top-search">
          <div class="search-field-row">
            <!-- Search by Select -->
            <div class="search-by-select">
              <v-select
                hide-details
                v-model="search_by"
                :items="
                  [
                    'Status',
                    'Patient Name',
                    'Visit Reason',
                    //'Appointment Date',
                    // 'Admin Notes',
                  ].sort()
                "
                outlined
                dense
                @change="searchChange"
                placeholder="Search by"
              ></v-select>
            </div>
            <!-- Search components -->
            <div class="search-components">
              <v-select
                v-if="search_by == 'Status'"
                hide-details
                :items="statuses"
                outlined
                v-model="search"
                dense
                placeholder="Status"
              ></v-select>

              <v-combobox
                v-else-if="search_by == 'Admin Notes'"
                hide-details
                :items="['Demo 1', 'Demo 2']"
                outlined
                v-model="search"
                dense
                placeholder="Search admin reason"
              ></v-combobox>

              <v-menu
                v-else-if="search_by == 'Appointment Date'"
                ref="menu1"
                v-model="appt_date_model"
                :close-on-content-click="false"
                :return-value.sync="appt_filter_date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="appt_filter_date"
                    placeholder="Select appointment date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    hide-details=""
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  color="primary"
                  v-model="appt_filter_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="appt_date_model = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu1.save(appt_filter_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>

              <v-text-field
                dense
                v-else
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                :placeholder="search_by"
                outlined
                hide-details
              ></v-text-field>
            </div>

            <!-- Filter Buttons -->
            <div class="appt-filter-btns text-end">
              <v-btn
                class="elevation-1 mr-2 appt-web-btns"
                @click="addFilters"
                fab
                dark
                small
                color="primary"
              >
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>

              <v-btn
                class="elevation-1 appt-web-btns"
                @click="clearFilters"
                fab
                light
                small
                color="white"
              >
                <v-icon color="primary">mdi-close</v-icon>
              </v-btn>

              <!-- View mobile case only -->
              <v-row class="appt-mobile-btns my-0">
                <v-col cols="6">
                  <v-btn depressed @click="addFilters" color="primary" block
                    >Add</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn depressed @click="clearFilters" block>Clear</v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="appt-con">
          <div class="appt-row">
            <!-- Sticky Calendar Area -->
            <div class="sticky-calendar ub-sticky-top">
              <FilterWidget
                @dateChanged="dateChanged"
                @providerChanged="providerChanged"
                @locationChanged="locationChanged"
                ref="resetFilters"
              />
            </div>

            <!-- Scrollable Appt Cards -->
            <div class="appt-card-con">
              <!-- LIST LAYOUT -->
              <div v-if="toggle_appoint == 0">
                <v-card
                  v-for="(appt, i) in appointments"
                  :key="i"
                  flat
                  :style="`border-left: ${
                    appt.reason ? '4px' : '1px'
                  } 1px solid ${
                    appt.reason ? appt.reason.color : '#e9e9e9'
                  } !important`"
                  class="en-appt-card mb-4"
                >
                  <div class="appt-card-flex">
                    <!-- <img :src="'/assets/plaeholder-user.png'" alt="" /> -->
                    <div style="width: 100%">
                      <h4 v-if="headers[0].selected" @click="openInfo(appt)">
                        {{ appt.PatientFullName }}
                      </h4>

                      <div v-if="headers[3].selected">
                        <p v-if="appt.patient" class="date-time">
                          <v-icon class="mr-1" size="15">mdi-account</v-icon>
                          <b
                            >DOB:
                            {{
                              moment
                                .tz(
                                  appt.patient.DOB,
                                  user.timezone
                                    ? default_timezon
                                    : default_timezon
                                )
                                .format("MM/DD/YYYY")
                            }}</b
                          >
                        </p>
                        <p v-else>-/-</p>
                      </div>
                      <p class="date-time">
                        <v-icon style="margin-right: 3px" size="15"
                          >mdi-calendar</v-icon
                        >
                        <span v-if="headers[4].selected">
                          {{
                            moment
                              .tz(
                                appt.StartTime,
                                user.timezone
                                  ? default_timezon
                                  : default_timezon
                              )
                              .format("MM/DD/YYYY")
                          }}
                        </span>
                        <!-- <br />
                        <span>{{
                          user.timezone ? user.timezone : default_timezon
                        }}</span>
                        <br />
                        <span>{{ appt.StartTime }}</span>
                        <br /> -->
                        <span v-if="headers[5].selected">
                          -
                          {{
                            moment
                              .tz(
                                appt.StartTime,
                                user.timezone
                                  ? default_timezon
                                  : default_timezon
                              )
                              .format("h:mm a")
                          }}</span
                        >

                        <span v-if="headers[5].selected">
                          -
                          {{
                            moment
                              .tz(
                                appt.EndTime,
                                user.timezone
                                  ? default_timezon
                                  : default_timezon
                              )
                              .format("h:mm a")
                          }}</span
                        >
                      </p>
                      <p
                        v-if="headers[2].selected"
                        class="appt-loaction-text mb-1"
                      >
                        <v-icon style="margin-right: 6px" size="15"
                          >mdi-map-marker</v-icon
                        >{{ appt.ServiceLocationName }}
                      </p>
                    </div>
                  </div>

                  <v-btn
                    class="status-btn"
                    depressed
                    small
                    v-if="headers[7].selected"
                    dark
                    :color="statusColors(appt)"
                    >{{ appt.AppointmentStatus }}</v-btn
                  >

                  <p
                    v-if="headers[6].selected"
                    class="mt-1 mb-2 appt-loaction-text"
                  >
                    <v-icon class="mr-1" size="15">mdi-clipboard-check</v-icon>
                    {{ appt.AppointmentReason1 }}
                  </p>

                  <div class="d-flex align-center">
                    <!-- <p
                      v-if="appt.note"
                      style="width: 100%"
                      class="mb-2 appt-loaction-text"
                    >
                      <v-icon class="mr-1" size="15"
                        >mdi-android-messages</v-icon
                      >

                      {{ appt.note.reason.name }} -
                      <span class="date-time">{{ appt.note.description }}</span>
                    </p>
                    <p
                      v-else
                      style="width: 100%"
                      class="mb-2 appt-loaction-text"
                    ></p> -->

                    <p
                      v-if="headers[1].selected"
                      style="width: 100%"
                      class="mb-2 appt-loaction-text"
                    >
                      <v-icon class="mr-1" size="15">mdi-account</v-icon>

                      <!-- <img
                        class="provider-image"
                        :src="'/assets/provider_img.png'"
                        alt=""
                      /> -->
                      <!-- <v-icon class="mr-1" size="15">mdi-doctor</v-icon> -->
                      Provider:
                      <v-chip label small>{{
                        appt.provider ? appt.provider.name : ""
                      }}</v-chip>
                    </p>

                    <v-btn
                      class="elevation-1 ml-3"
                      v-if="
                        user.account_type == $constants.ADMIN ||
                        (appt.provider == null
                          ? false
                          : appt.provider.id == user.id
                          ? true
                          : false)
                      "
                      @click="editAppt(appt)"
                      fab
                      light
                      x-small
                      color="white"
                    >
                      <v-icon color="primary">mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="
                        user.account_type == $constants.ADMIN ||
                        (appt.provider == null
                          ? false
                          : appt.provider.id == user.id
                          ? true
                          : false)
                      "
                      class="elevation-1 ml-3"
                      @click="deleteAppDialog(appt)"
                      fab
                      light
                      x-small
                      color="white"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>

                    <!-- <v-btn
                      v-if="user.account_type == 1"
                      @click="addNoteDialogOpen(appt)"
                      class="elevation-1 ml-3"
                      fab
                      light
                       x-small
                      color="white"
                    >
                      <v-icon color="primary">mdi-message-plus</v-icon>
                    </v-btn> -->
                  </div>

                  <v-btn
                    v-if="user.account_type != $constants.ADMIN"
                    @click="joinRoomDialog(appt)"
                    x-small
                    :loading="appt.loading"
                    depressed
                    color="primary"
                  >
                    <v-icon small left>mdi-video</v-icon
                    ><span v-if="user.account_type == $constants.COORDINATOR"
                      >Join</span
                    >
                    <span v-else>Start</span>
                  </v-btn>

                  <!-- <router-link
                  v-if="user.account_type == $constants.PROVIDER"
                  :to="{
                    path: `/notes/${appt.A_ID}`,
                    params: {
                      app: appt,
                    },
                  }"
                >
                  <v-icon class="ml-3" color="primary" small
                    >mdi-comment-text</v-icon
                  >
                </router-link> -->
                </v-card>
              </div>

              <!-- Showing if not Appointments -->
              <div v-if="toggle_appoint == 0">
                <div class="no-data-widget" v-if="appointments.length == 0">
                  <h3>No Appointments!</h3>
                </div>
              </div>

              <!-- CALENDAR LAYOUT -->
              <div class="calendar-page" v-if="toggle_appoint == 1">
                <FullCalendar
                  :key="calendarKey"
                  :headers="headers2"
                  :fromDashboard="dashboard"
                  @dateChanged="dateChanged"
                  @createEvent="createEvent"
                />
              </div>
            </div>
          </div>

          <div v-if="toggle_appoint == 0" class="text-center my-8">
            <v-pagination
              v-if="appointments.length != 0"
              v-model="page"
              @input="pageChanged"
              :total-visible="7"
              :length="lastPage"
            ></v-pagination>
          </div>
        </div>
      </v-card>
 

    <v-dialog v-model="info_dialog" scrollable max-width="800px">
      <v-card>
        <v-card-title>Appointment Info</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <Logs :app="ap" v-if="info_dialog" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" depressed style="width: 100px" @click="close"
            >Done</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="add_note_dialog" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Add Note</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                v-model="note.category"
                @change="categoryChanged"
                :error-messages="categoryErrors"
                @input="$v.note.category.$touch()"
                @blur="$v.note.category.$touch()"
                label="Category"
                outlined
                dense
                :items="categoiesList"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                v-model="note.reason"
                :disabled="note.category == null"
                :error-messages="reasonErrors"
                @input="$v.note.reason.$touch()"
                @change="reasonChanged"
                @blur="$v.note.reason.$touch()"
                label="Reason"
                outlined
                dense
                :items="reasonsList"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="note.description"
                :disabled="note.reason == null"
                label="Description"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Close</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="createAppointmentReason()"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="add_dialog" scrollable persistent max-width="900px">
      <create-appointment
        @close="close"
        v-if="add_dialog"
        :appointment="appointment"
        :edit="editApp"
      />
    </v-dialog>

    <v-dialog v-model="filter_dialog" scrollable persistent max-width="900px">
      <v-card v-if="filter_dialog">
        <v-card-title>Filter</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <FilterWidget
            @dateChanged="dateChanged"
            @providerChanged="providerChanged"
            @locationChanged="locationChanged"
          />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">Close</v-btn>
          <v-btn color="primary" depressed style="width: 100px" @click="close">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn
      @click="filter_dialog = true"
      class="filter-fab"
      color="primary"
      fab
      fixed
      dark
      bottom
      left
      small
    >
      <v-icon>mdi-filter</v-icon>
    </v-btn>

    <v-dialog v-model="delete_dialog" persistent max-width="290">
      <v-card>
        <v-card-title>Delete</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()">No</v-btn>
          <v-btn
            color="primary"
            depressed
            style="width: 100px"
            @click="deleteAppt(appt_delet_id)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Room DIALOG -->
    <v-dialog v-model="room_dialog" scrollable persistent max-width="600px">
      <RoomDialog
        v-if="room_dialog"
        @joinCall="joinCall"
        @createCall="createCall"
        @close="close()"
        :SL_ID="currentAppt.ServiceLocationId"
      ></RoomDialog>
    </v-dialog>
  </div>
</template>

<script>
import FullCalendar from "./FullCalendar";
import { mapState } from "vuex";
import moment from "moment-timezone";
import Logs from "./ApptLogDialog";
import CreateAppointment from "./CreateAppointment.vue";
import FilterWidget from "./ApptFilterWidget";
import { required } from "vuelidate/lib/validators";
import Axios from "axios";
import RoomDialog from "./room_dialog.vue";
import axios from "axios";
export default {
  components: {
    FullCalendar,
    Logs,
    CreateAppointment,
    FilterWidget,
    RoomDialog,
  },

  props: {
    dashboard: {
      default: false,
    },
  },

  data: () => ({
    i: 0,
    currentAppt: {},
    new: true,
    selected: false,
    selected2: false,
    patient_reasons: false,
    patient_reasons2: false,
    calendarKey: 0,
    editApp: false,
    appointmentId: 0,
    id: 0,
    categoiesList: [],
    reasonsList: [],
    today: "",
    add_note_dialog: false,
    appointment: {},
    popover: false,
    popover2: false,
    lastPage: 0,
    page: 1,
    // Status
    status_red: "st_red",
    status_yellow: "st_yellow",
    status_green: "st_green",
    delete_dialog: false,
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },

      {
        text: "Appointments",
        disabled: true,
        to: "",
      },
    ],

    select_location: false,
    locations: [],
    column: "PatientFullName",
    filters: [],
    second_filters: [],
    add_dialog: false,
    filter_dialog: false,
    dateSearch: [],
    current_user: "",
    ap: Object,
    statuses: [
      "Unknown",
      "Scheduled",
      "ReminderSent",
      "Confirmed",
      "CheckedIn",
      "Roomed",
      "CheckedOut",
      "NeedsReschedule",
      "ReadyToBeSeen",
      "NoShow",
      "Cancelled",
      "Rescheduled",
      "Tentative",
    ].sort(),
    timer: null,
    provider_ids: [],
    filter_date: null,
    filer_chipset: false,
    // search_by: "Patient Name",
    appt_modal: false,
    appt_date: [],
    options: Object,
    loading: false,
    appt_time_modal: false,
    totalItems: 0,
    appt_time: null,
    moment: moment,
    search: "",
    second_search: "",
    info_dialog: false,
    appt_delet_id: {},
    default_timezon: "America/Chicago",

    // This headers for List
    headers: [
      {
        name: "Patient Name",
        selected: true,
      },
      {
        name: "Provider Name",
        selected: true,
      },
      {
        name: "Service Location",
        selected: true,
      },
      {
        name: "DOB",
        selected: false,
      },
      {
        name: "Appt. Date",
        selected: true,
      },
      {
        name: "Appt. Time",
        selected: true,
      },
      {
        name: "Reason",
        selected: true,
      },
      {
        name: "Status",
        selected: true,
      },
    ],
    // This headers for Calendar
    headers2: [
      {
        name: "Patient Name",
        selected: true,
      },
      {
        name: "Provider Name",
        selected: true,
      },
      {
        name: "Service Location",
        selected: true,
      },
      {
        name: "DOB",
        selected: false,
      },
      //   {
      //     name: "Appt. Date",
      //     selected: true,
      //   },
      {
        name: "Appt. Time",
        selected: true,
      },
      {
        name: "Reason",
        selected: true,
      },
      {
        name: "Status",
        selected: true,
      },
    ],
    note: {
      category: null,
      reason: null,
      description: "",
    },
    appt_date_model: false,
    appt_filter_date: null,
    room_dialog: false,
    appt_id: "",
  }),
  validations: {
    note: {
      category: { required },
      reason: { required },
    },
  },

  computed: {
    ...mapState({
      //   second_search: (state) => state.appointments.filters.second_search,
      picker: (state) => state.appointments.picker,
      appointments: (state) => state.appointments.appointments,
      counts: (state) => state.appointments.counts,
      user: (state) => state.currentUser.user,
      settings: (state) => state.themes.get_general_setting,
      calType: (state) => state.global.tabs.calendarTypeTab,
      search_filters: (state) => state.appointments.filters,
    }),

    search_by: {
      get: function () {
        return this.$store.state.appointments.filters.search_by;
      },
      set: function (value) {
        // var search_filters = this.$store.state.appointments.filters;
        this.search_filters.search_by = value;
        this.$store.commit("appointments/SET_FILTERS", this.search_filters);
      },
    },

    dateRangeText() {
      return this.appt_date.join(" ~ ");
    },

    toggle_appoint: {
      get: function () {
        return this.$store.state.global.tabs.appointmentsToggle;
      },
      set: function (value) {
        var setTab = {
          type: 3,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },

    cardStatus() {
      return this.status_green;
    },
    categoryErrors() {
      const errors = [];
      if (!this.$v.note.category.$dirty) return errors;
      !this.$v.note.category.required && errors.push("Category is required");
      return errors;
    },

    reasonErrors() {
      const errors = [];
      if (!this.$v.note.reason.$dirty) return errors;
      !this.$v.note.reason.required && errors.push("Reason is required");
      return errors;
    },
  },
  watch: {
    page() {},
    dateSearch: function () {
      this.search = this.dateSearch.join(",");
    },

    search: function () {},

    options: {
      handler() {
        // this.refresh("Watch -> Options");
        //console.log("Options Handler Called" + JSON.stringify(this.options));
      },
      deep: true,
    },
  },

  mounted() {
    // for (let i = 0; i < 14; i++) {
    //   //   this.$set(this.checkbox, i, false);
    // }
  },

  created() {
    this.toggle_appoint = 1;
    this.$store.dispatch("themes/getAllGeneralSetting");

    this.default_timezon = this.settings.default_timezon;
    // alert(this.default_timezon);

    var data = localStorage.getItem("en_user");
    this.current_user = JSON.parse(data);

    var objs = {
      user_id: this.current_user.id,
      type: "appointment",
    };

    this.$store.dispatch("appointments/getUsersPreferencesData", objs);
    // this.initialize();
    this.getCategories();
    this.getReasons();

    // if (this.user.account_type == 2) this.dashboard = true;
  },

  methods: {
    changeToggle() {
      if (this.toggle_appoint == 0) {
        this.filter_date = this.picker;
        //console.log("APPOINTMENTS CHANGE");
      }
      this.dateChanged(this.picker);
    },
    pageChanged() {
      this.options.page = this.page;
      this.refresh("Watch -> Page");
    },

    async createCall(slId) {
      const data = new FormData();
      data.append("SL_ID", slId);
      const room = (await axios.post("/api/create_new_room", data)).data;
      this.joinCall(room);
    },

    joinCall(room) {
      this.startVideoCall(room);
    },

    joinRoomDialog(appt) {
      this.room_dialog = true;
      this.appt_id = appt;
      this.currentAppt = appt;
    },

    filterName(item) {
      switch (item) {
        case "ServiceLocationName": {
          return "Location";

          break;
        }

        case "ProviderId": {
          return "Provider";

          break;
        }

        case "AppointmentStatus": {
          return "Status";

          break;
        }

        case "PatientFullName": {
          return "Patient Name";

          break;
        }

        case "StartDate": {
          return "Appointment Date";

          break;
        }

        case "AppointmentReason1": {
          return "Visit Reason";

          break;
        }
      }
    },

    checkChange(item) {
      this.calendarKey += 1;
    },

    deleteAppDialog(appt) {
      this.appt_delet_id = appt;
      this.delete_dialog = true;
    },

    deleteAppt(appt) {
      this.$store.dispatch("global/actionloaderState", true, {
        root: true,
      });
      const data = new FormData();
      data.append("id", appt.A_ID);
      Axios.post("/api/delete_appointment", data)
        .then((r) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.refresh("Delete Appointment Response");
          this.$store.dispatch("global/snackBarState", {
            show: true,
            color: "primary",
            message: "Delete Successfully",
          });
        })
        .catch((error) => {
          this.$store.dispatch("global/actionloaderState", false, {
            root: true,
          });
          this.refresh("Delete appointment error");
          this.$store.dispatch("global/snackBarState", {
            show: true,
            color: "red",
            message: "Delete Failed!",
          });
        });
      this.delete_dialog = false;
    },
    editAppt(appt) {
      // console.log("edit===",appt);
      this.appointment = appt;
      this.editApp = true;
      this.add_dialog = true;
    },
    categoryChanged() {
      this.getReasons();
    },
    reasonChanged() {
      this.note.description = this.reasonsList.filter(
        (r) => r.value == this.note.reason
      )[0].description;
    },
    createAppointmentReason() {
      const data = new FormData();
      data.append("reason_id", this.note.category);
      data.append("appointment_id", this.appointmentId);
      data.append("category_id", this.note.reason);
      data.append("description", this.note.description);
      data.append("id", this.id);
      Axios.post("/api/create_appointment_note", data).then((r) => {
        this.close();
      });
      //console.log(this.note);
    },
    setToday() {
      this.today = "";
    },

    addNoteDialogOpen(item) {
      this.add_note_dialog = true;
      this.appointmentId = item.id;
    },
    createEvent(event) {
      if (!event) return;
      // console.log("CREATE EVENT CALLED " + new Date(event.StartTime));
      this.appointment = event;
      this.add_dialog = true;
    },
    locationChanged(item) {
      this.resetPage();
      this.searchChange("Location");
      //   this.search = item.map((e) => e.text).join(",");
      this.second_search = item.map((e) => e.text).join(",");

      //   this.addFilters();
      this.addFilters_second("Location changed");
    },

    resetPage() {
      this.page = 1;
    },
    providerChanged(item) {
      this.resetPage();
      //  console.log("PROVIDER CHANGED CALLED WITH ITEM = " + item);
      this.searchChange("Provider");

      //   this.search = item.map((e) => e.value).join(",");
      this.second_search = item.map((e) => e.value).join(",");
      this.provider_ids = item.map((e) => e.value);

      //   this.addFilters();
      this.addFilters_second("Provider changed");

      if (this.new) {
        this.new = false;
        this.searchChange("Patient Name");
      }
    },

    dateChanged(date) {
      this.resetPage();
      this.filter_date = date;
      this.searchChange("Appt. Date");
      this.second_search = date;
      // this.search = date;
      this.addFilters();
      this.addFilters_second("Date changed");
      this.searchChange(this.search_by);
    },

    dateChangedFromCal(date) {
      this.resetPage();
      this.filter_date = date;
      this.second_search = date;
      this.searchChange("Appt. Date");
      // this.search = date;
      this.searchChange(this.search_by);
      this.addFilters();
      this.addFilters_second("Date changed");
    },

    toggeLocation() {
      this.select_location = !this.select_location;
    },

    removeFilter(i) {
      this.filters.splice(i, 1);
      this.refresh("Remove filter");
    },
    clearFilters() {
      var c_filters = {
        search_by: "Patient Name",
        provider_model2: [],
        location_model2: [],
      };
      this.$store.commit("appointments/SET_FILTERS", c_filters);
      var today = new Date().toISOString().substr(0, 10);
      this.$store.commit("appointments/SET_APPOINTMENTS_DATE", today);
      //   (this.search = ""), (this.search_by = null);
      this.filters = [];
      this.refresh("Clear Filter");
      this.$refs.resetFilters.reset();
    },

    addFilters() {
      this.resetPage();
      let included = false;
      this.filters.forEach((e) => {
        if (e.column === this.column) {
          e.search = this.search;
          included = true;
        }
      });
      if (!included) {
        this.filters.push({
          column: this.column,
          search: this.search,
        });
      }

      this.refresh("Add filter");
    },

    addFilters_second(calledFrom = "none") {
      // console.log("2nd filter Called from " + calledFrom);
      let included = false;
      this.second_filters.forEach((e) => {
        if (e.column === this.column) {
          e.search = this.second_search;
          included = true;
        }
      });
      if (!included) {
        this.second_filters.push({
          column: this.column,
          search: this.second_search,
        });
      }
      if (this.i > 2) this.refresh("Add filter second " + calledFrom);
      else this.i++;
    },

    searchChange(item) {
      //   console.log("search change item ", item);
      this.search = "";
      switch (item) {
        case "Location": {
          this.column = "ServiceLocationName";
          this.filer_chipset = false;
          break;
        }

        case "Provider": {
          this.column = "ProviderId";
          this.filer_chipset = false;
          break;
        }

        case "Status": {
          this.column = "AppointmentStatus";
          this.filer_chipset = true;
          break;
        }

        case "Patient Name": {
          this.column = "PatientFullName";
          this.filer_chipset = true;
          break;
        }

        case "Appt. Date": {
          this.column = "StartDate";
          this.filer_chipset = false;

          break;
        }

        case "Appointment Date": {
          this.column = "StartDate";
          this.filer_chipset = false;
          break;
        }

        case "Appt. Time": {
          this.column = "StartDate";
          this.filer_chipset = false;
          break;
        }

        case "Visit Reason": {
          this.column = "AppointmentReason1";
          this.filer_chipset = true;
          break;
        }
      }

      if (this.filer_chipset) {
        this.filters.forEach((e) => {
          if (e.column === this.column) {
            this.search = e.search;
          }
        });
      } else {
        this.second_filters.forEach((e) => {
          if (e.column === this.column) {
            this.second_search = e.search;
          }
        });
      }
    },

    refresh(calledFrom = "none") {
      //   console.log("Caltype Getting Data for TabType : " + this.calType);
      //   console.log("Refresh Called from " + calledFrom);
      this.loading = true;
      //   this.$store.commit(
      //     "appointments/SET_APPOINTMENTS_DATE",
      //     this.filter_date
      //   );

      if (this.filer_chipset) {
        this.filters.forEach((e) => {
          if (e.column == "StartDate") {
            e.search = this.appt_filter_date;
          } else {
            if (e.search && e.search.text) e.search = e.search.text;
          }
        });

        this.options.filters = this.filters;
        if (!this.options.filters.map((e) => e.column).includes("StartDate"))
          this.options.filters.push({
            search: this.filter_date,
            column: "StartDate",
          });
        else
          this.options.filters.filter(
            (r) => r.column == "StartDate"
          )[0].search = this.filter_date;
      } else {
        this.second_filters.forEach((e) => {
          if (e.search && e.search.text) e.search = e.search.text;
        });

        // console.log('refresh getting called');

        // TODO: remove item from filter when clicked to dashboard
        if (this.dashboard == true) {
          this.options.filters = [];
          this.options.dashboard_appointments = true;
          this.dashboard = false;
          //   this.second_filters = [];
          //   this.second_filters.splice(this.second_filters[0], 1);
        } else {
          this.options.dashboard_appointments = false;
          this.options.filters = this.second_filters;
        }

        this.$store
          .dispatch("appointments/getFilterProviders", this.provider_ids)
          .then((response) => {});
      }
      this.options.calType = this.calType;
      this.options.viewType = this.toggle_appoint;
      this.options.timezone = this.user.timezone
        ? this.user.timezone
        : this.default_timezon;

      this.createFilters();

      this.$store
        .dispatch("appointments/getAppointments", this.options)
        .then((response) => {
          this.lastPage = response.data.last_page;
          this.totalItems = response.data.total;
          this.loading = false;
        });
    },

    createFilters() {
      var columns = [];
      var d_date = this.$store.state.appointments.appointments_filter_date;
      if (d_date != "") {
        columns.push({ column: "StartDate", search: d_date });
      }
      var f_locations = this.$store.state.appointments.filters.location_model2;
      if (f_locations) {
        columns.push({
          column: "ServiceLocationName",
          search: f_locations.map((e) => e.text).join(","),
        });
      }
      var f_providers = this.$store.state.appointments.filters.provider_model2;
      if (f_providers) {
        columns.push({
          column: "ProviderId",
          search: f_providers.map((e) => e.value).join(","),
        });
      }
      if (this.options.filters.length == 0) {
        this.options.filters = columns;
      }
    },

    async toVideoCall(item) {
      let slId;
      if (item.ServiceLocationId) slId = item.ServiceLocationId;
      else {
        const data = new FormData();
        data.append("name", item.ServiceLocationName);
        slId = (await Axios.post("/api/get_sl_id_from_name", data)).data;
      }
      item.loading = true;
      // console.log(JSON.stringify(item));

      const data = new FormData();
      data.append("log", "Call started by " + this.user.email);
      data.append("appointment", item.A_ID);
      await this.$store.dispatch("global/post", {
        url: "/api/log_video_call",
        body: data,
      });

      this.startVideoCall(slId);
    },

    startVideoCall(slId) {
      this.close();
      let routeData = this.$router.resolve({
        path: `/video-calling/${slId}`,
      });
      if (this.user.usePin) {
        localStorage.setItem("checkPin", 1);
        this.$router.push({ name: "lockScreen" });
      }
      //   if (this.user.account_type == this.$constants.PROVIDER)
      window.open(routeData.href, "_blank");
      //   else
      // this.$router.push({
      //   path: `/video-calling/${slId}`,
      // });
    },

    getCategories() {
      const data = new FormData();
      data.append("archived", 0);
      Axios.post("/api/get_categories", data).then((r) => {
        this.categoiesList = (r.data ?? []).map((r) => {
          return {
            text: r.name,
            value: r.id,
            id: r.id,
          };
        });
      });
    },
    getReasons() {
      const data = new FormData();
      data.append("archived", 0);
      data.append("category_id", this.note.category ?? 0);
      Axios.post("/api/get_admin_notes", data).then((r) => {
        this.reasonsList = (r.data ?? []).map((r) => {
          return { text: r.name, value: r.id, description: r.description };
        });
      });
    },
    initialize() {
      //   this.getCategories();
      //   this.getReasons();
      if (this.user.account_type != this.$constants.PROVIDER) {
        //  console.log("Account type Admin");
      } else {
        // console.log("Account type NON Admin");
      }
      this.refresh("Initialize");

      //
    },

    openInfo(item) {
      this.ap = item;
      this.info_dialog = true;
    },

    // Status Colors
    statusColors(item) {
      switch (item.AppointmentStatus) {
        case "Confirmed":
          return "success";
          break;
        case "CheckedIn":
          return "#3E63A3";
          break;
        case "Scheduled":
          return "orange";
          break;
        case "NoShow":
          return "grey";
          break;
        case "Cancelled":
          return "red";
          break;
        case "Rescheduled":
          return "secondary";
          break;
        case "CheckedOut":
          return "brown";
          break;
        case "ReminderSent":
          return "dark";
          break;
        case "Unknown":
          return "#933F64";
          break;
        case "Roomed":
          return "#234E59";
          break;
        case "NeedsReschedule":
          return "secondary";
          break;
        case "ReadyToBeSeen":
          return "#E07C7D";
          break;
        case "Tentative":
          return "#C7530E";
          break;

        default:
      }
    },

    addAppointments() {
      this.appointment = {};
      this.editApp = false;
      this.add_dialog = true;
    },

    setHeaders() {
      // save headers => this method works when open calendar
      this.popover = false;
    },

    setHeaders2() {
      // save headers => this method works when open calendar
      this.popover2 = false;
    },

    selectAllHeaders() {
      for (const i in this.headers) {
        this.headers[i].selected = this.selected;
        this.patient_reasons = this.selected2;
      }
    },

    selectAllHeadersReasons() {
      this.headers[0].selected = this.patient_reasons;
      this.headers[5].selected = this.patient_reasons;
    },

    selectAllHeaders2() {
      for (const j in this.headers2) {
        this.headers2[j].selected = this.selected2;
        this.patient_reasons2 = this.selected2;
      }
    },

    selectAllHeaders2Reasons() {
      this.headers2[0].selected = this.patient_reasons2;
      this.headers2[5].selected = this.patient_reasons2;
    },

    close(saveAppointment) {
      if (saveAppointment == false) {
        this.appointments.splice(
          this.appointments.indexOf(this.appointment),
          1
        );
      }
      this.delete_dialog = false;
      this.popover = false;
      this.popover2 = false;
      this.info_dialog = false;
      this.add_dialog = false;
      this.filter_dialog = false;
      this.add_note_dialog = false;
      this.room_dialog = false;
      //   this.refresh();
    },
  },
};
</script>

<style scoped>
.st_green {
  border-left: 4px solid green !important;
}
</style>
