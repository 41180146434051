var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"en-card mt-4"},[_c('v-data-table',{staticClass:"elevation-0 en-table-card pb-2",staticStyle:{"border":"none !important"},attrs:{"headers":_vm.headers,"items":_vm.providers,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItems,"items-per-page":5},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("PDF Reports")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"elevation-1 mr-3",attrs:{"fab":"","light":"","small":"","color":"white"},on:{"click":_vm.refresh}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-sync")])],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","dark":"","depressed":"","color":_vm.statusColors(item)}},[_vm._v(_vm._s(_vm.statusName(item)))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","depressed":""}},[_vm._v(_vm._s(_vm.reportType(item)))])]}},{key:"item.fromDate",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formattedDate(item.fromDate))+"\n    ")]}},{key:"item.toDate",fn:function(ref){
var item = ref.item;
return [_vm._v("\n      "+_vm._s(_vm.formattedDate(item.toDate))+"\n    ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [(item.status == 3)?_c('span',[_vm._v("\n        "+_vm._s(_vm._f("moment")(item.created_at,"MM/DD/YYYY - h:mm a"))+"\n      ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-icon',{staticClass:"mr-3 chat-icon",attrs:{"size":"20","color":"primary"},on:{"click":function($event){return _vm.viewPdf(item)}}},[_vm._v("mdi-information")]),_vm._v(" "),_c('img',{attrs:{"width":"20","src":'/assets/excel_icon.svg',"alt":""},on:{"click":function($event){return _vm.downloadXls(item)}}}),_vm._v(" "),(item.status == 3)?_c('v-icon',{staticClass:"ml-3 chat-icon",attrs:{"size":"20","color":"primary"},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v("mdi-download")]):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }