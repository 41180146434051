<template>
  <div>
    <div class="section-wrapper">
      <v-data-table
        id="table"
        :items="noshow_appointment"
        :loading="loading"
        :headers="headers"
      >
        <template v-slot:top>
          <div id="header" class="d-flex px-3 pt-2" color="white">
            <v-toolbar-title>
              <img class="nav-logo" :src="'/assets/icon.png'" alt />
              <!-- <v-btn outlined color="primary" @click="getReports"
                >REFRESH</v-btn
              > -->
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <div class="text-center m-auto">
              <h5>{{ title }}</h5>
              <p>Patient visits recorded as late cancellation or No-Show</p>
              <p>{{ fromDate }} - {{ toDate }}</p>
              <p v-if="totalUnits">
                {{ total }} : <b>{{ totalUnits }}</b>
              </p>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              class="elevation-0 ml-4"
              @click="getNoShowReports()"
              fab
              light
              small
              color="white"
            >
              <v-icon color="primary">mdi-sync</v-icon>
            </v-btn>
            <download-excel :name="`${fileName}.xls`" :data="excelCharges">
              <img class="excel-icon" :src="'/assets/excel_icon.svg'" alt="" />
            </download-excel>
            <div v-if="generatingPdf">
              <v-progress-circular width="2" indeterminate />
            </div>
            <div v-else @click="exportPdf2">
              <img class="excel-icon" :src="'/assets/pdf.svg'" alt="" />
            </div>
          </div>
        </template>
        <template v-slot:item.StartDate="{ item }">
          <p class="mb-0">
            {{ item.StartDate | moment("MM/DD/YYYY") }}
          </p>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import "jspdf-autotable";
import moment from "moment";
import { mapState } from "vuex";
import axios from "axios";
export default {
  props: ["from_date", "to_date", "selectedPartners", "selectedProvider"],
  data: () => ({
    title: "Patient No-Show Appointment Detail",
    total: " Total Patient No Show Appointment",
    noshow_appointment: [],
    generatingPdf: false,
    generatedPdf: false,
    fromDate: null,
    toDate: null,
    loading: false,
    totalUnits: 0,
    headers: [
      { text: "Service Date", value: "StartDate", sortable: false },
      { text: "Patient Name", value: "PatientFullName", sortable: false },
      { text: "Provider Name", value: "provider.name", sortable: false },
      { text: "Reason", value: "AppointmentReason1", sortable: false },
      {
        text: "Default Service Location",
        value: "ServiceLocationName",
        sortable: false,
      },
    ],
  }),
  mounted() {
    if (this.selectedProvider) {
      this.title = "No-Show Provider Patient Detail";
      this.total = " Total No Show Provider Patient";
    }
    if (!this.from_date || !this.to_date) {
      this.$router.go(-1);
    } else {
      //   if (this.selectedPartners) {
      //     if (this.isAdmin) {
      //       this.headers.push({
      //         text: "Default Service Location",
      //         value: "ServiceLocationName1",
      //         sortable: false,
      //       });
      //     }
      //   }
      this.getNoShowReports();
      this.fromDate = this.formattedDate(this.from_date);
      this.toDate = this.formattedDate(this.to_date);
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
    isAdmin() {
      return this.user.account_type == this.$constants.ADMIN;
    },
    excelCharges() {
      if (this.selectedPartners) {
        return this.noshow_appointment.map((e) => {
          return {
            "Service Date": moment(String(e.StartDate)).format("MM/DD/YYYY"),
            "Patient Name": e.PatientFullName,
            "Provider Name": e.provider.name,
            Reason: e.AppointmentReason1,
            // Reason: e.reason ? e.AppointmentReason1 : "",
            "Default Service Location": e.ServiceLocationName,
          };
        });
      } else {
        return this.noshow_appointment.map((e) => {
          return {
            "Service Date": moment(String(e.StartDate)).format("MM/DD/YYYY"),
            "Patient Name": e.PatientFullName,
            "Provider Name": e.provider.name,
            Reason: e.AppointmentReason1,
            // Reason: e.AppointmentReason1,
            "Default Service Location": e.ServiceLocationName,
          };
        });
      }
    },
    fileName() {
      if (this.from_date) {
        return `Encounter_Detail_${this.from_date.replace(/-/g, "")}`;
      }
    },
  },

  methods: {
    formattedDate(date) {
      const list = date.split("-");
      return `${list[1]}/${list[2]}/${list[0]}`;
    },
    getNoShowReports() {
      this.loading = true;
      let url = "";
      const data = new FormData();
      data.append("from_date", this.from_date);
      data.append("to_date", this.to_date);
      if (this.selectedPartners) {
        data.append("selectedPartners", this.selectedPartners);
        url = "/api/get_noshow_Appointment";
      }
      if (this.selectedProvider) {
        data.append("selectedProvider", this.selectedProvider);
        url = "/api/get_noshow_Provider";
      }

      axios
        .post(url, data)
        .then((r) => {
          this.loading = false;
          if (r.data.data) {
            this.noshow_appointment = r.data.data;
            this.totalUnits = that.noshow_appointment.length;
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    exportPdf2() {
      if (this.generatedPdf) {
        this.$store.dispatch(
          "global/snackBarState",
          {
            show: true,
            color: "red",
            message: "Pdf Already Generated !",
          },
          { root: true }
        );
        return false;
      }

      if (
        this.fromDate &&
        this.toDate &&
        // this.totalUnits &&
        this.noshow_appointment.length > 0
      ) {
        this.generatingPdf = true;
        const data = new FormData();
        data.append(
          "type",
          this.selectedPartners ? "No_Show_appointment" : "No_Show_provider"
        );
        data.append("fromDate", this.fromDate);
        data.append("toDate", this.toDate);
        data.append("totalUnits", this.totalUnits);
        data.append("charges", JSON.stringify(this.noshow_appointment));
        this.$store
          .dispatch("global/post", {
            url: "/api/to_pdf",
            body: data,
            headers: { responseType: "blob" },
          })
          .then((response) => {
            this.generatedPdf = true;
            this.generatingPdf = false;
            this.$store.dispatch(
              "global/snackBarState",
              {
                show: true,
                color: "primary",
                message: "Processing, check Reports > Dashboard shortly",
              },
              { root: true }
            );
            // console.log(response.data);
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement("a");
            // link.href = url;
            // link.setAttribute("download", `${this.fileName}.pdf`);
            // document.body.appendChild(link);
            // link.click();
          })
          .catch((e) => {
            this.generatingPdf = false;
          });
      }
    },
  },
};
</script>

<style></style>
