<template>
  <div>
    <v-container>
      <v-card-actions class="py-0 mb-1">
        <v-breadcrumbs class="pl-0" :items="bread"></v-breadcrumbs>
      </v-card-actions>

      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="primary"
        show-arrows>
        <v-tab v-for="item in items" :key="item">{{ item }}</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item v-if="user.account_type!=3"
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <All />
        </v-tab-item>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <Providers />
        </v-tab-item>
        <v-tab-item
        v-if="user.account_type!=3"
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <Partners />


        </v-tab-item>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <ETHOffice />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import All from "./users_tabs/All";
import Providers from "./users_tabs/Providers";
import Partners from "./users_tabs/Partners";
import ETHOffice from "./users_tabs/ETHOffice";
import { mapState } from 'vuex';

export default {
  components: {
    All,
    Providers,
    Partners,
    ETHOffice,
  },
  data: () => ({
    bread: [
      {
        text: "Dashboard",
        disabled: false,
        to: "/dashboard",
      },
      {
        text: "Users",
        disabled: true,
        to: "",
      },
    ],
    items: ["All", "Providers", "Partners", "ETH Office"],
  }),

created(){
    if(this.user.account_type ==3)
    {
        this.items.splice(0,1);
        this.items.splice(1,1);
    }
},
  computed: {
      ...mapState({
              user: (state) => state.currentUser.user,
      }),
    tab: {
      get: function () {
        return this.$store.state.global.tabs.usersTab;
      },
      set: function (value) {
        var setTab = {
          type: 1,
          value: value,
        };
        this.$store.commit("global/UPDATE_TABS", setTab);
      },
    },
  },
};
</script>


<style scoped>
>>> .v-window__container {
  background: var(--back-color) !important;
}
</style>
