const chatMemberList = [
    // {
    //     id: 1,
    //     name: 'chat.tabs.chats.recent.userslist.user1.name',
    //     message: "chat.tabs.chats.recent.userslist.user1.message",
    //     status: 'online',
    //     time: 'chat.tabs.chats.recent.userslist.user1.time'
    // },
    // {
    //     id: 2,
    //     name: 'chat.tabs.chats.recent.userslist.user2.name',
    //     message: "chat.tabs.chats.recent.userslist.user2.message",
    //     status: 'away',
    //     time: 'chat.tabs.chats.recent.userslist.user2.time'
    // },
    // {
    //     id: 3,
    //     name: 'chat.tabs.chats.recent.userslist.user3.name',
    //     message: "chat.tabs.chats.recent.userslist.user3.message",
    //     time: 'chat.tabs.chats.recent.userslist.user3.time'
    // },
    // {
    //     id: 4,
    //     name: 'chat.tabs.chats.recent.userslist.user4.name',
    //     message: "chat.tabs.chats.recent.userslist.user4.message",
    //     status: 'online',
    //     time: 'chat.tabs.chats.recent.userslist.user4.time'
    // },
    // {
    //     id: 5,
    //     name: 'chat.tabs.chats.recent.userslist.user5.name',
    //     message: "chat.tabs.chats.recent.userslist.user5.message",
    //     time: 'chat.tabs.chats.recent.userslist.user5.time'
    // },
    // {
    //     id: 6,
    //     name: 'chat.tabs.chats.recent.userslist.user6.name',
    //     message: "chat.tabs.chats.recent.userslist.user6.message",
    //     status: 'away',
    //     time: 'chat.tabs.chats.recent.userslist.user6.time'
    // },
    // {
    //     id: 7,
    //     name: 'chat.tabs.chats.recent.userslist.user7.name',
    //     message: "chat.tabs.chats.recent.userslist.user7.message",
    //     status: 'online',
    //     time: 'chat.tabs.chats.recent.userslist.user7.time'
    // },
    // {
    //     id: 8,
    //     name: 'chat.tabs.chats.recent.userslist.user8.name',
    //     message: "chat.tabs.chats.recent.userslist.user8.message",
    //     status: 'online',
    //     time: 'chat.tabs.chats.recent.userslist.user8.time'
    // },
    // {
    //     id: 9,
    //     name: 'chat.tabs.chats.recent.userslist.user9.name',
    //     message: "chat.tabs.chats.recent.userslist.user9.message",
    //     status: 'away',
    //     time: 'chat.tabs.chats.recent.userslist.user9.time'
    // },
    // {
    //     id: 10,
    //     name: 'chat.tabs.chats.recent.userslist.user10.name',
    //     message: "chat.tabs.chats.recent.userslist.user10.message",
    //     status: 'online',
    //     time: 'chat.tabs.chats.recent.userslist.user10.time'
    // },
    // {
    //     id: 11,
    //     name: 'chat.tabs.chats.recent.userslist.user11.name',
    //     message: "chat.tabs.chats.recent.userslist.user11.message",
    //     status: 'away',
    //     time: 'chat.tabs.chats.recent.userslist.user11.time'
    // },
    // {
    //     id: 12,
    //     name: 'chat.tabs.chats.recent.userslist.user12.name',
    //     message: "chat.tabs.chats.recent.userslist.user12.message",
    //     status: 'online',
    //     time: 'chat.tabs.chats.recent.userslist.user12.time'
    // },
];

const chatMessages = [
    // {
    //     id: 1,
    //     name: 'Doris Brown',
    //     content: 'Good morning',
    //     time: '10:00'
    // },
    // {
    //     id: 2,
    //     name: 'Patricia Smith',
    //     content: 'Good morning, How are you? What about our next meeting?',
    //     time: '10:02',
    //     align: 'right'
    // }
];

const profileDetail =
{
    name: 'Patricia Smith',
    email: 'adc@123.com',
    location: 'California, USA'
};

const contacts = [
    // { character: "chat.tabs.contacts.list.A.text" },
    // { id: 1, name: "chat.tabs.contacts.list.A.name" },
    // { id: 2, name: "chat.tabs.contacts.list.A.name2" },
    // { character: "chat.tabs.contacts.list.C.text" },
    // { id: 3, name: "chat.tabs.contacts.list.C.name" },
    // { character: "chat.tabs.contacts.list.D.text" },
    // { id: 4, name: "chat.tabs.contacts.list.D.name" },
    // { id: 5, name: "chat.tabs.contacts.list.D.name2" },
    // { character: "chat.tabs.contacts.list.H.text" },
    // { id: 6, name: "chat.tabs.contacts.list.H.name" },
    // { character: "chat.tabs.contacts.list.I.text" },
    // { id: 7, name: "chat.tabs.contacts.list.I.name" },
    // { character: "chat.tabs.contacts.list.J.text" },
    // { id: 8, name: "chat.tabs.contacts.list.J.name" },
    // { id: 9, name: "chat.tabs.contacts.list.J.name2" },
    // { id: 10, name: "chat.tabs.contacts.list.J.name3" },
    // { character: "chat.tabs.contacts.list.M.text" },
    // { id: 11, name: "chat.tabs.contacts.list.M.name" },
    // { id: 12, name: "chat.tabs.contacts.list.M.name2" },
    // { id: 13, name: "chat.tabs.contacts.list.M.name3" },
    // { character: "chat.tabs.contacts.list.O.text" },
    // { id: 14, name: "chat.tabs.contacts.list.M.name" },
    // { character: "chat.tabs.contacts.list.P.text" },
    // { id: 15, name: "chat.tabs.contacts.list.P.name" },
    // { id: 16, name: "chat.tabs.contacts.list.P.name2" },
    // { character: "chat.tabs.contacts.list.R.text" },
    // { id: 17, name: "chat.tabs.contacts.list.R.name" },
    // { character: "chat.tabs.contacts.list.S.text" },
    // { id: 18, name: "chat.tabs.contacts.list.S.name" },
    // { id: 19, name: "chat.tabs.contacts.list.S.name2" },
    // { id: 20, name: "chat.tabs.contacts.list.S.name3" },
];

const groups = [
    // {
    //     id: 1, name: 'chat.tabs.groups.list.general'
    // }, {
    //     id: 2, name: 'chat.tabs.groups.list.reporting'
    // }, {
    //     id: 3, name: 'chat.tabs.groups.list.designers's
    // }, {
    //     id: 4, name: 'chat.tabs.groups.list.developer'
    // }, {
    //     id: 5, name: 'chat.tabs.groups.list.projectalpha'
    // }, {
    //     id: 6, name: 'chat.tabs.groups.list.snacks',
    // }
]

export { chatMemberList, chatMessages, profileDetail, contacts, groups };
