<template>
  <v-app>
    <div class="admin-login">
      <div class="login-card">
        <div class="text-center">
          <img :src="'/assets/icon.png'" alt />

          <h4>Forgot Password</h4>
          <p class="mb-8">Confirm your email and we'll send the instructions.</p>
        </div>

        <v-text-field
          label="Email"
          v-model="email"
          :error-messages="emailErrors"
          @input="$v.email.$touch()"
          @blur="$v.email.$touch()"
          outlined
        ></v-text-field>

        <div class="d-flex justify-content-between mt-5">
          <v-btn depressed block large color="primary" @click="submit()">Submit</v-btn>
        </div>

        <div class="text-end mt-4">
          <v-btn :to="{name: 'login'}" small color="primary" text depressed>Go back to Login</v-btn>
        </div>
      </div>

      <v-snackbar :timeout="3000" :color="snackbar.color" top right v-model="snackbar.show">
        {{ snackbar.message }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>


<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  data: () => ({
    showPass: false,
    email: "",

    snackbar: {
      show: false,
      message: "",
      color: "",
    },
  }),

  validations: {
    email: { required, email },
  },

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
  },

  methods: {
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        this.snackbar = {
          message: "Email field is required.",
          color: "red",
          show: true,
        };
      } else {
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style scoped>
/* Mobile devices */
@media screen and (max-width: 480px) {
  >>> .v-snack__wrapper {
    min-width: 285px !important;
  }
}
</style>