<template>
  <v-col md="9" sm="7" cols="12">
    <!-- {{participant.readIndex}} -->
    <!-- <p v-show="false">{{newMain}}</p> -->
    <v-card class="en-chat-cards">
      <div class="chat-header">
        <v-row class="mx-0">
          <v-col md="9" cols="9" class="d-flex align-center pl-0 py-0">
            <v-badge
              bordered
              bottom
              :color="participant.color"
              dot
              offset-x="12"
              offset-y="12"
              class="mr-4 ma-3"
              :value="participant.color == '#00cc00'"
            >
              <v-avatar size="45">
                <v-img
                  v-if="participant.smallImage"
                  :src="participant.smallImage"
                ></v-img>
                <img v-else :src="'/assets/plaeholder-user.png'" />
              </v-avatar>
            </v-badge>

            <div>
              <h3 class>{{ participant.name }}</h3>
              <!-- <p class="en-reply-status en-reply-status-desk">
                {{ currentTime }}
              </p> -->
            </div>
          </v-col>

          <v-col md="3" cols="3" class="d-flex justify-end align-center py-0">
            <v-btn
              class="user-list-sidebar-drawer-toggle"
              elevation="1"
              fab
              x-small
              color="white"
              @click="$emit('drawer-click')"
            >
              <v-icon color="primary">mdi-contacts</v-icon>
            </v-btn>

            <v-btn
              @click="addForVideoCall(participant)"
              class="ml-3"
              elevation="1"
              fab
              x-small
              color="white"
            >
              <v-icon color="primary">mdi-video</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!-- <p class="en-reply-status en-reply-status-mobile">{{ currentTime }}</p> -->
      </div>

      <v-divider></v-divider>

      <!-- Message Area -->
      <div class="en-message-area scroll" ref="chat_area">
        <div v-show="isTyping" class="pb-9">
          <div class="is-typing">
            <div class="jump1"></div>
            <div class="jump2"></div>
            <div class="jump3"></div>
          </div>
        </div>

        <Bubble
          v-for="(item, i) in reverse(messages)"
          :key="i"
          :message="item"
          :isRead="participant.lastConsumedMessageIndex >= messages.length - i"
        >
        </Bubble>
        <v-btn
          @click="loadMoreMessages"
          text
          small
          v-if="participant.messagePage && participant.messagePage.hasPrevPage"
          >Load more</v-btn
        >

        <!-- Showing when start new conversation -->
        <div
          v-if="messages.length == 0 && loadingMessages == false"
          class="new-conversation"
        >
          <img :src="'/assets/conversation.svg'" alt="" />
          <p>Start Conversation with</p>
          <h3>{{ participant.name }}</h3>
        </div>

        <!-- Showing when no chat loaded -->
        <div
          v-if="loadingMessages"
          class="d-flex justify-center align-center"
          style="width: 100%; height: 100%"
        >
          <v-progress-circular
            size="40"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>

      <v-divider></v-divider>
      <v-alert v-if="file !== null" dense color="primary" dark icon="mdi-file">
        <v-row align="center">
          <v-col class="grow">
            {{ file.name }}
          </v-col>
          <v-col class="shrink">
            <v-icon v-if="!sendingMessage" @click="file = null">
              mdi-close
            </v-icon>

            <v-progress-circular
              v-if="sendingMessage"
              size="30"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert v-model="showError" dense color="red" dark icon="mdi-file">
        <v-row align="center">
          <v-col class="grow">
            {{ errorMessage }}
          </v-col>
          <v-col class="shrink">
            <v-icon @click="showError = false"> mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-alert>

      <div class="mt-3 mb-2 d-flex align-center">
        <v-file-input id="fileInput" v-show="false" v-model="file">
        </v-file-input>
        <v-icon class="mr-3" size="25" @click="pickFile">mdi-paperclip</v-icon>
        <!-- <v-text-field
          hide-details
          v-model="messageText"
          @input="setTyping"
          label="Write a message..."
          solo-inverted
          flat
          @keyup.enter="sendMessage()"
          ref="searchbar"
        ></v-text-field> -->

        <v-textarea
          v-model="messageText"
          @input="setTyping"
          hide-details
          placeholder="Write a message..."
          solo
          flat
          ref="searchbar"
          rows="2"
        >
        </v-textarea>

        <v-btn
          large
          class="ml-3 send-icon-desk"
          color="primary"
          @click="sendMessage"
        >
          Send
          <v-icon right>mdi-send</v-icon>
        </v-btn>
        <v-btn
          elevation="1"
          fab
          small
          color="primary"
          @click="sendMessage"
          class="send-icon-mobile ml-3"
        >
          <v-icon color="white">mdi-send</v-icon>
        </v-btn>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import Bubble from "./Bubble";
import moment from "moment-timezone";

import Axios from "axios";
import { mapState } from "vuex";
import Vue from "vue";
export default {
  components: {
    Bubble,
  },
  data: () => ({
    showError: false,
    errorMessage: "",
    timer: {},
    destroyed: true,
    currentTime: "",
    file: null,
    loading: false,
    newMessagesCount: 0,
    messageText: "",
    channelName: "",
    messages: [],
    channel: {},
    sendingMessage: false,
    loadingMessages: true,
  }),
  props: ["participant", "drawer-click"],
  computed: {
    newMain() {
      this.$forceUpdate();
      return this.participant.email;
    },
    isTyping() {
      return this.participant.typing;
    },
    ...mapState({
      client: (state) => state.chat.client,
      user: (state) => state.currentUser.user,
    }),
  },

  destroyed() {
    this.messages = [];
    this.destroyed = true;
    // console.log(" >>>>>>>>>>>>>>>>>> DESTROYED CALLED");
  },

  mounted() {
    this.destroyed = false;
    this.channelName = this.makeChannelName(
      this.user.email,
      this.participant.email
    );
    //  alert(this.participant.email)
    // alert(this.participant.timezone);
    if (this.participant.timezone) {
      var timezone = moment.tz(moment(), moment.tz.guess());
      this.currentTime = timezone
        .clone()
        .tz(this.participant.timezone)
        .format("LLLL");
    } else {
      this.$store
        .dispatch("themes/getAllGeneralSetting")
        .then((response) => {
          if (response.default_timezon) {
            var timezone = moment.tz(moment(), moment.tz.guess());
            this.currentTime = timezone
              .clone()
              .tz(response.default_timezon)
              .format("LLLL");
          } else {
            var timezone = moment.tz(moment(), moment.tz.guess());
            this.currentTime = timezone
              .clone()
              .tz("America/Chicago")
              .format("LLLL");
          }
        })
        .catch((error) => {});
    }

    // this.scrollToEnd();
    this.participant.readIndex = (this.participant.messages?.length ?? 1) - 1;
    //   this.$nextTick().then(r => {
    //    Vue.nextTick(()=>{
    //    setTimeout(() => {
    this.setupChatClient();
    //    }, 200);
    //    })
    // })
  },

  //   updated() {
  //     this.$nextTick(() => this.scrollToEnd());
  //   },

  watch: {
    participant() {
      //   alert("From watch:= >"+this.participant.email)
      console.log(this.participant.messages);
      this.$nextTick().then((r) => {
        this.setupChatClient();
      });
    },
    user() {
      console.log("user changed");
    },
  },

  methods: {
    reverse(array) {
      return array.map((item, idx) => array[array.length - 1 - idx]);
    },
    async loadMoreMessages() {
      let prev = await this.participant.messagePage.prevPage();
      this.messagesLoaded(prev.items, false);
      this.participant.messagePage = prev;
    },
    setTyping() {
      this.participant.channel?.typing();
    },
    makeVideoChatId(item) {
      let id;
      if (item.id > this.user.id) id = `${item.id}_${this.user.id}`;
      else id = `${this.user.id}_${item.id}`;
      const hash = id.split("").reduce(function (a, b) {
        a = (a << 5) - a + b.charCodeAt(0);
        return a & a;
      }, 0);
      return hash;
    },

    addForVideoCall(item) {
      const roomId = this.makeVideoChatId(item);

      this.$store.dispatch("users/addUserToCall", {
        sendTo: item.id,
        roomId: roomId,
        mail: item.email,
        name: item.name ? item.name : "",
      });
    },

    filePicked(files) {
      this.file = files[0];
    },

    pickFile() {
      document.getElementById("fileInput").click();
    },

    makeChannelName(mail1, mail2) {
      return (
        process.env.MIX_CURRENT_SERVER +
        "-" +
        (mail1 > mail2 ? mail1 + mail2 : mail2 + mail1)
      );
    },

    async setupChatClient() {
      this.messages = [];
      console.log(
        "IN SET UP CHAT CLIENT---------_> ",
        this.participant.messages
      );
      // this.$nextTick(() => {
      // alert("loading messages for "+this.participant.email);
      // });
      console.log("PARTICIPANT IN MESSAGE AREA", this.participant);
      if (!this.participant.channel) {
        this.participant = await this.$store.dispatch(
          "chat/addUserFromUser",
          this.participant
        );
      }

      this.messagesLoaded(this.participant.messages);
      this.participant.channel?.on("messageAdded", this.messageAdded);
    },

    async getFileUrl(sid) {
      const data = new FormData();
      data.append("file", sid);
      try {
        return (await Axios.post("/api/get_file_url", data)).data;
      } catch (error) {
        this.errorMessage = "File not found";
        this.showError = true;
        throw error;
      }
    },

    async messagesLoaded(messages, scroll = true) {
      if (this.messages.length == 0)
        this.messages = [...messages.map(this.mapMessage)];
      else this.messages.splice(0, 0, ...messages.map(this.mapMessage));
      //   this.messages = [...messages.map(this.mapMessage),this.messages];
      this.loadingMessages = false;
      if (this.participant.channel) {
        await this.participant.channel?.setAllMessagesConsumed();
        await Vue.nextTick();

        this.$nextTick(() => {
          this.$store.commit("chat/unreadCount");
        });
      }
      //   this.getCount();
      if (scroll) this.scrollToEnd();
    },

    // getCount() {
    //   var members = this.participant.channel?.getMembers();
    //   // for each member, set up a listener for when the member is updated
    //   members.then(function (currentMembers) {
    //     currentMembers.forEach(function (member) {
    //       // handle the read status information for this member
    //       // note this method would use the provided information to render
    //       // this to the user in some way.

    //         console.log('last read message for '+member.identity,member.lastConsumedMessageIndex);

    //     //   updateMemberMessageReadStatus(
    //     //     member.identity,
    //     //     member.lastConsumedMessageIndex,
    //     //     member.lastConsumptionTimestamp
    //     //   );
    //     });
    //   });
    // },

    messageAdded(message) {
      this.messages.push(this.mapMessage(message));
      clearTimeout(this.timer);
      this.timer = setTimeout(async () => {
        if (!this.destroyed) {
          this.participant.readIndex =
            (this.participant.messages?.length ?? 1) - 1;

          await this.participant.channel?.setAllMessagesConsumed();
        }
        this.$emit("unhideChat", message.author);
      }, 500);

      this.$nextTick(() => {
        this.scrollToEnd();
        this.$emit("messageSent", this.participant.email);
      });
    },

    isJson(str) {
      try {
        console.log("to parse => ", str);
        let p = JSON.parse(str);
        console.log(p.type === "file");
        return p.type ? true : false;
      } catch (error) {
        return false;
      }
    },

    mapMessage(message) {
      // console.log('message in map message',message.timestamp);
      const isJson = this.isJson(message.body ?? "");
      if (isJson) {
        console.log("MESSAGE => ", message);
        let m = JSON.parse(message.body ?? "");
        return {
          author: message.author == this.user.email ? "me" : message.author,
          type: "file",
          id: message.sid,
          meta: message.dateCreated,
          data: {
            file: {
              name: m.name,
              uuid: m.sid,
              size: m.size ?? 0,
              type: m.type ?? "document",
            },
          },
        };
      }
      return {
        author: message.author == this.user.email ? "me" : message.author,
        type: message.type,
        id: message.sid,
        isEdited: false,
        meta: message.dateCreated,
        data: {
          text: message.body ?? "",
        },
      };
    },

    async sendMessage() {
      this.$refs.searchbar.focus();

      this.sendingMessage = true;
      if (this.file) {
        await this.messageSent({
          type: "file",
          file: this.file,
        });
      }

      this.messageSent({
        type: "text",
        text: this.messageText,
      });
    },

    async messageSent(message) {
      console.log("Sending Message");
      console.log(message);

      if (message.type === "text") {
        if (message.text.length > 0) {
          await this.participant.channel?.sendMessage(message.text);
          this.messageText = "";
        }
      }

      if (message.type === "file") {
        const formData = new FormData();
        formData.append("file", message.file, message.file.name);
        formData.append("channel", this.channelName);
        try {
          const sid = (await Axios.post("/api/upload_file", formData)).data;
          const obj = {
            sid: sid.id,
            type: sid.aggregate_type,
            size: sid.size,
            name: message.file.name,
          };
          const msg = JSON.stringify(obj);
          this.file = null;
          await this.participant.channel?.sendMessage(msg);
        } catch (error) {}
      }

      this.sendingMessage = false;
    },

    scrollToEnd() {
      var content = this.$refs.chat_area;
      if (content) content.scrollTop = content.scrollHeight;
      console.log("Called scroll to end");
    },
  },
};
</script>

<style scoped>
/* IMportant do not remove */
>>> textarea {
  background-color: #e3e5e8 !important;
}
</style>
