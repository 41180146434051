<template>
  <div :class="me ? 'en-message-item en-outgoing-message' : 'en-message-item'">
    <!-- <div class="en-message-avatar">
      <v-avatar class="mr-3" size="35">
        <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img>
      </v-avatar>
      <div>
        <p>{{ user_name }}</p>
        <p class="en-time-sidebar">{{ send_time }}</p>
      </div>
    </div> -->

    <p
      v-if="message.type == 'text'"
       style="white-space: pre-line"
      :class="me ? 'outgoing-bubble' : 'incoming-bubble'"
    >
      {{ message.data.text }}
    </p>

    <!-- <p
      v-else-if="message.type == 'file'"
      :class="me ? 'outgoing-bubble' : 'incoming-bubble'"
    >
      {{ message.data.file.name }}
    </p> -->

    <div
      v-else-if="message.type == 'file'"
      :class="me ?
       'en-message-file align-start en-outgoing-file-content' :
       'en-message-file align-start en-message-file-content'">
            <v-icon :color="me ? 'white' : 'black'">mdi-file</v-icon>
      <div>
        <p>
          {{ message.data.file.name }}
          <span class="en-time-sidebar">({{ getSize(message.data.file.size) }}Kb)</span>
        </p>
        <v-btn
          @click="getFileUrl(message.data.file.uuid, true)"
          text
          small
          :color="me ? 'white' : 'black'">
          {{ me ? "View" : "Download" }}
          </v-btn>
      </div>
    </div>
    <small class="time-stamp" v-if="message.meta"> {{ timeStamp(message.meta) }}</small>
    <v-icon v-if="me && isRead" small color="#34B7F1">mdi-check-all</v-icon>
       <!-- <img @click="overlay = !overlay" :src="image_url" class="en-message-img" alt />
       <v-overlay :opacity="opacity" :value="overlay">
         <div class="d-inline-flex">
           <img :src="image_url" @click="overlay = !overlay" class="en-view-img" alt />
           <v-btn class="ml-4" icon @click="overlay = false">
             <v-icon size="40">mdi-close</v-icon>
           </v-btn>
         </div>
       </v-overlay> -->
     </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment-timezone";
export default {
  props: ["message",'isRead'],
  computed: {
    ...mapState({
      user: (state) => state.currentUser.user,
    }),
    me() {
      return this.message.author === "me";
    },
  },

  created() {
    if (this.message.type === "file")
      if (this.message.data.file.type == "image") {
        this.getFileUrl(this.message.data.file.uuid).then((r) => {
          this.image_url = r;
        });
      }
  },
  data: () => ({
    isImage: true,
    user_name: "John Doe",
    send_time: "11:30 am",
    file_name: "important_documents.pdf",
    file_size: "(50KB)",
    image_url:
      "https://images.pexels.com/photos/907489/pexels-photo-907489.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    overlay: false,
    opacity: 0.75,
  }),
  methods: {
      timeStamp(meta){
          return moment(meta).format('llll')
      },
    getSize(bytes) {
      return (bytes / 1024).toFixed(1);
    },
    async getFileUrl(sid, download = false) {
      console.log("UUID -------------------------------------> ", sid);
      const data = new FormData();
      data.append("uuid", sid);
      data.append("download", 0);
      const url = (await axios.post("/api/get_file_url", data)).data;
      if (download) window.open(url);
      else return url;
    },
  },
};
</script>
